import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { BptDateTimeComponent } from 'bpt-ui-library/bpt-datetime';
import { ColumnDefinition, ColumnSelectionService, ColumnType, DropdownCellEditorParamsDefaults } from 'bpt-ui-library/bpt-grid';
import { ClientValidationDetails } from 'model/client-validation-details';
import { Subscription } from 'rxjs';
import {
  ColumnSpecification,
  Unit,
  UnitList,
  UserPicklistResponse,
  ColumnType as ModelColumnType,
  TimeSelectOption,
  KeyColumnType
} from '../../api/models';
import {
  ColumnInfoItems,
  getColumnInfoItem,
  ServerColumnInfoItems,
  TableConstants
} from '../model/column-info-items';
import { ColumnInfo, ElnColumnType, MaskColumnType } from '../model/column-info.interface';
import {
  ColumnDefinitionMaskFields,
  ColumnDefinitionValueFieldDefaults,
  ExtendedColumnSpecification,
  NewColumnDefinition,
  ReservedFieldNames
} from '../model/new-column-definition';
import {
  ColumnSpecificationDirtyCheckResult,
  ColumnDirtySpecifications,
  ColumnSpecificationDirtyDefinition
} from '../model/column-specification-dirty';
import { ColumnWidthTypes } from '../model/column-width-types';
import { UnitsService, UserPicklistsService } from '../../api/services';
import { guid } from 'model/template.interface';
import { MessageService } from 'primeng/api';
import { BptDropdownComponent, DropdownChangeEvent } from 'bpt-ui-library/bpt-dropdown';
import { SpecTypeDropdownOption, SpecificationService, typesThatRequireUnits } from '../../shared/specification-input/specification.service';
import { SpecType } from '../../api/data-entry/models';
import { NA } from 'bpt-ui-library/shared';
import { PicklistPreviewComponent } from '../shared/picklist-preview/picklist-preview.component';
import { ReservedFieldNamePatterns } from '../shared/reserved-fields';
import { TableDataService } from '../../experiment/data/table/table-data.service';
import { CommonPropertiesComponent, DataType } from '../common-properties/common-properties.component';
import { UserService } from '../../services/user.service';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';

type DateFormatChoice =
  | 'default'
  | 'allowTimeSelect'
  | 'timeRequired'
  | 'timeOptional';

type ValueType = string | number | undefined;

export const keyColumnField = '~keyColumn~';

@Component({
  selector: 'app-column-properties',
  templateUrl: './column-properties.component.html',
  styleUrls: ['./column-properties.component.scss']
})
export class ColumnPropertiesComponent implements OnInit, OnDestroy {
  @HostBinding('class.hidden')
  @Input() hidden = false;
  @Input() readOnlyState = false;
  @Input() canApplyRecommendedSettings: (columnDefinition: ColumnDefinition, columnType: keyof typeof ColumnType) => boolean
    = () => true;
  @Input() validateColumnDefinition: (newColumnDefinition: ColumnDefinition, oldColumnDefinition: ColumnDefinition) => ClientValidationDetails
    = () => new ClientValidationDetails();

  /**
   * Indicates that one or more properties of a column have changed.
   * Note: Using the BptGrid ColumnDefinition type here, which is a different type in a table template.
   *
   * */
  @Output() propertyChange = new EventEmitter<ColumnDefinition>();
  @Output() columnTypeChange = new EventEmitter<ColumnDefinition>();
  @Output() columnSelected = new EventEmitter();
  @Output() listNameChange = new EventEmitter<any>();
  @Output() allowedUnitsChanged = new EventEmitter<{
    column: ColumnDefinition;
    units: { name: string; units: Unit[] }[];
  }>();

  @Input() excludedColumnInfoItems: (selectedColumn: ColumnSpecification) => ColumnInfo[] = () => [];
  columnInfoItems = ColumnInfoItems;

  @Output() resetKeyColumn = new EventEmitter<ColumnDefinition>();

  /** The Properties pane and its child controls: different template for row index vs other columns */
  @ViewChild('form') form: NgForm | undefined = undefined;
  @ViewChild('commonProperties') commonProperties!: CommonPropertiesComponent;
  @ViewChild('bptMaximumDateControl') bptMaximumDateControl!: BptDateTimeComponent;
  @ViewChild('bptMinimumDateControl') bptMinimumDateControl!: BptDateTimeComponent;
  @ViewChild('columnPropertiesContainer') columnPropertiesContainer?: ElementRef;
  @ViewChild('picklist') picklist?: BptDropdownComponent;
  @ViewChild('picklistPreviewButton') picklistPreviewButton?: ElementRef;
  @ViewChild('picklistPreview') picklistPreview?: PicklistPreviewComponent;

  get dataType(): DataType {
    switch (this.columnSpecification?.columnType) {
      case ModelColumnType.Date: return DataType.DateAndTime;
      case ModelColumnType.EditableList: return DataType.DropdownList;
      case ModelColumnType.Index: return DataType.Index;
      case ModelColumnType.Quantity: return DataType.Quantity;
      case ModelColumnType.RowId: return DataType.rowId;
      case ModelColumnType.Specification: return DataType.specification;
      case ModelColumnType.StepNumber: return DataType.stepNumber;
      case ModelColumnType.String: return DataType.textEntry;
    }
    throw new Error('LOGIC ERROR: should be able to determine data type by now but inputs are not set correctly to known used values.');
  }

  showClearTrue = true;
  labelValidation: ClientValidationDetails = new ClientValidationDetails();
  fieldValidation: ClientValidationDetails = new ClientValidationDetails();
  listNameValidation: ClientValidationDetails = new ClientValidationDetails();
  unitsToDisplayValidation: ClientValidationDetails = new ClientValidationDetails();
  columnSpecification?: ColumnSpecification;
  columnInfo?: ColumnInfo;
  serverColumnInfoItems = ServerColumnInfoItems;
  placeholderPicklist = $localize`:@@selectPicklist:Select Picklist`;
  listItems: { name: string; id: guid; isInactive: boolean; items: string[] }[] = [];
  applyUnitList: UnitList[] = [];
  everyUnit: Unit[] = [];
  everyUnitGrouped: { name: string; units: Unit[] }[] = [];
  allowedUnits: { name: string; units: Unit[] }[] = [];
  applyUnitListValue?: Unit[] = undefined;
  keyColumnItems: any[] = [
    {
      name: KeyColumnType.Samples,
      label: $localize`:@@samples:Samples`,
      isSelected: false
    },
    {
      name: KeyColumnType.Materials,
      label: $localize`:@@LabItemsMaterialsTableTitle:Materials`,
      isSelected: false
    },
    {
      name: KeyColumnType.Preparations,
      label: $localize`:@@preparations:Preparations`,
      isSelected: false
    }
  ];

  showTime?: boolean;
  timeRequirement?: TimeSelectOption;
  get includeTimeSelect(): TimeSelectOption | undefined {
    if (!this.columnSpecification) return undefined;

    if (this.columnSpecification.allowTimeSelect !== undefined) {
      return this.getTimeSelectOption(this.columnSpecification.allowTimeSelect);
    }

    this.columnSpecification.allowTimeSelect = this.showTime ? this.timeRequirement : TimeSelectOption.Never;
    return this.columnSpecification.allowTimeSelect;
  }
  set includeTimeSelect(value: TimeSelectOption | undefined) {
    this.timeRequirement = value;
    this.showTime = value !== TimeSelectOption.Never;
    if (!this.columnSpecification) return;

    this.columnSpecification.allowTimeSelect = value;
  }

  get noUnit(): boolean {
    const naUnit = this.everyUnit.find((u) => u.name === NA);
    return (
      this.columnSpecification?.allowedUnits?.length === 1 &&
      naUnit !== undefined &&
      this.allowedUnitIds[0] === naUnit.id &&
      this.columnSpecification.defaultUnit === naUnit.id
    );
  }

  set noUnit(value: boolean) {
    if (!this.columnSpecification) return;
    const naUnit = this.everyUnit.find((u) => u.name === NA);
    if (!naUnit?.id) return;
    if (value) {
      this.setSelectableUnitsFromUnitGuids([naUnit.id]);
      this.columnSpecification.defaultUnit = naUnit.id;
    } else if (this.noUnit) {
      // Only clear out if the previous state was noUnit=true
      this.setSelectableUnitsFromUnitGuids([]);
      this.columnSpecification.defaultUnit = '';
    }
  }

  private _widthValue?: string | number;
  get widthValue(): ValueType {
    return this._widthValue;
  }
  set widthValue(value: ValueType) {
    this._widthValue = value;
    this.setWidthValueToColumnSpecification();
  }

  get allowedUnitIds(): string[] {
    return (this.columnSpecification as ExtendedColumnSpecification).allowedUnitIds;
  }
  set allowedUnitIds(value: string[]) {
    (this.columnSpecification as ExtendedColumnSpecification).allowedUnitIds = value;
    this.validateUnitsToDisplay();
  }

  private _widthType = ColumnWidthTypes.Static;
  get widthType(): string {
    return this._widthType;
  }
  set widthType(value: string) {
    this._widthType = value;
    this.setWidthTypeToColumnSpecification();
  }

  public get allowedSpecificationTypes(): SpecTypeDropdownOption[] {
    return SpecificationService.validTypesForDropdown
  }

  get isUnitPropertiesRequiredBySpecType(): boolean {
    return [SpecType.SingleValue, SpecType.TwoValueRange].some(type => (this.columnSpecification?.allowedSpecTypes ?? []).includes(type));
  }

  /** For Specification type: Returns false if no numeric specs are allowed (e.g. if it's Observation only). */
  get unitsDisabled(): boolean {
    if (this.columnSpecification?.columnType !== ColumnType.specification) return false;

    const allowedSpecTypes = this.columnSpecification?.allowedSpecTypes;
    return !allowedSpecTypes || !allowedSpecTypes.some(t => typesThatRequireUnits.includes(t));
  }

  get isRemovedColumn(): string {
    return TableDataService.isRemovedColumn;
  }

  /** State of column immediately upon switching to it. */
  private columnSpecificationBeforeChange?: ColumnSpecification;
  private selectedColumnDefinitionSubscription?: Subscription;
  private columnSubscriptions: Subscription[] = [];
  /** Column is the row index column, which is a column like any other except intended use limits the properties that can be viewed and none can be changed. */
  public isIndexColumn = false;

  public interceptedColumnSpecification!: Pick<ColumnSpecification, 'columnType'>;

  public get interceptedColumnType(): ElnColumnType {
    if (this.columnSpecification?.isKeyColumn) {
      return MaskColumnType.editableListAsKeyColumn;
    }
    return this.columnSpecification?.columnType as any;
  }
  public set interceptedColumnType(value: ElnColumnType) {
    if (!value) return;

    if (this.columnSpecification) {
      if (value === MaskColumnType.editableListAsKeyColumn) {
        this.applyEditableListKeyColumnSpecifications(this.columnSpecification);
        return;
      }
      if (this.columnSpecification.isKeyColumn) {
        this.resetKeyColumn.emit(this.columnSpecification as ColumnDefinition);
      }
      this.columnSpecification.columnType = value as any;
    }
  }

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }
  constructor(
    private readonly columnSelectionService: ColumnSelectionService,
    private readonly picklistService: UserPicklistsService,
    private readonly messageService: MessageService,
    private readonly unitsService: UnitsService
  ) { }

  /** Some controls, like dropdowns, work better if we don't wait till blur to update
   * their value, but then we get into a little bit of a loop when selecting a column
   * where the property is changed, which causes column definitions to reload,
   * so we'll use this isUpdating flag to indicate when we are updating the column definition.
   * It will be reset once the change detection cycle is done in the setTimeout below.
   * This also helps with loading the component and pre-selecting the width type. */
  private isUpdating = false;

  ngOnInit(): void {
    this.watchColumnSelection();
    this.loadPicklists();
    this.loadUnitsAndUnitLists();
  }

  public scrollToTop() {
    this.columnPropertiesContainer?.nativeElement.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'start'
    });
  }

  /** Starts loading user-picklists */
  loadPicklists() {
    this.picklistService
      .userPicklistsGet$Json({
        availableInDesigners: true
      })
      .subscribe({
        next: picklists => {
          this.listItems = picklists.map((picklist: UserPicklistResponse) => ({
            name: picklist.picklistName,
            id: picklist.id,
            isInactive: !picklist.isActive,
            items: picklist.items
          }));
        },
        error: () => {
          this.listItems = [];
        }
      });
  }

  get picklistPreviewButtonEnabled(): boolean {
    return !this.picklist?.disabled && !!this.columnSpecification?.listSource;
  }

  /** Starts loading units and unitLists */
  loadUnitsAndUnitLists() {
    this.unitsService.unitsListsGet$Json().subscribe(units => {
      this.applyUnitList = units.unitLists;
    });
    this.unitsService.unitsGet$Json().subscribe(units => {
      //I don't love this, but it was a pretty easy way to group everything
      this.everyUnit = units.units;
      this.everyUnitGrouped = [...new Set(units.units.map(unit => unit.dimension))].map(dim => ({
        name: dim,
        units: units.units.filter(unit => unit.dimension === dim)
      }));
      this.setSelectableUnitsFromUnitGuids(this.columnSpecification?.allowedUnits ?? []);
    });
  }

  /**
   * Will be listening to the column selection changes to render
   */
  private watchColumnSelection(): void {
    this.selectedColumnDefinitionSubscription =
      this.columnSelectionService.selectedColumnDefinition$.subscribe((columnDefinition: ColumnDefinition | undefined) => {
        setTimeout(() => { // Wait until events like blur have been resolved, then change the selection
          // First, set the columnSpecification
          if (!this.isColumnAllowedToSetSpecification(columnDefinition)) {
            this.columnSpecification = undefined;
            return;
          }
          this.columnSpecification = columnDefinition as ColumnSpecification;
          this.resetUniqueColumnSpecification(this.columnSpecification.columnType);
          // Then, make adjustments
          this.finalizeColumnInfoItems(columnDefinition as ColumnSpecification);
          this.keyColumnItems.forEach((keyColumnItem) => {
            keyColumnItem.isSelected = this.columnSpecification?.allowedKeyColumnTypes?.includes(keyColumnItem.name) ?? false
          });
          this.columnSpecificationBeforeChange = { ...this.columnSpecification };

          if (this.columnSpecification.columnType === ModelColumnType.Specification) {
            this.columnSpecification.allowedSpecTypes ??= SpecificationService.validTypesForDropdown.map(specType => specType.value);
          }

          this.resetValidations();
          this.scrollToTop();
          this.columnSelected.emit();
          this.setPropertiesForUnitCompatibleColumn(columnDefinition as ColumnDefinition);
          this.isUpdating = true;
          this.setColumnInfo(columnDefinition as ColumnDefinition);
          this.digestColumnSizePreferences(columnDefinition as ColumnDefinition);
          setTimeout(() => {
            this.isUpdating = false;
          });

          this.isIndexColumn = this.columnSpecification.field === TableConstants.rowIndexColumnId;
          this.applyUnitListValue = undefined; // should be cleared often due to its use only as a preset for allowedUnits
        });
      });
  }

  resetUniqueColumnSpecification(columnType: string) {
    if (this.columnSpecification) {
      if (columnType !== ColumnType.quantity && columnType !== ColumnType.specification) {
        this.columnSpecification.showUnitNameInList = false;
      }
      if (columnType !== ColumnType.quantity) {
        this.columnSpecification.allowedUnits = undefined;
        this.columnSpecification.defaultUnit = undefined;
      }
      if (columnType !== ColumnType.specification) {
        this.columnSpecification.allowedSpecTypes = undefined;
      }
      if (columnType !== ColumnType.string) {
        this.columnSpecification.wrapText = undefined;
      }
      if (columnType !== ColumnType.list && columnType !== ColumnType.editableList) {
        this.columnSpecification.activityItemReferenceTypes = undefined;
      }
    }
  }

  private setPropertiesForUnitCompatibleColumn(columnDefinition: ColumnDefinition) {
    if (columnDefinition.columnType !== ColumnType.quantity && columnDefinition.columnType !== ColumnType.specification) return;
    this.setSelectableUnitsFromUnitGuids(this.allowedUnitIds);
    this.allowedUnitsChanged.emit({
      column: this.columnSpecification as ColumnDefinition,
      units: this.allowedUnits
    });
    this.propertyChanged(this.allowedUnits);
  }

  fetchUnitIds(allowedUnits: any[]): string[] {
    return allowedUnits ? allowedUnits.filter(unit => !!unit).map(unit => (this.instanceofUnit(unit) ? unit.id : unit)) : [];
  }

  instanceofUnit(object: any): object is Unit {
    return object.hasOwnProperty('abbreviation');
  }

  private setDefaultColumnProperties() {
    this.resetColumnSubscriptions();
    if (!this.form) return;

    this.columnSubscriptions.push(
      this.form.controls.columnType.valueChanges.subscribe(() => {
        this.columnInfo = getColumnInfoItem(this.form?.controls.columnType.value);
        if (!this.isUpdating) {
          this.recommendedSettings();
        }
        this.resetUniqueColumnSpecification(this.form?.controls.columnType.value);
      })
    );
  }

  private recommendedSettings() {
      if (
        this.columnSpecification &&
        this.columnInfo &&
        (this.columnInfo.columnType === ColumnType.specification ||
          this.columnInfo.columnType === ColumnType.quantity)
      ) {
        this.columnSpecification.showUnitNameInList = true;
      }
      if (
        this.columnSpecification &&
        this.columnInfo &&
        (this.columnInfo.columnType === ColumnType.number ||
          this.columnInfo.columnType === ColumnType.quantity)
      ) {
        this.columnSpecification.allowDecimal = true;
        this.columnSpecification.allowNegative = true;
      }
      if (this.columnInfo?.columnType === ColumnType.editableList) {
        this.setDefaultValuesDropdownListField();
      }
      if (this.columnSpecification && this.columnInfo?.columnType === ColumnType.date) {
        this.columnSpecification.showButtonBar = true;
        this.columnSpecification.allowTimeSelect = this.showTime ? this.timeRequirement : TimeSelectOption.Never;
      }
      if (this.columnSpecification && this.columnInfo?.columnType === ColumnType.specification) {
        this.columnSpecification.allowedSpecTypes ??= SpecificationService.validTypesForDropdown.map(specType => specType.value);
      }
      this.handleWrapTextAndMaxWidthRecommendedSettings();
  }

  private handleWrapTextAndMaxWidthRecommendedSettings() {
    if (this.columnSpecification && this.columnInfo && this.columnInfo.columnType === ColumnType.string) {
      this.columnSpecification.formatNaToUpperCase = true;
      if (!this.isUpdating) {
        this.columnSpecification.maxWidth ??= NewColumnDefinition.maxWidth;
        this.columnSpecification.wrapText ??= true;
      }
    }
    if (this.columnSpecification && this.columnInfo && this.columnInfo.columnType !== ColumnType.string) {
      if (this.columnSpecification.maxWidth === NewColumnDefinition.maxWidth) {
        this.columnSpecification.maxWidth = undefined;
      }
      this.columnSpecification.wrapText = undefined;
    }
  }

  ngOnDestroy(): void {
    this.selectedColumnDefinitionSubscription?.unsubscribe();
  }

  handleDateAndTimeChanges(dateFormatChoice: DateFormatChoice, e: any): void {
    if (!this.columnSpecification) {
      return;
    }
    switch (dateFormatChoice) {
      case 'allowTimeSelect':
      case 'timeRequired':
      case 'timeOptional':
        if (dateFormatChoice === 'allowTimeSelect' && this.showTime) this.timeRequirement = TimeSelectOption.Required;
        this.includeTimeSelect = this.showTime ? this.timeRequirement : TimeSelectOption.Never;
        break;
      default:
        this.includeTimeSelect = TimeSelectOption.Never;
        break;
    }
    this.propertyChanged(e);
  }

  /**
   * Sets recommended settings based on column type before property change event is published
   */
  beforePropertyChangeEventPublished(): void {
    switch (this.columnSpecification?.columnType) {
      case ColumnType.string:
        this.updateStringColumnFeatureFlagsBasedOnSpecification();
        break;
      case ColumnType.number:
      case ColumnType.quantity:
        this.updateNumericColumnFeatureFlagsBasedOnSpecification();
        break;
      case ColumnType.date:
        this.updateDateColumnFeatureFlagsBasedOnSpecification();
        break;
      case ColumnType.editableList:
        this.updateEditableListColumnFeatureFlagsBasedOnSpecification();
        break;
      default:
        break;
    }
    this.resetInvalidSpecificationToDefaults();
  }

  /**
   * Validates Column label with Empty and External validation check
   * changes will be rolled back in case of any validation failures
   */
  validateColumnLabel(label: string): void {
    this.labelValidation.clear();
    this.setValidationMessageWhenPropertyIsEmpty(label, 'Label');
    if (!this.labelValidation.isValid()) {
      this.rollbackColumnChangesOnValidationFailure('Label');
      return;
    }
    this.labelValidation = this.validateColumnDefinition(
      this.columnSpecification as ColumnDefinition,
      this.columnSpecificationBeforeChange as ColumnDefinition
    );
    if (!this.labelValidation.isValid()) {
      this.labelValidation.displayAsInline = true;
      this.rollbackColumnChangesOnValidationFailure('Label');
    } else {
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
      (this.columnSpecificationBeforeChange as ColumnSpecification).label = (
        this.columnSpecification as ColumnSpecification
      ).label;
    }
  }

  getListFromId(id: string): string[] {
    return this.listItems.find(i => i.id === id)?.items ?? [];
  }

  /**
   * Validates Column List Name with Empty and External validation check
   */
  validateListName(): void {
    if (this.columnSpecification?.isKeyColumn) return;

    const listSource = this.columnSpecification?.listSource ?? '';
    this.listNameValidation.clear();
    this.listNameChange.emit({
      column: this.columnSpecification,
      lists: this.getListFromId(listSource)
    });

    // business rule: do not allow an empty picklist if custom options or activity item references are not allowed.
    if (!this.columnSpecification?.allowCustomOptionsForDropdown &&
      (!this.columnSpecification?.activityItemReferenceTypes || this.columnSpecification?.activityItemReferenceTypes?.length === 0)) {
      this.setValidationMessageWhenPropertyIsEmpty(listSource, 'List Name');
    }
    if (!this.listNameValidation.isValid()) {
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
      return;
    }
    this.listNameValidation = this.validateColumnDefinition(
      this.columnSpecification as ColumnDefinition,
      this.columnSpecificationBeforeChange as ColumnDefinition
    );
    if (!this.listNameValidation.isValid()) {
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
    } else {
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
      (this.columnSpecificationBeforeChange as ColumnSpecification).label = (
        this.columnSpecification as ColumnSpecification
      ).label;
    }
  }

  /**
   * Validates field property for empty and duplicate entries
   * changes will be roll backed in case of any validation failure
   */
  validateColumnField(fieldName: string): void {
    this.fieldValidation.clear();
    this.setValidationMessageWhenPropertyIsEmpty(fieldName, 'Field');
    if (!this.fieldValidation.isValid()) {
      this.rollbackColumnChangesOnValidationFailure('Field');
      return;
    }
    this.setValidationMessageIfFieldNameMatchingReservedNames(fieldName, 'Field');
    if (!this.fieldValidation.isValid()) {
      this.rollbackColumnChangesOnValidationFailure('Field');
      return;
    }

    this.setValidationWarningIfFieldNameMatchesPatterns(fieldName);
    if (this.fieldValidation.warnings.length > 0) {
      // no requirement to roll back changes in this case - it's just a warning.
      return;
    }

    this.fieldValidation = this.validateColumnDefinition(
      this.columnSpecification as ColumnDefinition,
      this.columnSpecificationBeforeChange as ColumnDefinition
    );
    if (!this.fieldValidation.isValid()) {
      this.fieldValidation.displayAsInline = true;
      this.rollbackColumnChangesOnValidationFailure('Field');
    } else {
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
      (this.columnSpecificationBeforeChange as ColumnSpecification).field = (
        this.columnSpecification as ColumnSpecification
      ).field;
    }
  }

  get disableEditableInSetup(): boolean {
    if (this.readOnlyState) return true;
    return !this.columnSpecification?.editable;
  }

  get disableQtySetupEditabilitySelections(): boolean {
    if (this.readOnlyState) return true;
    return !this.editableInSetup;
  }

  /** Model value for "Only unit is editable" */
  get quantitySetupUnitOnly(): boolean | undefined {
    if (this.columnSpecification?.columnType !== ColumnType.quantity || !this.editableInSetup) return undefined;
    return !this.columnSpecification.numberEditableInSetup;
  }
  set quantitySetupUnitOnly(value: boolean | undefined) {
    if (this.columnSpecification?.columnType !== ColumnType.quantity || !this.editableInSetup) return;
    this.columnSpecification.numberEditableInSetup = !value;
  }

  /** Model value for "Number & unit are editable" */
  get quantitySetupUnitAndNum(): boolean | undefined {
    return this.quantitySetupUnitOnly === undefined ? undefined : !this.quantitySetupUnitOnly;
  }
  set quantitySetupUnitAndNum(value: boolean | undefined) {
    if (this.columnSpecification?.columnType !== ColumnType.quantity || !this.editableInSetup) return;
    this.columnSpecification.numberEditableInSetup = value;
  }

  /** model value for "Editable During Setup" */
  get editableInSetup(): boolean {
    if (!this.editableInExperiment) return false;
    return !this.columnSpecification?.containsObservableData;
  }
  set editableInSetup(value: boolean) {
    if (!this.columnSpecification) return;
    if (!this.editableInExperiment) {
      this.columnSpecification.containsObservableData = false;
      return;
    }
    this.columnSpecification.containsObservableData = !value;
  }

  /** model value for "Editable In Experiment" */
  get editableInExperiment(): boolean {
    return this.columnSpecification?.editable ?? false;
  }
  set editableInExperiment(value: boolean) {
    if (!this.columnSpecification) return;
    this.columnSpecification.editable = value;

    if (!value && !this.editableInSetup) {
      this.editableInSetup = true;
    }
  }

  /**
  * Callback to resolve complex logic when the editableInSetup property is changed when a given column's setup is not a simple true/false - ex: Quantity.
  *   Of the 3 possibilities for a Quantity column and 2 for other columns,
  *   these are the only combinations (out of 9) for containsObservableData and numberEditableInSetup:
  *
  * Quantity column:
  *     editable in setup:
  *         true:
  *             only unit editable:              containsObservableData=false    numberEditableInSetup=false
  *             both unit and number editable:   containsObservableData=false    numberEditableInSetup=true
  *         false:                               containsObservableData=true     numberEditableInSetup=undefined
  *
  * Other column:
  *     editable in setup:
  *         true:                                containsObservableData=false    numberEditableInSetup=undefined
  *         false:                               containsObservableData=true     numberEditableInSetup=undefined
  *
  * In other words, "containsObservableData" could be thought of as "disabledDuringExperimentSetup"
  */
  editableInSetupChanged(_e: any): void {
    if (!this.columnSpecification) throw new Error('LOGIC ERROR: columnSpecification is falsy.');
    if (
      this.columnSpecification?.columnType !== ColumnType.quantity ||
      this.columnSpecification.numberEditableInSetup !== undefined
    ) {
      this.columnTypeChanged(_e);
      return;
    }
    if (!this.columnSpecification?.containsObservableData) {
      this.columnSpecification.numberEditableInSetup = false; // Only unit is editable
    } else {
      this.columnSpecification.numberEditableInSetup = undefined;
    }
    this.columnTypeChanged(_e);
  }

  propertyChanged(_e: any): void {
    if (!this.isUpdating) {
      this.beforePropertyChangeEventPublished();
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
    }
  }

  columnTypeChanged(_e: any): void {
    if (!this.isUpdating) {
      this.beforePropertyChangeEventPublished();
      this.columnTypeChange.emit(this.columnSpecification as ColumnDefinition);
    }
  }

  allowCustomOptionsForDropdownChanged(_e: any) {
    this.validateListName();
    this.propertyChanged(_e);
  }

  maximumWidthChanged(_e: any): void {
    if (!this.isUpdating && this.columnSpecification) {
      const stringValue = this.columnSpecification?.maxWidth?.toString();
      this.columnSpecification.maxWidth = stringValue ? +stringValue : undefined;
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
    }
  }

  minimumWidthChanged(_e: any): void {
    if (!this.isUpdating && this.columnSpecification) {
      const stringValue = this.columnSpecification?.minWidth?.toString();
      this.columnSpecification.minWidth = stringValue ? +stringValue : undefined;
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
    }
  }

  widthChanged(_e: any): void {
    if (!this.isUpdating) {
      if (this.columnSpecification?.width?.toString() === '') {
        if (this.columnSpecification.minWidth) {
          this.columnSpecification.width = this.columnSpecification.minWidth;
        } else {
          this.columnSpecification.width = 200;
        }
      }
      this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
    }
  }

  wrapTextChanged(_e: any) {
    this.adjustMaxWidthForTextWrapping();
    this.propertyChange.emit(this.columnSpecification as ColumnDefinition);
  }

  /**
   * Handler to Column type change event and sets recommended specifications based on type of the column
   */
  changeColumnType(columnType: ElnColumnType): void {
    if (!columnType) return;

    this.setDefaultColumnProperties();
    if (this.columnSpecification) {
      this.columnSpecification.columnType = this.getEnumKeyByEnumValue(ColumnType, columnType);
      this.resetValidations();
      switch (columnType) {
        case ColumnType.string:
          this.columnSpecification.formatNaToUpperCase = true;
          this.adjustMaxWidthForTextWrapping();
          this.columnTypeChanged(columnType);
          break;
        case ColumnType.editableList:
          this.validateListName(); // moved from column selection so not sure if this goes here
          this.columnTypeChanged(columnType);
          break;
        case ColumnType.date:
          this.columnTypeChanged(columnType);
          break;
        case ColumnType.quantity:
          this.applyUnitListValue = undefined;
          this.allowedUnitIds = [];
          this.columnSpecification.defaultUnit = undefined;
          this.editableInSetupChanged(columnType); // chains to propertyChanged
          break;
        default:
          this.columnTypeChanged(columnType);
      }
    }
  }

  private adjustMaxWidthForTextWrapping() {
    if (!this.isUpdating && this.columnSpecification) {
      if (this.columnSpecification.wrapText && !this.columnSpecification?.maxWidth) {
        this.columnSpecification.maxWidth = NewColumnDefinition.maxWidth;
      }
    }
  }

  private getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): ModelColumnType {
    const keys = Object.keys(myEnum).filter(k => myEnum[k] === enumValue);
    return keys[0] as ModelColumnType;
  }

  private setDefaultValuesDropdownListField(): void {
    if (!this.columnSpecification) {
      return;
    }
    this.columnSpecification.allowNA = true;
    this.columnSpecification.allowCustomOptionsForDropdown = !this.columnSpecification.isKeyColumn;
    this.columnSpecification.enforceColumnUniqueness = this.columnSpecification.isKeyColumn;
    this.columnSpecification.allowMultiSelect = false;
    this.setDefaultSettingsForDropdownListField();
    this.propertyChanged(this.columnSpecification);
  }

  /**
   * Handler to Column resized event and Width type, value will be evaluated based on specifications
   */
  public columnResized(columnSpecificationBeforeChange: ColumnDefinition, width?: number): void {
    if (this.isCurrentSpecificationWidthTypeToBeStatic(columnSpecificationBeforeChange, width)) {
      this.widthType = ColumnWidthTypes.Static;
      this.widthValue = width;
      (this.columnSpecification as ColumnSpecification).width = width;
    } else {
      this.widthType = ColumnWidthTypes.Flex;
      this.setValueAndMarkAsDirty(this.form?.controls.width, this.widthType);
    }
  }

  /**
   * Sets Common Recommended settings to column specifications
   */
  setDefaultSettings() {
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@success:Success`,
      detail: $localize`:@@templateDefaultSettingsApplySuccess:Default settings applied successfully`
    });
    if (this.columnSpecification) {
      this.setValueAndMarkAsDirty(this.form?.controls.containsObservableData, true);
      this.columnSpecification.containsObservableData = true;
      this.widthType = ColumnWidthTypes.Auto;
      this.widthValue = undefined;
      this.setValueAndMarkAsDirty(this.form?.controls.width, this.widthType);
      this.setValueAndMarkAsDirty(this.form?.controls.minWidth, undefined);
      this.setValueAndMarkAsDirty(this.form?.controls.maxWidth, undefined);
      this.setValueAndMarkAsDirty(this.form?.controls.editable, true);
      this.setValueAndMarkAsDirty(this.form?.controls.filter, true);
      this.setValueAndMarkAsDirty(this.form?.controls.sortable, true);
      this.setValueAndMarkAsDirty(this.form?.controls.disableGrouping, false);
      this.setValueAndMarkAsDirty(this.form?.controls.disableHiding, false);
      this.setValueAndMarkAsDirty(this.form?.controls.hidden, false);
      this.setValueAndMarkAsDirty(this.form?.controls.alwaysHidden, false);
      this.setValueAndMarkAsDirty(this.form?.controls.suppressColumnMenu, false);
      this.setValueAndMarkAsDirty(this.form?.controls.suppressContextMenu, false);
      if (this.columnSpecification.columnType === ColumnType.quantity) {
        this.setDefaultSettingsForNumericField();
      }
      if (this.columnSpecification.columnType === ColumnType.editableList) {
        this.setDefaultSettingsForDropdownListField();
      }
      if (this.columnSpecification.isKeyColumn) {
        this.columnSpecification.enforceColumnUniqueness = true;
        this.setValueAndMarkAsDirty(this.form?.controls.enforceColumnUniqueness, true);
        this.columnSpecification.allowedKeyColumnTypes = [];
        this.keyColumnItems.forEach((keyColumnItem) => {
          this.setValueAndMarkAsDirty(this.form?.controls[keyColumnItem.name], false);
        });
      }

      if (this.columnSpecification.columnType === ColumnType.date) {
        this.setValueAndMarkAsDirty(this.form?.controls.showTime, false);
        this.setValueAndMarkAsDirty(this.form?.controls.showButtonBar, true);
      }
      if (this.columnSpecification.columnType === ColumnType.string) {
        this.columnSpecification.maxWidth = NewColumnDefinition.maxWidth;
        if (!this.columnSpecification.wrapText) this.columnSpecification.wrapText = true;
        this.setValueAndMarkAsDirty(this.form?.controls.maxWidth, NewColumnDefinition.maxWidth);
        this.setValueAndMarkAsDirty(this.form?.controls.allowWordWrap, true);
      }
    }
    this.propertyChanged(this.columnSpecification);
  }

  setDefaultSettingsWithoutToast() {
    if (this.columnSpecification) {
      this.setValueAndMarkAsDirty(this.form?.controls.containsObservableData, true);
      this.columnSpecification.containsObservableData = true;
      this.widthType = ColumnWidthTypes.Auto;
      this.widthValue = undefined;
      this.setValueAndMarkAsDirty(this.form?.controls.width, this.widthType);
      this.setValueAndMarkAsDirty(this.form?.controls.minWidth, undefined);
      this.setValueAndMarkAsDirty(this.form?.controls.maxWidth, undefined);
      this.setValueAndMarkAsDirty(this.form?.controls.editable, true);
      this.setValueAndMarkAsDirty(this.form?.controls.filter, true);
      this.setValueAndMarkAsDirty(this.form?.controls.sortable, true);
      this.setValueAndMarkAsDirty(this.form?.controls.disableGrouping, false);
      this.setValueAndMarkAsDirty(this.form?.controls.disableHiding, false);
      this.setValueAndMarkAsDirty(this.form?.controls.hidden, false);
      this.setValueAndMarkAsDirty(this.form?.controls.alwaysHidden, false);
      this.setValueAndMarkAsDirty(this.form?.controls.suppressColumnMenu, false);
      this.setValueAndMarkAsDirty(this.form?.controls.suppressContextMenu, false);
      if (this.columnSpecification.columnType === ColumnType.number) {
        this.setDefaultSettingsForNumericField();
      }
      if (this.columnSpecification.columnType === ColumnType.editableList) {
        this.setDefaultSettingsForDropdownListField();
      }
      if (this.columnSpecification.columnType === ColumnType.string) {
        if (!this.columnSpecification.wrapText) this.columnSpecification.wrapText = true;
        this.setValueAndMarkAsDirty(this.form?.controls.allowWordWrap, true);
      }
    }
    this.propertyChanged(this.columnSpecification);
  }

  minDateSelectionChanged(minDateOptionSelected: boolean): void {
    (this.columnSpecification as ColumnSpecification).minDate = undefined;
    this.setValueAndMarkAsDirty(this.form?.controls.minDate, undefined);
    this.clearMinAndMaxDatesToCalender();
    this.propertyChanged(minDateOptionSelected);
    this.setMinAndMaxDatesLimit();
  }

  maxDateSelectionChanged(maxDateOptionSelected: boolean): void {
    this.setValueAndMarkAsDirty(this.form?.controls.maxDate, undefined);
    this.propertyChanged(maxDateOptionSelected);
    this.setMinAndMaxDatesLimit();
  }

  /**
   * Sets limit to dates of Min, Max date controls
   */
  setMinAndMaxDatesLimit(): void {
    if (!this.bptMaximumDateControl || !this.bptMinimumDateControl) {
      return;
    }
    if (this.form?.controls.minDate && this.form?.controls.minDate.value) {
      const validMinDate = new Date(this.form?.controls.minDate.value);
      if (validMinDate) {
        this.bptMaximumDateControl.minDate = validMinDate?.toString();
        this.bptMinimumDateControl.minDate = validMinDate?.toString();
      }
    }
    if (this.form?.controls.maxDate && this.form?.controls.maxDate.value) {
      const validMaxDate = new Date(this.form?.controls.maxDate.value);
      if (validMaxDate) {
        this.bptMaximumDateControl.maxDate = validMaxDate?.toString();
        this.bptMinimumDateControl.maxDate = validMaxDate?.toString();
      }
    }
  }

  /**
   * Sets Disable hiding related changes
   */
  handleDisableHidingChanges(): void {
    if (this.columnSpecification) {
      this.columnSpecification.alwaysHidden = false;
      this.columnSpecification.hidden = !this.columnSpecification.disableHiding && this.columnSpecification.hidden;
      this.propertyChanged('');
    }
    this.propertyChanged(this.columnSpecification);
  }

  /**
   * Performs the dirty check along with change comparison for dirty fields
   */
  performDirtyCheck(): ColumnSpecificationDirtyCheckResult {
    if (!this.form) {
      return {
        dirty: false,
        isValid: true,
        dirtyFields: {},
        currentModel: this.columnSpecification as ColumnSpecification
      };
    }
    const dirtyFieldsDefinition: ColumnDirtySpecifications = this.getDirtyFieldsDefinition();
    return {
      dirty: Object.keys(dirtyFieldsDefinition).length > 0,
      dirtyFields: dirtyFieldsDefinition,
      isValid:
        this.labelValidation.isValid() &&
        this.listNameValidation.isValid() &&
        this.fieldValidation.isValid() &&
        this.unitsToDisplayValidation.isValid(),
      currentModel: { ...(this.columnSpecification as ColumnSpecification) }
    };
  }

  /**
   * Sets the default values to columns who's values found to be invalid
   */
  private resetInvalidSpecificationToDefaults(): void {
    Object.keys(ColumnDefinitionValueFieldDefaults).forEach((fieldName) => {
      if (
        ColumnDefinitionValueFieldDefaults[fieldName].invalidValue.includes(
          (this.columnSpecification as any)[fieldName]
        )
      ) {
        (this.columnSpecification as any)[fieldName] =
          ColumnDefinitionValueFieldDefaults[fieldName].defaultValue;
      }
    });
  }

  /**
   * Sets the default recommendations for string type column
   */
  private updateStringColumnFeatureFlagsBasedOnSpecification(): void {
    if (!this.columnSpecification) {
      return;
    }
    if (!this.columnSpecification.allowMinCharLength) {
      this.columnSpecification.minCharLength = undefined;
    }
    if (!this.columnSpecification.allowMaxCharLength) {
      this.columnSpecification.maxCharLength = undefined;
    }
    if (!this.columnSpecification.allowMultiLine) {
      this.columnSpecification.allowAutoResize = undefined;
    }
  }

  /**
   * Sets the default recommendations for numeric type column
   */
  private updateNumericColumnFeatureFlagsBasedOnSpecification(): void {
    if (!this.columnSpecification) {
      return;
    }
    if (!this.columnSpecification.allowMinNumericValue) {
      this.columnSpecification.minNumericValue = undefined;
    }
    if (!this.columnSpecification.allowMaxNumericValue) {
      this.columnSpecification.maxNumericValue = undefined;
    }
  }

  /**
   * Sets the default recommendations for date type column
   */
  private updateDateColumnFeatureFlagsBasedOnSpecification(): void {
    if (!this.columnSpecification) {
      return;
    }
    if (!this.columnSpecification.allowMinDate) {
      this.columnSpecification.minDate = undefined;
    }
    if (!this.columnSpecification.allowMaxDate) {
      this.columnSpecification.maxDate = undefined;
    }
  }

  /**
   * Sets the default recommendations for editable list type column
   */
  private updateEditableListColumnFeatureFlagsBasedOnSpecification(): void {
    if (!this.columnSpecification) return;

    this.setNAForDropDown(this.columnSpecification);
    if (this.columnSpecification.allowMultiSelect) this.validateListName();

    this.columnSpecification.showHeader = true;
    this.columnSpecification.showClear = true;
    this.columnSpecification.showToggleAll = true;
  }

  setNAForDropDown(columnSpecification: any) {
    columnSpecification.dropdownEditorConfiguration = {
      ...DropdownCellEditorParamsDefaults,
      labelField: 'label',
      valueField: 'value',
      group: false,
      allowNA: columnSpecification.allowNA,
      options: []
    };
  }

  /**
   * Resets the validations
   */
  private resetValidations(): void {
    this.labelValidation.clear();
    this.fieldValidation.clear();
    this.listNameValidation.clear();
  }

  /**
   * Restores the property back to its original value
   */
  private rollbackColumnChangesOnValidationFailure(property: string): void {
    switch (property) {
      case 'Label':
        (this.columnSpecification as ColumnDefinition).label = this.columnSpecificationBeforeChange
          ?.label as string;
        break;
      case 'Field':
        (this.columnSpecification as ColumnDefinition).field = this.columnSpecificationBeforeChange
          ?.field as string;
        break;
      default:
        break;
    }
  }

  /**
   * Sets the validation message to Column Name when it's empty
   */
  private setValidationMessageWhenPropertyIsEmpty(value: string, property: string): void {
    if (!value || value.trim().length === 0) {
      switch (property) {
        case 'Label':
          this.labelValidation.displayAsInline = true;
          this.labelValidation.errors.push(
            $localize`:@@propertyCannotBeEmpty:${property} cannot be empty`
          );
          break;
        case 'Field':
          this.fieldValidation.displayAsInline = true;
          this.fieldValidation.errors.push(
            $localize`:@@propertyCannotBeEmpty:${property} cannot be empty`
          );
          break;
        case 'List Name':
          this.listNameValidation.displayAsInline = true;
          this.listNameValidation.errors.push(
            $localize`:@@propertyCannotBeEmpty:${property} cannot be empty`
          );
          break;
        default:
          break;
      }
    }
  }

  /**
   * Sets value to form controls and marks to dirty
   */
  private setValueAndMarkAsDirty<T>(control: AbstractControl | undefined, value: T): void {
    if (!control) {
      return;
    }
    if (control.value !== value && (typeof value !== 'undefined' || control.value)) {
      control.markAsDirty();
    }
    control.setValue(value);
    control.updateValueAndValidity();
  }

  /**
   * Prepares dirty definition with verifying the dirty state of controls and its value changes
   * @returns ColumnDirtySpecifications with details like Field and Current Value
   */
  private getDirtyFieldsDefinition(): ColumnDirtySpecifications {
    const dirtyFieldDefinition: { [fieldName: string]: ColumnSpecificationDirtyDefinition } = {};
    Object.keys((this.form as NgForm).controls).some((controlName) => {
      const actualFieldName = ColumnDefinitionMaskFields[controlName] || controlName;
      let dirty =
        this.isControlDirty(actualFieldName) && this.isColumnSpecificationChanged(actualFieldName);
      if (
        this.columnSpecification?.columnType === ColumnType.editableList &&
        typeof this.columnSpecification.listSource !== undefined &&
        this.columnSpecification.listSource
      ) {
        dirty = false;
      }
      if (dirty) {
        dirtyFieldDefinition[actualFieldName] = {
          field: (this.columnSpecification as ColumnSpecification).field,
          currentValue: (this.columnSpecification as any)[actualFieldName]
        };
      }
      return dirty;
    });
    return dirtyFieldDefinition;
  }

  /**
   * Indicates controls dirty state
   */
  private isControlDirty(controlName: string): boolean {
    return this.form?.controls[controlName].dirty as boolean;
  }

  /**
   * Indicates whether specification changed post rendering
   */
  private isColumnSpecificationChanged(propertyName: string): boolean {
    const columnSpecificationBeforeChange = this.columnSpecificationBeforeChange as any;
    const currentColumnSpecification = this.columnSpecification as any;
    return (
      columnSpecificationBeforeChange[propertyName] !== currentColumnSpecification[propertyName] &&
      (typeof currentColumnSpecification[propertyName] !== 'undefined' ||
        columnSpecificationBeforeChange[propertyName])
    );
  }

  /**
   * Sets numeric type field recommended specifications
   */
  private setDefaultSettingsForNumericField(): void {
    if (!this.columnSpecification) {
      return;
    }
    this.setValueAndMarkAsDirty(this.form?.controls.allowNegative, true);
    this.setValueAndMarkAsDirty(this.form?.controls.allowNA, true);
    this.setValueAndMarkAsDirty(this.form?.controls.allowDecimal, true);
  }

  private setDefaultSettingsForDropdownListField(): void {
    if (!this.columnSpecification) {
      return;
    }
    this.setValueAndMarkAsDirty(this.form?.controls.allowNA, true);
    this.setValueAndMarkAsDirty(this.form?.controls.allowCustomOptionsForDropdown, true);
    this.setValueAndMarkAsDirty(this.form?.controls.allowMultiSelect, false);
    this.setValueAndMarkAsDirty(this.form?.controls.activityItemReferenceTypes, undefined);
  }

  /**
   * Sets the column info based on the column type
   */
  private setColumnInfo(columnDefinition: ColumnDefinition) {
    this.columnInfo = getColumnInfoItem(columnDefinition.columnType);
  }

  /**
   * Sets width type, value based on the Width specification like (Auto, Flex and Static) -
   * when column selection is changed
   */
  private digestColumnSizePreferences(columnDefinition: ColumnDefinition) {
    if (columnDefinition) {
      if (
        columnDefinition.width === ColumnWidthTypes.Flex ||
        columnDefinition.width === ColumnWidthTypes.Auto
      ) {
        // It's important we set the backing fields directly here or else the value will alway be blank for some reason
        this._widthType = columnDefinition.width;
        this._widthValue = undefined;
      } else {
        this._widthType = ColumnWidthTypes.Static;
        this._widthValue = columnDefinition.width;
      }
    }
  }

  /**
   * Sets width value and publish the property change event
   */
  private setWidthValueToColumnSpecification(): void {
    if (this.columnSpecification) {
      this.columnSpecification.width = this.widthValue;
      this.propertyChanged(this.widthValue);
    }
  }

  /**
   * Sets width to specifications based on the choice of width like (Auto, Flex and Static)
   */
  private setWidthTypeToColumnSpecification(): void {
    if (this.columnSpecification) {
      if (this.widthType !== ColumnWidthTypes.Static) {
        this.widthValue = undefined;
        this.columnSpecification.width = this.widthType;
      } else {
        this.columnSpecification.width = this.widthValue;
      }
      this.propertyChanged(this.widthType);
    }
  }

  /**
   * Indicates whether selected column is allowed to set the specifications,
   * Systematic fields (like RowId, Index) are not allowed to edit
   */
  private isColumnAllowedToSetSpecification(
    columnDefinition: ColumnDefinition | undefined
  ): boolean {
    if (!columnDefinition) {
      return false;
    }
    return columnDefinition?.columnType !== ColumnType.rowId;
  }

  /**
   * Indicates whether current width type is to be a Static
   */
  private isCurrentSpecificationWidthTypeToBeStatic(
    columnSpecificationBeforeChange: ColumnDefinition,
    width?: number
  ): boolean {
    const widthValueBeforeChange =
      columnSpecificationBeforeChange && (columnSpecificationBeforeChange.width as string);
    if (
      this.columnSpecification &&
      width &&
      widthValueBeforeChange !== ColumnWidthTypes.Auto &&
      widthValueBeforeChange !== ColumnWidthTypes.Flex
    ) {
      return true;
    }
    return false;
  }

  /**
   * Clears dates from date control
   */
  private clearMinAndMaxDatesToCalender(): void {
    this.bptMaximumDateControl['calendar'].value = undefined;
    this.bptMinimumDateControl['calendar'].value = undefined;
  }

  private resetColumnSubscriptions() {
    this.columnSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.columnSubscriptions = [];
  }

  private setValidationMessageIfFieldNameMatchingReservedNames(
    fieldName: string,
    property: string
  ) {
    if (
      !this.isIndexColumn &&
      ReservedFieldNames.some(
        (reservedName) => reservedName.toLowerCase() === fieldName.toLowerCase()
      )
    ) {
      this.fieldValidation.displayAsInline = true;
      this.fieldValidation.errors.push(
        $localize`:@@fieldNameIsRejected:${property} should not be ${fieldName} please enter different name`
      );
    }
  }

  private setValidationWarningIfFieldNameMatchesPatterns(fieldName: string) {
    if (this.isIndexColumn) return;
    ReservedFieldNamePatterns.forEach(pattern => {
      if (pattern.pattern.test(fieldName)) {
        this.fieldValidation.warnings.push(pattern.validationMessage);
      }
    });
    if (this.fieldValidation.warnings.length > 0) {
      this.fieldValidation.displayAsInline = true;
    }
  }

  applyUnitListChanged(e: DropdownChangeEvent) {
    if (!this.columnSpecification) return;
    if (!e.value) return; // Having no list to apply should not clear the units to display; it's not helpful.

    const newSelection = e.value as Unit[];
    this.setSelectableUnitsFromUnitGuids(newSelection.map(unit => unit.id));
    this.allowedUnitsChanged.emit({
      column: this.columnSpecification as ColumnDefinition,
      units: this.allowedUnits
    });
    this.propertyChanged(this.allowedUnits);
  }

  private setSelectableUnitsFromUnitGuids(units: string[]) {
    units = this.fetchUnitIds(units);
    const newlySelectedUnits = this.everyUnit.filter(unit => units.includes(unit.id));
    this.allowedUnits = [...new Set(newlySelectedUnits.map(unit => unit.dimension))].map(
      dim => ({
        name: dim,
        units: newlySelectedUnits.filter(unit => unit.dimension === dim)
      })
    );
    if (this.columnSpecification) {
      this.columnSpecification.allowedUnits = newlySelectedUnits.map(unit => unit.id);
      this.allowedUnitIds = this.columnSpecification?.allowedUnits;
    }
  }

  unitsToDisplayChanged(e: DropdownChangeEvent) {
    this.setSelectableUnitsFromUnitGuids(e.value);
    this.validateUnitsToDisplay();
    this.allowedUnitsChanged.emit({
      column: this.columnSpecification as ColumnDefinition,
      units: this.allowedUnits
    });
    this.propertyChanged(this.allowedUnits);
  }

  validateUnitsToDisplay() {
    this.unitsToDisplayValidation.clear();
    if (this.columnSpecification?.columnType !== 'quantity') return;

    const allowedUnitIds = (this.columnSpecification as ExtendedColumnSpecification).allowedUnitIds;
    if (allowedUnitIds && allowedUnitIds.length > 0) return;

    this.unitsToDisplayValidation.displayAsInline = true;
    this.unitsToDisplayValidation.errors.push(
      $localize`:@@unitsToDisplayCannotBeEmpty:Units to Display cannot be empty`
    );
  }

  editAllowedKeyColumnTypes(event: boolean, keyColumnType: string) {
    if (this.columnSpecification) {
      this.columnSpecification.allowedKeyColumnTypes = this.columnSpecification.allowedKeyColumnTypes ?? [];
      if (event) {
        this.columnSpecification?.allowedKeyColumnTypes?.push(keyColumnType as KeyColumnType)
      } else {
        this.columnSpecification.allowedKeyColumnTypes =
          this.columnSpecification.allowedKeyColumnTypes?.filter(allowedKeyColumnType => allowedKeyColumnType !== keyColumnType as KeyColumnType)
      }
    }
    this.propertyChanged(this.columnSpecification)
  }

  private finalizeColumnInfoItems(currentColumn: ColumnSpecification) {
    const columnTypes = this.excludedColumnInfoItems(currentColumn).map(columnToExclude => columnToExclude.columnType);
    this.columnInfoItems = ColumnInfoItems.filter(columnInfo => !columnTypes.includes(columnInfo.columnType));
    this.includeTimeSelect = this.getTimeSelectOption(currentColumn.allowTimeSelect);
  }

  private getTimeSelectOption(allowTimeSelect: TimeSelectOption | boolean | undefined): TimeSelectOption | undefined {
    switch (allowTimeSelect) {
      case true:
        return TimeSelectOption.Required;
      case false:
        return TimeSelectOption.Never;
      default:
        return allowTimeSelect;
    }
  }

  private applyEditableListKeyColumnSpecifications(columnSpecification: any): void {
    columnSpecification.columnType = ColumnType.editableList as any;
    columnSpecification.listSource = undefined;
    columnSpecification.listValues = [];
    columnSpecification.isKeyColumn = true;
    columnSpecification.field = keyColumnField;
    columnSpecification.enforceColumnUniqueness = columnSpecification.enforceColumnUniqueness ?? true;
    columnSpecification.dropdownEditorConfiguration = {
      ...DropdownCellEditorParamsDefaults,
      labelField: 'label',
      valueField: 'value',
      group: false,
      allowNA: columnSpecification.allowNA,
      options: []
    };
    columnSpecification.allowCustomOptionsForDropdown = false;
    this.propertyChanged(columnSpecification);
  }

  setListSource(listId: string) {
    if (!this.columnSpecification) return;

    this.columnSpecification.listSource = listId;
    this.propertyChanged(this.columnSpecification);
  }

  previewPicklist(_event: MouseEvent) {
    if (!this.picklistPreview) return;
    this.picklistPreview.visible = true;
  }

}
