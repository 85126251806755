import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { User } from '../../../app/api/models';
import { Subject } from 'rxjs';
import { UserService as UserApiService } from '../../api/services/user.service';
import { BuiltComment, BuiltReply, ReplyWithIndex, CommentDetails } from './comment.model';
import { CommentResponse, CommentsResponse, EditThread, InternalCommentReply,
    InternalCommentStatus, ReplyAddedResponse, ReplyEditedResponse, ReplyStatus, ReplyThread,
    ThreadStateChangeResponse, ThreadAddedResponse, ThreadEditedResponse, CommentContextType }
    from '../../api/internal-comment/models';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {
  CommentCollection,
  Comment,
  NewComment,
  CommentEdited,
  NewReply,
} from 'bpt-ui-library/bpt-comments';
import { UserService as CurrentUserService } from 'services/user.service';
import { v4 as uuidV4 } from 'uuid';
import { InternalCommentService } from '../../../app/api/internal-comment/services/internal-comment.service';
import { cloneDeep, first, nth } from 'lodash-es';
import { ExperimentService } from '../services/experiment.service';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { Router } from '@angular/router';
import { InternalCommentHelper } from './internal-comment-helper';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private readonly renderer: Renderer2;

  constructor(private readonly userApiService: UserApiService,
    private readonly currentUserService: CurrentUserService,
    private readonly experimentService: ExperimentService,
    private readonly internalCommentService: InternalCommentService,
    private readonly confirmationService: ConfirmationService,
    private readonly router: Router,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.checkUsersWithRolesHaveValues();
  }
  private readonly commentDetails = new Subject<CommentDetails>();
  private readonly refreshCommentDetails = new Subject<CommentsResponse>();
  private readonly maxCharLength = 1000;
  private readonly disableTabKey = true;
  private readonly constructCommentsAndReplies = {
    CommentThreadPlaceholder: $localize`:@@commentThreadPlaceholder:Add comment or question`,
    ReplyPlaceholder: $localize`:@@ReplyPlaceholder:Reply to above thread..`,
    maxCharLength: this.maxCharLength,
    disableTabKey: this.disableTabKey,
    dateFormat: DateAndInstantFormat.dateTimeToSecond,
    Comments: []
  } as CommentCollection<string>;

  private readonly currentUserPuid = this.currentUserService.currentUser.puid;
  public resolvedComments?: CommentResponse[];
  public loadInternalComments = this.commentDetails.asObservable();
  public internalCommentsResponse = {} as CommentsResponse;
  public refreshInternalComment = this.refreshCommentDetails.asObservable();
  public users: any = this.getAllUsers();
  public internalCommentDefaultDetails: CommentDetails = {} as CommentDetails;
  public hidePrimaryCommentEditor = false;
  public commentIdRefresh = {} as CommentDetails;
  internalCommentData?: CommentDetails;
  internalCommentsSliderClosed = new Subject<boolean>();
  public highLightingInterval: any;
  public setUpDataForInternalComments(nodeId: string, path: string[], contextType: CommentContextType){
    this.internalCommentData = {} as CommentDetails;
    this.internalCommentData.nodeId = nodeId;
    this.internalCommentData.path = path;
    this.internalCommentData.contextType = contextType;
    this.openInternalComments(this.internalCommentData);
  }
public getCurrentExperiment(){
  return this.experimentService.currentExperiment;
}
  public openInternalComments(command: CommentDetails) {
    command.displayLabel = this.getDynamicDisplayLabel(command.path, command.contextType);
    this.commentIdRefresh = command;
    this.commentDetails.next(command);
  }

  private readonly styleClassProperties: { [key: string]: string }  = {
    "rejectButtonStyleClass" : 'eln-standard-popup-button p-button-outlined',
      "acceptButtonStyleClass": 'eln-standard-popup-button',
      "icon": 'pi pi-exclamation-triangle',
  }
  currentUserRole =InternalCommentHelper.CurrentUserRole;

  getAllUsers() {
    if (!this.users) {
      this.userApiService
        .usersActiveUsersPost$Json({
          body: []
        })
        .subscribe({
          next: (userList: User[]) => {
            userList = userList.sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()));
            this.users = userList;
          },
          error: (error) => console.log(error)
        });
    }
  }
  public getUsersWithRequiredRoles( roles:string[]){
    this.userApiService
      .usersActiveUsersPost$Json({
        body:roles
      })
      .subscribe({
        next: (userList: User[]) => {
         userList = userList.sort((a, b) => a.fullName.trim().localeCompare(b.fullName.trim()));
         this.currentUserService.setUsersListBasedOnRoles(first(roles),userList);
      }
    })
  }

  checkUsersWithRolesHaveValues() {
    if(this.currentUserService.usersWithAnalystRoles.length === 0)
       this.getUsersWithRequiredRoles(["ELN Analyst"]);
    if(this.currentUserService.usersWithReviewerRoles.length === 0)
       this.getUsersWithRequiredRoles(["ELN Reviewer"]);
    if(this.currentUserService.usersWithSupervisorRoles.length === 0)
       this.getUsersWithRequiredRoles(["ELN Supervisor"]);

    this.currentUserRole=this.getCommentedByTitle(this.currentUserPuid);
  }
  getInternalComments(): CommentCollection<string> {
    const builtCommentsAndReplies = cloneDeep(this.constructCommentsAndReplies);
    const removedImpactAssessmentIds = this.experimentService.currentExperiment?.instrumentEventImpactAssessmentData
    ?.filter(impactAssessment => impactAssessment.isRemoved)?.map(s=> s.impactAssessmentId);
    (this.internalCommentsResponse?.comments).forEach((comment: CommentResponse) => {
      if (comment.status !== InternalCommentStatus.Removed
        && !comment.path.some(pathName => removedImpactAssessmentIds?.some(removedImpactAssessmentId => removedImpactAssessmentId === pathName))) {
        builtCommentsAndReplies.Comments.push(this.constructCommentResponse(comment));
      }
    });
    builtCommentsAndReplies.Comments = InternalCommentHelper.sortComments(builtCommentsAndReplies);
    return this.retitleSubmittedCommentsHeader(builtCommentsAndReplies);
  }
  getResolvedInternalComments(): CommentResponse[] {
    if (this.internalCommentsResponse?.comments) {
      return this.internalCommentsResponse?.comments?.filter((comment: CommentResponse) => comment.status === InternalCommentStatus.Resolved)
    }
    else {
      return [];
    }
  }

  confirmCommentDelete(event: { item: MenuItem }): void {
    if (event.item.state && event.item.state.context) {
      this.confirmationService.confirm({
        message: $localize`:@@confirmThreadDelete:Are you sure you want to permanently DELETE this Comment Thread?`,
        header: $localize `:@@ConfirmationHeader:Confirmation`,
        icon: InternalCommentHelper.Icons.confirmIcon,
        acceptLabel: $localize`:@@delete:Delete`,
        rejectLabel: $localize`:@@Cancel:Cancel`,
        ...this.styleClassProperties,
        accept: () => {
          this.internalCommentService
            .experimentsExperimentIdInternalCommentsCommentIdDelete$Json({
              experimentId: this.experimentService.currentExperiment!.id,
              commentId: event.item.state!.context.Id
            }).subscribe(
              {
                next: () => {
                  event.item.state!.context.Hidden = true;
                  event.item.state!.refresh();
                  this.updateStatus(InternalCommentStatus.Removed, event.item.state!.context.Id);
                  this.refreshCommentDetails.next(this.internalCommentsResponse);
                  this.openInternalComments(this.commentIdRefresh);
                }
              }
            );
        },
        reject: () => { }
      });
    }
  }
  confirmReplyDelete(event: { item: MenuItem }): void {
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdRepliesIndexReplyIndexDelete$Json({
        experimentId: this.experimentService.currentExperiment!.id,
        commentId: event.item.state!.context.Id,
        replyIndex: event.item.state!.context.ReplyIndex
      }).subscribe(
        {
          next: () => {
            const replies = first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) =>
            comment.commentId === event.item.state!.context.Id))?.replies as InternalCommentReply[];
            first(replies?.filter((reply: InternalCommentReply) => reply.number === event.item.state!.context.ReplyIndex + 1))!.status = ReplyStatus.Removed;
            first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) => comment.commentId === event.item.state!.context.Id))!.replies = replies;
            event.item.state!.context.Hidden = true;
            event.item.state!.refresh();
          }
        }
      );
  }
  getAdditionalActions(puid: string, commentStatus = "") {
    return [
      {
        label: $localize`:@@edit:Edit`,
        command: (e: { item: MenuItem }) => {
          InternalCommentHelper.getEditAction(e);
        },
        visible: InternalCommentHelper.isSameUser(this.currentUserPuid, puid) || this.isReviewer()
      },
      {
        label: $localize`:@@delete:Delete`,
        command: commentStatus === "" ? this.confirmReplyDelete.bind(this)
          : this.confirmCommentDelete.bind(this),
        visible: InternalCommentHelper.isSameUser(this.currentUserPuid, puid) || this.isReviewer()
      },
      {
        label: $localize`:@@Resolve:Resolve`,
        command: (e: { item: MenuItem }) => {
          this.getResolveAction(e);
        },
        visible: this.isReviewer() && commentStatus === InternalCommentStatus.Pending
      },
      {
        label: $localize`:@@Reopen:Reopen`,
        command: (e: { item: MenuItem }) => {
          this.getReopenAction(e);
        },
        visible: this.isReviewer() && commentStatus === InternalCommentStatus.Resolved
      },
      {
        label: $localize`:@@copyCommentLink:Copy Link`,
        tooltip: $localize`:@@copyCommentLink:Copy Link`,
        visible: commentStatus !==""
      },
    ];
  }
  getReopenAction(event: { item: MenuItem; }) {
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdReopenPut$Json({
        experimentId: this.experimentService.currentExperiment!.id,
        commentId: event.item.state!.context.Id
      }).subscribe(
        {
          next: (_results: ThreadStateChangeResponse) => {
            this.updateStatus(InternalCommentStatus.Pending, event.item.state!.context.Id);
            first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) =>
              comment.commentId === event.item.state!.context.Id))!.lastModified = _results.lastModifiedDate;
            this.refreshCommentDetails.next(this.internalCommentsResponse);
            this.openInternalComments(this.commentIdRefresh);
          }
        }
      );
  }
  getResolveAction(event: { item: MenuItem; }) {
    this.resolveComment(event.item.state!.context.Id);
  }

  showGridForInternalComments(gridApi: any, rowId: string, field: string, isDetail = false) {
    let currentRowId = rowId;
    let currentField = field;
    if (isDetail) {
      const checkForCell = gridApi?.getFocusedCell();
      if (checkForCell) {
        const row = gridApi?.getDisplayedRowAtIndex(checkForCell.rowIndex);
        const detailRow = gridApi?.getDetailGridInfo(`detail_${row?.id}`);
        const detailCell = detailRow?.api?.getFocusedCell();
        const detailField = detailCell?.column.getColDef()?.field as string;

        if (detailCell) {
          currentRowId = detailCell.rowIndex;
          currentField = detailField;
        }
      }
    }
    return {
      currentRowId, currentField
    };
  }

  resolveComment(commentId: string) {
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdResolvePut$Json({
        experimentId: this.experimentService.currentExperiment!.id,
        commentId: commentId
      }).subscribe(
        {
          next: (_results: ThreadStateChangeResponse) => {

            this.updateStatus(InternalCommentStatus.Resolved, commentId);
            first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) =>
              comment.commentId === commentId))!.lastModified = _results.lastModifiedDate;
            this.refreshCommentDetails.next(this.internalCommentsResponse);
            this.openInternalComments(this.commentIdRefresh);
          }
        }
      );
  }

  updateStatus(status: InternalCommentStatus, commentId: string) {
    first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) => comment.commentId ===
      commentId))!.status = status;
  }
  getPrimaryActions(commentStatus: InternalCommentStatus) {
    if (this.isReviewer() && commentStatus === InternalCommentStatus.Pending) {
      return {
        label: '',
        icon: InternalCommentHelper.Icons.check,
        tooltip: $localize`:@@resolve:Resolve`,
        visible: this.isReviewer() && commentStatus === InternalCommentStatus.Pending,
        command: (e: { item: MenuItem }) => {
          this.getResolveAction(e);
        }
      };
    }
    return null;
  }

  private constructCommentResponse(comment: CommentResponse): BuiltComment {
    return {
      Header: '',
      ClickableHeader:InternalCommentHelper.getHeaderStatus(comment.contextType),
      CommentedBy: InternalCommentHelper.getCommentedBy(this.users, comment.createdBy),
      CommentedOn: comment.lastModified,
      ClientContext: comment?.contextType,
      CommentedByTitle: this.getCommentedByTitle(comment.createdBy),
      Collapsed: InternalCommentHelper.getCollapsedStatus(this.internalCommentDefaultDetails, comment.path),
      Content: comment.content,
      Path: comment.path,
      Id: comment.commentId,
      AdditionalActions: this.getAdditionalActions(comment.createdBy, comment.status),
      PrimaryAction: this.getPrimaryActions(comment.status),
      Replies: this.getReplies(comment)
    } as BuiltComment;
  }

  private getReplies(comment: CommentResponse): BuiltReply[] {
    return comment.replies.length > 0
      ? this.getRepliesForComment(comment.replies, InternalCommentHelper.getCollapsedStatus(this.internalCommentDefaultDetails, comment.path))
      : [];
  }


  getRepliesForComment(replies: InternalCommentReply[], collapsedStatus: boolean) {
    const builtReplies: BuiltReply[] = [];
    if (replies?.length > 0) {
      replies.forEach((reply: InternalCommentReply) => {
        if(reply.status!==ReplyStatus.Removed){
          builtReplies.push({
            ReplyIndex: reply.number - 1,
            CommentedBy: InternalCommentHelper.getCommentedBy(this.users, reply.createdBy),
            CommentedOn: reply.lastModified,
            CommentedByTitle: this.getCommentedByTitle(reply.createdBy),
            Collapsed: collapsedStatus,
            Content: reply.content,
            Id: reply.commentId,
            AdditionalActions: InternalCommentHelper.isSameUser(this.currentUserPuid, reply.createdBy) ? this.getAdditionalActions(reply.createdBy) : [],
          });
        }
      });
    }
    return builtReplies;
  }
  isReviewer() {
    return this.currentUserService.usersWithReviewerRoles?.some(
      (user: User) => user.puid.localeCompare(this.currentUserPuid, undefined, {
        sensitivity: 'base'
      }) === 0);
  }

  submitComment(comment: NewComment<string>, experimentId: string) {
    if (comment.comment) {
      const newComment = this.getNewComment(comment);
      return this.internalCommentService
        .experimentsExperimentIdInternalCommentsAddPost$Json({
          experimentId: experimentId,
          body: InternalCommentHelper.buildComment(this.internalCommentDefaultDetails, newComment)
        })
        .subscribe({
          next: (response: ThreadAddedResponse) => {
            newComment.Id = response.commentId;
            newComment.CommentedOn = response.createdOn;
            const commentToApply = cloneDeep(newComment);
            commentToApply.CommentedBy = InternalCommentHelper.getCommentedBy(this.users, this.currentUserPuid);
            commentToApply.Id = response.commentId;
            this.internalCommentsResponse.comments.push(InternalCommentHelper.addToCommentDetails(this.internalCommentDefaultDetails, newComment));
            this.refreshCommentDetails.next(this.internalCommentsResponse);
            comment.applyNewComment(commentToApply);
            this.openInternalComments(this.commentIdRefresh);
          },
          error: (error) => console.log(error)
        });
    }
    return undefined;
  }

  private getNewComment(comment: NewComment<string>): Comment<string> {
    return {
      ClientContext: this.internalCommentData?.contextType,
      Collapsed: true,
      Replies: [],
      Id: uuidV4(),
      CommentedBy: this.currentUserService.currentUser.puid,
      Header: this.internalCommentDefaultDetails?.displayLabel,
      CommentedByTitle: this.currentUserRole,
      CommentedOn: undefined,
      AdditionalActions: this.getAdditionalActions(this.currentUserService.currentUser.puid, InternalCommentStatus.Pending),
      PrimaryAction: this.getPrimaryActions(InternalCommentStatus.Pending),
      Content: comment.comment
    } as Comment<string>;
  }



  commentEdited(editedComment: CommentEdited<string>, experimentId: string) {
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdEditPut$Json({
        experimentId: experimentId,
        commentId: editedComment.EventContext.Id,
        body: this.buildThreadDto(editedComment)
      })
      .subscribe({
        next: (response: ThreadEditedResponse) => {
          editedComment.EventContext.CommentedOn = response.lastModifiedDate;
          editedComment.commit(editedComment.EventContext as Comment<string>, editedComment.comment);
          this.internalCommentsResponse.comments.forEach((comment: CommentResponse) => {
            if (comment.commentId === editedComment.EventContext.Id) {
              comment.content = editedComment.EventContext.Content;
              comment.lastModified = editedComment.EventContext.CommentedOn as string;
            }
          })
          this.refreshCommentDetails.next(this.internalCommentsResponse);
        },
        error: (error) => console.log(error)
      });
  }
  buildThreadDto(editedComment: CommentEdited<string> | NewReply<string>): EditThread | ReplyThread {
    return {
      content: editedComment.comment
    };
  }
  newReply(newReply: NewReply<string>, experimentId: string) {
    const reply: BuiltReply = {
      Id: newReply.EventContext.Id,
      CommentedBy: this.currentUserService.currentUser.puid,
      CommentedByTitle: this.getCommentedByTitle(this.currentUserPuid),
      CommentedOn: undefined,
      AdditionalActions: this.getAdditionalActions(this.currentUserService.currentUser.puid),
      Content: newReply.comment
    };
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdAddPost$Json({
        experimentId: experimentId,
        commentId: newReply.EventContext.Id,
        body: this.buildThreadDto(newReply)
      })
      .subscribe({
        next: (response: ReplyAddedResponse) => {
          reply.CommentedOn = response.createdOn;
          const replyIndex = first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) => comment.commentId ===
          newReply.EventContext.Id))!.replies.length;
          reply.ReplyIndex = replyIndex;
          const applyNewReply = cloneDeep(reply);
          applyNewReply.CommentedBy = InternalCommentHelper.getCommentedBy(this.users, this.currentUserPuid);
          applyNewReply.CommentedOn = applyNewReply.CommentedOn ? formatInstant(applyNewReply.CommentedOn, DateAndInstantFormat.dateTimeToSecond) : '';
          newReply.applyNewReply(newReply.EventContext as Comment<string>, applyNewReply as Comment<string>);
          first(this.internalCommentsResponse.comments.filter((comment: CommentResponse) => comment.commentId === reply.Id))?.replies.push(this.addReplyDetails(reply));
          this.refreshCommentDetails.next(this.internalCommentsResponse);
          if (InternalCommentHelper.isCommentResolved(this.internalCommentsResponse, reply.Id) && replyIndex === 0) {
            this.openInternalComments(this.commentIdRefresh);
          }
        },
        error: (error) => console.log(error)
      });
  }
  addReplyDetails(reply: BuiltReply) {
    return {
      commentId: reply.Id,
      content: reply.Content,
      createdBy: reply.CommentedBy,
      createdOn: reply.CommentedOn as string,
      lastModified: reply.CommentedOn as string,
      number: reply.ReplyIndex! + 1,
      status: ReplyStatus.Created,
    }
  }


  replyEdited(editedReply: CommentEdited<string>, experimentId: string) {
    this.internalCommentService
      .experimentsExperimentIdInternalCommentsCommentIdRepliesReplyIndexEditPut$Json({
        experimentId: experimentId,
        commentId: editedReply.EventContext.Id,
        replyIndex: (editedReply.EventContext as ReplyWithIndex).ReplyIndex as number,
        body: this.buildThreadDto(editedReply)
      })
      .subscribe({
        next: (response: ReplyEditedResponse) => {
          editedReply.EventContext.CommentedOn = response.lastModifiedDate;
          editedReply.commit(editedReply.EventContext as Comment<string>, editedReply.comment);
          this.internalCommentsResponse.comments.forEach((comment: CommentResponse) => {
            if (comment.commentId === editedReply.EventContext.Id) {
              comment.replies[(editedReply.EventContext as ReplyWithIndex).ReplyIndex].content = editedReply.EventContext.Content;
              comment.replies[(editedReply.EventContext as ReplyWithIndex).ReplyIndex].lastModified = editedReply.EventContext.CommentedOn as string;
            }
          })
          this.refreshCommentDetails.next(this.internalCommentsResponse);
        },
        error: (error) => console.log(error)
      });
  }
  getCommentedByTitle(puid:string):string{
    if(this.currentUserService.usersWithReviewerRoles.some((user:User)=>user.puid === puid))
    {
       return InternalCommentHelper.UserRoleReviewer
    }
    if(this.currentUserService.usersWithSupervisorRoles.some((user:User)=>user.puid === puid))
    {
       return InternalCommentHelper.UserRoleSupervisor
    }
    if(this.currentUserService.usersWithAnalystRoles.some((user:User)=>user.puid === puid))
    {
       return InternalCommentHelper.UserRoleAnalyst
    }
    else
    {
      return InternalCommentHelper.UserRoleViewer
    }
  }
  public commentHeaderClicked(comment: any) {
    if (comment.ClientContext !== CommentContextType.TableCell) {
      return;
    }
    const activityId = comment.Path[0];
    const currentExperimentNumber = this.experimentService.currentExperiment?.experimentNumber;
      this.router.navigate(
        [`experiment/${currentExperimentNumber}/${InternalCommentHelper.getActivityName(this.experimentService.currentExperiment,activityId)}/${InternalCommentHelper.getActivityTitle(nth(comment.Path, -1))}`]
      ).then((result) => {
        const cell = InternalCommentHelper.getCell(comment.Path);
        if (result === null && cell) {
          if (cell.classList.contains(InternalCommentHelper.GlowComment)) {
            cell.classList.remove(InternalCommentHelper.GlowComment)
          }
          InternalCommentHelper.highlightContext(this.renderer, cell);

        }
        else if (result) {
          this.highLightCellOnNavigation(comment.Path);
        }
      });
  }

  public highLightCellOnNavigation(path: string[]) {
    this.highLightingInterval = setInterval(() => {
      const element = InternalCommentHelper.getCell(path);
      if (element) {
        InternalCommentHelper.highlightContext(this.renderer, element);
        clearInterval(this.highLightingInterval);
      }
    }, 500);
  }

  public getDynamicDisplayLabel(path: string[], contextType: string): string | undefined {
    const activity = this.experimentService.currentExperiment?.activities?.find(act => act.activityId === path[0]);
    const title = activity?.itemTitle;
    const module = activity?.dataModules.find(module => module.moduleId === path[1]);
    const moduleLabel = module?.moduleLabel;
    const formTitle = this.experimentService.getForm(path[2])?.itemTitle;
    const tableTitle = this.experimentService.getTable(path[5])?.itemTitle;
    const experimentNumber = this.experimentService.currentExperiment?.experimentNumber;
    switch (contextType) {
      case CommentContextType.Activity:
        return InternalCommentHelper.getDynamicDisplayLabelForLeftNavItems(path, title);
      case CommentContextType.Module:
        return InternalCommentHelper.getDynamicDisplayLabelForModule(title, moduleLabel);
      case CommentContextType.Table:
        return InternalCommentHelper.getDynamicDisplayLabelForTable(title, module, path);
      case CommentContextType.TableCell:
        return InternalCommentHelper.getDynamicDisplayLabelForTableCell(title, tableTitle, module, path, activity);
      case CommentContextType.Form:
        return InternalCommentHelper.getDynamicDisplayLabelForForm(title, formTitle, moduleLabel);
      case CommentContextType.FormField:
        return InternalCommentHelper.getDynamicDisplayLabelForFormField(title, formTitle, moduleLabel, path);
      case CommentContextType.Experiment:
        return experimentNumber;
      default:
        //should not come here
        throw new Error('LOGIC ERROR: Unknown comment context type was passed');
    }
  }

  retitleSubmittedCommentsHeader(builtCommentsAndReplies: CommentCollection<string>): CommentCollection<string> {
    builtCommentsAndReplies.Comments.forEach((comment: any) => {
      comment.Header = this.getDynamicDisplayLabel(comment.Path, comment.ClientContext);
    });

    return builtCommentsAndReplies;
  }
}
