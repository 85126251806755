import { BptControlSeverityIndicators, ControlCustomDefinition, SeverityIndicatorType } from 'bpt-ui-library/shared';
import { ValueState } from '../../../../app/api/models/value-state';
import { ActivityInputInstrumentEvent } from '../../model/activity-input-instrument-event';
import { Subject } from 'rxjs';
import { ActivityInputMaintenanceEvent } from '../../model/activity-input-maintenance-event';
import { ChangeReasonConstants } from '../../change-reason/change-reason-constants';

export class InstrumentEventHelper {
  static instrumentEventSelectionSaved = new Subject<ActivityInputMaintenanceEvent>();
  static instrumentEventSelectionClosed = new Subject<ActivityInputMaintenanceEvent>();

  constructor() { }


  public static getControlCustomDefinitionForEmptyContent(applyCondition: () => boolean): ControlCustomDefinition {
    return BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Empty,
      { CanApply: applyCondition });
  }

  public static getControlCustomDefinitionForModifiedContent(applyCondition: () => boolean, isChangeReasonAbsent = false): ControlCustomDefinition {
    return !isChangeReasonAbsent ?
    BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Modified,
      { CanApply: applyCondition }) :
    BptControlSeverityIndicators.BuildSeverityIndicator(SeverityIndicatorType.Modified,
      {
        Tooltip: ChangeReasonConstants.SeverityIndicatorTooltip,
        StyleClass: ChangeReasonConstants.SeverityIndicatorStyleClass,
        CanApply: applyCondition
      });
  }

  public static isDataValueEmpty(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return (
      (instrumentEventsDataSource?.description?.value?.state === ValueState.Empty ||
        instrumentEventsDataSource?.description?.value === undefined) ??
      true
    );
  }

  public static isDataValueModified(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return instrumentEventsDataSource?.description?.isModified ?? false;
  }

  public static isRemovedFromServiceEmpty(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return (
      (instrumentEventsDataSource?.removedFromService?.value?.state === ValueState.Empty ||
        instrumentEventsDataSource?.removedFromService?.value === undefined) ??
      true
    );
  }

  public static isRemovedFromServiceModified(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return instrumentEventsDataSource?.removedFromService?.isModified ?? false;
  }

  public static isDateRemovedEmpty(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return (
      (instrumentEventsDataSource?.dateRemoved?.value?.state === ValueState.Empty ||
        instrumentEventsDataSource?.dateRemoved?.value === undefined) ??
      true
    );
  }

  public static isDateRemovedModified(
    instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined
  ): boolean {
    return instrumentEventsDataSource?.dateRemoved?.isModified ?? false;
  }

  public static isEventEmpty(instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined): boolean {
    return (
      (instrumentEventsDataSource?.nameDescription?.value?.state === ValueState.Empty ||
        instrumentEventsDataSource?.nameDescription?.value === undefined) ??
      true
    );
  }

  public static isEventModified(instrumentEventsDataSource: ActivityInputInstrumentEvent | undefined): boolean {
    return instrumentEventsDataSource?.nameDescription?.isModified ?? false;
  }

}
