import { IdentifierFunctions } from '../inputs/shared/identifier-functions';
import { isEqual, omitBy, unionBy } from 'lodash-es';
import { Injectable } from '@angular/core';
import { Instant, LocalDate } from '@js-joda/core';
import { NA, Quantity } from 'bpt-ui-library/shared';
import { ActivityItemReferenceType } from '../../api/models/activity-item-reference-type';
import {
  ExperimentDataValue,
  InstantValue,
  LocalDateValue,
  ModifiableDataValue,
  NumberValue,
  StringArrayValue,
  ValueState,
  ValueType,
  StringValue,
  HtmlValue,
} from '../../api/models';
import { ColumnType } from '../../api/models/column-type';
import { FieldType } from '../../api/models/field-type';
import { UnitLoaderService } from 'services/unit-loader.service';
import { Experiment, FieldDefinition, SpecificationValue } from 'model/experiment.interface';
import { SpecificationService } from '../../shared/specification-input/specification.service';
import { ElnDateTimeTypes } from '../../shared/date-time-helpers';
import { StringTypeDictionaryValue } from '../../api/data-entry/models';
import { PicklistAttributes } from '../../model/template.interface';
import { ExperimentDataService } from './experiment-data.service';

export type DataValue =
  | (ExperimentDataValue & { value: boolean | undefined }) // For checkbox; deprecated. Revisit when quad-state is in the service API.
  | StringValue
  | StringArrayValue
  | StringTypeDictionaryValue
  | NumberValue
  | InstantValue
  | LocalDateValue
  | SpecificationValue
  | HtmlValue;

export type FieldOrColumnType = FieldType | ColumnType; // Alternatively, in some incarnation, this might be the right enum: keyof typeof ColumnType from 'bpt-ui-library/bpt-grid'

export type EmittedHtmlValue = EmittedStringValue;
export type EmittedStringValue = string | typeof NA | undefined; //NOSONAR
export type EmittedValue =
  | boolean
  | string[]
  | typeof NA //NOSONAR
  | null
  | undefined
  | EmittedStringValue
  | ElnDateTimeTypes //NOSONAR
  | Quantity
  | SpecificationValue
  | EmittedHtmlValue;

export type ListOption = { label: string, value: any };
export type OptionGroup = { groupLabel: string, subOptions: ListOption[] };
export type Options = ListOption[] | OptionGroup[];
export type GroupOptions = { groupingProperties?: { group: boolean, groupLabelField: string, groupChildrenField: string }, options: Options };

type Context = { experiment: Experiment, activityId: string };

export const elnReferenceTagName = 'eln-reference';
export const elnReferenceNodeName = 'ELN-REFERENCE';

export const htmlAndStringValuesAreEquivalent = (htmlValue: HtmlValue, stringValue: StringValue): boolean => {
  if (!htmlValue.value || !stringValue.value) {
    // Equal if Empty and Empty or N/A and N/A
    return htmlValue.state === stringValue.state;
  }
  const el = document.createElement('html');
  el.innerHTML = htmlValue.value;

  if (Array.from(el.querySelectorAll(elnReferenceTagName)).some(e => e.nodeName === elnReferenceNodeName)) return false;
  return el.textContent === stringValue.value;
}

/** This removes extra properties that can get added like Symbols, etc. */
export const isSerializablyEqual = (v1: any, v2: any): boolean => {
  if (v1 === undefined || v2 === undefined) return v1 === v2;
  return isEqual(JSON.parse(JSON.stringify(v1)), JSON.parse(JSON.stringify(v2)));
}

export type DropdownValue = StringValue | StringArrayValue | HtmlValue | undefined;

@Injectable({
  providedIn: 'root'
})
/**
 * Provides top-level functions for three purposes that consume or produce experiment data:
 *   * get display string from experiemnt data ("display string")
 *   * get ExperimentDataValue from data-entry component emission ("ExperimentDataValue")
 *   * get data-entry component value from experiment data ("primitive")
 */
export class DataValueService {
  constructor(
    private readonly unitLoaderService: UnitLoaderService,
    private readonly specificationService: SpecificationService,
    private readonly experimentDataService: ExperimentDataService,
  ) { }

  /**
   * This "cached"/"static" array multiselectNA seems required for inner working of bpt-dropdown and/or Angular to do some sort of value object comparison.
   */
  readonly multiselectNA = [NA];

  public static isEmpty(value: ModifiableDataValue | undefined): boolean {
    return !value || value.value.state === ValueState.Empty;
  }

  public static isNotApplicable(value: ModifiableDataValue | undefined): boolean {
    return value?.value.state === ValueState.NotApplicable;
  }

  /**
   * Transforms a value into a format that the rendering component for the field or column type accepts and displays and/or edits.
   * Some might do their own formatting and localization. But none take a `ModifiableDataValue`.
   *
   * @returns primitive (aka native) value for presumed component
   */
  public getPrimitiveValue(fieldOrColumnType: FieldOrColumnType, value: ModifiableDataValue | undefined, naAsArray = false): any {
    if (!value) return undefined; // if value is missing leave as undefined; It's an alternate representation of Empty and never modified.
    // Determine if we should use the passed in type or use the desired one from value.
    // This is could be relevant in general. Definitely needed when dealing with dynamic columns.
    const typeToUse = value.value?.type === ValueType.Number && fieldOrColumnType === ColumnType.String
      ? ColumnType.Quantity
      : fieldOrColumnType;
    const shouldProceed = this.getPrimitiveForNonModifiableDataValue(typeToUse, value, naAsArray);
    return shouldProceed === 'checkForModifiableDataValue' ? this.getPrimitiveForModifiableDataValue(typeToUse, value, naAsArray) : shouldProceed;
  }

  private getPrimitiveForModifiableDataValue(fieldOrColumnType: FieldOrColumnType, value: ModifiableDataValue, naAsArray: boolean) {
    switch (fieldOrColumnType) {
      case FieldType.Datepicker:
      case ColumnType.Date:
        return this.getPrimitiveDateValue(value);
      case FieldType.Quantity:
      case ColumnType.Quantity:
        return this.getPrimitiveQuantityValue(value);
      case ColumnType.Index:
      case ColumnType.StepNumber:
      case ColumnType.Number: {
        const number: NumberValue = value.value as NumberValue;
        return number.value;
      }
      case FieldType.Specification:
      case ColumnType.Specification:
        return this.specificationService.getDisplayString(value.value);
      case ColumnType.Boolean:
      case FieldType.Textbox:
      case ColumnType.String:
      case FieldType.Textarea:
      case FieldType.Textentry:
        return this.getPrimitiveTextValue(fieldOrColumnType, value.value as StringValue | HtmlValue);
      case FieldType.EditableList:
      case ColumnType.EditableList:
      case FieldType.List:
      case ColumnType.List:
        return this.getPrimitiveDropdownValue(value.value, naAsArray);
      default:
        {
          if (value.hasOwnProperty('isModified')) {
            return 'value' in value.value ? value.value.value : undefined;
          }
          return 'value' in value.value ? value.value.value
            : /* for much older, pre-release 1 values; currently shouldn't get here */ value.value;
        }
    }
  }

  /**
   * Escapes a string containing HTML reserved token characters using ampersand entities.
   *
   * Intended to be used to send safe HtmlValue value content to core services, etc.
   */
  private escapeHTML(inputText: string): string {
    const floatingElement = document.createElement('textarea');
    floatingElement.textContent = inputText;
    const escaped = floatingElement.innerHTML;
    floatingElement.remove();
    return escaped;
  }

  /**
   * Gets display string from an HtmlValue, unencoding text or dereferencing eln-refenence
   *
   * HtmlValue.value is either:
   *   * mixed text and eln-reference elements
   *   * ul li elements, each with one child as either text or an eln-reference
   * @returns display string and for a list, each item's display string
   *   * empty string if Empty
   *   * N/A if NotApplicable
   *   * array of strings if Set
   *   * undefined if undefined passed in or the value is not properly structured.
   */
  public getHtmlValueDisplayString(value: HtmlValue | undefined): { displayString: string; displayItems?: string[] } | undefined {
    if (!value) return undefined;
    if (value.state === ValueState.NotApplicable) return { displayString: NA };
    if (value.state === ValueState.Empty) return { displayString: '' };
    if (!value.value) return undefined; // for type inference

    // TODO assumes eln-referernce can be deferenced simply by key. This is valid for sampleAliquot. # 3287400
    const dereference = (reference: HTMLElement) => reference.getAttribute('key') ?? reference.textContent ?? '';

    const processNode: (node: ChildNode) => string | undefined = (node) => {
      if (node.nodeType === Node.TEXT_NODE) return node.textContent ?? undefined;
      if (node.nodeType !== Node.ELEMENT_NODE && node.nodeName !== elnReferenceNodeName) return undefined;
      return dereference(node as HTMLElement);
    }
    const doc = new DOMParser().parseFromString(value.value, 'text/html').documentElement;
    const items: string[] = Array.from(doc.querySelectorAll<HTMLLIElement>('ul li'))
      .map(li => {
        if (li.childNodes.length !== 1) return undefined;
        return processNode(li.childNodes[0]);
      })
      .filter((item): item is string => item !== undefined);
    if (items.length) return { displayString: items.join(', '), displayItems: items };

    return { displayString: Array.from(doc.children[1 /* body */].childNodes).map(processNode).join('') };
  }

  /**
   * Gets single or array of values for the dropdown.
   * ValueState:
   *   * empty => undefined
   *   * notApplicable => "N/A"    consumer might need to put this into an array if multi-select
   *   * set
   *     * ul li => array    element values are either textcontent string or eln-reference HTML string
   *     * textNode => textContent string
   *     * eln-reference => eln-reference HTML string
   */
  private getPrimitiveDropdownValue(value: DropdownValue, naAsArray: boolean): NoneToMany<string> {
    if (!value) return undefined;
    if (value.state === ValueState.NotApplicable) return naAsArray ? this.multiselectNA : NA;
    if (value.state === ValueState.Empty) return undefined;
    if (value.type === ValueType.String) return value.value;
    if (value.type === ValueType.StringArray) return value.value;

    const html = (value as HtmlValue).value;
    if (!html) return undefined; // for type inference
    const key = JSON.stringify(value);
    if (!this.cachedHtmlListValues[key]) {
      const doc = new DOMParser().parseFromString(html, 'text/html').documentElement;
      this.cachedHtmlListValues[key] ??= this.processHtmlElementForDropdown(doc);
    }
    return this.cachedHtmlListValues[key];
  }

  private processHtmlElementForDropdown(doc: HTMLElement): NoneToMany<string> {
    const processNode: (node: ChildNode) => string | undefined = (node) => {
      if (node.nodeType === Node.TEXT_NODE) return node.textContent ?? undefined;
      if (node.nodeType !== Node.ELEMENT_NODE && node.nodeName !== elnReferenceNodeName) return undefined;
      return (node as HTMLElement).outerHTML;
    }

    const items: string[] = Array.from(doc.querySelectorAll<HTMLLIElement>('ul li'))
     .map(li => li.childNodes.length !== 1 ? undefined : processNode(li.childNodes[0]))
     .filter((item): item is string => item !== undefined);

    return items.length ? items : Array.from(doc.children[1 /* body */].childNodes).map(processNode)[0];
  }

  private getPrimitiveTextValue(_fieldOrColumnType: FieldOrColumnType, value: StringValue | HtmlValue): string | undefined {
      if (value.state === ValueState.Empty) return undefined;
      if (value.state === ValueState.NotApplicable) return NA;
      if (value.type === ValueType.String) return value.value;

      // For HTMLValue, punt because we yet don't have an input component that deals with mixed text and eln-references nor do we have any such values.
      return this.getHtmlValueDisplayString(value)?.displayString ?? ''; // display string is equivalent for now.
    }


  private getPrimitiveForNonModifiableDataValue(
    fieldOrColumnType: FieldOrColumnType,
    value: ModifiableDataValue | undefined,
    naAsArray: boolean
  ): any {
    if (!value) return undefined;
    if (fieldOrColumnType === ColumnType.RowId) return value; // Shouldn't get here; Caller should not try to convert a Row Id.
    if ((value as any).type) value = { isModified: false, value: value as any }; // Shouldn't get here; This would be a ExperimentDataValue
    if (!value.value) return value; // Shouldn't get here; This would be old data records with just primitive values.
    if (
      value.value.state === ValueState.NotApplicable &&
      !(fieldOrColumnType === FieldType.Quantity || fieldOrColumnType === ColumnType.Quantity)
    ) {
      return value.value.type === ValueType.StringArray || value.value.type === ValueType.Html && naAsArray ? this.multiselectNA : NA;
    } else {
      return 'checkForModifiableDataValue';
    }
  }

  /**
   * Memoizes calls to getPrimitiveQuantityValue.
   * Primary reason is to return the same object to prevent event storms due to object reference changes.
   * Secondary reason is tiny performance improvement.
   */
  private readonly cachedQuantityValues: { [key: string]: Quantity | undefined } = {};

  private readonly cachedDateValues: { [key: string]: LocalDate | Instant } = {};

  private readonly cachedHtmlListValues: { [key: string]: string[] | string | undefined } = {};
  /*
   * 5 separate functions to reduce complexity of getExperimentDataValue to within SonarQube's comprehension; Sorry, humans.
   */

  /**
   * Converts from an missing, empty, notApplicable or set LocalDateValue or InstantValue to undefined, N/A, LocalDate or Instant
   */
  private getPrimitiveDateValue(value: ModifiableDataValue): ElnDateTimeTypes {
    if (!('value' in value.value)) return undefined;
    if (value.value.state === ValueState.Empty) return undefined;
    if (value.value.state === ValueState.NotApplicable) return NA;

    // Caching needed to prevent hangup
    const storeLocalDateOrInstance = (type: ValueType, str: string): LocalDate | Instant => {
      let storageName: string;
      let storageValue: LocalDate | Instant;
      switch (type) {
        case ValueType.LocalDate:
          storageValue = LocalDate.parse(str);
          storageName = `${type}|${storageValue}`
          break;
        case ValueType.Instant:
          storageValue = Instant.parse(str);
          storageName = `${type}|${storageValue}`
          break;
        default: throw new Error('LOGIC ERROR: ModifiableDataValue should contain a LocalDateValue or InstantValue');
      }
      this.cachedDateValues[storageName] ??= storageValue;
      return this.cachedDateValues[storageName];
    }

    return storeLocalDateOrInstance(value.value.type, value.value.value as string);
  }

  private readonly getPrimitiveQuantityValue: (value: ModifiableDataValue) => Quantity | undefined =
    (value) => {
      const number: NumberValue = value.value as NumberValue;
      const key = JSON.stringify(number); // using number because other properties of ModifiableDataValue are ignored in this entire function

      if (!this.cachedQuantityValues[key]) {
        const unit = this.unitLoaderService.allUnits.find((u) => u.id === number.unit);

        // Empty with N/A unit is not a valid state
        const unitToSet = unit?.id === this.unitLoaderService.naUnit.id && number.state === ValueState.Empty ? undefined : unit;

        const newQuantity = new Quantity(
          number.state,
          number.value,
          unitToSet,
          number.sigFigs,
          number.exact
        );
        this.cachedQuantityValues[key] = newQuantity;
      }
      return this.cachedQuantityValues[key];
    };

  /**
   * Converts a component-emitted value to an ExperimentDataValue.
   *
   * @param {(FieldOrColumnType | undefined)} fieldOrColumnType Data type that determines which component renders the value.
   * @param {EmittedValue} emittedValue Component-emitted value. It depends on the component but is likely the same type as the data-bound ("primitive") value.
   * @param {ValueType} valueType optional override of emitted data type. Only used where compatible; otherwise ignored.
   * @return {ExperimentDataValue} Subclass of ExperimentDataValue with target data type and converted value.
   * @memberof DataValueService
   */
  public getExperimentDataValue(fieldOrColumnType: FieldOrColumnType | undefined, emittedValue: EmittedValue, valueType?: ValueType): ExperimentDataValue {
    // Normalize parameters
    emittedValue = emittedValue === null || emittedValue === '' ? undefined : emittedValue;

    const valueState = this.getValueState(emittedValue);

    let dataValue: DataValue;
    switch (fieldOrColumnType) {
      case FieldType.Checkbox:
        dataValue = this.getCheckboxDataValue(
          valueState,
          emittedValue as boolean | typeof NA | undefined
        );
        break;
      case ColumnType.Number:
      case ColumnType.Index:
      case ColumnType.StepNumber:
        dataValue = this.getNumericDataValue(
          valueState,
          emittedValue?.toString() as EmittedStringValue,
          1, // TODO Stand-in dummy sigfigs until either number value is retired or retrofitted with sigfigs
          ''
        );
        break;

      case FieldType.Quantity:
      case ColumnType.Quantity:
        dataValue = this.getExperimentDataValueQuantity(emittedValue as Quantity);
        break;

      case ColumnType.Date:
      case FieldType.Datepicker:
        dataValue = emittedValue instanceof Instant ? this.getInstantValue(valueState, emittedValue)
          : this.getLocalDateValue(valueState, emittedValue as ElnDateTimeTypes)

        break;

      case FieldType.List:
      case FieldType.EditableList:
      case ColumnType.List:
      case ColumnType.EditableList:
        dataValue = Array.isArray(emittedValue)
          ? this.getStringArrayAsHtmlValue(valueState, emittedValue as string[] | typeof NA | undefined, valueType)
          : this.getHtmlValue(valueState, emittedValue as EmittedHtmlValue, valueType);
        break;

      case FieldType.Textarea:
      case FieldType.Textentry:
      case FieldType.Textbox:
      case ColumnType.String:
        dataValue = this.getHtmlValue(valueState, emittedValue as EmittedHtmlValue, valueType);
        break;
      case FieldType.Specification:
      case ColumnType.Specification:
        dataValue = emittedValue as SpecificationValue ?? { type: ValueType.Specification, state: ValueState.Empty };
        break;
      default:
        dataValue = this.getDefaultDataValue(valueState, emittedValue as EmittedStringValue);
    }

    // Re omitBy null: No DataValue subtype allows null properties so removing them doesn't change the type
    return omitBy(dataValue, v => v === null) as DataValue;
  }

  /**
   * Creates a list of options or option groups from:
   * * picklist, if any
   * * off-list entries from existing editable multi-select value, in case any are
   * * item selections for enabled item reference types (when context provides an activity)
   *
   * Has side effect of updating fieldDefinition with grouping properties
   *
   * Note: Today,
   *    * used by FieldComponent
   *    * not used by TableComponent
   */
  setupListValues(value: ExperimentDataValue | undefined, fieldDefinition: FieldDefinition, context?: Context): Options | undefined {
    const picklistAttributes = fieldDefinition.fieldAttributes as PicklistAttributes;

    const potentialOffListOptions = this.getPotentialOffListOptions(value).sort((a, b) => a.label.localeCompare(b.label));
    const pickListOptions = (picklistAttributes?.listValues ?? []).map(toListOption);
    let options: Options = unionBy(pickListOptions, potentialOffListOptions, 'value');

    if (context) {
      const types = fieldDefinition.fieldAttributes.activityItemReferenceTypes ?? [];
      if (types.length) {
        const extension = this.extendOptionsForItemSelection(context.experiment, context.activityId, types, options);
        options = extension.options;

        const groupingProperties = extension.groupingProperties;
        // WORKAROUND
        // Because bpt-dropdown doesn't, today, handle groups for the off-list entry feature of including all the off-list entries in the column,
        // we'll flatten out the groups
        if (fieldDefinition.fieldAttributes.allowCustomOptionsForDropdown && groupingProperties?.group) {
          groupingProperties.group = false;
          options = options.flatMap(g => 'groupLabel' in g ? g.subOptions : /* impossible */ []);
        }

        Object.assign(fieldDefinition.fieldAttributes, extension.groupingProperties);
      }
    }

    return options;
  }

  /**
   * Create options from value, which could be off-list or from the picklist
   *
   * Note: Used for a form field, which considers its current value. (bpt-grid handles a similar feature but it's for the whole column's values.)
   */
  private getPotentialOffListOptions(value: ExperimentDataValue | undefined): ListOption[] {
    if (!value || value.state !== ValueState.Set) return [];

    // all off-list values are text values
    switch (value.type) {
      case ValueType.String:
        return [(value as StringValue).value as string].map(toListOption);
      case ValueType.StringArray:
        return ((value as StringArrayValue).value).map(toListOption);
      case ValueType.Html:
        // off-list cannot be eln-reference; they are dropped
        return ([this.getPrimitiveDropdownValue(value as HtmlValue, false) ?? []].flatMap(v => Array.isArray(v) ? v : [v]).filter(v => v !== '')).map(toListOption);
      default:
        return [];
    }
  }

  private getValueState(emittedValue: EmittedValue): ValueState {
    //using JavaScript idiomatic truthy value selectors (&& ||) to avoid SonarQube's hatred for simple, sequential ternary expressions.
    return (
      // Just N/A
      (emittedValue === NA && ValueState.NotApplicable) ||
      // Array of just N/A
      (Array.isArray(emittedValue) &&
        emittedValue.length === 1 &&
        emittedValue[0] === NA &&
        ValueState.NotApplicable) ||
      // no value or just whitespace
      ((emittedValue === undefined ||
        emittedValue === null ||
        (Array.isArray(emittedValue) && emittedValue.length === 0) ||
        (typeof emittedValue === 'string' && emittedValue.trim() === '')) &&
        ValueState.Empty) ||
      // some value
      ValueState.Set
    );
  }

  /** checkbox is deprecated; it's not supported by form designer, though it still is by form templates and experiment. Revisit with quad-state. */
  private getCheckboxDataValue(
    valueState: ValueState,
    emittedValue: boolean | typeof NA | undefined
  ): ExperimentDataValue & { value: boolean | undefined } {
    return {
      type: ValueType.Boolean,
      state: valueState,
      value: valueState === ValueState.Set ? (emittedValue as boolean) : undefined
    };
  }

  /** Converts Instant to set InstantValue. See getLocalDateValue for Empty and NotApplicable */
  private getInstantValue(valueState: ValueState, emittedValue: Instant): InstantValue {
    return {
      type: ValueType.Instant,
      state: valueState,
      value: emittedValue.toJSON()
    };
  }

  /** Converts LocalDate to set LocalDateValue, also handles Empty and NotApplicable (because some type is required but is not very meaningful) */
  private getLocalDateValue(valueState: ValueState, emittedValue: ElnDateTimeTypes): LocalDateValue {
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return { type: ValueType.LocalDate, state: valueState };
      case ValueState.Set:
        return {
          type: ValueType.LocalDate,
          state: valueState,
          value: (emittedValue as LocalDate).toJSON()
        };
      default:
        throw new Error('LOGIC ERROR: Not implemented');
    }
  }

  private getNumericDataValue(
    valueState: ValueState,
    emittedValue: EmittedStringValue,
    sigFigs: number,
    unit: string
  ): DataValue {
    const fallbackUnit = valueState === ValueState.NotApplicable
      ? undefined
      : this.unitLoaderService.naUnit.id;
    return {
      type: ValueType.Number,
      state: valueState,
      value: valueState === ValueState.Set ? emittedValue : undefined,
      sigFigs: valueState === ValueState.Set ? sigFigs : undefined,
      exact: valueState === ValueState.Set ? emittedValue !== '' && !sigFigs : undefined,
      unit:
        valueState === ValueState.Set && unit && unit.length > 0
          ? unit
          : fallbackUnit
    };
  }

  private getStringArrayAsHtmlValue(
    valueState: ValueState,
    emittedValue: string[] | typeof NA | undefined,
    valueType?: ValueType
  ): HtmlValue | StringArrayValue {
    const type = valueType ?? ValueType.Html;
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return { type, state: valueState };
      case ValueState.Set: {
        if (!Array.isArray(emittedValue)) throw new Error("LOGIC ERROR: don't call this function with Set but without an array");
        if (type !== ValueType.Html) return { type, state: valueState, value: emittedValue };

        const value = emittedValue
          .map(v => v.includes('<eln-reference ') ? v : this.escapeHTML(v ?? ''));
        return {
          type,
          state: valueState,
          value: `<ul><li>${value.join('</li><li>')}</li></ul>`,
        };
      }

      default: throw new Error("LOGIC ERROR: don't call this function with invalid state");
    };
  }

  // Returning NA in the value can be avoided once saving data record
  // with value being undefined when state is not applicable.
  private getDataValue<TEmittedValue>(
    valueState: ValueState,
    emittedValue: TEmittedValue
  ): TEmittedValue | undefined {
    if (valueState === ValueState.Set) {
      return emittedValue;
    } else if (valueState === ValueState.NotApplicable) {
      return undefined;
    }
    return undefined;
  }

  private getHtmlValue(valueState: ValueState, emittedValue: EmittedStringValue, valueType?: ValueType): HtmlValue | StringValue {
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return {
          type: valueType ?? ValueType.Html,
          state: valueState
        };
      case ValueState.Set: {
        const type = valueType ?? ValueType.Html
        const value = emittedValue?.includes?.('<eln-reference ') || type !== ValueType.Html ? emittedValue : this.escapeHTML(emittedValue ?? '');
        return {
          type,
          state: valueState,
          value,
        };
      }
      default:
        throw new Error('LOGIC ERROR: value state is invalid');
    }
  }

  private getDefaultDataValue(
    valueState: ValueState,
    emittedValue: EmittedStringValue
  ): StringValue {
    return this.getStringValue(valueState, emittedValue);
  }

  private getStringValue(valueState: ValueState, emittedValue: EmittedStringValue): StringValue {
    switch (valueState) {
      case ValueState.Empty:
      case ValueState.NotApplicable:
        return {
          type: ValueType.String,
          state: valueState
        };
      case ValueState.Set:
        return {
          type: ValueType.String,
          state: valueState,
          value: this.getDataValue(valueState, emittedValue)
        };
      default:
        throw new Error('LOGIC ERROR: value state is invalid');
    }
  }

  static getValueFromString(value: string): StringValue {
    const trimmed = value.trim();
    const nonEmptyState = trimmed === NA ? ValueState.NotApplicable : ValueState.Set;
    const state = trimmed === '' ? ValueState.Empty : nonEmptyState;

    return {
      type: ValueType.String,
      state,
      value: trimmed
    };
  }

  static getStringFromValue(value: ExperimentDataValue): string {
    if (value.type === ValueType.String || value.type === ValueType.Html) return this.getStringFromStringValue(value);

    throw new Error('Logic Error: cannot get a string from an non-string');
  }

  static getStringFromStringValue(value: StringValue): string {
    switch (value.state) {
      case ValueState.NotApplicable:
        return NA;
      case ValueState.Set:
        return value.value ?? '';
      case ValueState.Empty:
        return '';
      default:
        return '';
    }
  }

  /**
   * Modifies the quantity parameter for consistency with property combinations.
   */
  static pruneQuantity(quantity: Quantity) {
    // need to remove the nonsensical properties for empty or N/A states that bpt-quantity might give.
    if (quantity.state === ValueState.Empty) {
      delete quantity.exact;
      delete quantity.sigFigs;
    } else if (quantity.state === ValueState.NotApplicable) {
      delete quantity.exact;
      delete quantity.sigFigs;
      delete quantity.unitDetails;
      delete quantity.value;
    }
  }

  /**
   * Compares one optional NumberValue with another, (extracting number from measurement or quantity as applicable).
   * Ignores inapplicable properties (e.g. unitDetails) and property values (e.g. undefined).
   *
   * To be clear, a number is not a measurement and a measurement is not just a number. But it can be useful to extract the number from a measurement.
   */
  static areEquivalentNumberValues(a: NumberValue | Quantity | undefined, b: NumberValue | Quantity | undefined): boolean {
    if (!a && !b) return true;
    if (!(a && b)) return false;

    if (a.type !== b.type) return false;
    if (a.state !== b.state) return false;
    if (a.value !== b.value) return false;
    if (a.sigFigs !== b.sigFigs) return false;
    if ((a.exact ?? false) !== (b.exact ?? false)) return false;
    if (a.unit !== b.unit) return false;
    // ignoring instrumentReading from NumberValue
    // ignoring all other properties of Quantity

    return true;
  }

  getExperimentDataValueQuantity(emittedValue: Quantity): Omit<NumberValue, 'instrumentReading'> {
    const value: NumberValue = (emittedValue ?? new Quantity(ValueState.Empty)).valueOf();
    if (value.exact === undefined) delete value.exact;
    if (value.sigFigs === undefined) delete value.sigFigs;
    if (value.unit === undefined) delete value.unit;
    if (value.value === undefined) delete value.value;
    if ('__proto__' in value) delete value['__proto__']; //NOSONAR
    return value;
  }

  joinValues(inputValue: StringTypeDictionaryValue): string {
    switch (inputValue.state) {
      case ValueState.Empty:
        return '';
      case ValueState.Set:
        return inputValue.value ? Object.values(inputValue.value).filter(item => item !== undefined && item !== '').map(data => data).join(' ') : '';
      case ValueState.NotApplicable:
        return NA;
      default:
        return '';
    }
  }

  /**
   * Given the 0 or more allowed item reference types, return extended or original options and grouping parameters, if needed.
   * If groups are added, original options are the first group and labeled 'Picklist'
   * @returns
   */
  extendOptionsForItemSelection(experiment: Experiment, activityId: string, types: ActivityItemReferenceType[], picklistOptions: ListOption[]): GroupOptions {
    /*
     * `ActivityItemReferenceType.Input` comes from a template. It means a list of
     *   * sample aliquot
     *   * material aliquot linked to study activity, (not other material aliquots from Lab Items)
     *   * instrument event
    */
    // This can be change here as the requirement and PBIs call for it.
    // Right now, only sampleAliquots are committed to.
    // TODO # 3287400 option list must suffix ` (${rowIndex})` for same names in the same subObjects
    const activity = experiment.activities.find(a => a.activityId === activityId);
    if (!activity) throw new Error('LOGIC ERROR: coding error. Need experiment.activity.');
    const activityInputs = (experiment.activityInputs ?? []).find(a => a.activityId === activityId);

    const appendAnyItemGroups = types.length;
    const appendInputs = types.includes(ActivityItemReferenceType.Input);
    const items = this.experimentDataService.getReferenceableItems(activity, activityInputs);
    const sampleAliquots: ListOption[] = !appendInputs ? [] : [...items.sampleAliquots]
      .sort((a, b) => IdentifierFunctions.compareIdentifier(a.aliquotNumber, b.aliquotNumber))
      .map(s => {
        // Has to match what getPrimitiveValue does when given the HtmlValue from the data
        const value = `<eln-reference type="${ActivityItemReferenceType.SampleAliquot}" key="${s.aliquotNumber}"></eln-reference>`;
        return { label: s.aliquotNumber, value };
      });
    const inputs: OptionGroup[] = !appendInputs ? [] : [
      { groupLabel: $localize`:@@samples:Samples`, subOptions: sampleAliquots },
    ];
    const itemGroups = inputs; // future will have more groups;

    const groupingProperties = appendAnyItemGroups ? { group: true, groupLabelField: 'groupLabel', groupChildrenField: 'subOptions' } : undefined;
    if (appendAnyItemGroups) {
      // clean out anything picked up as off-list when it now comes from Items.
      // In practice, remove entries the Picklist group that match values in other groups.
      const flatItemValues = itemGroups.flatMap(g => g.subOptions.map(s => s.value));
      picklistOptions = picklistOptions.filter(o => !flatItemValues.includes(o.value));
    }
    const groupedOptions: OptionGroup[] = [
      { groupLabel: $localize`:@@picklist:Picklist`, subOptions: picklistOptions },
      ...itemGroups,
    ];
    const options = appendAnyItemGroups ? groupedOptions : picklistOptions;
    return { groupingProperties, options };
  }
}

export enum ReferenceType {
  Invalid = 'invalid',
  SampleAliquot = 'sampleAliquot',
  MaterialInput = 'materialInput',
  MaterialLabItem = 'materialLabItem',
  InstrumentEvent = 'instrumentEvent',
  InstrumentLabItem = 'instrumentLabItem',
  Consumable = 'consumable',
  Column = 'column',
  Preparation = 'preparation'
};

export type NoneToMany<T> = T | T[] | undefined;

function toListOption(value: string | ListOption): { label: string; value: string; } {
  return typeof value === 'object' ? value : { label: value, value };
}
