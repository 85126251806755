<div class="eln-properties-container" *ngIf="!hidden">
  <div class="eln-properties-editor" *ngIf="columnSpecification && columnSpecification.field !== isRemovedColumn">
    <ng-template #UnitProperties>
      <div
        id="column-type-unit-properties"
        class="eln-property-subtitle-1 mb-3"
        i18n="@@tableDesignerUnitPropertiesTitle">Unit Properties</div>

      <p-checkbox
        class="mb-3"
        i18n-label="@@designerPropertyNoUnit"
        label="No Unit"
        id="eln-fieldDefinitionNoUnit"
        [binary]="true"
        [(ngModel)]="noUnit"
        name="noUnit"
        [disabled]="unitsDisabled || readOnlyState"></p-checkbox>

      <label i18n="@@designerApplyList">Apply List</label>
      <bpt-dropdown
        id="eln-table-designer-ApplyUnitList"
        name="applyList"
        class="mb-3"
        [options]="applyUnitList"
        labelField="name"
        valueField="units"
        (dropdownChange)="applyUnitListChanged($event)"
        [showClear]="true"
        [disabled]="unitsDisabled || noUnit || readOnlyState"
        [(ngModel)]="applyUnitListValue"></bpt-dropdown>

      <label i18n="@@designerUnitsToDisplay">Units to Display</label>
      <bpt-dropdown
        id="eln-table-designer-UnitsToDisplay"
        name="allowedUnits"
        class="mb-3"
        [options]="everyUnitGrouped"
        labelField="name"
        valueField="id"
        [group]="true"
        groupChildrenField="units"
        groupLabelField="name"
        [multiSelect]="true"
        (dropdownChange)="unitsToDisplayChanged($event)"
        [disabled]="unitsDisabled || noUnit || readOnlyState"
        [(ngModel)]="allowedUnitIds"></bpt-dropdown>
        <div
        *ngIf="unitsToDisplayValidation && !unitsToDisplayValidation.isValid()"
        class="eln-column-title-validation-message"
      >
        <app-validation-summary [validation]="unitsToDisplayValidation"></app-validation-summary>
      </div>
      <label i18n="@@designerDefaultUnit">Default Unit</label>
      <bpt-dropdown
        id="eln-table-designer-DefaultUnit"
        name="defaultUnit"
        class="mb-3"
        [options]="allowedUnits"
        labelField="name"
        valueField="id"
        [group]="true"
        groupChildrenField="units"
        groupLabelField="name"
        [showClear]="showClearTrue"
        [disabled]="unitsDisabled || noUnit || readOnlyState"
        [(ngModel)]="columnSpecification.defaultUnit"
        (ngModelChange)="propertyChanged($event)"></bpt-dropdown>

      <p-checkbox
        id="eln-table-designer-showUnitNameInList"
        name="eln-table-designer-showUnitNameInList"
        class="mb-3"
        label="Show Unit name in Dropdown"
        i18n-label="@@designerPropertyNameShowUnitNameInList"
        [binary]="true"
        [(ngModel)]="columnSpecification.showUnitNameInList"
        (ngModelChange)="propertyChanged($event)"
        [disabled]="unitsDisabled || noUnit || readOnlyState"
        name="showUnitNameInList"></p-checkbox>
    </ng-template>
    <div
      #columnPropertiesContainer
      class="eln-property-title"
      i18n="@@tableDesignerColumnPropertiesTitle"
    >Column Properties</div>
    <div>
      <app-common-properties
        #commonProperties
        [dataType] = "dataType"

        [(label)]="columnSpecification.label"
        [labelValidation]="labelValidation"
        (labelChange)="validateColumnLabel($event)"

        [(field)]="columnSpecification.field"
        [fieldValidation]="fieldValidation"
        (fieldChange)="validateColumnField($event)"

        (setDefaults)="setDefaultSettings()"
        [isReadOnly]="readOnlyState"
      ></app-common-properties>
      <div *ngIf="!isIndexColumn">
        <form class="mt-3 eln-property-list" #form="ngForm" novalidate>
          <p-checkbox
            class="mb-3 property-with-sub-options"
            id="ContainsObservableData"
            i18n-label="@@editableDuringSetup"
            label="Editable During Setup"
            [(ngModel)]="editableInSetup"
            [binary]="true"
            (ngModelChange)="editableInSetupChanged($event)"
            [disabled]="disableEditableInSetup"
            name="containsObservableData"
            [ngClass]="{'eln-setup-editable-property-dynamic-margin' : columnSpecification.columnType !== 'quantity'}"
          >
          </p-checkbox>

          <div *ngIf="columnSpecification.columnType === 'quantity'">
            <p-radioButton
              class="setupEditableUnitOnly sub-option-radio-button"
              label="Only unit is editable"
              i18n-label="@@designerFieldDefinitionAttributeOnlyUnitIsEditable"
              [(ngModel)]="quantitySetupUnitOnly"
              [disabled]="disableQtySetupEditabilitySelections"
              (ngModelChange)="propertyChanged($event)"
              name="unitEditable"
              [value]="false"
            ></p-radioButton>
            <p-radioButton
              class="sub-option-radio-button"
              id="eln-table-designer-column-properties-setupEditableAll"
              label="Number & unit are editable"
              i18n-label="@@designerFieldDefinitionAttributeNumberAndUnitAreEditable"
              [(ngModel)]="quantitySetupUnitAndNum"
              [disabled]="disableQtySetupEditabilitySelections"
              (ngModelChange)="propertyChanged($event)"
              name="unitEditable"
              [value]="true"
            ></p-radioButton>
          </div>

          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-enablefiltering"
            i18n-label="@@designerPropertyNameEnableFiltering"
            label="Enable Filtering"
            [(ngModel)]="columnSpecification.filter"
            (ngModelChange)="propertyChanged($event)"
            [binary]="true"
            [disabled]="readOnlyState"
            name="filter"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-enablesorting"
            i18n-label="@@designerPropertyNameEnableSorting"
            label="Enable Sorting"
            [(ngModel)]="columnSpecification.sortable"
            (ngModelChange)="propertyChanged($event)"
            [binary]="true"
            [disabled]="readOnlyState"
            name="sortable"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-editable"
            i18n-label="@@designerPropertyEditableInExperiment"
            label="Editable in Experiment"
            [(ngModel)]="editableInExperiment"
            (ngModelChange)="propertyChanged($event)"
            [binary]="true"
            [disabled]="readOnlyState"
            name="editable"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-disablehiding"
            label="Disable Hiding"
            i18n-label="@@designerPropertyNameDisableHiding"
            [binary]="true"
            [(ngModel)]="columnSpecification.disableHiding"
            (ngModelChange)="handleDisableHidingChanges()"
            [disabled]="readOnlyState"
            name="disableHiding"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-hidden"
            i18n-label="@@designerPropertyNameHidden"
            label="Hidden By Default"
            [binary]="true"
            [(ngModel)]="columnSpecification.hidden"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState || columnSpecification.disableHiding!"
            name="hidden"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-alwaysHidden"
            label="Always Hidden"
            i18n-label="@@designerPropertyNameAlwaysHidden"
            [binary]="true"
            [(ngModel)]="columnSpecification.alwaysHidden"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState || columnSpecification.disableHiding!"
            name="alwaysHidden"
          >
          </p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-suppressColumnMenu"
            label="Suppress Column Menu"
            i18n-label="@@designerPropertyNameSuppressColumnMenu"
            [binary]="true"
            [(ngModel)]="columnSpecification.suppressColumnMenu"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
            name="suppressColumnMenu"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-suppressContextMenu"
            label="Suppress Context Menu"
            i18n-label="@@designerPropertyNameSuppressContextMenu"
            [binary]="true"
            [(ngModel)]="columnSpecification.suppressContextMenu"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
            name="suppressContextMenu"
          ></p-checkbox>
          <div
            class="eln-property-subtitle-1"
            i18n="@@tableDesignerPropertiesTitle"
            id="column-type-properties"
          >Column Type Properties</div>
          <label class="mt-3 mb-3" i18n="@@tableDesignerPropertyNameColumnType">Column Type</label>
          <bpt-dropdown
            class="mb-3"
            inputId="eln-table-designer-dropdown-input"
            [options]="columnInfoItems"
            labelField="name"
            valueField="columnType"
            (ngModelChange)="changeColumnType($event)"
            [(ngModel)]="interceptedColumnType"
            [readOnly]="readOnlyState"
            name="columnType"
          ></bpt-dropdown>
          <ng-container *ngIf="columnSpecification.columnType === 'editableList' && !columnSpecification.isKeyColumn">
            <div class="eln-properties-alignment">
              <label class="mb-3" i18n="@@listName">List Name</label>
              <div class="flex flex-row align-items-center">
                <bpt-dropdown #picklist
                name="listSource"
                class="flex-grow-1 mb-3 picklist-width"
                id="eln-table-designer-dropdown-list-input"
                [options]="listItems"
                labelField="name"
                valueField="id"
                [(ngModel)]="columnSpecification.listSource"
                (ngModelChange)="validateListName()"
                [readOnly]="readOnlyState"
                name="listSource"
                disabledField="isInactive"
                [placeholder]="placeholderPicklist"
                [showFilter]="true"
                i18n-pTooltip="@@listNameToolTipColumn"
                pTooltip="List name is the picklist that will appear under the column to the user."
                tooltipPosition="top"
                [showClear]="true"
                (dropdownClear)="validateListName()" />
              <button #picklistPreviewButton
                id="picklist-preview-kabob"
                class="pi pi-ellipsis-v border-none"
                [disabled]="!picklistPreviewButtonEnabled"
                i18n-pTooltip="@@showPicklistOptions"
                pTooltip="Show picklist options"
                tooltipPosition="left"
                (click)="previewPicklist($event)"
              ></button>
              <app-picklist-preview #picklistPreview
                id="picklist-preview-slider"
                [visible]="false"
                [allPicklists]="listItems"
                [(chosenPicklist)]="columnSpecification!.listSource"
              />
              </div>
            </div>
            <div
              *ngIf="listNameValidation && !listNameValidation.isValid()"
              class="eln-column-title-validation-message"
            >
              <app-validation-summary [validation]="listNameValidation"></app-validation-summary>
            </div>
            <div class="flex">
              <p-checkbox *ngIf="columnInfo?.hideAllowEditing"
                id="eln-table-designer-chkBox-allowCustomOptionsForDropdown"
                name="allowCustomOptionsForDropdown"
                class="mb-3"
                i18n-label="@@allowOffListEntry"
                label="Allow off-list entry"
                [binary]="true"
                [(ngModel)]="columnSpecification.allowCustomOptionsForDropdown"
                (ngModelChange)="allowCustomOptionsForDropdownChanged($event)"
                [disabled]="readOnlyState"
              >
              </p-checkbox>
              <em
                class="eln-column-properties-info-icon pi pi-info-circle"
                id="allowOffListEntryTooltip"
                i18n-pTooltip="@@allowOffListEntryTooltip"
                pTooltip="Allow user to enter text other than what's provided in the dropdown"
                tooltipPosition="left"
              ></em>
            </div>
            <p-checkbox *ngIf="columnInfo?.showAllowNA"
              id="eln-table-designer-chkBox-allowNA"
              name="allowNA"
              class="mb-3"
              i18n-label="@@designerPropertyNameAllowNA"
              label="Adding N/A"
              [binary]="true"
              [disabled]="readOnlyState"
              [(ngModel)]="columnSpecification.allowNA"
              (ngModelChange)="propertyChanged($event)"
            ></p-checkbox>
            <p-checkbox *ngIf="columnInfo?.showMultiSelect"
              id="eln-table-designer-chkBox-allowMultiSelect"
              class="mb-3"
              name="allowMultiSelect"
              i18n-label="@@tableDesignerColumnPropertyNameAllowDropdownMultiSelect"
              label="Allow Multi Select"
              [binary]="true"
              [(ngModel)]="columnSpecification.allowMultiSelect"
              [disabled]="readOnlyState"
              (ngModelChange)="propertyChanged($event)"
            ></p-checkbox>

            <app-items-as-options
              [readOnlyState]="readOnlyState"
              [(selectedItemsAsOptions)]="columnSpecification.activityItemReferenceTypes"
              (selectedItemsAsOptionsChange)="validateListName()"
            ></app-items-as-options>

          </ng-container>

          <ng-container *ngIf="columnSpecification.isKeyColumn">
            <p-checkbox
              class="mb-3"
              id="eln-table-designer-chkBox-enforceUniqueValues"
              i18n-label="@@tableDesignerPropertyNameEnforceUniqueValues"
              label="Enforce Unique Values"
              [binary]="true"
              name="enforceColumnUniqueness"
              [disabled]="readOnlyState"
              [(ngModel)]="columnSpecification.enforceColumnUniqueness"
              (ngModelChange)="propertyChanged($event)"
            ></p-checkbox>
            <label class="mb-3" i18n="@@tableDesignerPropertyNameListItems">List Items</label>
            <ng-container *ngFor="let keyColumnItem of keyColumnItems">
              <p-checkbox
                class="mb-3"
                label="{{ keyColumnItem.label }}"
                [binary]="true"
                name="{{ keyColumnItem.name }}"
                [disabled]="readOnlyState"
                [(ngModel)]="keyColumnItem.isSelected"
                (ngModelChange)="editAllowedKeyColumnTypes( $event,keyColumnItem.name)"
              ></p-checkbox>
            </ng-container>
            <p-checkbox
              class="mb-3"
              i18n-label="@@designerPropertyNameAllowNA"
              id="eln-table-designer-chkBox-allowNA"
              label="Adding N/A"
              [binary]="true"
              name="allowNA"
              [disabled]="readOnlyState"
              [(ngModel)]="columnSpecification.allowNA"
              (ngModelChange)="propertyChanged($event)"
            ></p-checkbox>
          </ng-container>

          <p-fieldset
            legend="Column Width"
            i18n-legend="@@tableDesignerPropertyNameWidth"
            styleClass="eln-fieldset-tiny eln-sub-properties"
          >
            <div>
              <p-radioButton
                id="eln-table-designer-column-properties-widthtypeAuto"
                label="Auto"
                i18n-label="@@tableDesignerPropertyNameWidthAuto"
                value="auto"
                [(ngModel)]="widthType"
                [disabled]="readOnlyState"
                (ngModelChange)="propertyChanged($event)"
                name="width"
              ></p-radioButton>
              <em
                class="eln-column-properties-info-icon pi pi-info-circle"
                pTooltip="Auto-sizes to fit the content when the table is initially loaded."
                tooltipPosition="bottom"
              ></em>
            </div>
            <div>
              <p-radioButton
                id="eln-table-designer-column-properties-widthflex"
                label="Flex"
                i18n-label="@@tableDesignerPropertyNameWidthFlex"
                value="*"
                [(ngModel)]="widthType"
                [disabled]="readOnlyState"
                (ngModelChange)="propertyChanged($event)"
                name="width"
              ></p-radioButton>
              <em
                class="eln-column-properties-info-icon pi pi-info-circle"
                pTooltip="Expands the column to fill remaining space when the table is initially loaded. This is usually used for the last column in the table."
                tooltipPosition="bottom"
              ></em>
            </div>
            <div class="eln-static-width-property">
              <p-radioButton
                class="mr-2"
                id="eln-table-designer-column-properties-NameWidthStatic"
                label="Static"
                i18n-label="@@tableDesignerPropertyNameWidthStatic"
                value="static"
                [(ngModel)]="widthType"
                [disabled]="readOnlyState"
                (ngModelChange)="propertyChanged($event)"
                name="width"
              ></p-radioButton>
              <bpt-numeric-input
                inputId="eln-table-designer-column-properties-widthType"
                cssClass="eln-numeric-tiny"
                [disabled]="widthType !== 'static'"
                [(ngModel)]="widthValue"
                [allowNegative]="false"
                [allowNA]="false"
                [allowDecimal]="false"
                [showButtons]="true"
                [allowKeyboardIncrement]="true"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="propertyChanged($event)"
                [readOnly]="readOnlyState"
                name="widthValue"
              ></bpt-numeric-input>
            </div>
          </p-fieldset>
          <p id="eln-table-designer-minimum-width-label" i18n="@@tableDesignerPropertyNameWidthMinimum">Minimum width</p>
          <bpt-numeric-input
            inputId="eln-table-designer-minimum-width-input"
            [(ngModel)]="columnSpecification.minWidth"
            [allowNegative]="false"
            [allowNA]="false"
            [readOnly]="readOnlyState"
            [allowDecimal]="false"
            [showButtons]="true"
            [allowKeyboardIncrement]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="minimumWidthChanged($event)"
            name="minWidth"
            [maxValue]="columnSpecification.maxWidth"
          >
          </bpt-numeric-input>
          <p id="eln-table-designer-maximum-width-label" i18n="@@maximumWidth">Maximum width</p>
          <bpt-numeric-input
            inputId="eln-table-designer-maximum-width-input"
            name="maxWidth"
            class="mb-3"
            [(ngModel)]="columnSpecification.maxWidth"
            [allowNegative]="false"
            [allowNA]="false"
            [allowDecimal]="false"
            [readOnly]="readOnlyState"
            [showButtons]="true"
            [allowKeyboardIncrement]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="maximumWidthChanged($event)"
            [minValue]="columnSpecification.minWidth"
          >
          </bpt-numeric-input>
          <p-checkbox
            *ngIf="columnSpecification.columnType === 'string'"
            id="eln-table-designer-chkBox-allowWordWrap"
            name="allowWordWrap"
            class="mb-3"
            i18n-label="@@tableDesignerColumnPropertyNameWrapText"
            label="Allow Word Wrap"
            [binary]="true"
            [(ngModel)]="columnSpecification.wrapText"
            [disabled]="readOnlyState"
            (ngModelChange)="wrapTextChanged($event)"
          >
          </p-checkbox>
          <p-checkbox
            *ngIf="
              columnInfo?.hideAllowEditing && columnSpecification.columnType === 'autoIncrement'
            "
            id="eln-table-designer-chkBox-allowCustomOptionsForDropdown"
            name="allowCustomOptionsForDropdown"
            class="mb-3"
            i18n-label="@@tableDesignerColumnPropertyNameAllowEditing"
            label="Allow User Inputs"
            [binary]="true"
            [(ngModel)]="columnSpecification.allowCustomOptionsForDropdown"
            [disabled]="readOnlyState || columnSpecification.allowMultiSelect!"
            (ngModelChange)="propertyChanged($event)"
          >
          </p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-disablegrouping"
            *ngIf="!columnInfo?.hideDisableGrouping"
            i18n-label="@@designerPropertyNameDisableGrouping"
            label="Disable Grouping"
            [binary]="true"
            [(ngModel)]="columnSpecification.disableGrouping"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
            name="disableGrouping"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            *ngIf="columnInfo?.showAllowNA && columnSpecification.columnType !== 'editableList'"
            id="eln-table-designer-chkBox-allowNA"
            i18n-label="@@designerPropertyNameAllowNA"
            label="Adding N/A"
            [binary]="true"
            name="allowNA"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowNA"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>

          <div *ngIf="columnSpecification.columnType === 'quantity'"
            id="column-type-number-properties"
            class="eln-property-subtitle-1 mb-3"
            i18n="@@tableDesignerNumberPropertiesTitle"
          >Number Properties</div>

          <p-checkbox
            class="mb-3"
            *ngIf="columnInfo?.showAllowNegative"
            label="Allow Negative"
            i18n-label="@@designerPropertyNameAllowNegative"
            id="eln-table-designer-chkBox-allowNegative"
            [binary]="true"
            name="allowNegative"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowNegative"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            *ngIf="columnInfo?.showAllowDecimal"
            label="Allow Decimal"
            i18n-label="@@designerPropertyNameAllowDecimal"
            id="eln-table-designer-chkBox-allowDecimal"
            [binary]="true"
            name="allowDecimal"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowDecimal"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
          >
          </p-checkbox>
          <p-checkbox
            class="mb-3"
            *ngIf="columnInfo?.showMultiLineText"
            label="Allow MultiLine Text"
            i18n-label="@@tableDesignerColumnPropertyNameAllowMultiLineText"
            id="eln-table-designer-chkBox-allowMultiLine"
            name="allowMultiLine"
            [disabled]="readOnlyState"
            [binary]="true"
            [(ngModel)]="columnSpecification.allowMultiLine"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
          >
          </p-checkbox>
          <p-checkbox
            class="mb-3"
            *ngIf="columnInfo?.showAutoResize"
            label="Allow AutoResize"
            name="allowAutoResize"
            i18n-label="@@tableDesignerColumnPropertyNameAllowAutoResizeText"
            id="eln-table-designer-chkBox-allowAutoResize"
            [binary]="true"
            [disabled]="!columnSpecification.allowMultiLine || readOnlyState"
            [(ngModel)]="columnSpecification.allowAutoResize"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>
          <p-checkbox *ngIf="columnInfo?.showCharLengthSettings"
            id="eln-table-designer-chkBox-allowMinCharLength"
            name="allowMinCharLength"
            class="mb-3"
            i18n-label="@@minimumTextLength"
            label="Min Text Length"
            [binary]="true"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowMinCharLength"
            [disabled]="readOnlyState"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>
          <bpt-numeric-input
            inputId="eln-column-properties-numeric-input"
            class="mb-3"
            *ngIf="columnInfo?.showCharLengthSettings"
            name="minCharLength"
            [disabled]="!columnSpecification.allowMinCharLength || readOnlyState"
            [(ngModel)]="columnSpecification.minCharLength"
            id="eln-table-designer-numTxt-minCharLength"
            [allowNegative]="false"
            [allowNA]="false"
            [allowDecimal]="false"
            [showButtons]="true"
            [maxValue]="columnSpecification.maxCharLength"
            [allowKeyboardIncrement]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="propertyChanged($event)"
          ></bpt-numeric-input>
          <p-checkbox *ngIf="columnInfo?.showCharLengthSettings"
            id="eln-table-designer-chkBox-allowMaxCharLength"
            name="allowMaxCharLength"
            i18n-label="@@maximumTextLength"
            label="Max Text Length"
            class="mb-3"
            [binary]="true"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowMaxCharLength"
            [disabled]="readOnlyState"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>
          <bpt-numeric-input
            class="mb-3"
            *ngIf="columnInfo?.showCharLengthSettings"
            name="maxCharLength"
            [disabled]="!columnSpecification.allowMaxCharLength || readOnlyState"
            [(ngModel)]="columnSpecification.maxCharLength"
            inputId="eln-table-designer-numTxt-maxCharLength"
            [allowNegative]="false"
            [allowNA]="false"
            [allowDecimal]="false"
            [minValue]="columnSpecification.minCharLength"
            [showButtons]="true"
            [allowKeyboardIncrement]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="propertyChanged($event)"
          ></bpt-numeric-input>
          <p-checkbox *ngIf="columnInfo?.showNumericLimit"
            id="eln-table-designer-chkBox-allowMinNumericValue"
            name="allowMinNumericValue"
            label="Min Value"
            i18n-label="@@minimumNumericValue"
            class="mb-3"
            [binary]="true"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowMinNumericValue"
            [disabled]="readOnlyState"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>
          <bpt-numeric-input
            class="mb-3"
            *ngIf="columnInfo?.showNumericLimit"
            [disabled]="!columnSpecification.allowMinNumericValue || readOnlyState"
            [(ngModel)]="columnSpecification.minNumericValue"
            name="minNumericValue"
            inputId="eln-table-designer-numTxt-minNumericValue"
            [allowNegative]="columnSpecification.allowNegative ?? false"
            [allowNA]="columnSpecification.allowNA ?? false"
            [allowDecimal]="columnSpecification.allowDecimal ?? false"
            [maxValue]="columnSpecification.maxNumericValue"
            [showButtons]="true"
            [allowKeyboardIncrement]="true"
          >
          </bpt-numeric-input>

          <p-checkbox *ngIf="columnInfo?.showNumericLimit"
            id="eln-table-designer-chkBox-allowMaxNumericValue"
            name="allowMaxNumericValue"
            label="Max Value"
            i18n-label="@@maximumNumericValue"
            class="mb-3"
            [binary]="true"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowMaxNumericValue"
            [disabled]="readOnlyState"
            (ngModelChange)="propertyChanged($event)"
          ></p-checkbox>
          <bpt-numeric-input
            class="mb-3"
            *ngIf="columnInfo?.showNumericLimit"
            [disabled]="!columnSpecification.allowMaxNumericValue || readOnlyState"
            [(ngModel)]="columnSpecification.maxNumericValue"
            inputId="eln-table-designer-numTxt-maxNumericValue"
            [allowNegative]="columnSpecification.allowNegative ?? false"
            [allowNA]="columnSpecification.allowNA ?? false"
            [allowDecimal]="columnSpecification.allowDecimal ?? false"
            [minValue]="columnSpecification.minNumericValue"
            [showButtons]="true"
            [allowKeyboardIncrement]="true"
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="propertyChanged($event)"
            name="maxNumericValue"
          >
          </bpt-numeric-input>

          <p-checkbox *ngIf="columnInfo?.showDateOptions"
            id="eln-table-designer-chkBox-allowMinDate"
            name="allowMinDate"
            i18n-label="@@minimumDate"
            label="Min Date"
            class="mb-3"
            [binary]="true"
            [disabled]="readOnlyState"
            [(ngModel)]="columnSpecification.allowMinDate"
            [disabled]="readOnlyState"
            (ngModelChange)="minDateSelectionChanged($event)"
          >
          </p-checkbox>
          <bpt-datetime
            #bptMinimumDateControl
            class="mb-3"
            *ngIf="columnInfo?.showDateOptions"
            [disabled]="!columnSpecification.allowMinDate || readOnlyState"
            [(ngModel)]="columnSpecification.minDate"
            [useJsJodaTypes]="true"
            id="eln-table-designer-date-minDate"
            [includeTime]="includeTimeSelect ?? false"
            (ngModelChange)="propertyChanged($event); setMinAndMaxDatesLimit()"
            [maxDate]="columnSpecification.maxDate ?? ''"
            [minDate]="columnSpecification.minDate ?? ''"
            name="minDate"
            [ianaTimeZone]="labSiteTimeZone"
          ></bpt-datetime>

          <p-checkbox *ngIf="columnInfo?.showDateOptions"
            id="eln-table-designer-chkBox-allowMaxDate"
            name="allowMaxDate"
            label="Max Date"
            i18n-label="@@maximumDate"
            class="mb-3"
            [binary]="true"
            [(ngModel)]="columnSpecification.allowMaxDate"
            [disabled]="readOnlyState"
            (ngModelChange)="maxDateSelectionChanged($event)"
          >
          </p-checkbox>
          <bpt-datetime
            #bptMaximumDateControl
            class="mb-3"
            *ngIf="columnInfo?.showDateOptions"
            [disabled]="!columnSpecification.allowMaxDate || readOnlyState"
            [(ngModel)]="columnSpecification.maxDate"
            [useJsJodaTypes]="true"
            id="eln-table-designer-date-maxDate"
            [includeTime]="includeTimeSelect ?? false"
            (ngModelChange)="propertyChanged($event); setMinAndMaxDatesLimit()"
            [hourFormat]="'12'"
            name="maxDate"
            [ianaTimeZone]="labSiteTimeZone"
            [maxDate]="columnSpecification.maxDate ?? ''"
            [minDate]="columnSpecification.minDate ?? ''"
          >
          </bpt-datetime>

          <p-checkbox
            class="mb-3 property-with-sub-options"
            *ngIf="columnInfo?.showTimeSelect"
            label="Show Time"
            i18n-label="@@showTimeInCalendarEditor"
            id="eln-table-designer-chkBox-allowTimeSelect"
            [binary]="true"
            [(ngModel)]="showTime"
            (ngModelChange)="handleDateAndTimeChanges('allowTimeSelect', $event)"
            name="allowTimeSelect"
            [disabled]="readOnlyState"
          >
          </p-checkbox>

          <div *ngIf="columnInfo?.showTimeSelect">
            <div>
              <p-radioButton
                id="eln-table-designer-column-properties-timeRequired"
                class="sub-option-radio-button"
                label="Required"
                i18n-label="@@required"
                [(ngModel)]="timeRequirement"
                [disabled]="!showTime"
                (ngModelChange)="handleDateAndTimeChanges('timeRequired', $event)"
                name="timeRequired"
                value="required"
              ></p-radioButton>
            </div>
            <div>
              <p-radioButton
                id="eln-table-designer-column-properties-timeOptional"
                class="sub-option-radio-button"
                label="Optional"
                i18n-label="@@optional"
                [(ngModel)]="timeRequirement"
                [disabled]="!showTime || readOnlyState"
                (ngModelChange)="handleDateAndTimeChanges('timeOptional', $event)"
                name="timeOptional"
                value="optional"
                ></p-radioButton>
              </div>
          </div>

          <p-checkbox
          class="mb-3"
          *ngIf="columnInfo?.showDateOptions"
          label="Show Button Bar"
          i18n-label="@@showButtonBar"
          [binary]="true"
          [(ngModel)]="columnSpecification.showButtonBar"
          (ngModelChange)="propertyChanged($event)"
          name="showButtonBar"
          i18n-pTooltip="@@showButtonBarToolTip"
          pTooltip="Show Today/Now and Clear buttons"
          tooltipPosition="top"
          [disabled]="readOnlyState"
        >
        </p-checkbox>

          <ng-container *ngIf="columnSpecification.columnType === 'quantity'">
            <ng-container *ngTemplateOutlet="UnitProperties"></ng-container>
          </ng-container>

          <ng-container *ngIf="columnSpecification.columnType === 'specification'">
            <div
              id="column-type-specification-properties"
              class="eln-property-subtitle-1 mb-3"
              i18n="@@tableDesignerSpecificationPropertiesTitle"
              >Specification Properties</div>
            <label class="mb-3" i18n="@@designerAllowedSpecifications">Allowed Specifications</label>

            <bpt-dropdown
              class="mb-3"
              inputId="eln-table-designer-dropdown-input"
              [options]="allowedSpecificationTypes"
              labelField="label"
              valueField="value"
              [(ngModel)]="columnSpecification.allowedSpecTypes"
              [readOnly]="readOnlyState"
              name="allowedSpecTypes"
              [multiSelect]="true"></bpt-dropdown>

            <ng-container>
              <ng-container *ngTemplateOutlet="UnitProperties"></ng-container>
            </ng-container>

          </ng-container>
        </form>
      </div>

      <div *ngIf="isIndexColumn">
        <form class="mt-3 eln-property-list" #form="ngForm" novalidate>
          <label class="mb-3" i18n="@@tableDesignerPropertyNameColumnType">Column Type</label>
          <bpt-dropdown
            id="eln-table-designer-dropdown-input"
            [options]="serverColumnInfoItems"
            labelField="name"
            valueField="columnType"
            [(ngModel)]="columnSpecification.columnType"
            [readOnly]="true"
            name="columnType"
          ></bpt-dropdown>
          <p-checkbox
            class="mt-3 mb-3"
            id="eln-table-designer-column-properties-hidden"
            i18n-label="@@designerPropertyNameHidden"
            label="Hidden By Default"
            [binary]="true"
            [(ngModel)]="columnSpecification.hidden"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
            name="hidden"
          ></p-checkbox>
          <p-checkbox
            class="mb-3"
            id="eln-table-designer-column-properties-alwaysHidden"
            label="Always Hidden"
            i18n-label="@@designerPropertyNameAlwaysHidden"
            [binary]="true"
            [(ngModel)]="columnSpecification.alwaysHidden"
            (ngModelChange)="propertyChanged($event)"
            [disabled]="readOnlyState"
            name="alwaysHidden"
          ></p-checkbox>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="!columnSpecification"
    class="eln-properties-notselected"
    i18n="@@tableDesignerSelectColumnToViewProperties"
  >Select a column to view its properties</div>
  <div *ngIf="columnSpecification?.field === isRemovedColumn"
    class="eln-properties-notselected"
    i18n="@@rowRemovalColumnNotCustomizableMessage"
  >Column is not customizable when the table is configured to allow row removal</div>
</div>
