import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ColumnSpecification, SpecificationValue, Table, TableValueRow } from '../../../model/experiment.interface';
import {
  BptGridCellValueChangedEvent,
  BptGridComponent,
  BptGridRowsAddedEvent,
  CellObjectCallback,
  ColumnDefinition,
  DropdownCellEditorParamsDefaults,
  GridContextMenuItem,
  RowSelection
} from 'bpt-ui-library/bpt-grid';
import { ConfirmationService, MenuItem, MessageService, OverlayOptions } from 'primeng/api';
import {
  AddRecipeRowCommand,
  AddRecipeRowResponse,
  ChangeRecipeCellCommand,
  ElnCell,
  ExperimentDataValue,
  NumberValue,
  StringValue,
  ValueType,
  NodeType,
  RecipeState,
  RecipePreLoadDataTransformContextType
} from '../../../api/cookbook/models';
import { Quantity, ValueState } from 'bpt-ui-library/shared';
import { DataValueService, FieldOrColumnType } from '../../../experiment/services/data-value.service';
import { cloneDeep, isEqual, mapValues, remove } from 'lodash-es';
import { ClientValidationDetails } from '../../../model/client-validation-details';
import { ColumnType, ModifiableDataValue, RuleEvents, SpecType, TemplateType, Unit } from '../../../api/models';
import { DataRecordService } from '../../../experiment/services/data-record.service';
import { RecipeEventsService } from '../../../api/cookbook/services';
import { Subscription, Subject, finalize, take } from 'rxjs';
import { CellDoubleClickedEvent, ColumnApi, EditableCallbackParams, RowNode } from 'ag-grid-community';
import { UnitLoaderService } from '../../../services/unit-loader.service';
import { RecipeService, SpecificationEditorContext, SpecificationPreloadScalingOptions } from '../../services/recipe.service';
import { v4 as uuid } from 'uuid';
import { RecipeNodeRetitleService } from '../../services/recipe-node-re-title.service';
import { RuleActionNotificationService, SetValueNotificationEvent } from '../../../rule-engine/action-notification/rule-action-notification.service';
import { RuleHandler } from '../../../rule-engine/rule-handler';
import { RuleActionNotification } from '../../../rule-engine/actions/rule-action-notification';
import { ChangeCellCommand } from '../../../api/data-entry/models';
import { RuleActionObjectResult } from '../../../rule-engine/actions/rule-action-result';
import { CellFullContext } from '../../../experiment/data/table/table.component';
import { FillWithNaGridHelper, NaGridTableIdentifierData } from '../../../services/fill-with-na-grid-helper';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/user.interface';
import { UnsubscribeAll } from '../../../shared/rx-js-helpers';
import { AugmentedTable } from '../../model/recipe';
import { TemplateDeleteService } from '../../../recipe-template-loader/experiment-template-load/services/template-delete.service';
import { RepeatForEachChooserComponent, RepeatForEachOption } from '../../repeat-for-each-chooser/repeat-for-each-chooser.component';
import { RepeatGroupRemovalArgs, TableDataService } from '../../../experiment/data/table/table-data.service';
import { AddRowEventParams } from 'bpt-ui-library/bpt-grid/model/add-row-event-params.interface';

const missingGridIdMessage = 'Logic error: When a grid is used to render a table, gridId must be set to tableId but gridId is missing.';
const missingRowIdMessage = 'Logic error: When a grid creates a row, it must set rowId but rowId is missing.';
const missingFieldMessage = 'Logic error: When a grid is used to render a table, each column definition must have a field value but at least one is missing.';
const missingColumnMessage = 'Logic error: When a grid event has a cell value the field property must identify a column but its definition is missing.';
const repeatingError = $localize`:@@repeatingError:Repeating groups cannot contain rows which are already part of another repeating group.`;
const repeatingGroupMaxExceededError = $localize`:@@repeatingGroupMaxExceeded:Number of repeat for each groups cannot exceed 26 per table.`

@Component({
  selector: 'app-recipe-data-table',
  templateUrl: './recipe-table.component.html',
  styleUrls: ['./recipe-table.component.scss']
})
export class RecipeTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() table!: Table;
  @Input() recipeId!: string;
  @Input() isRecipe = false;
  @ViewChild('Grid') grid!: BptGridComponent;
  @ViewChild('RepeatModal') repeatModal!: RepeatForEachChooserComponent;
  isLoading = false;
  validation!: ClientValidationDetails;
  primitiveValue!: { [key: string]: any }[];
  items!: MenuItem[];
  retitleEnabled = false;
  currentContextMenuTableId!: string;
  /** @deprecated This exists only to return data to certain tests. Do not use for any other purpose. */
  cellChangedValues!: ChangeRecipeCellCommand;
  numberOfRows = 0;
  columnsToPopulateByAddRowResponse = ['rowIndex'];
  suppressContextMenu = false;
  repeatForModal = false;
  gridPaginationOverlayOptions: OverlayOptions = {
    appendTo: 'body',
    baseZIndex: 2000
  };
  cellObjectCallbacks!: { [colType: string]: CellObjectCallback };
  nodeType = NodeType;
  @Input() parentNodeId: string[] = [];
  currentUser!: User;
  recipeHasErrors = false;

  get nonRemovedData(): { [key: string]: any }[] {
    return this.primitiveValue?.filter((row: { [key: string]: any }) => !TableDataService.rowIsRemoved(row)) ?? [];
  }

  private get nonRemovedRows(): { [key: string]: any }[] {
    return this.table?.value.filter((row: { [key: string]: any }) => !TableDataService.rowIsRemoved(row)) ?? [];
  }

  private _ruleHandler!: RuleHandler;
  public get RuleHandler(): RuleHandler {
    return this._ruleHandler;
  }

  private readonly subscriptions: Subscription[] = [];

  get isUserAllowedToEdit() {
    return this.recipeService.currentRecipe.tracking.state === RecipeState.Draft
      && this.recipeService.currentRecipe.tracking.assignedEditors.includes(this.currentUser.puid)
      && !this.recipeService.recipeHasErrors;
  }

  get selectedCount(): number {
    if (!this.grid?.gridApi) return 0;
    if (!this.table.allowRowAdd) return 0;

    return this.grid?.gridApi?.getSelectedRows().length;
  }

  get showRepeatForEachButton(): boolean {
    return this.table.allowRowAdd && !!this.selectedCount;
  }

  rowSelection: RowSelection = 'multiple';

  get columnDefinitions(): ColumnDefinition[] {
    return this.table?.columnDefinitions as ColumnDefinition[] || [];
  }

  /** IANA time zone id for lab site */
  get labSiteTimeZone(): string {
    return UserService.currentLabSiteTimeZone.id();
  }

  get containsRemovedRows(): boolean {
    return this.table.value.some(TableDataService.rowIsRemoved);
  }

  beforeAddRow = (params: AddRowEventParams, addRows: (params: AddRowEventParams) => void) => {
    this.tableDataService.refreshBeforeAddRow(this, this.grid, params, addRows);
  };

  constructor(
    private readonly dataValueService: DataValueService,
    private readonly recipeEventsService: RecipeEventsService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly recipeService: RecipeService,
    private readonly templateDeleteService: TemplateDeleteService,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly fillWithNaGridHelper: FillWithNaGridHelper,
    private readonly userService: UserService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    @Inject('RecipeTableDataService') private readonly tableDataService: TableDataService<'recipe'>
  ) {
    this.validation = new ClientValidationDetails();
    this.watchRuleActions();
  }

  private watchRuleActions() {
    this.watchRuleActionsOfSetCellValue();
    this.watchRuleActionsOfAddBlankRow();
  }

  private watchRuleActionsOfSetCellValue() {
    this.subscriptions.push(
      this.ruleActionNotificationService.SetCellValueActionNotification.subscribe({
        next: this.applyRuleCellValue.bind(this)
      })
    );
  }

  private watchRuleActionsOfAddBlankRow() {
    this.subscriptions.push(
      this.ruleActionNotificationService.AddNewRowActionNotification.subscribe({
        next: this.addingNewRowInstructionFromRule.bind(this)
      })
    );
    this.currentUser = { ...this.userService.currentUser };
    this.cellObjectCallbacks = {
      specification: (_rawData, rowDefinition, columnDefinition) => {
        const row = this.table.value.find(r => r.id === rowDefinition.id);
        if (!row) throw new Error('Row ID not in table ' + rowDefinition.id);
        if (!columnDefinition.field) throw new Error('Field not not defined for column');

        return row[columnDefinition.field].value;
      }
    };
    this.updateTableModel.bind(this);
  }

  private addingNewRowInstructionFromRule(
    notification: RuleActionNotification<SetValueNotificationEvent>
  ) {
    console.log('Rule Notification', notification);
    if (this.table.tableId !== notification.ruleContext.templateInstanceId) {
      return;
    }
    this.grid.addRows(new Event(JSON.stringify(notification.ruleContext)));
  }

  ngOnInit(): void {
    // Note: This should be setting primitiveValue per our practices. But primitiveValue is not set until later.
    this.initializeRuleHandler();
    this.addSubscriptions();
    this.isRecipe = this.isRecipe || ((this.table as AugmentedTable).isRecipeNode ?? false);
  }

  private setTableValues() {
    if (!this.table) {
      return;
    }

    this.buildContextMenuItems();
    this.primitiveValue = this.tableDataService.getPrimitiveDataValueRows(this);
    this.numberOfRows = this.table.value.length;
    this.setColumnProperties();
    this.suppressContextMenu = this.table.columnDefinitions?.some(c => c.suppressContextMenu) ?? false;
  }

  private initializeRuleHandler(): void {
    this._ruleHandler = new RuleHandler(
      this.table.tableId,
      this.table.templateId,
      this.table.rules || [],
      this.ruleActionNotificationService
    );
  }

  onContextMenu() {
    this.currentContextMenuTableId = this.table.tableId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.table) {
      if (this.table && this.table.columnDefinitions) {
        this.setTableValues();
      }
    }
  }

  ngOnDestroy() {
    UnsubscribeAll(this.subscriptions);
  }

  showRepeatForModal() {
    const stepNumberCol = this.columnDefinitions.find(col => col.columnType === ColumnType.StepNumber);
    const indexCol = this.columnDefinitions.find(col => col.columnType === ColumnType.Index);
    if (!indexCol) throw new Error('LOGIC ERROR: table is missing an index column.');

    const validationMsg = this.getRepeatGroupValidationMsg(stepNumberCol ?? indexCol);
    if (validationMsg) {
      // toast & bail.
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@error:Error`,
        detail: validationMsg
      });
      return;
    }

    const fields = [TableDataService.repeatForField, TableDataService.repeatWithField];
    const existingValue = fields.some(col => this.grid.gridApi.getSelectedRows().some(row => col in row && !!row[col]));

    if (existingValue) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@error:Error`,
        detail: repeatingError
      });
      return;
    }

    const nextGrouping = this.calculateNextRepeatGroupNumber();

    if (nextGrouping > 26) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@error:Error`,
        detail: repeatingGroupMaxExceededError
      });
      return;
    }

    this.repeatModal.show();
  }

  repeatGroupChosen(repeatFor: RepeatForEachOption) {
    const rows = this.grid?.gridApi?.getSelectedRows();
    const grouping = this.calculateNextRepeatGroupNumber();

    if (rows) {
      rows.forEach(row => {
        this.cellValueEditing({
          type: 'cellValueChanged',
          oldValue: row.value,
          newValue: repeatFor,
          rowId: row.id,
          field: TableDataService.repeatForField,
          gridId: this.grid.gridId
        });

        this.cellValueEditing({
          type: 'cellValueChanged',
          oldValue: row.value,
          newValue: grouping,
          rowId: row.id,
          field: TableDataService.repeatWithField,
          gridId: this.grid.gridId
        });
      });
      TableDataService.setRepeatColumnHiddenState(this.table, this.grid);
    }
    this.grid.gridApi.deselectAll();
    this.grid.gridApi.refreshCells();
  }

  public repeatGroupRemovalRequested(args: RepeatGroupRemovalArgs) {
    args.originalEvent.stopImmediatePropagation();
    const confirmationMessage = $localize`:@@removeRecipeRepeatGroupConfirmation:Are you sure you want to remove this group?`;
    this.confirmationService.confirm({
      message: `${confirmationMessage}`,
      header: $localize`:@@confirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      closeOnEscape: true,
      dismissableMask: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.repeatGroupRemoved(args);
      },
      reject: () => { }
    });
  }

  public repeatGroupRemoved(args: RepeatGroupRemovalArgs) {
    const rows = this.table?.value?.filter(row => TableDataService.repeatWithField in row
      && (parseInt(((row[TableDataService.repeatWithField].value as StringValue | undefined)?.value ?? row[TableDataService.repeatWithField]) as string)) === args.groupNumber);

    if (!rows) return;

    rows.forEach(row => {
      this.cellValueEditing({
        type: 'cellValueChanged',
        oldValue: row.value,
        newValue: '',
        rowId: row.id,
        field: TableDataService.repeatForField,
        gridId: this.grid.gridId
      });

      this.cellValueEditing({
        type: 'cellValueChanged',
        oldValue: row.value,
        newValue: '',
        rowId: row.id,
        field: TableDataService.repeatWithField,
        gridId: this.grid.gridId
      });

      TableDataService.setRepeatColumnHiddenState(this.table, this.grid);
    });
    this.grid.gridApi.refreshCells({ force: true });
  }

  private calculateNextRepeatGroupNumber(): number {

    const repeatWithValues = this?.table?.value
      .map(row => TableDataService.repeatWithField in row ? +((row[TableDataService.repeatWithField].value as NumberValue | undefined)?.value ?? '0') : 0);

    // get unique values, drop the zero value if applicable, and sort ascending.
    const repeatWithSequence = [...new Set(repeatWithValues)].filter(n => n > 0).sort((a: number, b: number) => a - b);

    // since all 0s have been filtered out, no values here means no groups exist
    if (repeatWithSequence.length === 0) return 1;

    let newGroupNumber: number | undefined = undefined;
    for (let index = 0; index < repeatWithSequence.length; index++) {
      // identify the first break in the sequence by comparing the array index with the value.
      if (repeatWithSequence[index] > index + 1) {
        newGroupNumber = index + 1;
        break;
      }
    }

    // return the first break in the sequence, or the next value in the sequence.
    return newGroupNumber ?? Math.max(...repeatWithSequence) + 1;
  }

  private addSubscriptions() {
    this.subscriptions.splice(0, 0,
      this.recipeService.recipeWorkFlowState.subscribe(() => {
        this.setTableValues();
      }),
      this.recipeService.isRecipeUsersChanged.subscribe({
        next: () => {
          this.buildContextMenuItems();
        }
      }),
      this.recipeService.handleRecipeError.subscribe(value => {
        if (value) {
          this.recipeHasErrors = true;
          this.buildContextMenuItems();
        }
      }),
      this.tableDataService.rowRemoved.subscribe(() => this.refreshDataSource()),
      this.tableDataService.rowRestored.subscribe(() => this.refreshDataSource()),
      this.recipeService.columnDefinitionsReady$
        .pipe(take(1))
        .subscribe(() => {
          this.setTableValues();
        })
    );
  }

  public applyRuleCellValue(notification: RuleActionNotification<SetValueNotificationEvent>) {
    console.log('SetCell');
    console.log(notification);
    console.log('Rule Notification', notification);

    if (this.table.tableId !== notification.ruleContext.templateInstanceId) {
      return;
    }
    const ruleAction = notification.action as RuleActionObjectResult;
    const cellContext = this.findRowFromTableAndGrid(
      notification.sourceEvent.currentRow.id as string,
      notification.action.Target
    );

    try {
      if (cellContext.valid) {
        const changeCellCommand: ChangeRecipeCellCommand = {
          recipeId: this.recipeId,
          rowIds: [cellContext.rowId],
          columnValues: [this.getCellForRuleAction(cellContext.fieldId, ruleAction.Value)],
          tableIds: [this.table.tableId],
          ruleContext: notification.ruleContext
        };
        if (
          cellContext.gridRowNode?.data[cellContext.fieldId]?.value?.value.value === (ruleAction.Value.state ? ruleAction.Value.value : ruleAction.Value)
        ) {
          this._ruleHandler.continueOnCorrelatedRulesEvaluation(
            RuleEvents.CellChanged,
            { changeCellCommand, rows: this.table.value },
            JSON.stringify(notification.ruleContext)
          );
        } else {
          this.handleApplyCellValue(notification, cellContext, changeCellCommand);
        }
      }
    } catch (error) {
      console.error(
        'ELN-RuleEngine: could not set value on cell',
        notification.action,
        cellContext.tableRow,
        error
      );
    }
  }

  private handleApplyCellValue(notification: RuleActionNotification<SetValueNotificationEvent>, cellContext: CellFullContext, changeCellCommand: ChangeRecipeCellCommand) {
    const cellColumnDefinition = this.columnDefinitions.find(s => s.field === cellContext.columnDefinition.field);
    if (cellColumnDefinition && cellColumnDefinition.containsObservableData) {
      if (this.recipeService.currentRecipe.tracking.state === RecipeState.Draft) return;
    }
    this.setRulePrimitiveValue(notification, cellContext, changeCellCommand);
    this.cellChangeServiceCall(changeCellCommand);
  }

  private cellChangeServiceCall(changeCellCommand: ChangeRecipeCellCommand) {
    remove(changeCellCommand.columnValues, (v => v.propertyName === TableDataService.rowSelectedField || v.propertyName === TableDataService.repeatField));
    if (changeCellCommand.columnValues.length === 0) {
      this.isLoading = false;
      return;
    }

    this.recipeEventsService
      .recipeEventsChangeCellsPost$Json({ body: cloneDeep(changeCellCommand) })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.validation.successes.push(
            $localize`:@@TableRowEditedSuccessfully:Row Edited successfully`
          );
          this.postCellChangeFeedback(changeCellCommand)
        },
        error: () => {
          this.validation.errorTitle = $localize`:@@receivedErrorFromServer:Received the following error from server`;
          this.isLoading = false;
        }
      });
  }

  private postCellChangeFeedback(changeCellCommand: ChangeRecipeCellCommand) {
    const recipeCurrentTable = this.recipeService.currentRecipe.tables.find(table => table.tableId === this.table.tableId);
    if (recipeCurrentTable) {
      changeCellCommand.rowIds.forEach(rowId => {
        changeCellCommand.columnValues.forEach(columnValue => {
          const currentRow = recipeCurrentTable.value.find(r => r.id === rowId) as {
            [key: string]: ModifiableDataValue;
          };
          currentRow[columnValue.propertyName] = DataRecordService.getModifiableDataValue(columnValue.propertyValue, currentRow[rowId]);
        })
      })
    }
    this.tableDataService.postChangeCellCommand(this, changeCellCommand);
  }

  private findRowFromTableAndGrid(rowId: string, fieldId: string): CellFullContext {
    const gridRowNode = this.grid.gridApi.getRowNode(rowId);
    const tableRow = this.table.value.find((r) => r.id === rowId) as {
      [key: string]: ModifiableDataValue;
    };
    const columnDefinition = this.table.columnDefinitions?.find((c) => c.field === fieldId);
    let valid = false;
    if (gridRowNode && columnDefinition?.columnType) {
      valid = true;
    }
    return {
      rowId,
      gridRowNode: gridRowNode as RowNode,
      tableRow,
      columnDefinition: columnDefinition as ColumnSpecification,
      fieldId,
      valid
    };
  }

  private setRulePrimitiveValue(
    notification: RuleActionNotification<SetValueNotificationEvent>,
    cellContext: CellFullContext,
    ChangeCellCommand: ChangeRecipeCellCommand
  ) {
    const ruleAction = notification.action as RuleActionObjectResult;

    const parsedDataValue =
      ruleAction.Value?.state
        ? ruleAction.Value
        : this.dataValueService.getExperimentDataValue(
          cellContext.columnDefinition?.columnType,
          ruleAction.Value
        );

    const primitiveValue = this.dataValueService.getPrimitiveValue(
      cellContext.columnDefinition.columnType,
      { isModified: true, value: parsedDataValue },
      cellContext.columnDefinition.allowMultiSelect ?? false
    );
    this.updateTableModel(ChangeCellCommand, []);
    cellContext.gridRowNode?.setDataValue(
      cellContext.fieldId,
      primitiveValue,
      JSON.stringify(notification.ruleContext)
    );
  }

  cellValueEditing(e: BptGridCellValueChangedEvent): void {
    if (!e.field || !this.isUserAllowedToEdit) return;

    if (this.columnDefinitions.find(columnDefinition => columnDefinition.field === e.field)?.columnType === ColumnType.StepNumber) {
      if (!e.rowId) return;

      const getPrimitiveNumber = (mdv: ModifiableDataValue) => (mdv?.value as NumberValue)?.value;
      // ignore SonarQube warning on e.field!. This is guarded against on first line in method
      const oldStepNumbers = this.nonRemovedRows.map(row => ({ id: row.id, step: +(getPrimitiveNumber(row[e.field!]) ?? 0) }));
      const changingRow = oldStepNumbers.find(oldStepNumber => oldStepNumber.id === e.rowId);
      if (changingRow) changingRow.step = +e.newValue;
      const proposedNewStepNums = this.getNewStepNumbers(e.rowId, oldStepNumbers);

      // Check if proposedNewStepNums are contiguous for all groups
      const groupBy = (items: any, key: string) => items.reduce((retVal: any, item: any) => {
        const groups = retVal[item[key]] = retVal[item[key]] ?? [];
        groups.push(item);
        return retVal;
      }, {});
      const repeatGroups = this.nonRemovedRows.map(row => ({ id: row.id, repeatGroup: (row[TableDataService.repeatWithField]?.value as StringValue).value }));
      const groups = groupBy(repeatGroups, 'repeatGroup');
      delete groups.undefined;
      const repeatGroupProjections: { proposedSteps: number[] }[] = Object.keys(groups).map(repeatGroupNumber => ({
        proposedSteps: groups[repeatGroupNumber].map((group: { id: string }) => proposedNewStepNums.find(proposedNewStepNumber => proposedNewStepNumber.id === group.id)?.step)
      }));

      const anyGroupNoncontiguous = repeatGroupProjections.some(projection => !this.isContiguous(projection.proposedSteps));
      if (anyGroupNoncontiguous) {
        // toast & bail.
        this.messageService.add({
          key: 'notification',
          severity: 'error',
          summary: $localize`:@@error:Error`,
          detail: $localize`:@@invalidStepChangeForRepeatForEach:Invalid step number change. Repeat for Each groups must contain sequential Step Numbers.`
        });
        this.refreshDataSource();
        return;
      }
    }
    this.cellValueChanged(e);
  }

  /**
   * Handle cell value changed due to reason indicated by e.source:
   */
  cellValueChanged(e: BptGridCellValueChangedEvent) {
    if (!e.gridId) throw new Error(missingGridIdMessage);
    if (!e.rowId) throw new Error(missingRowIdMessage);
    if (!e.field) throw new Error(missingFieldMessage);

    const row = this.getChangedRow(e);
    if (!row) return;

    const column = this.table.columnDefinitions.find((c) => c.field === e.field);
    if (!column) throw new Error(missingColumnMessage);

    // For Specification, it's possible to get into here where it's trying to paste a non-spec (like a string) into a spec column
    // If that happens then this method will be hit a second time and contain the correct data (if the user in fact copied a spec from ELN)
    if (column.columnType === ColumnType.Specification && e.newValue && !e.newValue?.type) return;

    const oldCellValue = cloneDeep(row);
    const newValue = this.dataValueService.getExperimentDataValue(column.columnType, e.newValue);

    const _oldCellValue: ModifiableDataValue = {
      isModified: oldCellValue[e.field].isModified,
      value: oldCellValue[e.field].value
    };
    if (isEqual(newValue, _oldCellValue.value)) return;

    const newCellValue = DataRecordService.getModifiableDataValue(newValue, _oldCellValue);
    if (isEqual(newCellValue, _oldCellValue)) return;

    if (newCellValue && !newCellValue.value) throw Error('Expected newCellValue to be a ModifiableDataValue');

    if (e.source !== 'specificationEdit') row[e.field] = newCellValue;
    if (e.newValue === undefined || e.source === 'systemFields' || e.source === 'specificationEdit') return;

    if (e.newValue instanceof Quantity) DataValueService.pruneQuantity(e.newValue);

    const cellChangedValues = {
      recipeId: this.recipeId,
      rowIds: [e.rowId],
      columnValues: [
        {
          propertyName: e.field,
          propertyValue: newValue as ExperimentDataValue
        }
      ],
      tableIds: [e.gridId]
    };
    this.cellChangedValues = cellChangedValues;
    this.postChangeCellCommand(cellChangedValues, e.source, [oldCellValue]);
  }

  private getChangedRow(e: BptGridCellValueChangedEvent) {
    e.rowId = this.handleModifiableRowId(e.rowId);
    if (e.newValue instanceof Quantity) DataValueService.pruneQuantity(e.newValue);

    return this.table.value.find((row: TableValueRow) => row.id === e.rowId);
  }

  isGridReadOnly() {
    return this.table.allowReadOnly || this.isRecipe || (!this.isUserAllowedToEdit) || this.recipeHasErrors;
  }

  handleModifiableRowId(rowId: any): string {
    if (rowId.hasOwnProperty('isModified')) console.error('LOGIC ERROR: Code or data is junk because row is not just a string.');
    return rowId.hasOwnProperty('isModified')
      ? ((rowId as ModifiableDataValue).value as StringValue).value
      : rowId;
  }

  getCell(propertyName: string, newValue: any): ElnCell {
    const column = this.table.columnDefinitions.find(col => col.field === propertyName);
    if (!column) throw new Error(missingFieldMessage);

    return {
      propertyName,
      propertyValue: this.dataValueService.getExperimentDataValue(
        column.columnType,
        newValue
      ) as ExperimentDataValue
    };
  }

  getCellForRuleAction(propertyName: string, primitiveOrDataValue: any): ElnCell {
    const column = this.table.columnDefinitions.find(col => col.field === propertyName);
    if (!column) throw new Error(missingFieldMessage);

    if (primitiveOrDataValue?.state) {
      // Need to check if any specific data record to be created for other than value change.
      return {
        propertyName,
        propertyValue: primitiveOrDataValue
      };
    } else {
      return {
        propertyName,
        propertyValue: this.dataValueService.getExperimentDataValue(
          column.columnType,
          primitiveOrDataValue
        ) as ExperimentDataValue
      };
    }
  }

  /**
   * Posts change command. Updates Table Model, including completion tracking.
   */
  private postChangeCellCommand(
    commandValues: ChangeRecipeCellCommand | ChangeCellCommand,
    eventSource: string | undefined,
    oldCellValue?: TableValueRow[]
  ) {
    const command: ChangeRecipeCellCommand = this.castAsChangeRecipeCellCommand(commandValues);
    this.updateTableModel(command, oldCellValue ?? []);
    if (!this.grid?.gridApi) {
      return;
    }
    this.grid.gridApi.refreshCells({ force: true });
    this.cellChangedEventRuleEvaluation(commandValues, eventSource);
    commandValues.ruleContext = this._ruleHandler.getRuleCommandContext(eventSource);
    this.cellChangeServiceCall(command);
  }

  private cellChangedEventRuleEvaluation(
    commandValues: ChangeRecipeCellCommand | ChangeCellCommand,
    eventSource: string | undefined
  ): string | undefined {
    return this._ruleHandler.cellChanged(
      commandValues.columnValues[0].propertyName,
      // `as unknown` !!! See TableValueRow docs
      { changeCellCommand: commandValues, rows: this.table.value as unknown as { [key: string]: ModifiableDataValue & string }[] },
      eventSource
    )?.correlationId;
  }

  private castAsChangeRecipeCellCommand(commandValues: ChangeRecipeCellCommand | ChangeCellCommand): ChangeRecipeCellCommand {
    return (commandValues as any).hasOwnProperty('experimentId') ? {
      columnValues: commandValues.columnValues,
      recipeId: (commandValues as ChangeCellCommand).experimentId,
      rowIds: commandValues.rowIds,
      tableIds: commandValues.tableIds
    } : commandValues as ChangeRecipeCellCommand;
  }

  private updateTableModel(commandValues: ChangeRecipeCellCommand, oldRowImage: TableValueRow[]): void {
    if (oldRowImage.length === 0) {
      oldRowImage = this.table.value.filter(row => commandValues.rowIds.includes(row.id));
    }
    commandValues.rowIds.forEach(rowId => {
      const oldRow = oldRowImage.find(row => row.id === rowId);
      const newRow = this.table.value.find(row => row.id === rowId);
      const gridRow = this.grid.gridApi.getRowNode(rowId);

      for (const { propertyName, propertyValue } of commandValues.columnValues) {
        if (newRow && oldRow) {
          const column = this.table.columnDefinitions.find(d => d.field === propertyName);
          const columnType = column?.columnType ?? ColumnType.String;
          const value = DataRecordService.getModifiableDataValue(
            propertyValue,
            oldRow[propertyName]
          );
          newRow[propertyName] = value;
          gridRow?.setDataValue(
            propertyName,
            this.dataValueService.getPrimitiveValue(columnType, value, column?.allowMultiSelect ?? false)
          );
        }
      }
    });
  }

  rowsAdded(e?: BptGridRowsAddedEvent) {
    if (e === undefined) return;
    if (!e.gridId) throw new Error(missingGridIdMessage);

    const rows = this.tableDataService.rowsAdded(this, e);

    const addRowsCommand = {
      recipeId: this.recipeId,
      tableId: e.gridId,
      rows,
    };
    // add row event missing event source when it's added in bpt-ui-library
    // the undefined will be changed to e.eventSource
    this.postAddRowsCommandAndUpdateTableCompletion(addRowsCommand, e.source);
  }

  private postAddRowsCommandAndUpdateTableCompletion(
    commandValues: AddRecipeRowCommand,
    eventSource: string | undefined
  ) {
    commandValues.rows.forEach((row) => remove(row.data, (v => v.propertyName === TableDataService.rowSelectedField || v.propertyName === TableDataService.repeatField)));
    commandValues.ruleContext = this._ruleHandler.getRuleCommandContext(eventSource);
    this.recipeEventsService.
      recipeEventsAddRowsPost$Json({ body: commandValues })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (rowAddedResponse) => {
          this.applyAddRowResponse(rowAddedResponse);
          this.rowAddedEventRuleEvaluation(rowAddedResponse, eventSource);
          this.validation.successes.push(
            $localize`:@@TableRowAddedSuccessfully:Row Added successfully`
          );
        },
        error: () => {
          //error is handled by ExceptionInterceptor
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    this.numberOfRows += 1;
  }

  private rowAddedEventRuleEvaluation(
    rowAddedResponse: AddRecipeRowResponse,
    eventSource: string | undefined
  ): string | undefined {
    return this._ruleHandler.rowAdded(
      RuleEvents.RowAdded,
      // `as unknown` !!! See TableValueRow docs
      { rowAddedResponse, rows: this.table.value as unknown as { [key: string]: ModifiableDataValue & string }[] },
      eventSource
    )?.correlationId;
  }

  private setColumnProperties() {
    this.columnDefinitions.forEach((column) => {
      if (column.field === 'rowIndex') this.rowIndexColumnSort(column);
      if (column.field === TableDataService.repeatField) this.setRepeatColumnProperties(column);

      RecipeTableComponent.normalizeColumnDefinition(column);
      column.lockVisible = column.disableHiding;
      if (column.editable) {
        column.editable = (params: EditableCallbackParams) => this.isCellEditable(params);
      }
      switch (column.columnType) {
        case ColumnType.List:
        case ColumnType.EditableList:
          this.setPropertiesForListOrEditableList(column);
          break;
        case ColumnType.Quantity:
        case ColumnType.Specification:
          this.setSpecificationColumnProperties(column);
          break;
        case ColumnType.StepNumber:
          column.filterType = 'string';
          break;
      }
      column.suppressMenu = (column as ColumnSpecification).suppressColumnMenu;
    });
  }

  private setRepeatColumnProperties(column: ColumnDefinition) {
    const removeGroupCallback = this.repeatGroupRemovalRequested.bind(this);
    TableDataService.setRepeatColumnProperties(column, removeGroupCallback);
  }

  private setSpecificationColumnProperties(column: ColumnDefinition) {
    column.onCellDoubleClicked = (e: CellDoubleClickedEvent) => {
      if (!this.isCellEditable(e)) return;

      const colSpec = column as ColumnSpecification;
      const rowId = e.node.id;
      const coldId = e.column.getId();
      const allowedSpecTypes = colSpec.allowedSpecTypes ?? [];
      const allowedUnits = (column.allowedUnits ?? []) as Unit[];
      const defaultUnit = ((column as ColumnSpecification).defaultUnit ?? undefined) as Unit;

      // Empty with N/A unit is not a valid state
      const unitToSet = defaultUnit?.id === this.unitLoaderService.naUnit.id ? undefined : defaultUnit;

      if (rowId) this.toggleSpecificationSlider(rowId, coldId, allowedSpecTypes, allowedUnits, unitToSet);
    };
  }

  refreshDataSource(): void {
    this.primitiveValue = this.tableDataService.refreshDataSource(this);
  }

  toggleSpecificationSlider(rowId: string, field: string, allowedSpecTypes: SpecType[], allowedUnits: Unit[], defaultUnit?: Unit, readOnly = false) {
    const tr = this.table.value.find(r => r.id === rowId);
    const tableRow = tr as {
      [key: string]: ModifiableDataValue;
    };

    // If a value does not exist we want to make sure it does before we proceed.
    tableRow[field] ??= {
      isModified: false,
      value: { type: ValueType.Specification, state: ValueState.Empty }
    };

    if (tableRow[field].value.type !== ValueType.Specification) return; // shouldn't get here.

    const onClose = new Subject<never>();
    const onChange = new Subject<SpecificationValue>();
    onClose.subscribe({
      complete: () => {
        onClose.unsubscribe();
        onChange.unsubscribe();
        onPreloadScalingOptionsChanged.unsubscribe();
      }
    });
    onChange.subscribe({
      next: newValue => {
        const gridRow = this.grid.gridApi.getRowNode(rowId);
        if (!gridRow) throw new Error('Grid row not found!');
        const modifiableDataValue = {
          isModified: true, // This assumption is invalid, but works because getPrimitiveValue doesn't care.
          value: newValue
        };
        const primitiveValue = this.dataValueService.getPrimitiveValue(
          ColumnType.Specification,
          modifiableDataValue
        );
        gridRow.setDataValue(field, primitiveValue, 'specificationEdit');

        const e: BptGridCellValueChangedEvent = {
          type: 'cellValueChanged',
          oldValue: tableRow[field].value,
          newValue,
          field,
          rowId,
          gridId: this.table.tableId
        };
        this.cellValueEditing(e);
      }
    });

    const onPreloadScalingOptionsChanged = new Subject<SpecificationPreloadScalingOptions>();
    onPreloadScalingOptionsChanged.subscribe({
      next: (newValue: SpecificationPreloadScalingOptions) => this.specificationPreloadScalingOptionsChanged(newValue, this.table.tableId, [rowId, field])
    });

    const currentColumnDefinition = this.columnDefinitions.find(data => data.field === field);

    const scalingOptions = this.recipeService.getScalingOptionsForPath(this.table.tableId, [rowId, field]);

    const currentPreloadScalingOptions: SpecificationPreloadScalingOptions = {
      allowScaling: !currentColumnDefinition?.containsObservableData,
      allowScaleUp: scalingOptions?.allowScaleUp ?? false,
      allowScaleDown: scalingOptions?.allowScaleDown ?? false
    };

    const context: SpecificationEditorContext = {
      id: uuid(),
      value: tableRow[field].value as SpecificationValue,
      readOnly,
      disabled: false,
      allowedSpecTypes,
      allowedUnits,
      preloadScalingOptions: currentPreloadScalingOptions,
      defaultUnit,
      onChange,
      onClose,
      onPreloadScalingOptionsChanged
    };
    this.recipeService.beginEditSpecification.next(context);
  }

  private setPropertiesForListOrEditableList(column: ColumnDefinition) {
    const listSpecificConfig = () => { /* nothing special */ };
    const editableListSpecificConfig = () => ({
      labelField: 'label',
      valueField: 'value',
      options: column.listValues ?? [],
      allowMultiSelect: column.allowMultiSelect ?? false,
      allowCustomOptionsForDropdown: column.allowCustomOptionsForDropdown ?? false,
    });
    const typeSpecificConfig = (column.columnType === ColumnType.List) ? listSpecificConfig() : editableListSpecificConfig();

    column.dropdownEditorConfiguration = {
      ...DropdownCellEditorParamsDefaults,
      ...typeSpecificConfig,
      allowNA: column.allowNA,
      showHeader: column.showHeader ?? column.allowMultiSelect ?? false,
      showToggleAll: column.showToggleAll ?? column.allowMultiSelect ?? false
    };
  }

  private static normalizeColumnDefinition(columnDefinition: ColumnDefinition) {
    if (!isNaN(columnDefinition.width as number)) {
      columnDefinition.width = parseInt(columnDefinition.width as string);
    }
  }

  private rowIndexColumnSort(columnDefinition: ColumnDefinition): void {
    columnDefinition.sort = 'asc';
    columnDefinition.comparator = (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
      if (!valueA || +valueA > +valueB) {
        return 1;
      } else if (+valueA < +valueB) {
        return -1;
      }
      return 0;
    }
  }

  onGridReady() {
    this.grid.gridApi.setColumnsPinned(this.table.columnDefaults.leftPinnedColumns, 'left');
    this.grid.gridApi.setColumnsPinned(this.table.columnDefaults.rightPinnedColumns, 'right');
    this.grid.gridApi.setRowGroupColumns(this.table.columnDefaults.groupedColumns);
    TableDataService.setRepeatColumnHiddenState(this.table, this.grid);
  }

  private isCellEditable(params: EditableCallbackParams) {
    if (!this.isUserAllowedToEdit || this.isRecipe || this.recipeHasErrors) return false;

    const columnName = params.colDef.field;
    const currentColumnDefinition = this.columnDefinitions.find(
      data => data.field === columnName
    );
    if (!(currentColumnDefinition as ColumnSpecification)?.editable) return false;

    if (columnName === TableDataService.repeatField) return false;

    const canEditCellInSetup = currentColumnDefinition?.containsObservableData === true;
    return canEditCellInSetup !== true;
  }

  private applyAddRowResponse(rowAddedResponse: AddRecipeRowResponse) {
    const newlyAddedRows: any[] = [];
    const toModifiableDataValue: (value: ExperimentDataValue) => ModifiableDataValue = (value) => ({
      isModified: false, // value in cell would not be modified since this is new row and therefore this can't be a change from non-empty to a different value
      value
    });
    rowAddedResponse.values.forEach(newlyAddedRow => {
      const newRow: TableValueRow = {
        // id value is a string
        ...mapValues(newlyAddedRow, toModifiableDataValue),
        id: newlyAddedRow.id
      };
      newlyAddedRows.push(newRow);
      this.table.value.push(newRow);
    });

    this.numberOfRows += newlyAddedRows.length;
    newlyAddedRows.forEach(newRow => {
      Object.keys(newRow)
        .filter(columnId => this.columnsToPopulateByAddRowResponse.includes(columnId))
        .forEach(columnId => {
          const columnDefinition = this.table.columnDefinitions?.find(c => c.field === columnId) as ColumnDefinition;
          const primitiveValue = this.dataValueService.getPrimitiveValue(
            columnDefinition.columnType as FieldOrColumnType,
            newRow[columnId]
          );
          const gridRow = this.grid.gridApi.getRowNode(newRow.id as string);
          if (!gridRow) {
            return;
          } else {
            gridRow.setDataValue(columnId, primitiveValue, 'systemFields');
          }
        });
    });

    this.grid.gridApi.refreshClientSideRowModel('sort');
    this.grid.gridApi.refreshCells({ force: true });
  }

  private buildContextMenuItems(): void {
    this.items = [
      {
        label: $localize`:@@retitle:Retitle`,
        icon: 'icon-pencil',
        disabled: !RecipeNodeRetitleService.HasPermissionToEditTitle || this.recipeHasErrors ||
          RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
            this.recipeService.currentRecipe?.tracking.state
          ) || !this.recipeService.currentRecipe.tracking.assignedEditors.includes(
            this.userService.currentUser.puid),
        id: `eln-context-table-retitle-${this.table.itemTitle.replace(/ /g, '')}`,
        command: (_event$) => {
          this.retitleEnabled = true;
        }
      },
      {
        label: $localize`:@@remove:Remove`,
        icon: 'far fa-trash-alt',
        disabled:
          !RecipeNodeRetitleService.HasPermissionToEditTitle ||
          RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(
            this.recipeService.currentRecipe?.tracking.state
          ) || !this.recipeService.currentRecipe?.tracking.assignedEditors.includes(
            this.userService.currentUser.puid),
        id: `eln-context-form-delete-${this.table.itemTitle.replace(/ /g, '')}`,
        command: (_event$) => {
          this.templateDeleteService.deleteRecipeItem(this.table, TemplateType.Table, this.isRecipe);
        },
        visible: true,
      }
    ];
  }

  public get contextMenu(): GridContextMenuItem[] {
    if (!this.grid?.gridApi) return []; // Can't do much without a grid API, but this is normal due to how it's binding
    return this.tableDataService.getContextMenu(this);
  }

  checkForPermission() {
    if (
      RecipeNodeRetitleService.HasPermissionToEditTitle && (!this.recipeHasErrors) &&
      !RecipeNodeRetitleService.NotAllowedWorkflowStates.includes(this.recipeService.currentRecipe?.tracking.state) &&
      this.recipeService.currentRecipe?.tracking.assignedEditors.includes(this.userService.currentUser.puid)) {
      this.retitleEnabled = true;
    } else {
      this.retitleEnabled = false;
    }
  }

  fillWithNaMenuItem(): GridContextMenuItem | undefined {
    const args: NaGridTableIdentifierData = {
      isCellEditable: this.isCellEditable.bind(this),
      getCell: this.getCell.bind(this),
      postChangeCellCommand: this.postChangeCellCommand.bind(this),
      grid: this.grid,
      table: this.table,
      containerId: this.recipeId,
      columnDefinitions: this.columnDefinitions,
      activityId: this.recipeService.currentActivityId
    }
    return this.fillWithNaGridHelper.getContextMenuOptionsOfFillWithNa(args);
  }

  getViewSpecMenuItem() {
    const cell = this.grid?.gridApi?.getFocusedCell();
    if (cell) {
      const row = this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
      if (row) {
        const colId = cell.column.getColId();
        const colDef = this.columnDefinitions.find((c) => c.field === colId);
        const params: EditableCallbackParams = {
          node: row,
          data: undefined,
          column: cell.column,
          colDef: cell.column.getColDef(),
          api: this.grid.gridApi,
          columnApi: new ColumnApi(this.grid.gridApi),
          context: undefined
        };
        const readOnly = !this.isCellEditable(params);

        if (colDef?.columnType === ColumnType.Specification && readOnly) {
          return {
            label: $localize`:@@ViewSpec:View Specification`,
            action: () => {
              const rowId = row.id;
              const coldId = colId;
              const colSpec = colDef as ColumnSpecification;
              const allowedSpecTypes = colSpec.allowedSpecTypes ?? [];
              const allowedUnits = (colDef.allowedUnits ?? []) as Unit[];
              const defaultUnit = (colSpec.defaultUnit ?? undefined) as Unit;
              if (rowId) {
                this.toggleSpecificationSlider(
                  rowId,
                  coldId,
                  allowedSpecTypes,
                  allowedUnits,
                  defaultUnit,
                  true
                );
              }
            },
            icon: '<img class="pi pi-search" />'
          };
        }
      }
    }
    return undefined;
  }

  toggleSelection() {
    if (this.selectedCount === this.grid?.gridApi.getDisplayedRowCount()) {
      this.grid?.gridApi?.deselectAll();
    } else {
      this.grid?.gridApi?.selectAll();
    }
  }

  private specificationPreloadScalingOptionsChanged(selectedScalingOptions: SpecificationPreloadScalingOptions, tableId: string, path: string[]) {
    this.isLoading = true;
    this.recipeService.changePreLoadDataTransformOptions(selectedScalingOptions, tableId, path, RecipePreLoadDataTransformContextType.TableCell)
      .subscribe({
        next: () => {
          this.validation.successes.push(
            $localize`:@@changedPreloadOptionSuccessfully:Changed Pre-Load Option successfully`
          );
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  private getRepeatGroupValidationMsg(columnDefinition: ColumnDefinition): string | undefined {
    const field = columnDefinition.field ?? '';
    const values = this.grid?.gridApi?.getSelectedRows().map(r => parseInt(r[field]));
    if (!this.isContiguous(values)) {
      return columnDefinition.columnType === ColumnType.StepNumber
        ? $localize`:@@nonContiguousStepsSelected:You must select a contiguous series of step numbers to create a repeating group.`
        : $localize`:@@nonContiguousIndicesSelected:You must select a contiguous series based on row index in order to create a repeating group.`;
    }
    return undefined;
  }

  private isContiguous(values: number[]) {
    values.sort((a, b) => a - b);
    // Contains sequential array [x...y] where x is lowest step/index selected and y is # of rows selected
    const sequence = Array.from({ length: values.length }, (_, i) => i + values[0]);
    return isEqual(values, sequence);
  }

  loadRemovedRowsDialog() {
    this.tableDataService.loadRemovedRowsDialog(this);
  }

  public static createRepeatColumn(): ColumnDefinition {
    return {
      label: $localize`:@@repeatForEach:Repeat for each`,
      field: TableDataService.repeatField,
      editable: true,
      pinned: false,
      lockPosition: false,
      columnType: ColumnType.String,
      allowNA: false,
      alwaysHidden: false,
      hidden: true,
      showInColumnChooser: false,
      allowMultiSelect: false,
      disableGrouping: true,
      sortable: false,
      filter: false
    };
  }

  public getNewStepNumbers(rowId: string, oldStepNumbers: { id: string, step: number }[]): { id: string, step: number }[] {
    oldStepNumbers.sort((a, b) => a.step - b.step);
    const rowThatChanged = oldStepNumbers.find(stepNumber => stepNumber.id === rowId);

    let altered;
    if (rowThatChanged) {
      altered = oldStepNumbers.filter(s => s.id !== rowThatChanged.id);
      let desiredNewStepNumForRow = rowThatChanged.step;
      if (desiredNewStepNumForRow > altered.length + 1) desiredNewStepNumForRow = altered.length + 1;
      altered.splice(desiredNewStepNumForRow - 1, 0, rowThatChanged);
    } else {
      altered = oldStepNumbers;
    }

    const newStepNumbers = [];
    for (let i = 1; i <= altered.length; i++) {
      newStepNumbers.push({ id: altered[i - 1].id, step: i });
    }

    return newStepNumbers;
  }
}

export const repeatForColumn: ColumnDefinition = {
  label: 'Repeat For',
  field: TableDataService.repeatForField,
  editable: true,
  pinned: true,
  lockPosition: true,
  columnType: ColumnType.String,
  allowNA: true,
  alwaysHidden: true,
  hidden: true,
  showInColumnChooser: false,
  allowMultiSelect: false
};

export const repeatWithColumn: ColumnDefinition = {
  label: 'Repeat With',
  field: TableDataService.repeatWithField,
  editable: true,
  pinned: true,
  lockPosition: true,
  columnType: ColumnType.String,
  allowNA: true,
  alwaysHidden: true,
  hidden: true,
  showInColumnChooser: false,
  allowMultiSelect: false
};

export const rowSelectedColumn: ColumnDefinition = {
  label: '',
  field: TableDataService.rowSelectedField,
  editable: false,
  cellClass: '',
  headerCheckboxSelection: true,
  checkboxSelection: true,
  minWidth: 36,
  maxWidth: 36,
  pinned: true,
  lockPosition: true,
};
