import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { BptTreeNode } from 'bpt-ui-library/bpt-tree/model/bpttreenode.interface';
import { ActivityItemReferenceType } from '../../../api/models';

@Component({
  selector: 'app-items-as-options',
  templateUrl: './items-as-options.component.html',
  styleUrls: ['./items-as-options.component.scss']
})
export class ItemsAsOptionsComponent implements OnInit {

  private static readonly allLabItemsGroup: string = 'allLabItemsGroup';

  public availableTreeNodes: BptTreeNode[] = [
    {
      label: $localize`:@@allLabItems:All Lab Items`,
      data: ItemsAsOptionsComponent.allLabItemsGroup,
      children: [
        {
          label: $localize`:@@LabItemsMaterialsTableTitle:Materials`,
          data: ActivityItemReferenceType.MaterialAliquot
        },
        {
          label: $localize`:@@preparations:Preparations (scanned)`,
          tooltip: $localize`:@@preparationsScannedAsLabItems:Preparations (scanned as lab items)`,
          data: ActivityItemReferenceType.PreparationLabItem
        },
        {
          label: $localize`:@@LabItemsInstrumentsTableTitle:Instruments`,
          data: ActivityItemReferenceType.Instrument
        },
        {
          label: $localize`:@@ColumnsTabHeader:Columns`,
          data: ActivityItemReferenceType.Column
        },
        {
          label: $localize`:@@ConsumablesTag:Consumables`,
          data: ActivityItemReferenceType.Consumable
        },
      ]
    },
    {
      label: $localize`:@@activityInputPageTitle:Inputs`,
      data: ActivityItemReferenceType.Input
    },
    {
      label: $localize`:@@preparations:Preparations`,
      tooltip: $localize`:@@preparationsCreatedInTheActivity:Preparations (created in the activity)`,
      data: ActivityItemReferenceType.Preparation
    }
  ];

  public selectedTreeNodes: BptTreeNode[] = [];

  get selectedItemsAsOptions(): ActivityItemReferenceType[] | undefined {
    return ItemsAsOptionsComponent.chopDownTree(this.selectedTreeNodes);
  }

  @Input() set selectedItemsAsOptions(value: ActivityItemReferenceType[] | undefined) {
    this.plantTree(value);
  }

  @Output() selectedItemsAsOptionsChange = new EventEmitter();

  @Input() readOnlyState = false;

  public ngOnInit(): void {
    this.selectedItemsAsOptions ??= [];
  }

  public nodeSelected(e: any) {
    this.selectNodeAndChildren(e.node);
    this.selectedItemsAsOptionsChange.emit(ItemsAsOptionsComponent.chopDownTree(this.selectedTreeNodes));
  }

  public nodeUnselected(e: any) {
    this.deselectNodeAndChildren(e.node);
    this.selectedItemsAsOptionsChange.emit(ItemsAsOptionsComponent.chopDownTree(this.selectedTreeNodes));
  }

  private selectNodeAndChildren(node: BptTreeNode) {
    if (!this.selectedTreeNodes?.includes(node)) {
      this.selectedTreeNodes.push(node);
    }
    node.children?.forEach((child: BptTreeNode) => this.selectNodeAndChildren(child));
  }

  private deselectNodeAndChildren(node: BptTreeNode) {
    const i = this.selectedTreeNodes.findIndex(i => i === node);
    this.selectedTreeNodes?.splice(i, 1);
    node.children?.forEach((child: BptTreeNode) => this.deselectNodeAndChildren(child));
  }

  private plantTree(selectedActivityReferences?: ActivityItemReferenceType[]): void {
    this.selectedTreeNodes = [];

    if (!selectedActivityReferences) return;

    for (const option of selectedActivityReferences) {
      const node = ItemsAsOptionsComponent.findNodeByDataField(option, this.availableTreeNodes);
      if (node && !this.selectedTreeNodes.includes(node)) {
        this.selectedTreeNodes.push(node);
        if (node?.parent) {
          // check if all children are selected. If not, then set it as a partial selection.
          const allChildrenAreSelected = node.parent.children?.every(child => this.selectedTreeNodes.includes(child));
          if (allChildrenAreSelected && !this.selectedTreeNodes.includes(node.parent)) {
            this.selectedTreeNodes.push(node.parent);
          }
          node.parent.partialSelected = !allChildrenAreSelected;
        }
      }
    }
  }

  /** Converts BptTreeNode[] to ActivityItemReferenceType[] */
  private static chopDownTree(selectedOptions: BptTreeNode[]): ActivityItemReferenceType[] {
    // Note: 'allLabItemsGroup' is not an actual option - it is only used as part of the UI presentation, so it must be filtered out prior to emission.
    const opts: ActivityItemReferenceType[] = selectedOptions.filter(sel => sel.data !== ItemsAsOptionsComponent.allLabItemsGroup).map(sel => sel.data);
    return opts.length > 0 ? opts : [];
  }

  /** Recursively traverses the bpt-tree to locate the node given its data field value. returns undefined if the field is not found. */
  private static findNodeByDataField(field: string, subtree: BptTreeNode[]): BptTreeNode | undefined {
    for (const node of subtree) {
      if (node.data === field) return node;
      if (node.children && node.children.length > 0) {
        const foundNode = ItemsAsOptionsComponent.findNodeByDataField(field, node.children);
        if (foundNode) {
          // assigning the parent here is necessary for the first rendering of the control
          foundNode.parent = node;
          return foundNode;
        }
      }
    }
    return undefined;
  }
}
