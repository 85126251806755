import { Injectable } from "@angular/core";
import { ExperimentEventsService } from "../../api/data-entry/services";
import { ChangeReason, ChangeReasonLookupEntity, ChangeReasonLookupResponse } from "../../api/models";
import { NA } from "bpt-ui-library/shared";
import { ExperimentService } from "./experiment.service";
import { Subject } from "rxjs";
import { ChangeReasonsSliderDetails } from "../model/change-reason-slider-details";
import { AddChangeReasonCommand } from "../../api/data-entry/models";
import { ElnLookupService } from "../../api/services";
import { ChangeReasonHelper } from "./change-reason-helper";

@Injectable({
  providedIn: 'root'
})
export class ChangeReasonService {
  public static changeReasonId?: string;
  public static oldValue: any = '';

  public readonly isChangeReasonSliderDetails = new Subject<ChangeReasonsSliderDetails>();
  public readonly openSlider = new Subject<boolean>();

  private newValue?: string = '';
  private changeReasonContextTitle = '';
  private changeDetails: any = {};
  private operationType = '';
  private preDefinedChangeReasons: ChangeReasonLookupEntity[] = [];

  constructor(
    private readonly experimentService: ExperimentService,
    private readonly experimentEventsService: ExperimentEventsService,
    private readonly elnLookupService: ElnLookupService,
    private readonly changeReasonHelper: ChangeReasonHelper
  ){
  }

  getOrSetUIFeatures(changeReason : ChangeReason, canUpdate: boolean) : ChangeReasonUIFeatures {
    const activityChangeReasonNode = this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
    if(!!changeReason && !!changeReason.changeReasonId)
    {
     const mapping = activityChangeReasonNode?.changeReasonAvailabilityMapping![changeReason.changeReasonId];
     return {
       isChangeReasonMissing: !!(changeReason.changeReasonDetails === '' && !mapping?.isMapped),
       canUpdateChangeReason: canUpdate,
       defaultTextForUpdatingMissedReason: changeReason.changeReasonDetails === '' && mapping?.isMapped ? 'Not provided' : (changeReason.changeReasonDetails ?? NA)
     }
    }
    else return {
      isChangeReasonMissing: false,
      canUpdateChangeReason: canUpdate,
      defaultTextForUpdatingMissedReason: changeReason?.changeReasonDetails ?? NA
    }
  }

  getChangeReasonsFromLookup() {
    this.elnLookupService.elnLookupPredefinedChangeReasonsGet$Json().subscribe({
      next: (response: ChangeReasonLookupResponse) => {
        this.preDefinedChangeReasons = response.changeReasons;
      }
    });
  }

  getPredefinedChangeReasons() {
    return this.preDefinedChangeReasons;
  }

  setContextTitle(changeDetails: any, operationType: string) {
    this.changeDetails = changeDetails;
    this.operationType = operationType;
    this.changeReasonContextTitle = this.changeReasonHelper.setContextPath[operationType](changeDetails);
  }

  setNewValue(changeDetails: any, operationType: string) {
    this.newValue = this.changeReasonHelper.setNewValue[operationType](changeDetails);
  }

  setOldValue() {
    this.changeReasonHelper.setOldValue()
  }

  saveChangeReason(index: number, changeReasonText: string, isClientFacingReason: boolean, nodeId: string, updateForMissingChangeReason: boolean) {
    if (!ChangeReasonService.changeReasonId) return;
    const addChangeReasonCommand: AddChangeReasonCommand = {
      changeReasonCategory: isClientFacingReason ? 'ClientFacingReason' : 'PredefinedReason ',
      changeReasonDetails: changeReasonText,
      experimentId: this.experimentService.currentExperiment?.id ?? '',
      activityId: this.experimentService.currentActivityId,
      nodeId: nodeId ?? this.experimentService.currentActivityId,
      changeReasonId: ChangeReasonService.changeReasonId,
      index: index,
      wasMissing: updateForMissingChangeReason === true ? updateForMissingChangeReason : undefined
    }
    this.experimentEventsService.experimentEventsChangeReasonEventsAddPost$Json({
      body: addChangeReasonCommand
    }).subscribe({
      next: (result) => {
        ChangeReasonService.oldValue = '';
        ChangeReasonService.changeReasonId = result.changeReasonAddedEventNotification.changeReasonId;
        this.experimentService.changedReasonSubmitted.next({ isSaved: true, wasMissing: addChangeReasonCommand.wasMissing });
      }
    });
    this.changeReasonHelper.addChangeReasonNodeToCurrentExperiment(addChangeReasonCommand);
    this.changeReasonHelper.hideChangeReasonSlider();
  }

  triggerSliderVisibilityFromInterceptor(
    isVisible: boolean,
  ) {
    this.experimentService.changeReasonSliderDisplayDetails.next({
      isVisible: isVisible,
      oldValue: ChangeReasonService.oldValue,
      newValue: this.newValue,
      contextTitle: this.changeReasonContextTitle,
      showCloseIcon: false,
      nodeId: this.changeReasonHelper.getNodeId(this.changeDetails, this.operationType),
      showChangedValues: ChangeReasonService.oldValue !== undefined
    });
  }

  triggerSliderVisibilityFromAuditHistory(
    isVisible: boolean,
    nodeId?: string,
    oldValue?: string,
    newValue?: string,
    contextTitle?: string,
    showCloseIcon = false,
    changeReasonMissing = false
  ) {
    this.experimentService.changeReasonSliderDisplayDetails.next({
      isVisible: isVisible,
      oldValue: oldValue,
      newValue: newValue,
      contextTitle: contextTitle ?? this.changeReasonContextTitle,
      showCloseIcon: showCloseIcon,
      nodeId: nodeId ?? this.changeReasonHelper.getNodeId(this.changeDetails, this.operationType),
      showChangedValues: oldValue !== undefined,
      updateForMissingReason: changeReasonMissing
    });
  }

  getChangeReasonNodeForActivity() {
    return this.experimentService.currentExperiment?.activityChangeReasonNodes?.find(n => n.activityId === this.experimentService.currentActivityId);
  }
}

export interface ChangeReasonUIFeatures {
   isChangeReasonMissing: boolean,
   canUpdateChangeReason: boolean,
   defaultTextForUpdatingMissedReason: string
}
