import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CellLockDetails, ExpirationType, NewPreparationsSubmission, PreparationAuditHistoryInputs, PreparationInternalInformation } from '../models/preparation.model';
import { ChangePreparationInternalInformationCommand, ExperimentNewPreparations, PreparationChangeStatusCommand, StringTypeDictionaryValue,PreparationCellChangeCommand, LocalDateValue } from '../../api/data-entry/models';
import { ActivityPreparationsPresentation } from '../models/preparation-presentation.model';
import { DiscardOrConsumePreparationCommand } from '../../api/data-entry/models/discard-or-consume-preparation-command';
import { AuditHistoryDataRecordResponse } from '../../api/audit/models';
import { InstantValue } from '../../api/models';

@Injectable({
  providedIn: 'root'
})
export class PreparationEventService {
  public refreshGrid = new Subject<void>();

  public isHistoryLoading = false;
  public openSlider = new Subject<{
    isVisible: boolean,
    isReadOnly: boolean
  }>();
  public openExpirationSliderSubject = new BehaviorSubject<{showSlider: boolean, isReadOnlyRow : boolean}>({
    showSlider: false,
    isReadOnlyRow: false
  });
  public openExpirationSlider$ = this.openExpirationSliderSubject.asObservable();
  public sliderVisibilitySubject = new BehaviorSubject<boolean>(false);
  public sliderVisibility$ = this.sliderVisibilitySubject.asObservable();
  public containerDescriptionModel = new Subject<StringTypeDictionaryValue>();
  public activityPreparations: ActivityPreparationsPresentation[] = [];
  public allPreparationsFieldsFilledSubject = new BehaviorSubject<boolean>(true);
  public isAllPreparationsFieldsFilled = this.allPreparationsFieldsFilledSubject.asObservable();

  private readonly activityPreparationsSubject = new Subject<Array<ActivityPreparationsPresentation>>();
  public activityPreparationsSubject$ = this.activityPreparationsSubject.asObservable();

  public readonly createdPreparationsNotification = new Subject<ExperimentNewPreparations>();
  public createdPreparationsNotificationSubject$ = this.createdPreparationsNotification.asObservable();

  public readonly newPreparationsSubject = new Subject<NewPreparationsSubmission>();
  public readonly newPreparationsSubmitData = this.newPreparationsSubject.asObservable();

  private readonly preparationInternalInformationChangesSubject = new Subject<PreparationInternalInformation>();
  public readonly preparationInternalInformationChanged = this.preparationInternalInformationChangesSubject.asObservable();

  private readonly preparationInternalInformationChangedSubject = new Subject<ChangePreparationInternalInformationCommand>();
  public readonly preparationInternalInformationChangedData = this.preparationInternalInformationChangedSubject.asObservable();

  private readonly preparationDiscardedOrConsumedSubject = new Subject<DiscardOrConsumePreparationCommand>();
  public readonly preparationDiscardedOrConsumedData = this.preparationDiscardedOrConsumedSubject.asObservable();

  private readonly preparationStatusChangedSubject = new Subject<PreparationChangeStatusCommand>();
  public readonly preparationStatusChangedData = this.preparationStatusChangedSubject.asObservable();

  private readonly preparationExpirationChangesSubject = new Subject<ExpirationType>();
  public readonly preparationExpirationChanged = this.preparationExpirationChangesSubject.asObservable();

  private readonly preparationCellChangesSubject = new Subject<{preparationCellChangeCommand: PreparationCellChangeCommand, oldCellValue: string | InstantValue | LocalDateValue}>();
  public readonly preparationCellChanged = this.preparationCellChangesSubject.asObservable();

  private readonly preparationDescriptionChangesSubject = new Subject<StringTypeDictionaryValue>();
  public readonly preparationDescriptionChanged = this.preparationDescriptionChangesSubject.asObservable();

  private readonly preparationRemovalSubject = new Subject<string>();
  public readonly preparationRemoved = this.preparationRemovalSubject.asObservable();

  private readonly preparationRestoreSubject = new Subject<string>();
  public readonly preparationRestored = this.preparationRestoreSubject.asObservable();

  private readonly preparationRemovalSuccessSubject = new Subject<boolean>();
  public readonly preparationRemovedSuccessFlag = this.preparationRemovalSuccessSubject.asObservable();

  private readonly preparationRestoreSuccessSubject = new Subject<boolean>();
  public readonly preparationRestoredSuccessFlag = this.preparationRestoreSuccessSubject.asObservable();

  public readonly preparationHistoryRequestSubject = new Subject<PreparationAuditHistoryInputs>();
  public readonly preparationHistoryRequestData = this.preparationHistoryRequestSubject.asObservable();

  public readonly preparationHistoryResultSubject = new Subject<AuditHistoryDataRecordResponse>();
  public readonly preparationHistoryResponseData = this.preparationHistoryResultSubject.asObservable();

  private readonly preparationNodeIdSubject = new Subject<string>();
  public readonly preparationNodeId$ = this.preparationNodeIdSubject.asObservable();

  //For collaborative editing
  private readonly preparationCellLockSubject = new Subject<CellLockDetails>();
  public readonly preparationCellLockData = this.preparationCellLockSubject.asObservable();

  //for create preparation event
  public newPreparationSubmit(preparation: NewPreparationsSubmission): void {
    this.newPreparationsSubject.next(preparation);
  }

  //This method to listen whenever there is a removal process
  public restorePreparationSuccessNotification(successFlag: boolean): void {
    this.preparationRestoreSuccessSubject.next(successFlag);
  }

  //This method to listen whenever there is a removal process
  public removePreparationSuccessNotification(successFlag: boolean): void {
    this.preparationRemovalSuccessSubject.next(successFlag);
  }

  //This method to listen whenever there is a removal process
  public removePreparation(preparationId: string): void {
    this.preparationRemovalSubject.next(preparationId);
  }

   //This method to listen whenever there is a removal process
  public requestAuditHistory(preparationAuditHistoryInputs: PreparationAuditHistoryInputs): void {
    this.preparationHistoryRequestSubject.next(preparationAuditHistoryInputs);
  }

  //This method to listen whenever there is a removal process
  public restorePreparation(preparationId: string): void {
    this.preparationRestoreSubject.next(preparationId);
  }


  public preparationInternalInformationChangedEvent(preparationChangeInternalInformationCommand: ChangePreparationInternalInformationCommand): void {
    this.preparationInternalInformationChangedSubject.next(preparationChangeInternalInformationCommand);
  }

  public preparationDiscardedOrConsumedEvent(preparationDiscardedOrConsumedCommand: DiscardOrConsumePreparationCommand): void {
    this.preparationDiscardedOrConsumedSubject.next(preparationDiscardedOrConsumedCommand);
  }

  public preparationStatusChangedEvent(changeStatusCommand: PreparationChangeStatusCommand) {
    this.preparationStatusChangedSubject.next(changeStatusCommand);
  }

  public preparationExpirationTypeChangedEvent(expiration: ExpirationType):void {
    this.preparationExpirationChangesSubject.next(expiration);
  }

  public preparationContainerDescriptionChangedEvent(containerDescription: StringTypeDictionaryValue): void {
    this.preparationDescriptionChangesSubject.next(containerDescription);

  }

  //Any changes made to the fields in preparation data grid
  public preparationDataFieldChangedEvent(preparationCellChangeCommand:PreparationCellChangeCommand, oldCellValue: string | InstantValue | LocalDateValue): void {
    this.preparationCellChangesSubject.next({preparationCellChangeCommand, oldCellValue});
  }

  getCreatePreparationObservable() {
    return this.newPreparationsSubject.asObservable();
  }

  getSliderObservable() {
    return this.openSlider.asObservable();
  }

  openContainerSlider(isVisible: boolean, isReadOnly = false) {
    this.openSlider.next({isVisible, isReadOnly});
  }

  getContainerDescriptionModel() {
    return this.containerDescriptionModel.asObservable();
  }

  emitNodeId(nodeId: string) {
    this.preparationNodeIdSubject.next(nodeId);
  }

  //For collaborative editing
  public sendPreparationCellLockData(data: CellLockDetails) {
    this.preparationCellLockSubject.next(data)
  }
}