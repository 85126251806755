import { Injectable } from "@angular/core";
import { ExperimentDataRecordNotification, ExperimentRecipeAppliedEventNotification,
  ExperimentRecordType, ExperimentNodeTitleChangedNotification } from "../../api/data-entry/models";
import { NodeType, PromptType, RecipeScalingAppliedEventDetails } from "../../api/models";
import { objectCache } from "../../shared/rx-js-helpers";
import { AuditHistory } from "./audit-history.interface";
import { ExperimentRecordTypesHelper } from "./experiment-data-record-types-helper";
import { DataRecordUtilityService } from "./data-record-utility.service";

enum scalingDetailsType {
  Form = "Form",
  Table = "Table",
  Preparation = "Preparation"
}

@Injectable({
  providedIn: 'root'
})
export class RecipeDataRecordService {

  private readonly cover = $localize`:@@Cover:Cover`;
  private readonly labItemsTitle = $localize`:@@activityLabItemsPageTitle:Lab Items`;
  private readonly promptsTitle = $localize`:@@prompts:Prompt`;

  public static readonly NodeTypesLocaleDictionary: { [key in string]: () => string } = {
    table: () => $localize`:@@Table:Table`,
    form: () => $localize`:@@Form:Form`,
    module: () => $localize`:@@Module:Module`,
    activity: () => $localize`:@@Activity:Activity`,
  }

  constructor(private readonly dataRecordUtilityService: DataRecordUtilityService) {
  }

  public static promptTableValues: { [key: string]: string } = {
    invalid: $localize`:@@invalid:invalid`,
    materials: $localize`:@@materialPrompts: Materials Prompts`,
    instruments: $localize`:@@instrumentPrompts:Instruments Prompts`,
    columns: $localize`:@@columnPrompts:Columns Prompts`,
    consumablesAndSupplies: $localize`:@@consumablesAndSuppliesPrompts:Consumables And Supplies Prompts`,
    preparations: $localize`:@@preparationsPrompts:Preparations Prompts`
  }

  public getRecipeAppliedRecord(experimentRecord: ExperimentDataRecordNotification, nodeType?: NodeType, nodeId?: string | undefined) {
    const record = experimentRecord as ExperimentRecipeAppliedEventNotification;
    const recordType = ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.Recipe]();
    const recipeApplied = $localize`:@@recipeApplied:Recipe Applied`;
    const additionalContext = record.eventContext.additionalContext;

    let recipeNumber = '';
    let nodeTitle = '';
    let scalingAppliedDetails = [];
    let promptDetails = {
      prompts: []
    };
    const auditHistory: any[] = [];
    if (record.eventContext.blobReference?.blobName) {
      const recipe = objectCache[record.eventContext.blobReference?.blobName];
      recipeNumber = recipe?.recipeNumber;
      promptDetails = recipe?.promptDetails;
      nodeTitle = recipe?.recipeFormAndTableTitles[nodeId ?? ''];
      scalingAppliedDetails = recipe?.scalingApplied;
    }

    if (promptDetails && promptDetails.prompts.length > 0) {
      promptDetails.prompts.forEach((activityPrompt: any) => {
        const auditDisplayContext = this.dataRecordUtilityService.getActivityContext(activityPrompt.activityId);
        const tableValue = RecipeDataRecordService.promptTableValues[activityPrompt.type ?? PromptType.Invalid];
        auditHistory.unshift(this.dataRecordUtilityService.getHistory(
          record,
          `${auditDisplayContext.fullPath} > ${this.labItemsTitle} > ${this.promptsTitle} > ${tableValue}`,
          $localize`:@@promptRecipe:prompt, recipe`,
          $localize`:@@Table:Table`,
          `${auditDisplayContext.fullPath} > Lab Items > Prompt > ${tableValue} | promptAdded`,
          $localize`:@@promptAddedMessage:Added prompt for: ${tableValue}`,
          tableValue
        ));
      })
    }

    switch (nodeType) {
      case NodeType.Table:
        const tableAddedEvents = this.getNodeAddedEventHistory($localize`:@@TableLoadedFrom:Table loaded from ${recipeNumber}`, experimentRecord, nodeId, nodeTitle, nodeType);
        auditHistory.unshift(tableAddedEvents);
        break;
      case NodeType.Form:
        const formAddedEvents = this.getNodeAddedEventHistory($localize`:@@FormLoadedFrom:Form loaded from ${recipeNumber}`, experimentRecord, nodeId, nodeTitle, nodeType);
        auditHistory.unshift(formAddedEvents);
        break;
      case NodeType.Module:
        nodeTitle = this.getModuleTitle(additionalContext, nodeTitle, nodeId);
        const moduleAddedEvents = this.getNodeAddedEventHistory($localize`:@@ModuleLoadedFrom:Module loaded from ${recipeNumber}`, experimentRecord, nodeId, nodeTitle, nodeType);
        auditHistory.unshift(moduleAddedEvents);
        break;
      case NodeType.Activity:
        if (additionalContext) {
          nodeTitle = additionalContext[nodeId ?? ""];
        }
        const activityAddedEvents = this.getNodeAddedEventHistory($localize`:@@ActivityLoadedFrom:Activity loaded from ${recipeNumber}`, experimentRecord, nodeId, nodeTitle, nodeType);
        auditHistory.unshift(activityAddedEvents);
        break;
      default:
        this.getAuditHistoryForScaledItems(record, auditHistory, scalingAppliedDetails);
        const contextType = `${recipeNumber}, ${record.recipeName}`;
        this.getPromptRecords(experimentRecord, auditHistory, recipeApplied, recordType, contextType);
    }

    return auditHistory;
  }

  private getPromptRecords(experimentRecord: ExperimentDataRecordNotification, auditHistory: any[], recipeApplied: string, recordType: string, contextType: string) {
    if (!experimentRecord.recordTypes['prompts']) {
      auditHistory.push(this.dataRecordUtilityService.getHistory(
        experimentRecord,
        `${this.cover} > ${recipeApplied}`,
        recordType,
        contextType,
        `${this.cover} > ${recipeApplied}`,
        contextType,
        recordType
      ));
    }
  }

  private getModuleTitle(additionalContext: { [key: string]: any; } | undefined, nodeTitle: string, nodeId: string | undefined) {
    if (additionalContext) {
      nodeTitle = additionalContext[nodeId ?? ""];
      if (!nodeTitle) {
        const templateId = this.dataRecordUtilityService.allModulesInExperiment.find(module => module.moduleId === nodeId)?.templateId;
        if (templateId) {
          const template = objectCache[templateId];
          nodeTitle = template.moduleName;
        } else {
          nodeTitle = $localize`:@@noTitle:No Title`;
        }
      }
    }
    return nodeTitle;
  }

  private getAuditHistoryForScaledItems(record: ExperimentRecipeAppliedEventNotification, auditHistory: any[], scalingAppliedDetails: RecipeScalingAppliedEventDetails[]) {
    if (scalingAppliedDetails && scalingAppliedDetails.length > 0) {
      scalingAppliedDetails.forEach((scaledEntityDetails: any) => {
        let auditDisplayContextForScaledEntity;
        let value;
        if (scaledEntityDetails.formId) {
          value = scalingDetailsType.Form;
          auditDisplayContextForScaledEntity = this.dataRecordUtilityService.getTableOrFormContext(scaledEntityDetails.activityId, scaledEntityDetails.moduleId, scaledEntityDetails.formId)
        } else if (scaledEntityDetails.tableId) {
          value = scalingDetailsType.Table;
          auditDisplayContextForScaledEntity = this.dataRecordUtilityService.getTableOrFormContext(scaledEntityDetails.activityId, scaledEntityDetails.moduleId, scaledEntityDetails.tableId)
        } else {
          value = scalingDetailsType.Preparation;
          auditDisplayContextForScaledEntity = this.dataRecordUtilityService.getPreparationContext(scaledEntityDetails.activityId, scaledEntityDetails.preparationId)
        }
        const scaledValue = scaledEntityDetails.scalingFactor;
        auditHistory.unshift(this.dataRecordUtilityService.getHistory(
          record,
          auditDisplayContextForScaledEntity,
          $localize`:@@recipe:Recipe`,
          $localize`:@@recipe:Recipe`,
          auditDisplayContextForScaledEntity,
          $localize`:@@scaledItemAddedMessage:${value} scaled by ${scaledValue}`,
          value
        ));
      })
    }
  }

  private getNodeContextForRecipe(nodeId: string | undefined, title: string, nodeType: NodeType): string {
    let module = this.dataRecordUtilityService.allModulesInExperiment.find(m => m.items.find(i => i.nodeId === nodeId));
    if (nodeType === NodeType.Module) {
      module = this.dataRecordUtilityService.allModulesInExperiment.find(m => m.moduleId === nodeId);
    }
    const moduleTitle = module?.moduleLabel as string;
    const activityTitle = this.dataRecordUtilityService.experiment.activities.find(a => a.dataModules.some(m => m.nodeId === module?.nodeId))?.itemTitle ?? $localize`:@@noTitle:No Title`;
    if (nodeType === NodeType.Module) {
      return `${activityTitle} > ${moduleTitle}`;
    } else {
      return `${activityTitle} > ${moduleTitle} > ${title}`;
    }
  }

  private getNodeAddedEventHistory(description: string, experimentRecord: ExperimentDataRecordNotification,
    nodeId: string | undefined, nodeTitle: string, nodeType: NodeType): AuditHistory {

    const recordType = ExperimentRecordTypesHelper.RecordTypesLocaleDictionary[ExperimentRecordType.Recipe]();
    let context = '';
    if (nodeType === NodeType.Activity) {
      const itemTitle = this.dataRecordUtilityService.experiment.activities.find(m => m.activityId === nodeId)?.itemTitle ?? $localize`:@@noTitle:No Title`;
      context = `${itemTitle}`;
    } else {
      context = this.getNodeContextForRecipe(nodeId, nodeTitle, nodeType);
    }
    return this.dataRecordUtilityService.getHistory(
      experimentRecord,
      context,
      recordType,
      RecipeDataRecordService.NodeTypesLocaleDictionary[nodeType.toString()](),
      context,
      description,
      nodeTitle
    );
  }

  public getNodeTitleBasedOnRecordType(notification: ExperimentNodeTitleChangedNotification) {
    if (notification.recordTypes.default?.includes(ExperimentRecordType.Recipe)) {
      const additionalContext = notification.eventContext.additionalContext;
      if (additionalContext) {
        const nodeTitle = additionalContext[notification.nodeId];
        if (!nodeTitle) {
          const templateId = this.dataRecordUtilityService.allModulesInExperiment.find(module => module.moduleId === notification.nodeId)?.templateId;
          if (templateId) {
            const template = objectCache[templateId];
            return template.moduleName;
          } else {
            return $localize`:@@noTitle:No Title`;
          }

        }
        return nodeTitle;
      }
    }
    return notification.title;
  }
}
