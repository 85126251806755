import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ChangeReasonService } from '../services/change-reason.service';
import { BptControlSeverityIndicators, NA } from 'bpt-ui-library/shared';
import { ExperimentService } from '../services/experiment.service';
import { ExperimentWorkflowState } from '../../api/models';
import { UserService } from '../../services/user.service';
import { ChangeReasonHelper } from '../services/change-reason-helper';

@Component({
  selector: 'app-change-reason-renderer',
  template: `
                 <div class="eln-change-reason">
  <div>{{formattedChangeReasonDetails}}</div>
  <div class="change-reason-indicators">
    <div *ngIf="changeReasonMissing" class="empty-content icon-exclamation-mark empty-change-reason-icon"></div>
    <div
      *ngIf="canUpdateChangeReason"
      id="eln-change-reason-icon"
      class="icon-s icon-pencil eln-change-reason-icon"
      [ngClass]="{'disabled': isDisabled}"
      (click)="updateChangeReason()"
      [attr.tabindex]="isDisabled ? -1 : 0"
    ></div>
  </div>
</div>
            `,
  styleUrls: ['./change-reason-renderer.component.scss']
})
export class ChangeReasonRendererComponent implements ICellRendererAngularComp {
  notApplicable = NA;
  canUpdateChangeReason = false;
  isDisabled = false;
  changeReasonMissing = false;
  indicator = BptControlSeverityIndicators.ServerityIndicators['empty'];
  refresh(): boolean {
    return false;
  }

  constructor(private readonly changeReasonService: ChangeReasonService,
    private readonly experimentService: ExperimentService,
    private readonly userService : UserService,
    private readonly changeReasonHelper : ChangeReasonHelper
  ) {}
  public params!: any;
  formattedChangeReasonDetails = '';

  agInit(params: any): void {
    this.params = params;
    var uiFeatures = this.changeReasonService.getOrSetUIFeatures(this.params.data.ChangeReason, this.params.data.ChangeReason?.canUpdate);
    this.formattedChangeReasonDetails = uiFeatures.defaultTextForUpdatingMissedReason;
    this.changeReasonMissing = uiFeatures.isChangeReasonMissing;
    this.canUpdateChangeReason = uiFeatures.canUpdateChangeReason;
    this.disableChangeReasonUpdate();
  }

  isPermittedToUpdateChangeReason(): boolean {
    return (this.userService.hasAnalystRights(this.userService.currentUser.puid) ||
      this.userService.hasSupervisorRights(this.userService.currentUser.puid)) &&
      ((this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InCorrection) ||
      (this.experimentService.currentExperiment?.workflowState === ExperimentWorkflowState.InProgress))
  }

  disableChangeReasonUpdate() {
    if (!this.isPermittedToUpdateChangeReason())
      this.isDisabled = true;
  }

  setDescription(params: any) {
    const isTestSelectionField = ['Sample Test Changed', 'Study Activity Selected'];
    if (isTestSelectionField.includes(params.Name)) {
      return this.changeReasonHelper.setNewValueForTestOrStudySelectionFromAudit(params.Source.aliquotTests ?? params.Source.studyActivities, params.Name);
    }
    return params.Description;
  }


  updateChangeReason() {
    ChangeReasonService.changeReasonId = this.params.data.ChangeReason.changeReasonId;
    if (this.canUpdateChangeReason && this.isPermittedToUpdateChangeReason())
        {
        this.changeReasonService.triggerSliderVisibilityFromAuditHistory(
          true,
          this.params.data.ChangeReason.nodeId,
          this.params.data.ChangeReason.oldValue,
          this.setDescription(this.params.data),
          this.params.data.Context,
          true,
          this.changeReasonMissing
        );
      }
    }
  }
