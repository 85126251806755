import { Injectable } from "@angular/core";
import { ActivityInputType, ExperimentDataRecordNotification, LabItemsCellChangedEventNotification, NumberValue, PromptSatisfiedEventNotification } from "../../api/data-entry/models";
import { ChangeReasonContext } from "../../model/change-reason-context";
import { AuditHistory } from "./audit-history.interface";
import { User } from "../../api/models/user";
import { ExperimentService } from "./experiment.service";
import { Activity, Experiment, Module } from "../../model/experiment.interface";
import { ActivityLabItemsNode, Consumable } from "../../api/models";
import { LabItemsConsumablesTableOptions } from "../labItems/consumables/lab-items-consumable-table-options";
import { LabItemsMaterialTableOptions } from "../labItems/materials/lab-items-material/lab-items-material-table-options";
import { LabItemsColumnTableOptions } from "../labItems/columns/lab-items-column/lab-items-column-table-options";
import { LabItemsInstrumentTableOptions } from "../labItems/instruments/lab-items-instrument/lab-items-instrument-table-options";

@Injectable({
  providedIn: 'root'
})

export class DataRecordUtilityService {

  public allModulesInExperiment: Array<Module> = [];

  usersList!: User[];

  private readonly labItemsConsumableTitle = $localize`:@@LabItemsConsumableTableTitle:Consumables and Supplies`;
  private readonly labItemsInstrumentColumnTitle = $localize`:@@LabItemsColumnsTableTitle:Columns`;
  private readonly labItemsInstrumentTitle = $localize`:@@LabItemsInstrumentsTableTitle:Instruments`;
  private readonly labItemsMaterialTitle = $localize`:@@LabItemsMaterialsTableTitle:Materials`;
  private readonly labItemsPreparationTitle = $localize`:@@preparations:Preparations`;
  private readonly labItemsTitle = $localize`:@@activityLabItemsPageTitle:Lab Items`;

  get experiment(): Experiment {
    /*
      Tests break this rule so skipping until they can be changed.
      if (!this.experimentService.currentExperiment) throw Error('LOGIC ERROR: Can't use DataRecordService methods without an experiment being loaded.');
    */
    return this.experimentService.currentExperiment as Experiment;
  }

  constructor(private readonly experimentService: ExperimentService) {

  }

  public getHistory(
    experimentRecord: ExperimentDataRecordNotification,
    fullContextExp: string,
    recordType: string,
    contextExp: string,
    actualContextExp: string,
    description: string,
    name?: string,
    changeReason?: ChangeReasonContext
  ): AuditHistory {
    const fullName = this.usersList?.find(
      u => u.puid.toLowerCase() === experimentRecord.eventContext.puid.toLowerCase()
    )?.fullName;
    return {
      Source: experimentRecord,
      Time: experimentRecord.eventContext.eventTime,
      Context: fullContextExp,
      RecordType: recordType,
      ContextType: contextExp,
      Name: name ?? contextExp,
      Description: description,
      PerformedBy: typeof fullName === 'undefined' ? experimentRecord.eventContext.puid : fullName,
      RecordVersion: 1,
      ActualContext: actualContextExp,
      ChangeReason: changeReason
    };
  }

  /**
   * Gets the history-style formatted context of a activity (current title etc)
   */
  public getActivityContext(nodeId: string): { fullPath: string, title: string } {
    const activityTitle = this.experiment?.activities.find((f) => f.activityId === nodeId)?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    return { fullPath: `${activityTitle ?? $localize`:@@NoTitle:No Title`}`, title: activityTitle };
  }

  public getTableOrFormContext(activityId: string, moduleId: string, nodeId: string): string {
    const activityTitle = this.experiment?.activities.find((a) => a.activityId === activityId)?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    const moduleTitle = this.experiment?.activities.flatMap((a: Activity) => a.dataModules).find(m => m.moduleId === moduleId)?.moduleName ?? $localize`:@@NoTitle:No Title`;
    const tableOrFormTitle = this.experiment?.activities.flatMap((a: Activity) => a.dataModules).find(m => m.moduleId === moduleId)?.items.find(t => t.nodeId === nodeId)?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    return tableOrFormTitle ? `${activityTitle} > ${moduleTitle} > ${tableOrFormTitle}` : $localize`:@@NoTitle:No Title` ;
  }

  public getPreparationContext(nodeId: string, preparationId: string): string {
    const activity = this.experiment?.activities.find((a) => a.activityId === nodeId);
    const preparation = activity?.preparations.find((p) => p.preparationId === preparationId);
    const activityTitle = activity?.itemTitle ?? $localize`:@@NoTitle:No Title`;
    return preparation ? `${activityTitle} > Preparation > Preparation > ${preparation?.preparationNumber}` : $localize`:@@NoTitle:No Title` ;
  }

  public getLabItemsCellChangedContext(notification: LabItemsCellChangedEventNotification): string {
    let context = this.getActivityInputContextByType(notification.itemType, this.labItemsTitle);
    if (notification.itemReference) {
      context = context.concat(` > ${this.getItemReference(notification)} > ${this.getPropertyName(notification)}`);
    } else {
      context = context.concat(` > ${this.getItemReference(notification)}`);
    }

    return context;
  }

  private getItemReference(notification: LabItemsCellChangedEventNotification) {
    if (notification.itemType === ActivityInputType.Consumable) {
      const datasource = (this.experimentService.currentExperiment?.activityLabItems.find(
        (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
      )?.consumables as Array<Consumable>) || [];
      const consumable = datasource.find(k => k.itemReference === notification.itemReference);
      const fields = consumable?.tableData.filter((r: any) => !!r.rowIndex);
      if (fields) {
        const rowIndexField = fields[0];
        const rowIndex = (rowIndexField?.rowIndex.value as NumberValue).value;
        return 'Row Number ' + rowIndex;
      }
      return '';
    }
    return notification.itemReference;
  }

  public getPropertyName(notification: LabItemsCellChangedEventNotification): string {
    switch (notification.itemType) {
      case ActivityInputType.Consumable: {
        const columnDefinitions = LabItemsConsumablesTableOptions.GetColumnDefinitions(false);
        const column = columnDefinitions.find((c) => c.field === notification.propertyName);
        return column?.label ?? '';
      }
      case ActivityInputType.Material: {
        const instrumentMaterialColumnDefinitions = LabItemsMaterialTableOptions.GetColumnDefinitions(undefined, () => undefined);
        const instrumentMaterialColumn = instrumentMaterialColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentMaterialColumn?.label ?? '';
      }
      case ActivityInputType.InstrumentColumn: {
        const instrumentColumnDefinitions = LabItemsColumnTableOptions.GetColumnDefinitions();
        const instrumentColumn = instrumentColumnDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentColumn?.label ?? '';
      }
      case ActivityInputType.Instrument:
      case ActivityInputType.InstrumentDetails: {
        const instrumentTableDefinitions = LabItemsInstrumentTableOptions.GetColumnDefinitions();
        const instrumentTableColumn = instrumentTableDefinitions.find((c) => c.field === notification.propertyName);
        return instrumentTableColumn?.label ?? '';
      }
      default:
        return notification.propertyName;
    }
  }

  public getActivityInputContextByType(type: ActivityInputType, contextTitle: string): string {
    const pageTitle = this.getActivityInputTypeTitleByType(type);
    let activityId = this.experimentService.currentContextMenuActivityId;
    if (activityId === '' && this.experimentService.currentActivity?.activityId) {
      activityId = this.experimentService.currentActivity?.activityId;
    }
    return `${this.getActivityContext(activityId).fullPath} > ${contextTitle} > ${pageTitle}`;
  }

  public getActivityInputTypeTitleByType(type: ActivityInputType): string {
    let pageTitle = '';
    switch (type) {
      case ActivityInputType.Material:
        pageTitle = this.labItemsMaterialTitle;
        break;
      case ActivityInputType.InstrumentDetails:
        pageTitle = this.labItemsInstrumentTitle;
        break;
      case ActivityInputType.Consumable:
        pageTitle = this.labItemsConsumableTitle;
        break;
      case ActivityInputType.InstrumentColumn:
        pageTitle = this.labItemsInstrumentColumnTitle;
        break;
      case ActivityInputType.Preparation:
        pageTitle = this.labItemsPreparationTitle;
        break;
    }
    return pageTitle;
  }

  public getConsumableRowNumber(notification: PromptSatisfiedEventNotification) {
    const datasource = (this.experimentService.currentExperiment?.activityLabItems.find(
      (labItemNode: ActivityLabItemsNode) => labItemNode.nodeId === notification.activityId
    )?.consumables) || [];
    const rowIndex = datasource.findIndex(k => k.itemReference === notification.labItemId) + 1;
    return 'Row Number ' + rowIndex;
  }

  public getContextTitleForPreparation(propertyName: string) {
    switch (propertyName) {
      case "Name":
        return $localize`:@@name:Name`;
      case "FormulaComponents":
        return $localize`:@@Formulation/Components:Formulation/Components`
      case "ExpirationValue":
        return $localize`:@@Expiration:Expiration`;
      case "StorageCondition":
        return $localize`:@@StorageCondition:Storage Condition`;
      case "Concentration":
        return $localize`:@@Concentration:Concentration`;
      case "Description":
        return $localize`:@@containerDescription:Container Description`;
      default:
        throw new Error("Title not found for property");
    }
  }
}