/* tslint:disable */
/* eslint-disable */
export enum ActivityItemReferenceType {
  Invalid = 'invalid',
  SampleAliquot = 'sampleAliquot',
  MaterialAliquot = 'materialAliquot',
  InstrumentEvent = 'instrumentEvent',
  Instrument = 'instrument',
  Consumable = 'consumable',
  Column = 'column',
  Preparation = 'preparation',
  PreparationLabItem = 'preparationLabItem',
  Input = 'input'
}
