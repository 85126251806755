import { Injectable, OnDestroy } from '@angular/core';
import {
  RecipeEventsService,
  RecipeService as RecipeAPIService
} from '../../api/cookbook/services';
import {
  CancelRecipeCommand,
  ChangeRecipeAdditionalNotesCommand,
  ChangeRecipeAnalysisTechniqueCommand,
  ChangeRecipeApprovalRequiredCommand,
  ChangeRecipeApprovedCommand,
  ChangeRecipeApproversCommand,
  ChangeRecipeClientsCommand,
  ChangeRecipeCompendiaCommand,
  ChangeRecipeConsumingLabsitesCommand,
  ChangeRecipeEditorsCommand,
  ChangeRecipeMethodCommand,
  ChangeRecipeNameCommand,
  ChangeRecipeProjectsCommand,
  ChangeRecipePurposeCommand,
  ChangeRecipeReviewStatusCommand,
  ChangeRecipeSatisfiesPurposeCommand,
  ChangeRecipeSubBusinessUnitsCommand,
  ChangeRecipeTagsCommand,
  ChangeRecipeVerifiedCommand,
  ChangeRecipeVerifiersCommand,
  CopyRecipeCommand,
  RecipeChangeToPendingApprovalCommand,
  RecipeChangeToPendingVerificationCommand,
  RecipeReferenceResponse,
  RecipeReturnToInDraftCommand,
  RecipePublishedCommand,
  RecipeState,
  RestoreRecipeCommand,
  StartNewVersionCommand,
  RetireRecipeCommand,
  RecipeType,
  RecipePublishedResponse,
  RecipeResponse,
  ActivityResponseNode,
  ModuleResponseNode,
  FormResponseNode,
  TableResponseNode,
  RecipeAddTemplateCommand,
  RecipeTemplateAddedResponse,
  RecipeVariable,
  SetVariableCommand,
  RecipeReferenceRowsAddedResponse,
  ChangeRecipeReferenceCellCommand,
  ElnCell,
  RecipeDeleteTemplateCommand,
  RecipePreLoadDataTransformType,
  RecipePreLoadDataTransformContextType,
  RecipePreLoadDataTransformOption,
  ChangeRecipePreLoadDataTransformOptionsResponse,
  ChangeRecipePreLoadDataTransformOptionsCommand,
  RecipePreparation,
  PreparationsResponseNode,
  PromptResponseNode,
  ReferencesResponseNode,
  PromptResponse,
  ReferenceItem,
  ChangeRecipeTypeCommand
} from '../../api/cookbook/models';
import { environment } from '../../../environments/environment';
import { ColumnSpecification as ApiColumnSpecification } from '../../api/models/column-specification';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  concatMap,
  forkJoin,
  map,
  mergeAll,
  of,
  single,
  tap
} from 'rxjs';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { PromptModel, RecipeModel, RecipeStartNewVersionChange } from '../model/recipe';
import { TemplateSearchCommand } from '../../recipe-template-loader/models/template-search-command.interface';
import {
  FieldDefinitionResponse,
  FieldGroupResponse,
  FieldType,
  FormItemResponse,
  FormNode,
  FormTemplate,
  LabsiteGetResponse,
  ModifiableDataValue,
  NotificationDetails,
  NumberValue,
  SpecType,
  StringValue,
  SubBusinessUnit,
  TableNode,
  TableTemplate,
  TemplateType,
  Unit,
  UnitList,
  UserPicklistResponse,
  ValueState,
  ValueType
} from '../../api/models';
import { ControlType, SearchControl } from 'bpt-ui-library/bpt-search';
import { UserService } from '../../services/user.service';
import { LabsiteService, TemplatesService, UserPicklistsService } from '../../api/services';
import { ProjectLogLoaderService } from '../../services/project-log-loader.service';
import { DropdownAttributes } from 'model/template.interface';
import { UnitLoaderService } from '../../services/unit-loader.service';
import {
  Activity,
  ColumnSpecification,
  SpecificationValue,
  Table
} from '../../model/experiment.interface';
import { BptGridPreferences } from 'bpt-ui-library/bpt-grid';
import { UnsubscribeAll, clearObjectCache, elnShareReplay } from '../../shared/rx-js-helpers';
import { TemplateEventService } from '../../recipe-template-loader/experiment-template-load/services/template-event.service';

/** Dictionary of Table Templates */
interface Tables {
  [key: string]: TableTemplate;
}

interface Forms {
  [key: string]: FormTemplate;
}

import { ConfigurationService } from '../../services/configuration.service';
import {
  AuthResult,
  AuthStatusType
} from '../../shared/authentication-maintainance/model/auth.model';
import { FeatureService } from '../../services/feature.service';
import { AuthenticationHelperService } from '../../shared/authentication-maintainance/services/authentication-helper.service';
import {
  AugmentedActivity,
  AugmentedForm,
  AugmentedModule,
  AugmentedTable
} from '../../model/recipe.interface';
import { RuleActionHandlerHostService } from '../../rule-engine/handler/rule-action-handler-host.service';
import { SetVariableCommand as RulesSetVariable, NodeType } from '../../api/data-entry/models';
import {
  ConditionType,
  DataType,
  SearchItemType,
  SortDirection,
  StringMatchType
} from '../../api/search/models';
import { TemplateApplyService } from '../../recipe-template-loader/experiment-template-load/services/template-apply.service';
import { TemplateInsertLocationOptions } from '../../recipe-template-loader/experiment-template-load/models/recipe-template-insert-location-options';
import { SelectedTemplateCommand } from '../../recipe-template-loader/models/template-loader-information';
import { compatibleRecipeTypes, TemplateLocationOptions, TemplateLocationOptionsConstants } from '../../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { SelectedTemplate } from '../../recipe-template-loader/models/selected-template';
import { isEqual } from 'lodash-es';
import { PreparationItem } from '../../preparation/models/preparation-presentation.model';
import { repeatWithColumn, repeatForColumn, RecipeTableComponent, rowSelectedColumn } from '../data/table/recipe-table.component';
import { NA } from 'bpt-ui-library/shared';
import { navigateRecipeToAboutPage } from '../recipe.routes';

export type SpecificationEditorContext = {
  /** Arbitrary, unique id, such as a UUID string created by the emitter of ExperimentService.beginEditSpecification */
  id: string;
  /** Initial value. */
  value: SpecificationValue;
  readOnly: boolean;
  disabled: boolean;
  allowedUnits: Unit[];
  allowedSpecTypes: SpecType[];

  preloadScalingOptions?: SpecificationPreloadScalingOptions;
  defaultUnit?: Unit;
  /** "Callback" Subject to emit a committed value into, such as by the Commit button of specificationInputComponent */
  onChange: Subject<SpecificationValue>;
  onClose?: Subject<never>;
  onPreloadScalingOptionsChanged?: Subject<SpecificationPreloadScalingOptions>;
};

export type SpecificationPreloadScalingOptions = {
  allowScaling: boolean;
  allowScaleUp: boolean;
  allowScaleDown: boolean;
};

interface PicklistItem {
  label: string;
  value: string;
};

export interface RecipeSources {
  itemType: SearchItemType;
  referenceId: string;
  referenceNumber?: string;
  instanceId?: string;
  childInstanceIds?: Array<string>;
}
@Injectable({
  providedIn: 'root'
})
export class RecipeService implements OnDestroy {
  public static readonly modifiableDataValueNAPlaceholder: ModifiableDataValue = {
    isModified: false,
    value: {
      type: ValueType.String,
      state: ValueState.NotApplicable,
      value: NA
    }
  };
  public readonly activitySelectionChanged = new Subject<string>();
  private readonly tableList: Tables[] = [];
  private readonly formList: Forms[] = [];
  public recipe: RecipeModel
  public RecipeSources: RecipeSources[] = [];
  public consumingRecipes: RecipeSources[] = [];
  public allVersionsOfReferencedRecipes: RecipeReferenceResponse[] = [];
  subscriptions: Subscription[] = [];
  private readonly storageConditionId = 'eb7aef54-5e41-4b2f-879c-c246152f5e4a';
  /** Most of the recipe VerificationDetails fields are dependent on recipe state, so declaring this variable. */
  public readonly recipeWorkFlowState = new Subject<void>();
  public recipeLoaded = new Subject<boolean>();
  public isApprovalRequiredChanged = new Subject<boolean>();
  isRecipeLoaded = false;
  public isRecipeVerified = new Subject<boolean>();
  public isRecipeApproved = new Subject<boolean>();
  public isRecipeUsersChanged = new Subject<boolean>();
  public isRecipeNameChanged = new Subject<boolean>();
  public isRecipeNameNotChanged = new Subject<boolean>();
  public isRecipeNameEmpty = new Subject<boolean>();
  public isRecipeEditorNotSelected = new Subject<boolean>();
  public isRecipeEditorNotChanged = new Subject<boolean>();
  public isRecipeLabSiteNotSelected = new Subject<boolean>();
  public isRecipeLabSiteNotChanged = new Subject<boolean>();
  public isRecipeSubBusinessUnitNotSelected = new Subject<boolean>();
  public isRecipeSubBusinessUnitNotChanged = new Subject<boolean>();
  public isRecipeSubBusinessUnitChanged = new Subject<boolean>();
  public isRecipePurposeValuesChanged = new Subject<void>();
  public readonly activityCompletionStatus = new Subject<Activity>();
  public warningReceived = new Subject<string[]>();
  public readonly recipeReferenceRowsAdded = new Subject<RecipeReferenceRowsAddedResponse>();
  public readonly savePromptNotifier = new Subject<boolean>();
  public readonly recipeReferenceCellChanged = new Subject<ChangeRecipeReferenceCellCommand>();
  public allVersionsOfReferencedRecipesAvailability = new Subject<void>();
  public isRecipeNewMinorVersionPresent = new Subject<RecipeStartNewVersionChange>();
  public otherVersionsOfReferencedRecipesDictionary: { [key: string]: string[] } = {};
  public otherMajorVersionsOfReferencedRecipesDictionary: { [key: string]: string[] } = {};
  public isLoading = new BehaviorSubject<boolean>(false);
  public isDesignerDataLoaded = false;
  public readonly beginEditSpecification = new Subject<SpecificationEditorContext>();
  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: ''
  };

  public insertLocationOptions: TemplateInsertLocationOptions[] = [];
  newTableOrFormOrPpr = TemplateLocationOptionsConstants.newTableOrFormOrPpr;
  newActivity = TemplateLocationOptionsConstants.newActivity;
  newModule = TemplateLocationOptionsConstants.newModule;
  existingModule = TemplateLocationOptionsConstants.existingModule;
  existingActivityAsModule = TemplateLocationOptionsConstants.existingActivityAsModule;
  insertOptions: { label: string, value: string }[] = [];
  //true in dev and false in release
  recipeChecked = true;
  templateSearchCriteria: TemplateSearchCommand = {};
  recipeSearchCriteria: any = {};
  searchControls: SearchControl[] = [];
  subBusinessUnits: SubBusinessUnit[] = [];
  isSBULoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoadingDocumentsOrCompendia = false;
  recipeHasErrors = false;

  private readonly compendiaValuesSubject = new BehaviorSubject<PicklistItem[]>([]);
  public compendiaValues$ = this.compendiaValuesSubject.asObservable();

  private readonly documentValuesSubject = new BehaviorSubject<PicklistItem[]>([]);
  public documentType$ = this.documentValuesSubject.asObservable();

  private readonly completionStatus = new Subject<boolean>();
  completionStatus$ = this.completionStatus.asObservable();

  private readonly handleRecipeErrorSubject = new BehaviorSubject<boolean>(false);
  handleRecipeError = this.handleRecipeErrorSubject.asObservable();

  private readonly columnDefinitionsReadySubject = new BehaviorSubject<boolean>(false);
  columnDefinitionsReady$ = this.columnDefinitionsReadySubject.asObservable();

  public pickList: UserPicklistResponse[] = [];
  lastMajorVersionType = RecipeType.None;

  get currentRecipe(): RecipeModel {
    return this.recipe;
  }

  get currentRecipeId(): string {
    return this.recipe.recipeId;
  }

  private _currentActivityId = '';
  get currentActivityId(): string {
    return this._currentActivityId;
  }
  set currentActivityId(value: string) {
    this._currentActivityId = value;
    this.activitySelectionChanged.next(value);
  }

  private _currentActivityTitle = '';
  get currentActivityTitle(): string {
    return this._currentActivityTitle;
  }
  set currentActivityTitle(value: string) {
    this._currentActivityTitle = value;
  }
  currentModuleId = '';
  currentModuleName = '';

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly recipeApiService: RecipeAPIService,
    private readonly recipeEventsService: RecipeEventsService,
    public readonly templateEventService: TemplateEventService,
    public readonly templateApplyService: TemplateApplyService,
    private readonly messageService: MessageService,
    private readonly userService: UserService,
    private readonly labsiteService: LabsiteService,
    private readonly projectLogLoaderService: ProjectLogLoaderService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly templatesService: TemplatesService,
    private readonly picklistService: UserPicklistsService,
    private readonly authenticationHelperService: AuthenticationHelperService,
    private readonly featureService: FeatureService
  ) {
    // TODO: Will be removed post finding the fix for the issue of Units not loading
    this.unitLoaderService.allUnits$.subscribe({
      next: () => { }
    });
    this.recipe = this.currentRecipe;
    this.templateEventService.LoadedItem = () => this.currentRecipe;
    this.templateEventService.assessTypeOnLoad = (newlyLoadedTemplateType: TemplateType) =>
      this.assessRecipeType(newlyLoadedTemplateType);
    recipeEventsService.rootUrl = environment.cookbookServiceUrl;
    this.getSubBusinessUnits();
    this.handleSubscriptions();
  }

  private handleSubscriptions() {
    this.subscriptions.push(
      this.templateEventService.TemplateApplyCommandFinalized.subscribe(({ command, number }) => {
        this.sendRecipeTemplateApplyCommand(command, number);
      })
    );

    this.subscriptions.push(
      this.recipeReferenceCellChanged.subscribe(response => {
        response.rowIds.forEach(rowId => {
          const currentRow = !response.activityId
            ? this.recipe.orphan?.references.flatMap(r => r.rows).find(r => r.id === rowId)
            : this.recipe.activities
              .find(ac => ac.activityId === response.activityId)
              ?.references.flatMap(r => r.rows)
              .find(r => r.id === rowId);
          if (currentRow) {
            response.columnValues.forEach((columnValue: ElnCell) => {
              currentRow[columnValue.propertyName] = {
                isModified: false,
                value: columnValue.propertyValue
              };
            });
          }
        });
      })
    );

    this.subscriptions.push(
      this.recipeReferenceRowsAdded.subscribe(response => {
        const newRows = response.values.map(obj => {
          const newObj: any = {};
          for (const key in obj) {
            if (key !== 'id') {
              newObj[key] = {
                isModified: false,
                value: {
                  ...obj[key]
                }
              };
            } else {
              newObj['id'] = obj[key];
            }
          }
          return newObj;
        });

        newRows.forEach(rowItem => {
          if (!response.activityId || response.activityId === this.recipe.recipeId) {
            this.recipe.orphan?.references.push({ referenceType: response.type, rows: [rowItem] });
            //update recipe type
            if (this.currentRecipe.type === RecipeType.None) {
              const changeRecipeTypeCommand = {
                recipeId: this.currentRecipe.recipeId,
                recipeType: RecipeType.ReferencePromptPreparation
              } as ChangeRecipeTypeCommand
              this.changeType(changeRecipeTypeCommand).subscribe(type => {
                this.currentRecipe.type = type;
              });
            }
          } else {
            this.recipe.activities
              .find(ac => ac.activityId === response.activityId)
              ?.references.push({ referenceType: response.type, rows: [rowItem] });
          }
        });
      })
    );

    this.subscriptions.push(
      this.savePromptNotifier.subscribe(response => {
        if (response === true && this.recipe.type === RecipeType.None) this.recipe.type = RecipeType.ReferencePromptPreparation;
      })
    );
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  loadRecipe(recipeNumber: string, version: string): Observable<RecipeModel> {
    this.isLoading.next(true);
    this.clearSelectedNodes();
    return this.recipeApiService
      .recipesRecipeNumberVersionGet$Json({
        recipeNumber,
        version
      })
      .pipe(
        map(snapshot => this.populateFromTemplates(snapshot)),
        mergeAll()
      );
  }

  clearSelectedNodes() {
    this.currentActivityId = '';
    this.currentActivityTitle = '';
    this.currentModuleId = '';
    this.currentModuleName = '';
  }

  loadRecipeById(recipeId: string): Observable<RecipeModel> {
    return this.recipeApiService
      .recipesRecipeIdGet$Json({
        recipeId
      })
      .pipe(
        map(snapshot => this.populateFromTemplates(snapshot, true)),
        mergeAll()
      );
  }

  setActivityCompletionStatus(activity: Activity) {
    this.activityCompletionStatus.next(activity);
  }

  changeName(value: ChangeRecipeNameCommand): Observable<boolean> {
    const nameChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeNamePost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.name = value.title;
            nameChanged.next(true);
            this.isRecipeNameChanged.next(true);
          } else {
            nameChanged.next(false);
          }
        },
        error: () => {
          nameChanged.next(false);
        }
      });
    return nameChanged;
  }

  changeType(value: ChangeRecipeTypeCommand): Observable<RecipeType> {
    const typeChanged = new Subject<RecipeType>();
    this.recipeEventsService
      .recipeEventsChangeRecipeTypePost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.type = response.recipeType;
            typeChanged.next(this.recipe.type);
          } else {
            typeChanged.next(this.recipe.type);
          }
        },
        error: () => {
          typeChanged.next(this.recipe.type);
        }
      });
    return typeChanged;
  }

  nameNotChanged(): Observable<boolean> {
    this.isRecipeNameNotChanged.next(true);
    return this.isRecipeNameNotChanged;
  }

  isNameEmpty(): Observable<boolean> {
    this.isRecipeNameEmpty.next(true);
    return this.isRecipeNameEmpty;
  }

  changeMethod(value: ChangeRecipeMethodCommand): Observable<boolean> {
    const methodChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeMethodPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          const hasNotifications = response.notifications.notifications.length === 0;
          if (hasNotifications) this.recipe.method = value.method;
          methodChanged.next(hasNotifications);
        },
        error: () => {
          methodChanged.next(false);
        }
      });
    return methodChanged;
  }

  changeAnalysisTechnique(value: ChangeRecipeAnalysisTechniqueCommand): Observable<boolean> {
    const analysisTechniqueChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeAnalysisTechniquePost$Json({
        body: value
      })
      .subscribe({
        next: response => {
          const hasNotifications = response.notifications.notifications.length === 0;
          if (hasNotifications) this.recipe.analysisTechnique = value.analysisTechnique;
          analysisTechniqueChanged.next(hasNotifications);
        },
        error: () => {
          analysisTechniqueChanged.next(false);
        }
      });
    return analysisTechniqueChanged;
  }

  changeEditors(value: ChangeRecipeEditorsCommand): Observable<boolean> {
    const assignedEditorsChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeEditorsPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedAssignedEditors.forEach((addedItem: string) => {
              if (!this.recipe.tracking.assignedEditors.includes(addedItem)) this.recipe.tracking.assignedEditors.push(addedItem);
            });
            response.removedAssignedEditors.forEach((removedItem: string) => {
              if (this.recipe.tracking.assignedEditors.includes(removedItem)) {
                this.recipe.tracking.assignedEditors = this.recipe.tracking.assignedEditors.filter(e => !value.removed.includes(e));
              }
            });
            assignedEditorsChanged.next(true);
            this.isRecipeUsersChanged.next(true);
          } else {
            assignedEditorsChanged.next(false);
          }
        },
        error: () => {
          assignedEditorsChanged.next(false);
        }
      });
    return assignedEditorsChanged;
  }

  editorsNotChanged(): Observable<boolean> {
    this.isRecipeEditorNotChanged.next(true);
    return this.isRecipeEditorNotChanged;
  }

  editorsNotSelected(): Observable<boolean> {
    this.isRecipeEditorNotSelected.next(true);
    return this.isRecipeEditorNotSelected;
  }

  changeConsumingLabsite(value: ChangeRecipeConsumingLabsitesCommand): Observable<boolean> {
    const consumingLabsitesChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeConsumingLabsitePost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedConsumingLabsites.forEach((addedItem: string) => {
              if (!this.recipe.organization.consumingLabsites.includes(addedItem)) this.recipe.organization.consumingLabsites.push(addedItem);
            });
            response.removedConsumingLabsites.forEach((removedItem: string) => {
              if (this.recipe.organization.consumingLabsites.includes(removedItem)) {
                this.recipe.organization.consumingLabsites = this.recipe.organization.consumingLabsites.filter(l => !value.removed.includes(l));
              }
            });
            consumingLabsitesChanged.next(true);
          } else {
            consumingLabsitesChanged.next(false);
          }
        },
        error: () => {
          consumingLabsitesChanged.next(false);
        }
      });
    return consumingLabsitesChanged;
  }

  labSiteNotChanged(): Observable<boolean> {
    this.isRecipeLabSiteNotChanged.next(true);
    return this.isRecipeLabSiteNotChanged;
  }

  labSiteNotSelected(): Observable<boolean> {
    this.isRecipeLabSiteNotSelected.next(true);
    return this.isRecipeLabSiteNotSelected;
  }

  changeSubBusinessUnits(value: ChangeRecipeSubBusinessUnitsCommand): Observable<boolean> {
    const subBusinessUnitsChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeSubBusinessUnitPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedSubBusinessUnits.forEach((addedItem: string) => {
              if (!this.recipe.organization.subBusinessUnits.includes(addedItem)) this.recipe.organization.subBusinessUnits.push(addedItem);
            });
            response.removedSubBusinessUnits.forEach((removedItem: string) => {
              if (this.recipe.organization.subBusinessUnits.includes(removedItem)) {
                this.recipe.organization.subBusinessUnits = this.recipe.organization.subBusinessUnits.filter(s => !value.removed.includes(s));
              }
            });
            subBusinessUnitsChanged.next(true);
            this.isRecipeSubBusinessUnitChanged.next(true);
          } else {
            subBusinessUnitsChanged.next(false);
          }
        },
        error: () => {
          subBusinessUnitsChanged.next(false);
        }
      });
    return subBusinessUnitsChanged;
  }

  subBusinessUnitNotChanged(): Observable<boolean> {
    this.isRecipeSubBusinessUnitNotChanged.next(true);
    return this.isRecipeSubBusinessUnitNotChanged;
  }

  subBusinessUnitNotSelected(): Observable<boolean> {
    this.isRecipeSubBusinessUnitNotSelected.next(true);
    return this.isRecipeSubBusinessUnitNotSelected;
  }

  changeVerifiers(value: ChangeRecipeVerifiersCommand): Observable<boolean> {
    const assignedVerifiersChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeVerifierPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedAssignedVerifiers.forEach((addedItem: string) => {
              if (!this.recipe.tracking.assignedVerifiers.includes(addedItem)) this.recipe.tracking.assignedVerifiers.push(addedItem);
            });
            response.removedAssignedVerifiers.forEach((removedItem: string) => {
              if (this.recipe.tracking.assignedVerifiers.includes(removedItem)) {
                this.recipe.tracking.assignedVerifiers = this.recipe.tracking.assignedVerifiers.filter(v => !value.removed.includes(v));
              }
            });
            assignedVerifiersChanged.next(true);
            this.isRecipeUsersChanged.next(true);
          } else {
            assignedVerifiersChanged.next(false);
          }
        },
        error: () => {
          assignedVerifiersChanged.next(false);
        }
      });
    return assignedVerifiersChanged;
  }

  changeApprovers(value: ChangeRecipeApproversCommand): Observable<boolean> {
    const assignedApproversChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeApproverPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedAssignedApprovers.forEach((addedItem: string) => {
              if (!this.recipe.tracking.assignedApprovers.includes(addedItem)) this.recipe.tracking.assignedApprovers.push(addedItem);
            });
            response.removedAssignedApprovers.forEach((removedItem: string) => {
              if (this.recipe.tracking.assignedApprovers.includes(removedItem)) {
                this.recipe.tracking.assignedApprovers = this.recipe.tracking.assignedApprovers.filter(a => !value.removed.includes(a));
              }
            });
            assignedApproversChanged.next(true);
            this.isRecipeUsersChanged.next(true);
          } else {
            assignedApproversChanged.next(false);
          }
        },
        error: () => {
          assignedApproversChanged.next(false);
        }
      });
    return assignedApproversChanged;
  }

  changeClients(value: ChangeRecipeClientsCommand): Observable<boolean> {
    const clientsChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeClientPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedClients.forEach((addedItem: string) => {
              if (!this.recipe.clients.includes(addedItem)) this.recipe.clients.push(addedItem);
            });
            response.removedClients.forEach((removedItem: string) => {
              if (this.recipe.clients.includes(removedItem)) this.recipe.clients = this.recipe.clients.filter(c => !value.removed.includes(c));
            });
            clientsChanged.next(true);
          } else {
            clientsChanged.next(false);
          }
        },
        error: () => {
          clientsChanged.next(false);
        }
      });
    return clientsChanged;
  }

  changeProjects(value: ChangeRecipeProjectsCommand): Observable<boolean> {
    const projectsChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeProjectPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedProjects.forEach((addedItem: string) => {
              if (!this.recipe.projects.includes(addedItem)) this.recipe.projects.push(addedItem);
            });
            response.removedProjects.forEach((removedItem: string) => {
              if (this.recipe.projects.includes(removedItem)) {
                this.recipe.projects = this.recipe.projects.filter(p => !value.removed.includes(p));
              }
            });
            projectsChanged.next(true);
          } else {
            projectsChanged.next(false);
          }
        },
        error: () => {
          projectsChanged.next(false);
        }
      });
    return projectsChanged;
  }

  changeCompendias(value: ChangeRecipeCompendiaCommand): Observable<boolean> {
    const compendiasChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeCompendiaPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedCompendia.forEach((addedItem: string) => {
              if (!this.recipe.compendia.includes(addedItem)) this.recipe.compendia.push(addedItem);
            });
            response.removedCompendia.forEach((removedItem: string) => {
              if (this.recipe.compendia.includes(removedItem)) {
                this.recipe.compendia = this.recipe.compendia.filter(c => !value.removed.includes(c));
              }
            });
            compendiasChanged.next(true);
          } else {
            compendiasChanged.next(false);
          }
        },
        error: () => {
          compendiasChanged.next(false);
        }
      });
    return compendiasChanged;
  }

  changeTags(value: ChangeRecipeTagsCommand): Observable<boolean> {
    const tagsChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeTagPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            response.addedTags.forEach((addedItem: string) => {
              if (!this.recipe.tags.includes(addedItem)) this.recipe.tags.push(addedItem);
            });
            response.removedTags.forEach((removedItem: string) => {
              if (this.recipe.tags.includes(removedItem)) this.recipe.tags = this.recipe.tags.filter(t => !value.removed.includes(t));
            });
            tagsChanged.next(true);
          } else {
            tagsChanged.next(false);
          }
        },
        error: () => {
          tagsChanged.next(false);
        }
      });
    return tagsChanged;
  }

  changeApprovalRequired(
    approvalRequiredCommand: ChangeRecipeApprovalRequiredCommand
  ): Observable<boolean> {
    const approvalRequiredChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeApprovalRequiredPost$Json({
        body: approvalRequiredCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.isApprovalRequired =
              approvalRequiredCommand.isApprovalRequired;
            approvalRequiredChanged.next(true);
            this.isApprovalRequiredChanged.next(approvalRequiredCommand.isApprovalRequired);
          } else {
            approvalRequiredChanged.next(false);
          }
        },
        error: () => {
          approvalRequiredChanged.next(false);
        }
      });
    return approvalRequiredChanged;
  }

  changePurpose(purposeCommand: ChangeRecipePurposeCommand): Observable<boolean> {
    const purposeChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipePurposePost$Json({
        body: purposeCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.purpose = purposeCommand.purpose;
            this.emitPurposeValueChanged();
            purposeChanged.next(true);
          } else {
            purposeChanged.next(false);
          }
        },
        error: () => {
          purposeChanged.next(false);
        }
      });
    return purposeChanged;
  }

  changeAdditionalNotes(
    additionalNotesCommand: ChangeRecipeAdditionalNotesCommand
  ): Observable<boolean> {
    const additionalNotesChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeAdditionalNotesPost$Json({
        body: additionalNotesCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.additionalNotes =
              additionalNotesCommand.additionalNotes;
            additionalNotesChanged.next(true);
          } else {
            additionalNotesChanged.next(false);
          }
        },
        error: () => {
          additionalNotesChanged.next(false);
        }
      });
    return additionalNotesChanged;
  }

  changeRecipeVerified(value: ChangeRecipeVerifiedCommand): Observable<boolean> {
    const recipeVerified = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeVerifyRecipePost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.isVerified = value.isVerified;
            recipeVerified.next(true);
            this.isRecipeVerified.next(true);
          } else {
            recipeVerified.next(false);
          }
        },
        error: () => {
          recipeVerified.next(false);
        }
      });
    return recipeVerified;
  }

  changeRecipeSatisfiesPurpose(value: ChangeRecipeSatisfiesPurposeCommand): Observable<boolean> {
    const satisfiesPurposeChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeSatisfiesPurposePost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.isRecipeSatisfiesItsPurpose = value.isSatisfyPurpose;
            this.emitPurposeValueChanged();
            satisfiesPurposeChanged.next(true);
          } else {
            satisfiesPurposeChanged.next(false);
          }
        },
        error: () => {
          satisfiesPurposeChanged.next(false);
        }
      });
    return satisfiesPurposeChanged;
  }

  private emitPurposeValueChanged() {
    this.isRecipePurposeValuesChanged.next();
  }

  public reauthenticate(successCallback: () => void, feature: string, message: string): void {
    // To Support the PE environment until FAMS enabled there.
    if (!this.featureService.isEnabled(feature) || !ConfigurationService.isLimsHosted) {
      successCallback();
      return;
    }
    (window as any).Master?.oidcTokenManager?.checkFamsAndGetWindowPopupForReauth();
    const request = {
      successCallback: (authResult: AuthResult) => {
        if (authResult && authResult.status === AuthStatusType.Success && authResult.token) {
          successCallback();
        }
      },
      messageForUnauthorizedCredentials: message
    };
    this.authenticationHelperService.handleReauthenticationResponse(request);
  }

  changeRecipeReviewed(value: ChangeRecipeReviewStatusCommand): Observable<boolean> {
    const considerReviewedChanged = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeReviewPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.isConsideredReviewed = value.isConsiderReviewed;
            this.emitPurposeValueChanged();
            considerReviewedChanged.next(true);
          } else {
            considerReviewedChanged.next(false);
          }
        },
        error: () => {
          considerReviewedChanged.next(false);
        }
      });
    return considerReviewedChanged;
  }

  pendingApprovalRecipe(command: RecipeChangeToPendingApprovalCommand): Observable<boolean> {
    this.isLoading.next(true);
    const recipePendingApproval = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsRecipeChangeToPendingapprovalPost$Json({
        body: command
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.PendingApproval;
            this.createNotificationMessage(
              $localize`:@@recipeTransitionedToPendingApprovalSuccess:Transitioned to "${recipeStateLabel[RecipeState.PendingApproval]}"`,
              $localize`:@@recipePendingApprovalMessageDetails:The recipe has been submitted for approval`,
              'success'
            );
            recipePendingApproval.next(true);
            this.recipeWorkFlowState.next();
          }
        },
        error: () => {
          this.isLoading.next(false);
          this.createNotificationMessage(
            $localize`:@@pendingApprovalErrorMessage:Error while submitting recipe for approval. Try again or contact support.`,
            'error'
          );
        }
      });
    return recipePendingApproval;
  }

  pendingVerificationRecipe(
    command: RecipeChangeToPendingVerificationCommand
  ): Observable<boolean> {
    this.isLoading.next(true);
    const recipePendingVerification = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsRecipeChangeToPendingverificationPost$Json({
        body: command
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.PendingVerification;
            this.createNotificationMessage(
              $localize`:@@recipeTransitionedToPendingVerificationSuccess:Transitioned to "${recipeStateLabel[RecipeState.PendingVerification]}"`,
              $localize`:@@recipePendingVerificationMessageDetails:The recipe has been submitted for verification`,
              'success'
            );
            recipePendingVerification.next(true);
            this.recipeWorkFlowState.next();
          }
        },
        error: (error) => {
          this.isLoading.next(false);
          console.log(error);
        }
      });
    return recipePendingVerification;
  }

  deleteTemplate(deleteCommand: RecipeDeleteTemplateCommand): Observable<boolean> {
    const recipePendingVerification = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsDeleteTemplatePost$Json({
        body: deleteCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.createNotificationMessage(
              $localize`:@@templateDeletedSuccess:Selected template/recipe removed successfully and source has been updated`,
              '',
              'success'
            );
            this.templateEventService.TemplateDeletedSuccessNotification(deleteCommand, response)
          }
        }
      })
    return recipePendingVerification;
  }

  cancelRecipe(cancelRecipeCommand: CancelRecipeCommand): Observable<boolean> {
    this.isLoading.next(true);
    const recipeCancelled = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsCancelRecipePost$Json({
        body: cancelRecipeCommand
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.Cancelled;
            this.createNotificationMessage(
              $localize`:@@recipeCancelledSuccess:Transitioned to "${recipeStateLabel[RecipeState.Cancelled]}"`,
              $localize`:@@recipeCancelledMessageDetails:The recipe has been cancelled successfully`,
              'success'
            );
            recipeCancelled.next(true);
            this.recipeWorkFlowState.next();
          } else {
            recipeCancelled.next(false);
          }
        },
        error: () => {
          this.isLoading.next(false);
          recipeCancelled.next(false);
        }
      });
    return recipeCancelled;
  }

  retireRecipe(retireRecipeCommand: RetireRecipeCommand): Observable<boolean> {
    this.isLoading.next(true);
    const recipeRetired = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsRetireRecipePost$Json({
        body: retireRecipeCommand
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.Retired;
            this.createNotificationMessage(
              $localize`:@@recipeCancelledSuccess:Transitioned to "${recipeStateLabel[RecipeState.Retired]}"`,
              $localize`:@@recipeRetiredMessageDetails:Recipe is retired successfully`,
              'success'
            );
            recipeRetired.next(true);
            this.recipeWorkFlowState.next();
          } else {
            recipeRetired.next(false);
          }
        },
        error: () => {
          this.isLoading.next(false);
          recipeRetired.next(false);
        }
      });
    return recipeRetired;
  }

  returnRecipeToInDraft(
    returnRecipeToInDraftCommand: RecipeReturnToInDraftCommand
  ): Observable<boolean> {
    this.isLoading.next(true);
    const recipeReturnedToInDraft = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsRecipeReturnToIndraftPost$Json({
        body: returnRecipeToInDraftCommand
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.Draft;
            this.recipe.verificationDetails = {
              isApprovalRequired: this.recipe.verificationDetails.isApprovalRequired,
              isApproved: false,
              isConsideredReviewed: false,
              isRecipeSatisfiesItsPurpose: false,
              isVerified: false,
              purpose: this.recipe.verificationDetails.purpose,
              additionalNotes: this.recipe.verificationDetails.additionalNotes
            };
            this.createNotificationMessage(
              $localize`:@@recipeCancelledSuccess:Transitioned to "${recipeStateLabel[RecipeState.Draft]}"`,
              $localize`:@@recipeReturnedToInDraftMessageDetails:Recipe transitioned back to draft`,
              'success'
            );
            recipeReturnedToInDraft.next(true);
            this.recipeWorkFlowState.next();
          } else {
            recipeReturnedToInDraft.next(false);
          }
        },
        error: () => {
          this.isLoading.next(false);
          recipeReturnedToInDraft.next(false);
        }
      });
    return recipeReturnedToInDraft;
  }

  restoreRecipe(restoreRecipeCommand: RestoreRecipeCommand): Observable<boolean> {
    this.isLoading.next(true);
    const recipeRestored = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsRestoreRecipePost$Json({
        body: restoreRecipeCommand
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.Draft;
            this.createNotificationMessage(
              $localize`:@@recipeRestoreSuccess:Transitioned to "${recipeStateLabel[RecipeState.Draft]}"`,
              $localize`:@@recipeRestoredMessageDetails:The recipe has been restored successfully`,
              'success'
            );
            recipeRestored.next(true);
          } else {
            recipeRestored.next(false);
          }
        },
        error: () => {
          this.isLoading.next(false);
          recipeRestored.next(false);
        }
      });
    return recipeRestored;
  }

  publishRecipe(publishRecipeCommand: RecipePublishedCommand): Observable<RecipePublishedResponse> {
    this.isLoading.next(true);
    const recipePublished = new Subject<RecipePublishedResponse>();
    this.recipeEventsService
      .recipeEventsRecipePublishedPost$Json({
        body: publishRecipeCommand
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.state = RecipeState.Published;
            this.createNotificationMessage(
              $localize`:@@recipePublishedSuccess:Transitioned to "${recipeStateLabel[RecipeState.Published]}"`,
              $localize`:@@recipePublishedMessageDetails:The recipe has been ${recipeStateLabel[RecipeState.Published]} successfully`,
              'success'
            );
            this.isLoading.next(false);
            recipePublished.next(response);
            this.recipeWorkFlowState.next();
          }
        },
        error: () => {
          this.isLoading.next(false);
        }
      });
    return recipePublished;
  }

  changeRecipeApproved(value: ChangeRecipeApprovedCommand): Observable<boolean> {
    const recipeApproved = new Subject<boolean>();
    this.recipeEventsService
      .recipeEventsChangeRecipeApprovedPost$Json({
        body: value
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.verificationDetails.isApproved = value.isApproved;
            recipeApproved.next(true);
            this.isRecipeApproved.next(value.isApproved);
          } else {
            recipeApproved.next(false);
            this.isRecipeApproved.next(false);
          }
        },
        error: () => {
          recipeApproved.next(false);
        }
      });
    return recipeApproved;
  }

  copyRecipe(recipeName: string) {
    this.isLoading.next(true);
    const recipeCommand = this.prepareCopyRecipeCommand(recipeName);
    return this.recipeEventsService
      .recipeEventsCopyRecipePost$Json$Response({
        body: recipeCommand
      })
      .subscribe({
        next: (response) => {
          this.isLoading.next(false);
          navigateRecipeToAboutPage(response.body.recipeNumber);
        },
        error: () => {
          this.isLoading.next(false);
          this.createNotificationMessage(
            $localize`:@@saveAsNewErrorMessage:Error while saving as new recipe.`,
            'error'
          );
        }
      });
  }

  public createNotificationMessage(summary: string, detail: string, severity = 'success', sticky = false) {
    const messageObj: Message = {
      key: 'notification',
      severity,
      summary,
      detail,
      sticky
    };
    this.messageService.add(messageObj);
  }

  reloadWithRecipeVersion(recipeNumber: string, recipeVersion: string): void {
    if (this.recipe.type === RecipeType.Activity || this.recipe.type === RecipeType.ActivityGroup) {
      window.location.assign(`${window.location.origin}/recipe/${recipeNumber}_V${recipeVersion}/about`);
    } else {
      window.location.assign(`${window.location.origin}/recipe/${recipeNumber}_V${recipeVersion}`);
    }
  }

  prepareCopyRecipeCommand(recipeName: string): CopyRecipeCommand {
    return {
      recipeId: this.recipe.recipeId,
      recipeName
    };
  }

  prepareStartNewRecipeCommand(): StartNewVersionCommand {
    return {
      recipeId: this.recipe.recipeId
    };
  }

  getAllVersions(recipeNumbers: string[]) {
    this.recipeApiService.recipesAllVersionsGet$Json({
      recipeNumbers
    })
      .subscribe({
        next: response => {
          this.allVersionsOfReferencedRecipes = response;
          this.allVersionsOfReferencedRecipesAvailability.next();
        }
      })
  }

  startNewVersion(recipeNumber: string, version: string): void {
    this.isLoading.next(true);
    this.getOtherVersionsOfRecipe(
      recipeNumber,
      version,
      ['Draft', 'PendingVerification', 'PendingApproval'],
      true
    ).subscribe({
      next: response => {
        if (response && response.length > 0) {
          this.createNotificationMessage(
            $localize`:@@inProgressRecipeVersionsExist:This recipe already has a new version in progress. Proceed with the in progress version, or cancel the in progress version to restart from the latest published version`,
            '',
            'error'
          );
          this.isLoading.next(false);
        } else {
          const recipeStartNewCommand = this.prepareStartNewRecipeCommand();
          this.recipeEventsService
            .recipeEventsStartNewPost$Json({
              body: recipeStartNewCommand
            })
            .subscribe({
              next: response => {
                this.isLoading.next(false);
                this.isRecipeNewMinorVersionPresent.next({ recipeId: this.recipe.recipeId, number: response.recipeNumber, state: RecipeState.Draft, version: response.version });
                navigateRecipeToAboutPage(response.recipeNumber, response.version);
              },
              error: () => {
                this.isLoading.next(false);
                this.createNotificationMessage(
                  $localize`:@@recipeSaveNewVersionErrorMessage:Error while starting new version of the recipe.`,
                  '',
                  'error'
                );
              }
            });
        }
        this.isLoading.next(false);
      }
    });
  }

  private readonly getOtherVersionsCaller = (params: any) =>
    this.recipeApiService
      .recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json(params)
      .pipe(
        tap({
          next: (response) => {
            if(parseFloat(this.recipe.version ?? '0.1') <= 1) {
              this.lastMajorVersionType = this.recipe.type;
            } else {
              this.lastMajorVersionType = RecipeType[this.getLastMajorVersionTypeKey(response)];
              this.constructSlider();
            }
          }
        })
      );

  private getLastMajorVersionTypeKey(response: RecipeReferenceResponse[]) {
    return response.find((r) => Number(r.version) === parseInt(this.recipe.version))
      ?.type as keyof typeof RecipeType;
  }

  getOtherVersionsOfRecipe(
    recipeNumber: string,
    version: string,
    states: string[],
    bypassCache = false
  ): Observable<RecipeReferenceResponse[]> {
    const params = {
      recipeNumber,
      version,
      body: states
    };
    const key = this.getOtherVersionsOfRecipe.name.concat(JSON.stringify(params));
    if (bypassCache) clearObjectCache(key);
    return elnShareReplay(key, () => this.getOtherVersionsCaller(params), params);
  }

  createTemplateOptionsQuery(insertOption: string) {
    this.templateSearchCriteria = {
      getLatestVersion: true,
      templateTypes: this.insertLocationMapOf(insertOption).join(','),
      consumingLabsiteCodes: this.userService.currentUser.labSiteCode
    };
  }

  createRecipeOptionsQuery(insertOption: string) {
    const pageSize = 10000;
    const labSiteCode = this.userService.currentUser.labSiteCode;
    const recipeTypes = this.insertLocationMapOfRecipeInclude(insertOption);
    this.recipeSearchCriteria = {
      bypassSecurity: false,
      filterConditions: [
        {
          conditionType: ConditionType.And,
          filters: [
            {
              columnName: 'state',
              matchType: StringMatchType.Not,
              text: 'cancelled',
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: 'state',
              matchType: StringMatchType.Not,
              text: 'retired',
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: 'type',
              matchType: StringMatchType.In,
              values: recipeTypes,
              isSecurityFlag: false,
              dataType: DataType.String
            }
          ]
        },
        {
          conditionType: ConditionType.Or,
          filters: [
            {
              columnName: 'labsiteCode',
              matchType: StringMatchType.Word,
              text: labSiteCode,
              isSecurityFlag: true,
              dataType: DataType.String
            },
            {
              columnName: 'consumingLabsites',
              matchType: StringMatchType.Word,
              text: labSiteCode,
              isSecurityFlag: true,
              dataType: DataType.String
            }
          ]
        }
      ],
      pagination: { pageNumber: 1, pageSize },
      sort: [
        {
          columnType: DataType.Date,
          columnName: 'createdOn',
          order: 1,
          sortDirection: SortDirection.Descending
        }
      ]
    };
  }

  getSubBusinessUnits() {
    this.labsiteService
      .labsitesSubBusinessUnitsGet$Json({
        labsiteCodes: this.userService.currentUser.labSiteCode
      })
      .subscribe({
        next: (labsiteGetResponse: LabsiteGetResponse) => {
          this.subBusinessUnits = labsiteGetResponse.labsites[0].subBusinessUnits;
        },
        error: () => {
          this.subBusinessUnits = [];
        },
        complete: () => {
          this.isSBULoaded.next(true);
        }
      });
  }

  changeAvailability() {
    this.recipeEventsService
      .recipeEventsChangeAvailabilityPost$Json({
        body: {
          available: !this.recipe.tracking.available,
          recipeId: this.currentRecipeId
        }
      })
      .subscribe({
        next: (response) => {
          if (response.notifications.notifications.length === 0) {
            this.recipe.tracking.available = !this.recipe.tracking.available;
            this.recipeLoaded.next(true);
          }
        }
      });
  }

  loadInsertOptionsForRecipe() {
    if (parseFloat(this.recipe.version) > 1.0) {
      this.setInsertOptionsForNewVersionRestrictions();
    } else {
      if (this.recipe.modules.length > 0) {
        this.insertOptions = [this.existingModule, this.newModule, this.newActivity];
      } else if (this.recipe.activities.length > 0) {
        this.insertOptions = [this.newActivity, this.existingActivityAsModule, this.existingModule];
      } else if (this.recipe.activities.length === 0 || (this.recipe.tables.length > 0 || this.recipe.forms.length > 0)) {
        this.insertOptions = [this.newTableOrFormOrPpr, this.newModule, this.newActivity];
      }
    }
  }

  refreshInsertLocationOptionsAndSetDefaultLocation(insertOption: TemplateLocationOptions) {
    this.refreshInsertLocationOptions(insertOption);
  }

  private setInsertOptionsForNewVersionRestrictions() {
    if (
      [
        RecipeType.TableForm,
        RecipeType.Table,
        RecipeType.ReferencePromptPreparation,
        RecipeType.Form
      ].includes(this.lastMajorVersionType)
    ) {
      this.insertOptions = [this.newTableOrFormOrPpr];
    } else if (this.lastMajorVersionType === RecipeType.Module) {
      this.insertOptions = [this.existingModule, this.newModule];
    } else if ([RecipeType.Activity, RecipeType.ActivityGroup].includes(this.lastMajorVersionType)) {
      this.insertOptions = [this.newActivity, this.existingActivityAsModule, this.existingModule];
    }
  }

  isRecipeTypeCompatible() {
    const hasAtLeastOneMajor = parseFloat(this.recipe.version) > 1.0;
    const isCompatible = !!compatibleRecipeTypes.find(
      (typeOption) =>
        typeOption.includes(this.lastMajorVersionType) &&
        typeOption.includes(this.recipe.type)
    );
    if (hasAtLeastOneMajor && !isCompatible) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@error:Error`,
        detail: $localize`:@@newVersionCompatibilityErrorMessage:New version's recipe type should be same as parent version`
      });
      console.log(`Expected recipe to be of type "${this.lastMajorVersionType}". But current recipe is of type "${this.recipe.type}"`);
      return isCompatible;
    }
    return true;
  }

  private refreshInsertLocationOptions(sourceTemplateType: TemplateLocationOptions): void {
    this.insertLocationOptions =
      this.templateApplyService.prepareInsertLocationOptionsBySourceTemplateType(
        sourceTemplateType
      );
  }

  private insertLocationMapOf(sourceTemplateType: string): string[] {
    switch (sourceTemplateType) {
      case TemplateLocationOptions.AddAsNewTableOrFormOrPpr:
        return this.isRecipePPRandGreaterMinorVersion() ? [] : [TemplateType.Table, TemplateType.Form];
      case TemplateLocationOptions.AddAsNewModule:
        return [TemplateType.Table, TemplateType.Form, TemplateType.Module];
      case TemplateLocationOptions.AddAsNewActivity:
        return [TemplateType.Table, TemplateType.Form, TemplateType.Module, TemplateType.Activity];
      case TemplateLocationOptions.AddToExistingModule:
        return [TemplateType.Table, TemplateType.Form];
      case TemplateLocationOptions.AddAsNewModuleAndExistingActivity:
        return [TemplateType.Table, TemplateType.Form, TemplateType.Module];
      default:
        return [];
    }
  }

  private insertLocationMapOfRecipeInclude(sourceTemplateType: string): string[] {
    switch (sourceTemplateType) {
      case TemplateLocationOptions.AddAsNewTableOrFormOrPpr:
        return this.isRecipePPRandGreaterMinorVersion()
          ? [TemplateType.ReferencePromptPreparation]
          : [
            TemplateType.Table,
            TemplateType.Form,
            TemplateType.TableForm,
            TemplateType.ReferencePromptPreparation
          ];
      case TemplateLocationOptions.AddAsNewModule:
        return [TemplateType.Table, TemplateType.Form, TemplateType.TableForm, TemplateType.Module];
      case TemplateLocationOptions.AddAsNewActivity:
        return [
          TemplateType.Table,
          TemplateType.Form,
          TemplateType.TableForm,
          TemplateType.Module,
          TemplateType.Activity,
          TemplateType.ActivityGroup
        ];
      case TemplateLocationOptions.AddToExistingModule:
        return [
          TemplateType.Table,
          TemplateType.Form,
          TemplateType.TableForm,
          TemplateType.ReferencePromptPreparation
        ];
      case TemplateLocationOptions.AddAsNewModuleAndExistingActivity:
        return [TemplateType.Table, TemplateType.Form, TemplateType.TableForm, TemplateType.Module];
      default:
        return [];
    }
  }

  private isRecipePPRandGreaterMinorVersion() {
    return (
      !!this.recipe &&
      this.lastMajorVersionType === RecipeType.ReferencePromptPreparation &&
      parseFloat(this.recipe.version) > 1.0
    );
  }

  buildTemplateTypeFilter(insertOption: string) {
    const type = this.recipeChecked
      ? this.insertLocationMapOfRecipeInclude(insertOption)
      : this.insertLocationMapOf(insertOption);
    return type.map(t => ({ label: this.capitalizeFirstLetter(t), value: t, inactive: false }));
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  constructFilters(insertOption: string) {
    const baseTypeControl = {
      controlType: ControlType.List,
      fieldName: 'baseType',
      label: $localize`:@@baseType:Base Type`,
      options: [
        { label: 'Template', value: 'Template' },
        { label: 'Recipe', value: 'Recipe' }
      ],
      labelField: 'label',
      valueField: 'value',
      order: 1,
      value: ['Template', 'Recipe'],
      multiSelect: true
    };

    if (this.isRecipePPRandGreaterMinorVersion()) {
      baseTypeControl.options.splice(0, 1);
      baseTypeControl.value.splice(0, 1);
    }

    this.searchControls = [
      baseTypeControl,
      {
        controlType: ControlType.List,
        fieldName: 'recipeTypes',
        label: $localize`:@@Type:Type`,
        options: this.buildTemplateTypeFilter(insertOption),
        labelField: 'label',
        valueField: 'value',
        order: 2,
        value: this.recipeChecked
          ? this.insertLocationMapOfRecipeInclude(insertOption)
          : this.insertLocationMapOf(insertOption),
        multiSelect: true
      },
      {
        controlType: ControlType.List,
        fieldName: 'sbu',
        label: $localize`:@@sbu:SBU`,
        options: this.subBusinessUnits,
        order: 3,
        value: [],
        multiSelect: true,
        labelField: 'displayLabel',
        valueField: 'code'
      },
      {
        controlType: ControlType.List,
        fieldName: 'client',
        label: $localize`:@@createPreparationClient:Client`,
        options: this.clientOptions,
        order: 4,
        value: [],
        multiSelect: true
      },
      {
        controlType: ControlType.List,
        fieldName: 'projects',
        label: $localize`:@@projects:Projects`,
        options: [],
        order: 5,
        value: undefined,
        disabled: true,
        multiSelect: true
      }
    ];
    return this.searchControls;
  }

  alterFilters(_filters: any[], insertOption: string, fieldName?: string) {
    const types = _filters.find(f => f.columnName === 'baseType')?.values;

    if (fieldName === 'baseType') {
      if ((types && !types.includes('Recipe')) || (!types)) {
        RecipeService.applyFilter(this.searchControls, 'client', RecipeService.getClientAndProjectFilter(true, this.clientOptions)[0]);
        RecipeService.applyFilter(this.searchControls, 'projects', RecipeService.getClientAndProjectFilter(true, this.clientOptions)[1]);
      }
    }

    this.resetRecipeFiltersAccessibility(types, insertOption, _filters, fieldName);

    if (fieldName === 'client') {
      const clients = _filters.find((f) => f.columnName === 'client').values;
      if (fieldName === 'client') {
        this.searchControls.splice(
          this.searchControls.findIndex((f) => f.fieldName === 'projects'),
          1
        );
        this.searchControls.push({
          controlType: ControlType.List,
          fieldName: 'projects',
          label: $localize`:@@projects:Projects`,
          options: this.projectLogLoaderService.fetchProjectsLinkedToClients(clients),
          order: 5,
          value: undefined,
          multiSelect: true
        });
      }
    }
    _filters.find((c) => c.columnName === 'recipeTypes').values = this.searchControls.find(
      (c) => c.fieldName === 'recipeTypes'
    )?.value;
    return this.searchControls;
  }

  resetRecipeAndTemplateCommand(insertOption: string) {
    this.createTemplateOptionsQuery(insertOption);
    this.createRecipeOptionsQuery(insertOption);
  }

  selectionChanged(selectedTemplate: SelectedTemplate): void {
    this.templateApplyService.ResetApplyCommand();
    this.templateApplyService.buildCommandForSourceTemplateSelection(selectedTemplate);
  }

  applySelectedTemplate(templateToLoadInformation: SelectedTemplateCommand) {
    this.templateApplyService
      .StartCommandPreparation(
        this.assessFromInsertOption(templateToLoadInformation) ?? TemplateType.Invalid
      )
      .SetInsertedLocation(
        templateToLoadInformation.selectedInsertOption as TemplateLocationOptions,
        templateToLoadInformation.selectedTemplateLocationId as string,
        this.getNodeIdBySelectedTemplateLocationId(
          templateToLoadInformation.insertLocationOptions,
          templateToLoadInformation.selectedTemplateLocationId as string
        ),
        this.getTitleBySelectedTemplateLocationId(
          templateToLoadInformation.insertLocationOptions,
          templateToLoadInformation.selectedTemplateLocationId as string
        )
      )
      .setSelectedRecipeReference(
        templateToLoadInformation.selectedTemplate?.isRecipe ?? false,
        templateToLoadInformation.selectedTemplate?.id ?? ''
      )
      .PublishApplyRecipeTemplateCommand(templateToLoadInformation.selectedTemplateNumber);
  }

  private assessFromInsertOption(templateToLoadInformation: SelectedTemplateCommand): TemplateType {
    switch (templateToLoadInformation.selectedInsertOption) {
      case TemplateLocationOptions.AddAsNewModule:
        return TemplateType.Module;
      case TemplateLocationOptions.AddAsNewActivity:
        return templateToLoadInformation.selectedTemplate?.templateType === TemplateType.ActivityGroup ? TemplateType.ActivityGroup : TemplateType.Activity;
      default:
        return templateToLoadInformation.selectedTemplate?.templateType ?? TemplateType.Invalid;
    }
  }

  private getNodeIdBySelectedTemplateLocationId(
    insertLocationOptions: any[],
    selectedTemplateLocationId: string
  ): string | undefined {
    return insertLocationOptions.find(ilo => ilo.id === selectedTemplateLocationId)?.nodeId;
  }

  private getTitleBySelectedTemplateLocationId(
    insertLocationOptions: any[],
    selectedTemplateLocationId: string
  ): string {
    return (
      insertLocationOptions.find(ilo => ilo.id === selectedTemplateLocationId)?.displayLabel ?? ''
    );
  }

  resetRecipeFiltersAccessibility(
    baseTypes: string[],
    insertOption: string,
    _filters: any[],
    fieldName?: string,
  ) {
    const clientValues = _filters.find(f => f.columnName === 'client')?.values;
    const clientFilter = this.searchControls.find(c => c.fieldName === 'client');
    const projectsFilter = this.searchControls.find(c => c.fieldName === 'projects');
    if ((baseTypes && !baseTypes.includes('Recipe')) || (!baseTypes)) {
      this.recipeChecked = false;
      if (clientFilter) clientFilter.disabled = true;
      if (projectsFilter) projectsFilter.disabled = true;
    } else {
      this.recipeChecked = true;
      if (clientFilter) clientFilter.disabled = false;
      if (projectsFilter && clientValues && clientValues.length > 0) {
        projectsFilter.disabled = false;
      } else if (projectsFilter && !clientValues) {
        projectsFilter.disabled = true;
      }
    }
    this.setTypeFilter(insertOption, fieldName)
  }

  private setTypeFilter(insertOption: string, fieldName?: string) {
    if (fieldName === 'baseType') {
      this.searchControls.splice(
        this.searchControls.findIndex(c => c.fieldName === 'recipeTypes'),
        1,
        this.customizeTypeFilter(insertOption)
      );
    }
  }

  private static applyFilter(searchControls: SearchControl[], filterName: string, searchArray: SearchControl) {
    searchControls.splice(
      searchControls.findIndex((f) => f.fieldName === filterName),
      1,
      searchArray
    );
  }

  private static getClientAndProjectFilter(disabled: boolean, clients?: { label: string; value: string; }[]) {
    return [{
      controlType: ControlType.List,
      fieldName: 'client',
      label: $localize`:@@createPreparationClient:Client`,
      options: clients || [],
      order: 4,
      value: [],
      disabled,
      multiSelect: true
    },
    {
      controlType: ControlType.List,
      fieldName: 'projects',
      label: $localize`:@@projects:Projects`,
      options: [],
      order: 5,
      value: undefined,
      disabled,
      multiSelect: true
    }]
  }

  private customizeTypeFilter(insertOption: string) {
    return {
      controlType: ControlType.List,
      fieldName: 'recipeTypes',
      label: $localize`:@@Type:Type`,
      options: this.buildTemplateTypeFilter(insertOption),
      labelField: 'label',
      valueField: 'value',
      order: 2,
      value: this.recipeChecked
        ? this.insertLocationMapOfRecipeInclude(insertOption)
        : this.insertLocationMapOf(insertOption),
      multiSelect: true
    };
  }

  get clientOptions() {
    return this.projectLogLoaderService.getAllClients();
  }

  confirmationDialog(message: string, acceptCallback: () => void, rejectCallback = () => { }) {
    return this.confirmationService.confirm({
      message: `${message}`,
      header: $localize`:@@ConfirmationHeader:Confirmation`,
      acceptVisible: true,
      acceptLabel: $localize`:@@confirmationDialogYes:Yes`,
      rejectVisible: true,
      rejectLabel: $localize`:@@confirmationDialogNo:No`,
      closeOnEscape: true,
      dismissableMask: true,
      ...this.styleClassProperties,
      accept: acceptCallback,
      reject: rejectCallback
    });
  }

  private populateFromTemplates(
    snapshot: RecipeResponse,
    referencedRecipe = false
  ): Observable<RecipeModel> {
    if (!snapshot.recipe) throw new Error('Recipe header node is missing');

    const recipe = snapshot.recipe as any;

    this.initializeRecipe(recipe, snapshot, referencedRecipe);
    this.evaluateNotifications(snapshot.notifications.notifications);
    this.populateSources(snapshot);
    this.populateConsumingRecipes(snapshot);
    //Return the recipe, if it is created not using initialTemplateReference
    if (
      snapshot.activities.length === 0 &&
      snapshot.forms.length === 0 &&
      snapshot.tables.length === 0 &&
      snapshot.modules.length === 0
    ) {
      recipe.activities = [];
      recipe.tables = [];
      recipe.forms = [];
      recipe.modules = [];
      this.mappingSubnodeItems(recipe, snapshot);
      recipe.type = this.assessRecipeType();
      return of(recipe);
    }
    recipe.activities = snapshot.activities as AugmentedActivity[];
    recipe.activities.sort(
      (a: AugmentedActivity, b: AugmentedActivity) =>
        snapshot.recipe.childOrder.indexOf(b.activityId) -
        snapshot.recipe.childOrder.indexOf(a.activityId)
    );
    recipe.tables = [];
    recipe.forms = [];
    recipe.modules = [];
    this.mappingSubnodeItems(recipe, snapshot);
    return this.populateRecipe(recipe, snapshot, referencedRecipe);
  }

  private mappingSubnodeItems(recipe: RecipeModel, snapshot: RecipeResponse) {
    this.mappingPreparations(recipe, snapshot.preparationsList);
    this.mappingPrompts(recipe, snapshot.promptsList);
    this.mappingReferences(recipe, snapshot.referencesList);
  }

  private mappingReferences(recipe: RecipeModel, referencesList: ReferencesResponseNode[]) {
    if (recipe.activities && recipe.activities.length !== 0 && referencesList && referencesList.length !== 0) {
      recipe.activities.forEach(activity => activity.references = mappingLogic(activity.activityId));
    } else if (referencesList && referencesList.length !== 0) {
      this.recipe.orphan.references = mappingLogic(this.recipe.recipeId);
    }

    function mappingLogic(id: string): ReferenceItem[] {
      const collection: ReferenceItem[] = [];
      const matchedReferences = referencesList.filter((p: ReferencesResponseNode) => p.nodeId === id) ?? undefined;
      matchedReferences?.forEach((referenceNode: ReferencesResponseNode) => {
        referenceNode.references.forEach((referenceResponse: ReferenceItem) => {
          const matchedItem = collection.find(p => p.referenceType === referenceResponse.referenceType);
          if (!matchedItem) collection.push(referenceResponse);
          else matchedItem.rows.push(...referenceResponse.rows);
        });
      });
      return collection;
    }
  }

  private mappingPrompts(recipe: RecipeModel, promptsList: PromptResponseNode[]) {
    if (recipe.activities && recipe.activities.length !== 0 && promptsList && promptsList.length !== 0) {
      recipe.activities.forEach(activity => activity.prompts = mappingLogic(activity.activityId));
    } else if (promptsList && promptsList.length !== 0) {
      this.recipe.orphan.prompts = mappingLogic(this.recipe.recipeId);
    }

    function mappingLogic(id: string): PromptModel[] {
      const collection: PromptModel[] = [];
      const matchedPrompts = promptsList.filter((prompt: PromptResponseNode) => prompt.nodeId === id) ?? undefined;
      matchedPrompts?.forEach((promptNode: PromptResponseNode) => {
        promptNode.prompts.forEach((promptResponse: PromptResponse) => {
          const matchedItem = collection.find(p => p.promptType === promptResponse.promptType);
          if (!matchedItem) collection.push(promptResponse);
          else matchedItem.promptItems.push(...promptResponse.promptItems);
        });
      });
      return collection;
    }
  }

  private mappingPreparations(recipe: RecipeModel, preparations: PreparationsResponseNode[]) {
    const pickLists = this.loadStorageDropDownForPreparations();
    pickLists.subscribe((storageConditionData: UserPicklistResponse) => {
      this.pickList = storageConditionData.items;
    });
    if (recipe.activities && recipe.activities.length !== 0 && preparations && preparations.length !== 0) {
      recipe.activities.forEach(activity => activity.preparations = mappingLogic(activity.activityId));
    } else if (preparations && preparations.length !== 0) {
      this.recipe.orphan.preparations = mappingLogic(this.recipe.recipeId);
    }

    function mappingLogic(id: string): PreparationItem[] {
      const collection: PreparationItem[] = [];
      const preparationsMatched = preparations.filter((preparation: PreparationsResponseNode) => preparation.nodeId === id) ?? undefined;
      preparationsMatched?.forEach((prep: PreparationsResponseNode) => {
        prep.preparations.forEach((preparation: RecipePreparation) => {
          if (!preparation.summary || !preparation.name || !preparation.description) throw new Error('LOGIC ERROR: summary, name & description must be defined');

          const prepItem: PreparationItem = {
            additionalInformation: preparation.additionalInformation,
            internalInformation: preparation.internalInformation,
            summary: preparation.summary,
            name: preparation.name,
            preparationNumber: preparation.preparationNumber,
            status: preparation.status,
            preparationId: preparation.preparationId,
            expirationValue: preparation.expirationValue,
            description: preparation.description,
            isRemoved: preparation.isRemoved,
            source: processRecipeSource(preparation.source)
          };
          collection.push(prepItem);
        });
      });
      return collection;
    }
  }

  loadStorageDropDownForPreparations(): Observable<UserPicklistResponse> {
    return elnShareReplay<UserPicklistResponse>('storageCondition', () =>
      this.picklistService.userPicklistsIdGet$Json({ id: this.storageConditionId })
    );
  }

  evaluateNotifications(notifications: NotificationDetails[]) {
    const messages = notifications
      .filter(n => n.translationKey === 'invalidRecipe')
      .map(n => n.message);
    this.recipeHasErrors = messages.length > 0;
    if (this.recipeHasErrors) this.warningReceived.next(messages);
  }

  private initializeRecipe(recipe: RecipeModel, snapshot: RecipeResponse, referencedRecipe: boolean) {
    recipe.tracking.assignedApprovers = snapshot.recipe.tracking.assignedApprovers.map(
      s => s.puid.value
    );
    recipe.tracking.assignedEditors = snapshot.recipe.tracking.assignedEditors.map(
      s => s.puid.value
    );
    recipe.tracking.assignedVerifiers = snapshot.recipe.tracking.assignedVerifiers.map(
      s => s.puid.value
    );
    recipe.orphan = {
      prompts: [],
      preparations: [],
      references: []
    };

    if (!referencedRecipe) {
      this.recipe = recipe;
      this.recipe.version = snapshot.recipe.version;
    }
    recipe.preLoadDataTransformOptions = snapshot.recipe.preLoadDataTransformOptions;
  }

  populateRecipe(
    recipe: any,
    snapshot: RecipeResponse,
    referencedRecipe = false
  ): Observable<RecipeModel> {
    return this.unitLoaderService.allUnits$.pipe(
      concatMap(_allUnits => {
        // incoming _allUnits not used here directly because `UnitLoaderService` caches it, which is where it's used from at anytime thereafter

        type innerObservableTypes =
          | ActivityResponseNode
          | Unit[]
          | UnitList[]
          | void
          | TableNode
          | ModuleResponseNode
          | FormResponseNode
          | TableResponseNode; // we could consume `any` but this helps understanding when adding new inner observables
        const observables: Observable<innerObservableTypes>[] = [];

        observables.push(this.unitLoaderService.allUnitLists$); // for completeness. Not currently used by Recipe or RecipeComponent.

        observables.push(
          ...recipe.childOrder.map((itemId: string) => {
            if (snapshot.activities.length > 0) {
              const activity = snapshot.activities?.find(a => a.activityId === itemId);
              if (!activity) throw new Error(`Activity ${itemId} not found`);
              return this.populateModules(snapshot, activity);
            } else if (snapshot.modules.length > 0) {
              return this.populateRootModules(recipe, snapshot, itemId);
            } else {
              return this.populateFormTable(recipe, snapshot, itemId);
            }
          })
        );
        if (!referencedRecipe) {
          this.recipe = recipe;
          //Used to assess the recipeType based on current recipe image
          this.recipe.type = this.assessRecipeType();
        }
        return forkJoin(observables.filter(o => o !== EMPTY));
      }),
      map(() => recipe as RecipeModel)
    );
  }

  private populateSources(snapshot: RecipeResponse) {
    this.RecipeSources = [];
    const referencedTemplates = Object.entries(snapshot.referencedTemplates);
    snapshot.referencedRecipes.forEach((recipe) => {
      this.RecipeSources.push({
        referenceId: recipe.recipeId, itemType: SearchItemType.Recipe,
        referenceNumber: recipe.number, instanceId: recipe.instanceId, childInstanceIds: recipe.childInstanceIds
      });
    });
    referencedTemplates.forEach((referencedTemplate: any) => {
      this.RecipeSources.push({
        referenceId: referencedTemplate[1],
        itemType: SearchItemType.Template,
        instanceId: referencedTemplate[0]
      });
    });
  }

  private populateConsumingRecipes(snapshot: RecipeResponse) {
    snapshot.consumingRecipesList?.forEach((recipeId) => {
      this.consumingRecipes.push({ referenceId: recipeId, itemType: SearchItemType.Recipe })
    })
  }

  private populateRootModules(
    recipe: any,
    snapshot: RecipeResponse,
    itemId: string
  ): Observable<ModuleResponseNode> {
    const module = snapshot.modules?.find((m) => m.moduleId === itemId);
    if (!module) throw new Error(`Module ${itemId} not found`);

    recipe.modules.push(module);
    return this.populateFromModuleTemplate(snapshot, module);
  }

  private populateFormTable(
    recipe: any,
    snapshot: RecipeResponse,
    itemId: string
  ): Observable<FormResponseNode> | Observable<TableResponseNode> {
    const form = snapshot.forms?.find((f) => f.formId === itemId) as AugmentedForm;
    if (form) {
      form.itemType = NodeType.Form;
      recipe.forms.push(form);
      return this.populateFromFormTemplate(form);
    }

    const table = snapshot.tables?.find((t) => t.tableId === itemId) as AugmentedTable;
    if (table) {
      table.itemType = NodeType.Table;
      table.preferencesReady = new Subject<BptGridPreferences>();
      recipe.tables.push(table);

      return this.populateFromTableTemplate(table);
    }
    throw new Error('No templates not found');
  }

  public constructSlider() {
    this.loadInsertOptionsForRecipe();
    if (this.insertOptions.length > 0) {
      this.constructFilters(this.insertOptions[0].value);
      this.createTemplateOptionsQuery(this.insertOptions[0].value);
      this.createRecipeOptionsQuery(this.insertOptions[0].value);
    }
  }

  public setVariableUsingCommand(ruleData: RulesSetVariable) {
    if (!this.currentRecipe || !this.currentRecipe.variablesNode) {
      return;
    }
    this.currentRecipe.variablesNode.variables[ruleData.name] = {
      value: ruleData.value.value,
      nodeId: ruleData.nodeId
    } as RecipeVariable;
    const recipeSetVariableCommand: SetVariableCommand = {
      name: ruleData.name,
      nodeId: ruleData.nodeId,
      recipeId: ruleData.experimentId,
      value: ruleData.value.value,
      ruleContext: ruleData.ruleContext
    };
    this.recipeEventsService
      .recipeEventsSetVariablePost$Json({ body: recipeSetVariableCommand })
      .subscribe({
        next: () => {
          if (this.currentRecipe.variablesNode) {
            RuleActionHandlerHostService.ItemVariablesNode = this.currentRecipe.variablesNode;
            RuleActionHandlerHostService.delegateCacheVariablesToBlazor();
          }
        }
      });
  }

  private populateModules(
    snapshot: RecipeResponse,
    activity: ActivityResponseNode
  ): Observable<ActivityResponseNode> {
    (activity as AugmentedActivity).dataModules ??= [];
    activity.childOrder ??= [];

    const observables = activity.childOrder.map((moduleId) => {
      const module =
        activity.modules.find(m => m.moduleId === moduleId) ??
        snapshot.modules?.find(m => m.moduleId === moduleId);
      if (module) {
        (activity as AugmentedActivity).dataModules?.push(module as AugmentedModule);
        return this.populateFromModuleTemplate(snapshot, module);
      }

      throw new Error(`Module ${moduleId} not found`);
    });
    return forkJoin(observables.filter(o => o !== EMPTY)).pipe(
      single(),
      map(() => activity)
    );
  }

  private populateFromModuleTemplate(
    snapshot: RecipeResponse,
    module: ModuleResponseNode
  ): Observable<ModuleResponseNode> {
    // nothing to copy from a module template so don't even look it up
    // Extension point: Copy future attributes of a module template, if applicable
    (module as AugmentedModule).items ??= [];
    module.childOrder ??= [];

    const observables = module.childOrder.map((moduleItemId) => {
      const form =
        (module.forms.find((f) => f.formId === moduleItemId) as AugmentedForm) ??
        (snapshot.forms?.find((f) => f.formId === moduleItemId) as AugmentedForm);
      if (form) {
        form.itemType = NodeType.Form;
        (module as AugmentedModule).items.push(form);
        return this.populateFromFormTemplate(form);
      }

      const table =
        (module.tables.find(t => t.tableId === moduleItemId) as AugmentedTable) ??
        (snapshot.tables?.find(t => t.tableId === moduleItemId) as AugmentedTable);
      if (table) {
        table.preferencesReady = new Subject<BptGridPreferences>();
        table.itemType = NodeType.Table;
        (module as AugmentedModule).items.push(table);
        return this.populateFromTableTemplate(table);
      }

      throw new Error(`Module Item ${moduleItemId} not found`);
    });

    return forkJoin(observables.filter(o => o !== EMPTY)).pipe(
      single(),
      map(() => module)
    );
  }

  private populateFromTableTemplate(table: AugmentedTable): Observable<TableResponseNode> {
    if (!table.templateId) {
      throw new Error(`Table ${table.tableId} doesn't have a templateId`);
    }

    const observables = elnShareReplay(
      table.templateId,
      this.templatesService.templatesIdGet$Json.bind(this.templatesService),
      {
        id: table.sourceTemplateId
      }
    ).pipe(
      single(),
      map((template) => {
        this.getTableList(table, template);
        // Extension point: Copy future attributes of a table template, if applicable
        table.columnDefinitions = (
          template as TableTemplate
        ).columnDefinitions.map((apiColumn: ApiColumnSpecification) => {
          const allowedUnits = apiColumn.allowedUnits
            ? this.unitLoaderService.allUnits.filter(
              u => u.isAvailable && apiColumn.allowedUnits?.includes(u.id)
            )
            : undefined;
          const defaultUnit = this.unitLoaderService.allUnits.find(
            u => u.id === apiColumn.defaultUnit
          );
          return {
            ...apiColumn,
            allowedUnits,
            defaultUnit
          };
        });
        this.appendRowGroupingColumnDefinitions(table.columnDefinitions);
        table.allowPagination = (template as TableTemplate).allowPagination;
        table.allowRangeSelection = (
          template as TableTemplate
        ).allowRangeSelection;
        table.allowReadOnly = (template as TableTemplate).allowReadOnly;
        table.allowRowAdd = (template as TableTemplate).allowRowAdd;
        table.allowRowRemoval = (template as TableTemplate).allowRowRemoval;
        table.allowMultipleRows = (template as TableTemplate).allowMultipleRows;
        table.columnDefaults = (template as TableTemplate).columnDefaults;
        table.rules = template.rules;
        this.notifyColumnDefinitionsReady();
        return this.populateColumnsFromPicklists(table.columnDefinitions ?? []);
      })
    );
    return observables.pipe(
      single(),
      mergeAll(),
      map(_ => table)
    );
  }

  public appendRowGroupingColumnDefinitions(columnSpecifications: ColumnSpecification[]) {
    [RecipeTableComponent.createRepeatColumn(), repeatForColumn, repeatWithColumn, rowSelectedColumn]
      .filter(repeat => !columnSpecifications.some(col => col.field === repeat.field)) // skip ones already in columnSpecifications
      .forEach(col => columnSpecifications.push(col as ColumnSpecification));
  }

  private getTableList(table: TableNode, template: unknown) {
    const tables: Tables = {};
    tables[table.tableId] = template as TableTemplate;
    this.tableList.push(tables);
  }

  notifyColumnDefinitionsReady() {
    this.columnDefinitionsReadySubject.next(true);
  }

  private populateColumnsFromPicklists(
    columns: ColumnSpecification[]
  ): Observable<ColumnSpecification[]> {
    const observables = columns.map((column) => {
      if (!column.listSource) return of(column);

      return elnShareReplay(
        column.listSource,
        this.picklistService.userPicklistsIdGet$Json.bind(this.picklistService),
        { id: column.listSource }
      ).pipe(
        map((list: any) => {
          column.listValues = list.items;
          return column;
        })
      );
    });
    return forkJoin(observables);
  }

  private populateFromFormTemplate(form: AugmentedForm): Observable<FormResponseNode> {
    if (!form.templateId) {
      throw new Error(`Form ${form.formId} doesn't have a templateId`);
    }

    const observables = elnShareReplay(
      form.templateId,
      this.templatesService.templatesIdGet$Json.bind(this.templatesService),
      {
        id: form.sourceTemplateId
      }
    ).pipe(
      single(),
      map(template => {
        this.sortFieldDefinitions(template as FormTemplate);
        this.getFormList(form, template);
        // Extension point: Copy future attributes of a table template, if applicable
        form.fieldDefinitions = (template as FormTemplate).fieldDefinitions;
        form.rules = template.rules;

        this.configureFormQuantityFields(
          form.value ?? {},
          form.fieldDefinitions ?? []
        );
        return this.populateFieldsFromPicklists(form.fieldDefinitions ?? []);
      })
    );
    return observables.pipe(
      single(),
      mergeAll(),
      map(() => form)
    );
  }

  private populateFieldsFromPicklists(
    fieldDefinitions: FormItemResponse[]
  ): Observable<FormItemResponse[]> {
    const observables: Observable<FormItemResponse[]>[] = fieldDefinitions?.map((item) => {
      if ((item as FieldGroupResponse).itemType === 'fieldGroup') {
        // field group doesn't support picklists but children might
        return this.populateFieldsFromPicklists(
          (item as FieldGroupResponse).fieldDefinitions as FormItemResponse[]
        );
      }

      const field = item as FieldDefinitionResponse;
      if (field.fieldType === FieldType.Quantity && field?.fieldAttributes?.defaultUnit) {
        return of([field as FormItemResponse]);
      }

      const dropdownAttributes = field?.fieldAttributes as DropdownAttributes;
      if (!dropdownAttributes?.listSource) {
        return of([field as FormItemResponse]);
      }

      return elnShareReplay(
        dropdownAttributes.listSource,
        this.picklistService.userPicklistsIdGet$Json.bind(this.picklistService),
        { id: dropdownAttributes.listSource }
      ).pipe(
        map((list: any) => {
          dropdownAttributes.listValues = list.items ?? [];
          return [field as FormItemResponse];
        })
      );
    });
    return forkJoin(observables).pipe(
      mergeAll(),
      map(() => fieldDefinitions)
    );
  }

  private sortFieldDefinitions(template: FormTemplate): void {
    template.fieldDefinitions.sort((lt, rt) => lt.row - rt.row);
  }

  private configureFormQuantityFields(
    value: { [key: string]: any },
    fieldDefinitions: FormItemResponse[]
  ) {
    const fieldGroups = fieldDefinitions.filter(
      (item): item is FieldGroupResponse => item.itemType === 'fieldGroup'
    );

    fieldGroups.forEach((group) => {
      value[group.field] ??= {};
      this.configureFormQuantityFields(value[group.field], group.fieldDefinitions ?? []);
    });

    const quantitiesWithDefaults = fieldDefinitions.filter(
      (item): item is FieldDefinitionResponse =>
        (item as FieldDefinitionResponse)?.fieldType === FieldType.Quantity &&
        (item as FieldDefinitionResponse).fieldAttributes?.defaultUnit
    );

    quantitiesWithDefaults.forEach((item) => {
      const defaultUnit = this.unitLoaderService.allUnits.find(
        (unit: Unit) => unit.id === item.fieldAttributes.defaultUnit
      );
      if (defaultUnit) {
        if (value[item.field]) {
          const unit = (value[item.field] as NumberValue).unit;
          if (!unit || unit.length === 0) {
            // shouldn't happen that a NumberValue doesn't have a unit but this can help to deal with legacy values that might not
            (value[item.field] as NumberValue).unit = defaultUnit.id;
          }
        } else {
          value[item.field] = {
            isModified: false,
            value: {
              type: ValueType.Number,
              state: ValueState.Empty,
              unit: defaultUnit.id
            }
          };
        }
      } else {
        // if defaultUnit isn't found then it would be astonishing but just leave it alone
        console.error(
          'defaultUnit is not in the units dataset; Should never happen; Ignoring.',
          item.fieldAttributes.defaultUnit
        );
      }
    });
  }

  private getFormList(form: FormNode, template: unknown) {
    const forms: Forms = {};
    forms[form.formId] = template as FormTemplate;
    this.formList.push(forms);
  }

  private sendRecipeTemplateApplyCommand(command: RecipeAddTemplateCommand, number: string): void {
    this.recipeEventsService
      .recipeEventsAddTemplatePost$Json({
        body: command
      })
      .subscribe({
        next: (response) => {
          this.processRecipeTemplateAppliedResponse(response, command, number);
        },
        error: (errorResponse) => {
          this.processRecipeTemplateAppliedResponse(errorResponse.error, command);
        }
      });
  }

  private processRecipeTemplateAppliedResponse(
    response: RecipeTemplateAddedResponse,
    command: RecipeAddTemplateCommand,
    number = ''
  ) {
    if (response.notifications && response.notifications.notifications.length > 0) {
      this.templateEventService.TemplateApplyFailedNotification(response.notifications);
    } else {
      this.templateEventService.TemplateApplySuccessNotification(command, response, number);
    }
  }

  /**
   * @param newlyLoadedTemplateType Pass the type of template being loaded
   * @param removedTemplateType Pass the type of template being removed
   * @returns the determined type of the recipe
   *
   * for delete scenarios, the correct assessment of recipe type will be returned,
   * only by passing the actual count of templates after delete
   */
  assessRecipeType = (
    newlyLoadedTemplateType: TemplateType = TemplateType.Invalid,
    removedTemplateType: TemplateType = TemplateType.Invalid
  ): RecipeType => {
    const count: { [key: string]: number } = this.templateCountHelper();
    // Handle newly loaded template type
    if (newlyLoadedTemplateType !== TemplateType.Invalid) {
      this.handleNewlyLoadedTemplate(newlyLoadedTemplateType, count);
    }
    // Handle removed template type
    if (removedTemplateType !== TemplateType.Invalid) {
      this.handleRemovedTemplate(removedTemplateType, count);
    }
    return this.assessRecipeTypeHelper(count);
  };

  private handleNewlyLoadedTemplate(newlyLoadedTemplateType: TemplateType, count: { [key: string]: number }) {
    if (newlyLoadedTemplateType === TemplateType.Activity) {
      if (count[RecipeType.Activity] === 1) {
        count[RecipeType.ActivityGroup] = 2;
        count[RecipeType.Activity] = 0;
      } else if (count[RecipeType.ActivityGroup] > 0) {
        count[RecipeType.ActivityGroup] += 1;
      } else {
        count[newlyLoadedTemplateType.toString()] += 1;
      }
    }
    else {
      count[newlyLoadedTemplateType.toString()] += 1;
    }
  }

  private handleRemovedTemplate(removedTemplateType: TemplateType, count: { [key: string]: number }) {
    if (removedTemplateType === TemplateType.Activity) {
      if (count[RecipeType.ActivityGroup] === 2) {
        count[RecipeType.ActivityGroup] = 0;
        count[RecipeType.Activity] = 1;
      } else if (count[RecipeType.ActivityGroup] > 0) {
        count[RecipeType.ActivityGroup] -= 1;
      }
      else {
        count[removedTemplateType.toString()] -= 1;
      }
    }
    else {
      count[removedTemplateType.toString()] -= 1;
    }
  }

  private assessRecipeTypeHelper(count: { [key: string]: number }): RecipeType {
    let recipeType = RecipeType.None;
    if (count[RecipeType.Activity] === 1 && count[RecipeType.ActivityGroup] <= 1) {
      recipeType = RecipeType.Activity;
    } else if (count[RecipeType.Activity] > 1 || count[RecipeType.ActivityGroup] > 0) {
      recipeType = RecipeType.ActivityGroup;
    } else if (count[RecipeType.Module] > 0) {
      recipeType = RecipeType.Module;
    } else if (
      count[RecipeType.TableForm] > 0 ||
      (count[RecipeType.Table] > 0 && count[RecipeType.Form] > 0)
    ) {
      recipeType = RecipeType.TableForm;
    } else if (count[RecipeType.Table] > 0) {
      recipeType = RecipeType.Table;
    } else if (count[RecipeType.Form] > 0) {
      recipeType = RecipeType.Form;
    } else if (count[RecipeType.ReferencePromptPreparation] > 0) {
      recipeType = RecipeType.ReferencePromptPreparation;
    }
    return recipeType;
  }

  private templateCountHelper(): { [key: string]: number } {
    const count: { [key: string]: number } = {
      [RecipeType.Activity]: (this.recipe.activities && this.recipe.activities.length === 1) ? this.recipe.activities.length : 0,
      [RecipeType.ActivityGroup]: (this.recipe.activities && this.recipe.activities.length > 1) ? this.recipe.activities.length : 0,
      [RecipeType.Module]: this.recipe.modules?.length ?? 0,
      [RecipeType.Table]: this.recipe.tables?.length ?? 0,
      [RecipeType.Form]: this.recipe.forms?.length ?? 0,
      [RecipeType.TableForm]: Math.min(this.recipe.tables?.length ?? 0, this.recipe.forms?.length ?? 0),
      [RecipeType.ReferencePromptPreparation]: this.recipe.orphan
        ? this.recipe.orphan.preparations.length +
        this.recipe.orphan.prompts.length +
        this.recipe.orphan.references.length
        : 0
    };
    return count;
  }

  isVerifierAndApproverSameASCurrentUser(currentUserPuid: string) {
    if (
      this.currentRecipe.verificationDetails.verifiedBy &&
      this.currentRecipe.verificationDetails.verifiedBy === currentUserPuid &&
      this.currentRecipe.tracking.assignedApprovers.includes(this.currentRecipe.verificationDetails.verifiedBy)
    ) {
      this.messageService.add({
        key: 'notification',
        severity: 'error',
        summary: $localize`:@@verifierCannotApproveOrPublishHeader:Error`,
        detail: $localize`:@@verifierCannotApproveOrPublish:Same user cannot verify and approve the recipe`
      });
      return true;
    }
    return false;
  }

  public getScalingOptionsForPath(nodeId: string, path: string[]): { allowScaleUp: boolean, allowScaleDown: boolean } | undefined {
    const preloadDataTransformations = this.currentRecipe.preLoadDataTransformOptions
      .find(opt => opt.nodeId === nodeId && isEqual(opt.path, path))?.transformTypes;

    if (!preloadDataTransformations) return undefined;

    return {
      allowScaleUp: preloadDataTransformations.some(t => t === RecipePreLoadDataTransformType.ScaleUp),
      allowScaleDown: preloadDataTransformations.some(t => t === RecipePreLoadDataTransformType.ScaleDown)
    };
  }

  public getScalingOptionChanges(nodeId: string, path: string[], newValue: SpecificationPreloadScalingOptions | undefined)
    : { added: RecipePreLoadDataTransformType[], removed: RecipePreLoadDataTransformType[] } | undefined {
    const currentOpts = this.getScalingOptionsForPath(nodeId, path);

    if (!currentOpts && !newValue) return undefined;

    const added: RecipePreLoadDataTransformType[] = [];
    const removed: RecipePreLoadDataTransformType[] = [];

    if (newValue?.allowScaleUp) {
      if (!currentOpts?.allowScaleUp) added.push(RecipePreLoadDataTransformType.ScaleUp);
    } else if (currentOpts?.allowScaleUp) {
      removed.push(RecipePreLoadDataTransformType.ScaleUp);
    }

    if (newValue?.allowScaleDown) {
      if (!currentOpts?.allowScaleDown) added.push(RecipePreLoadDataTransformType.ScaleDown);
    } else if (currentOpts?.allowScaleDown) {
      removed.push(RecipePreLoadDataTransformType.ScaleDown);
    }

    return { added, removed };
  }

  public changePreLoadDataTransformOptions(selectedScalingOptions: SpecificationPreloadScalingOptions,
    nodeId: string,
    path: string[],
    contextType: RecipePreLoadDataTransformContextType
  ): Observable<ChangeRecipePreLoadDataTransformOptionsResponse> {
    const scalingOptionsChanged = this.getScalingOptionChanges(nodeId, path, selectedScalingOptions);
    if (!scalingOptionsChanged) throw new Error('LOGIC ERROR: scaling option changes are undefined.');

    const command: ChangeRecipePreLoadDataTransformOptionsCommand = {
      added: scalingOptionsChanged.added,
      removed: scalingOptionsChanged.removed,
      contextType,
      nodeId,
      path,
      recipeId: this.currentRecipeId
    };

    return this.recipeEventsService.recipeEventsChangePreLoadDataTransformOptionsPost$Json({ body: command }).pipe(tap(() => {
      this.applyPreLoadDataTransformOptionChange(
        command.nodeId,
        command.path,
        {
          added: command.added,
          removed: command.removed
        }
      );
    }));
  }

  public applyPreLoadDataTransformOptionChange(
    nodeId: string,
    path: string[],
    optionChanges: { added: RecipePreLoadDataTransformType[], removed: RecipePreLoadDataTransformType[] }
  ) {
    let newOrExistingOption = this.currentRecipe.preLoadDataTransformOptions
      .find(opt => opt.nodeId === nodeId && isEqual(opt.path, path));

    if (!newOrExistingOption) {
      const newOption: RecipePreLoadDataTransformOption = {
        contextType: RecipePreLoadDataTransformContextType.FormField,
        nodeId,
        path,
        transformTypes: []
      };
      newOrExistingOption = newOption;
      this.currentRecipe.preLoadDataTransformOptions.push(newOption);
    }
    const preloadDataTransformationOption = newOrExistingOption;

    optionChanges.added.forEach(added => {
      if (!preloadDataTransformationOption.transformTypes.some(t => t === added)) {
        preloadDataTransformationOption.transformTypes.push(added);
      }
    });

    optionChanges.removed.forEach(removed => {
      preloadDataTransformationOption.transformTypes = preloadDataTransformationOption.transformTypes.filter(opt => opt !== removed);
    });

    if (preloadDataTransformationOption.transformTypes.length < 1) {
      this.currentRecipe.preLoadDataTransformOptions =
        this.currentRecipe.preLoadDataTransformOptions.filter(opt => opt.nodeId !== nodeId || (opt.nodeId === nodeId && opt.path !== path));
    }
  }

  loadCompendiaValues(compendiaPicklistId: string): void {
    this.picklistService.userPicklistsIdGet$Json({ id: compendiaPicklistId })
      .subscribe({
        next: (picklist: { items: PicklistItem[] }) => {
          this.compendiaValuesSubject.next(picklist.items);
        },
        error: () => {
          this.compendiaValuesSubject.next([]);
        }
      }
      );
  }

  loadDocumentType(compendiaPicklistId: string): void {
    this.picklistService.userPicklistsIdGet$Json({ id: compendiaPicklistId })
      .subscribe({
        next: (picklist: { items: PicklistItem[] }) => {
          this.documentValuesSubject.next(picklist.items);
        },
        error: () => {
          this.documentValuesSubject.next([]);
        }
      }
      );
  }

  updateCompletionStatus(isComplete: boolean) {
    this.completionStatus.next(isComplete);
  }

  recipeHasError(isDisabled: boolean) {
    this.handleRecipeErrorSubject.next(isDisabled);
  }

  isTemplateSynthetic(id: string, type?: TemplateType) {
    const template = this.recipe?.modules?.find(x => x.sourceTemplateId === id);
    if (template && 'isSynthetic' in template) {
      return template.isSynthetic;
    }
    return undefined
  }

  /**
    * Gets all Table objects in the recipe, regardless of RecipeType or how the recipe was constructed and evolved.
    * Needed based on existing practices with RecipeModel.
    */
  public getAllTables(): Table[] {
    const nestedTables = this.recipe.activities
      .flatMap((a) => a.dataModules).concat(this.recipe.modules)
      .flatMap((m) => m.items.filter((i): i is Table => i.itemType === NodeType.Table));
    return this.recipe.tables.concat(nestedTables);
  }
}

export const recipeTypeLabel: { [key: string]: string } = {
  referencePromptPreparation: $localize`:@@recipeTypeReferencePromptPreparation:ReferencePromptPreparation`,
  activity: $localize`:@@recipeTypeActivity:Activity`,
  activityGroup: $localize`:@@recipeTypeActivityGroup:Activity group`,
  module: $localize`:@@recipeTypeModule:Module`,
  tableForm: $localize`:@@recipeTypeTableForm:Table, form`,
  form: $localize`:@@recipeTypeForm:Form`,
  table: $localize`:@@recipeTypeTable:Table`,
  none: ''
};
export const recipeStateLabel: { [key: string]: string } = {
  draft: $localize`:@@draft:Draft`,
  inDraftLabel: $localize`:@@inDraft:In draft`,
  pendingVerification: $localize`:@@recipePendingVerification:Pending verification`,
  pendingApproval: $localize`:@@recipeStatePendingApproval:Pending approval`,
  cancelled: $localize`:@@recipeStateCancelled:Cancelled`,
  published: $localize`:@@recipeStatePublished:Published`,
  retired: $localize`:@@recipeStateRetired:Retired`
};

/**
 * Note: Ideally, the source can be undefined but never null.
 * But this below fix as part of #3289410 is to address the crashing old recipes.
 *
 * https://dev.azure.com/BPTCollection/Eurofins%20ELN/_workitems/edit/3289410
 */
export function processRecipeSource(source: ModifiableDataValue | undefined = undefined): ModifiableDataValue {
  return !source || !(source.value as StringValue)?.value || (source.value as StringValue).state === ValueState.NotApplicable
    ? RecipeService.modifiableDataValueNAPlaceholder
    : source;
}
