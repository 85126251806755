import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BptSliderComponent } from 'bpt-ui-library/bpt-slider';
import {
  ClientFacingNote,
  ClientFacingNoteContextType,
  FieldType,
  ExperimentWorkflowState,
  User,
  StatementContextType,
  Statement
} from '../../api/models';
import {
  ClientFacingNoteChangedEventNotification,
  ClientFacingNoteCreatedEventNotification,
  StatementAppliedEventNotification
} from '../../api/data-entry/models';
import { ExperimentComponent } from '../experiment.component';
import { DataRecordService } from '../services/data-record.service';
import { ClientFacingNoteComponent } from './client-facing-note/client-facing-note.component';
import { UserService } from '../../../app/api/services/user.service';
import { UserService as CurrentUserService } from 'services/user.service';
import { User as CurrentUser } from 'model/user.interface';
import {
  ActivityInputClientFacingNoteContext,
  CrossReferenceClientFacingNoteContext,
  FormFieldClientFacingNoteContext,
  LabItemsClientFacingNoteContext,
  LabItemsPreparationClientFacingNoteContext,
  PreparationsClientFacingNoteContext,
  ShowClientFacingNotesEventData,
  TableCellClientFacingNoteContext
} from './client-facing-note/client-facing-note-event.model';
import { BaseComponent } from '../../../../src/app/base/base.component';
import { ClientStateService } from 'services/client-state.service';
import { ActivatedRoute } from '@angular/router';
import { ClientFacingNoteModel } from './client-facing-note/client-facing-note.model';
import { isEqual, omit } from 'lodash-es';
import { DataValueService } from '../services/data-value.service';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ExperimentService } from '../services/experiment.service';
import { CommentService } from './comment.service';
import { StatementsService } from './statements/statements.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('commentsSlider') slider!: BptSliderComponent;
  @ViewChild('commentsSlider', { read: ElementRef }) sliderElement!: ElementRef;
  @ViewChild('newComment') newCommentComponent!: ClientFacingNoteComponent;
  @ViewChildren(ClientFacingNoteComponent) childNotes!: QueryList<ClientFacingNoteComponent>;

  /** Determines if the slider shows client-facing note cards, including the one for a new note (if applicable) */
  showNotes = false;
  visible = false;
  readonly currentUser: CurrentUser;

  /**
   * Context of the initial interest in notes. For example, the context of a show-notes context menu command.
   *
   * Example usages: creating a new note or setting the initial focus to an existing one.
   */
  public clientFacingNotesEventData?: ShowClientFacingNotesEventData;

  public readonly parentExperiment: ExperimentComponent;

  /**
   * Note the one candidate new note, when applicable; otherwise undefined.
   * This property is set upon the slider being opened for a context that doesn't have a note, yet.
   * It is unset upon successfully submitting the new note.
   */
  public newClientFacingNote?: ClientFacingNoteModel;

  public get clientFacingNotes() {
    return this.parentExperiment.experiment?.clientFacingNotes ? this.filterClientFacingNotes(this.parentExperiment.experiment?.clientFacingNotes) : [];
    // Note: there is no practical use of this component while there is no experiment but it is created before the experiment is loaded
  }

  public get statements() {
    return this.parentExperiment.experiment?.statements ?? [];
  }

  private filterClientFacingNotes(clientFacingNotes: ClientFacingNoteModel[]){
    clientFacingNotes = this.filterRemovedInstrumentEvents(clientFacingNotes);
    return clientFacingNotes;
  }

  private filterRemovedInstrumentEvents(clientFacingNotes: ClientFacingNoteModel[]){
    const removedImpactAssessmentIds = this.parentExperiment.experiment?.instrumentEventImpactAssessmentData
    ?.filter(impactAssessment => impactAssessment.isRemoved)?.map(s=> s.impactAssessmentId);
    if(removedImpactAssessmentIds)
      clientFacingNotes = clientFacingNotes.filter(clientFacingNote => !removedImpactAssessmentIds
    .some(removedImpactAssessmentId => removedImpactAssessmentId === clientFacingNote.nodeId))
    return clientFacingNotes
  }

  public getTableCellAppliedStatements(): Statement[] {
    return this.parentExperiment.experiment?.statements?.filter((statement) =>
      (statement.cfnNumber === null || statement.cfnNumber === undefined)
      && statement.contextType === StatementContextType.TableCell
    ) ?? [];
  }

  public closeOnEsc = true;

  users: User[] = [];

  private viewInitialized = false;

  public clientFacingNoteConstants: CommentType = {
    header: $localize`:@@clientFacingNoteAndStatementsHeader:Client-facing Notes/Statements`,
    newEntryLabel: $localize`:@@newClientFacingNote:Enter a Client-facing Note`
  };

  /**
   * Returns false if there is already a note attached to the particular node.
   * Example application: if there is then do not show the new note entry form.
   */
  public get newCommentVisible(): boolean { // NOSONAR S3776 Cognitive Complexity
    if (this.readOnly) return false;

    const nodeType = this.clientFacingNotesEventData?.eventContext?.contextType;
    if (!nodeType) return false;

    const newNote = this.newClientFacingNote;
    if (!newNote) return false;

    // Not using the new comment card if a comment already exists for the context (or the context is nonsensical)
    switch (nodeType) {
      case ClientFacingNoteContextType.TableCell:
        if (this.tableCellHasNote(newNote.getTableCellContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.CrossReference:
        if (this.crossReferenceHasNote(newNote.getCrossReferenceCellContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.LabItems:
        if (this.labItemsHasNote(newNote.getLabItemCellContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.Preparations:
        if (this.preparationsHasNote(newNote.getPreparationsCellContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.ActivityInput:
        if (this.activityInputHasNote(newNote.getActivityInputContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.LabItemsPreparation:
        if (this.labItemPreparationHasNote(newNote.getLabItemPreparationsCellContext()) !== false) return false;
        break;
      case ClientFacingNoteContextType.FormField:
        if (this.formFieldHasNote(newNote.getFormFieldContext()) !== false) return false;
        break;
    }
    return !this.viewInitialized || this.visible;
  }

  constructor(
    experiment: ExperimentComponent,
    private readonly dataRecordService: DataRecordService,
    private readonly userService: UserService,
    currentUserService: CurrentUserService,
    public readonly clientStateService: ClientStateService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dataValueService: DataValueService,
    private readonly commentsService: CommentService,
    private readonly experimentService: ExperimentService,
    private readonly statementService: StatementsService
  ) {
    super(clientStateService, activatedRoute);
    this.parentExperiment = experiment;
    this.userService = userService;
    this.currentUser = { ...currentUserService.currentUser };

    this.activeSubscriptions.push(
      this.dataRecordService.clientFacingNoteCreatedDataRecordReceiver.subscribe((data) =>
        this.applyClientFacingNoteCreatedEvent(data)
      ),
      this.dataRecordService.clientFacingNoteChangedDataRecordReceiver.subscribe((data) =>
        this.applyClientFacingNoteChangedEvent(data)
      ),
      this.dataRecordService.experimentWorkFlowDataRecordReceiver.subscribe((data) =>
        this.setReadOnly(data.state)
      )
    );
  }

  loadUsers() {
    this.userService
      .usersActiveUsersPost$Json({ body: [] })
      .subscribe((result) => {
        this.users = result;
      });
  }

  private _userCache: { [puid: string]: User } = {};
  findUser(puid: string): User | undefined {
    const cachedUser = this._userCache[puid];
    if (cachedUser) return cachedUser;

    const foundUser = this.users.find((u) => u.puid === puid);
    if (foundUser) this._userCache[puid] = foundUser;
    return foundUser;
  }

  ngOnInit(): void {
    this.activeSubscriptions.push(
      this.parentExperiment.showComments.subscribe((args) => {
        if (args?.eventContext?.mode === 'clientFacingNotes') {
          this.showClientFacingNotes(args as ShowClientFacingNotesEventData);
        }
      }),
      this.statementService.statementAdded.subscribe((notification: StatementAppliedEventNotification) => {
        notification.contentDetails.forEach(element => {
          this.publishStatementEvent({
            contextType: notification.contextType,
            content: element.content,
            lastEditedBy: notification.lastEditedBy,
            lastEditedOn: notification.lastEditedOn,
            nodeId: notification.nodeId,
            number: element.number,
            path: element.path,
            cfnNumber: notification.cfnNumber
          })
        });
      })
    );
    this.loadUsers();
  }

  private setReadOnly(workflowState: ExperimentWorkflowState) {
    // For client-facing notes, do not consider canEditExperimentInReviewState even if present in default clientstate's clientstate-permissions' feature flags.
    // For statements, at this point they always readonly by other means, today.
    const readOnlyDueToWorkflowState =
      workflowState === ExperimentWorkflowState.InReview
      || workflowState === ExperimentWorkflowState.Authorized
      || workflowState === ExperimentWorkflowState.Cancelled;

    this.readOnly = this.clientStateService.isClientStateReadOnly || readOnlyDueToWorkflowState;
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.activeSubscriptions);
  }

  /**
   * Repopulates and shows the client-facing notes slider.
   * Focuses on the target note if any, or new note if one can be created.
   *
   * Makes it visible if it's not. Rebinds children to existing notes in the experiment.
   * @param event
   */
  showClientFacingNotes(event: ShowClientFacingNotesEventData) {
    if (!event) throw new Error('Event missing data');
    if (!this.parentExperiment.experiment) throw new Error('Logic Error: cannot show for experiment without experiment.');

    this.setReadOnly(this.parentExperiment.experiment?.workflowState);

    this.clientFacingNotesEventData = event;
    const nodeType = this.clientFacingNotesEventData.eventContext.contextType;

    // Create a fresh newClientFacingNote for each showing in case its needed. (An old one is definitely not wanted.)
    this.newClientFacingNote = new ClientFacingNoteModel(nodeType, undefined, event.targetContext);

    this.visible = true;
    this.showNotes = true;
    this.changeDetector.detectChanges(); // update/create the children

    const targetContext = omit(event.targetContext, ['__proto__']); // so can use isEqual
    const targetNote = this.childNotes
      .filter((c) => !c.isNewClientFacingNote)
      .find((c) => isEqual(targetContext, omit(c.clientFacingNote?.context, ['__proto__']))) ?? this.newCommentComponent;
    if (targetNote?.clientFacingNote) targetNote.isBeingEdited = !this.readOnly
    if (targetNote) targetNote.focus();
  }

  /**
   * Checks if table cell has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  tableCellHasNote(tableContext: TableCellClientFacingNoteContext | undefined): boolean | undefined {
    if (!tableContext) return undefined; //can happen on load

    const currentColumn = tableContext?.columnField;
    const currentRow = tableContext?.rowId;
    const currentTable = tableContext?.tableId;
    if (!(currentColumn && currentRow && currentTable)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as TableCellClientFacingNoteContext;
      return (
        ctx?.columnField === currentColumn &&
        ctx?.rowId === currentRow &&
        ctx?.tableId === currentTable
      );
    });
  }

  /**
   * Checks if cross reference has a client-facing note.
   */
  crossReferenceHasNote(context: CrossReferenceClientFacingNoteContext | undefined): boolean {
    if (!context) return false; //can happen on load

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as CrossReferenceClientFacingNoteContext;
      return (
        ctx?.columnField === context.columnField &&
        ctx?.rowId === context.rowId &&
        ctx?.activityId === context.activityId
      );
    });
  }

  /**
   * Checks if lab items has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  labItemsHasNote(labItemContext: LabItemsClientFacingNoteContext | undefined): boolean | undefined {
    if (!labItemContext) return false; //can happen on load

    const currentColumnLabItem = labItemContext?.columnField;
    const currentRowLabItem = labItemContext?.rowId;
    const currentTableLabItem = labItemContext?.labItemId;
    const currentTableLabItemType = labItemContext?.labItemType;
    if (!(currentColumnLabItem && currentRowLabItem && currentTableLabItem)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as LabItemsClientFacingNoteContext;
      return (
        ctx?.columnField === currentColumnLabItem &&
        ctx?.rowId === currentRowLabItem &&
        ctx?.labItemId === currentTableLabItem &&
        ctx?.labItemType === currentTableLabItemType
      );
    });
  }

  /**
   * Checks if preparations has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  preparationsHasNote(prepContext: PreparationsClientFacingNoteContext | undefined): boolean | undefined {
    if (!prepContext) return false; //can happen on load

    const currentColumn = prepContext?.columnField;
    const currentRow = prepContext?.rowId;
    const currentActivityId = prepContext?.nodeId;
    if (!(currentColumn && currentRow && currentActivityId)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as PreparationsClientFacingNoteContext;
      return (
        ctx?.columnField === currentColumn &&
        ctx?.rowId === currentRow &&
        ctx?.nodeId === currentActivityId
      );
    });
  }

  /**
   * Checks if activity input has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  activityInputHasNote(activityInputContext: ActivityInputClientFacingNoteContext | undefined): boolean | undefined {
    if (!activityInputContext) return false; //can happen on load

    const presentColumn = activityInputContext?.columnField;
    const presentRow = activityInputContext?.rowId;
    const presentActivityInput = activityInputContext?.activityInputId;
    if (!(presentColumn && presentRow && presentActivityInput)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as ActivityInputClientFacingNoteContext;
      return (
        ctx?.columnField === presentColumn &&
        ctx?.rowId === presentRow &&
        ctx?.activityInputId === presentActivityInput
      );
    });
  }

  /**
   * Checks if lab item preparation has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  labItemPreparationHasNote(lPrepContext: LabItemsPreparationClientFacingNoteContext | undefined): boolean | undefined {
    if (!lPrepContext) return undefined;

    const labItemCurrentColumn = lPrepContext?.columnField;
    const labItemCurrentRow = lPrepContext?.rowId;
    const activityId = lPrepContext?.nodeId;
    if (!(labItemCurrentColumn && labItemCurrentRow && activityId)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as LabItemsPreparationClientFacingNoteContext;
      return (
        ctx?.columnField === labItemCurrentColumn &&
        ctx?.rowId === labItemCurrentRow &&
        ctx?.nodeId === activityId
      );
    });
  }

  /**
   * Checks if form field has a client-facing note.
   * @returns undefined if indeterminate; true/false if known.
   */
  formFieldHasNote(formContext: FormFieldClientFacingNoteContext | undefined): boolean | undefined {
    if (!formContext) return undefined; //can happen on load

    const formId = formContext.formId;
    const fieldIdentifier = formContext.fieldIdentifier;
    if (!(formId && fieldIdentifier)) return undefined;

    return this.clientFacingNotes.some((c) => {
      const ctx = c.context as FormFieldClientFacingNoteContext;
      return ctx?.formId === formId && ctx?.fieldIdentifier === fieldIdentifier;
    });
  }

  applyClientFacingNoteCreatedEvent(data: ClientFacingNoteCreatedEventNotification): void {
    // reshape with deep cloning because there might be multiple subscribers and mutators among them.
    const value: ClientFacingNote = {
      ...data,
      content: {
        isModified: false,
        value: { ...data.content }
      },
      path: [...data.path]
    };
    const newNote = new ClientFacingNoteModel(data.contextType, value);

    // Update experiment model
    this.parentExperiment.experiment?.clientFacingNotes.unshift(newNote);

    this.publishClientFacingNoteEvent(newNote);
  }

  applyClientFacingNoteChangedEvent(data: ClientFacingNoteChangedEventNotification): void {
    // Update experiment model
    const note = this.parentExperiment.experiment?.clientFacingNotes.find((n) => n.number === data.number);
    if (!note) {
      throw new Error('ClientFacingNoteChangedEventNotification does not match any existing note');
    }

    // only 3 properties can change
    note.lastEditedOn = data.lastEditedOn;
    note.lastEditedBy = data.lastEditedBy;
    note.content = DataRecordService.getModifiableDataValue(data.content, note.content);
    note.currentComment = this.dataValueService.getPrimitiveValue(FieldType.Textbox, note.content);

    this.publishClientFacingNoteEvent(note);
  }

  refreshCloseButtonEnablement() {
    const disabled = this.newClientFacingNote?.isBeingEdited || this.clientFacingNotes.some((c) => c.isBeingEdited);

    const className = 'header-close-button';
    const closeButton = this.sliderElement.nativeElement.getElementsByClassName(className)[0] as HTMLButtonElement;
    if (closeButton) {
      closeButton.disabled = disabled;
      //NOTE Can CSS pseudo-class :disabled but used instead of procedural code?
      closeButton.style.opacity = disabled ? '40%' : '100%';
    }
  }

  closeSlider(message: string) {
    if (message === 'closePanel') {
      this.visible = false;
    }
  }

  commentsSliderVisibleChange(_event: any) { // `any` because that what BptSliderComponent says/does and without any documentation
    if (!this.visible) {
      this.clientFacingNotes.forEach((note) => note.isBeingEdited = false);
    }
  }

  publishClientFacingNoteEvent(note: ClientFacingNoteModel) {
    this.newClientFacingNote = undefined;
    if (!this.parentExperiment.experiment?.clientFacingNotes.find((n) => n.number === note.number)) {
      this.parentExperiment.experiment?.clientFacingNotes.unshift(note);
    }

    this.experimentService.clientFacingNoteEvents.next(note);
    this.changeDetector.detectChanges();
  }

  publishStatementEvent(statement: Statement) {
    this.parentExperiment.experiment?.statements?.unshift(statement);
    this.changeDetector.detectChanges();
  }
}

type CommentType = {
  header: string;
  newEntryLabel: string;
  currentText?: string;
};
