import { Injectable } from '@angular/core';
import {
  ExperimentAuditHistoryService,
  FormAuditHistoryService,
  ActivityInputAuditHistoryService,
  ActivityOutputAuditHistoryService,
  TableAuditHistoryService,
  LabItemsAuditHistoryService,
  PreparationAuditHistoryService,
  ExperimentDataPackageAuditHistoryService
} from '../../api/audit/services';
import { Observable, forkJoin, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AuditHistoryDataRecordResponse,
  ExperimentDataRecordNotification,
  ExperimentNodeIdentity
} from '../../api/audit/models';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditHistoryComponent } from './audit-history.component';
import { ClientFacingNoteContextType } from '../../api/models';
import { ExperimentEventType, NodeType } from '../../api/data-entry/models';
import { remove } from 'lodash-es';

/**
 * Queries history for various locations. And, provides a single point to launch the History dialog.
 */
@Injectable({
  providedIn: 'root'
})
export class AuditHistoryService {
  constructor(
    private readonly experimentAuditService: ExperimentAuditHistoryService,
    private readonly tableAuditHistoryService: TableAuditHistoryService,
    private readonly formAuditHistoryService: FormAuditHistoryService,
    private readonly activityInputAuditHistoryService: ActivityInputAuditHistoryService,
    private readonly labItemsAuditHistoryService: LabItemsAuditHistoryService,
    private readonly dialogService: DialogService,
    private readonly activityOutputAuditHistoryService: ActivityOutputAuditHistoryService,
    private readonly preparationAuditHistoryService: PreparationAuditHistoryService,
    private readonly dataPackageAuditHistoryService: ExperimentDataPackageAuditHistoryService
  ) { }

  public loadExperimentCoverAuditHistory(
    experimentId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditService.experimentAuditHistoryExperimentIdCoverGet$Json({
      experimentId: experimentId
    });
  }

  public loadExperimentAuditHistory(
    experimentId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditService.experimentAuditHistoryExperimentIdGet$Json({
      experimentId: experimentId
    });
  }

  public loadDataPackageAuditHistory(
    experimentId: string,
    activityIds: string[]
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.dataPackageAuditHistoryService.dataPackageAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: {ids: activityIds}
    });
  }

  public loadPreparationsAuditHistory(
    experimentId: string,
    activityId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.preparationAuditHistoryService.preparationsAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [activityId] }
    });
  }
  public loadActivityOrModuleAuditHistory(
    experimentId: string,
    tableIds?: string[],
    formIds?: string[]
  ): Observable<{ r1: AuditHistoryDataRecordResponse; r2: AuditHistoryDataRecordResponse }> {
    return forkJoin({
      r1: this.tableAuditHistoryService.tableAuditHistoryExperimentIdPost$Json({
        experimentId: experimentId,
        body: { ids: tableIds }
      }),
      r2: this.formAuditHistoryService.formAuditHistoryExperimentIdPost$Json({
        experimentId: experimentId,
        body: { ids: formIds }
      })
    });
  }

  public loadSelectedActivityOrModuleAuditHistory(
    experimentId: string,
    allIds: ExperimentNodeIdentity[],
    nodeType: NodeType
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.experimentAuditService.experimentAuditHistoryExperimentIdActivityModulePost$Json({
      experimentId,
      nodeType,
      body: allIds
    });
  }

  public loadFormAuditHistory(
    experimentId: string,
    formId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.formAuditHistoryService.formAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [formId] }
    });
  }

  public loadActivityInputsAuditHistory(
    experimentId: string,
    activityId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.activityInputAuditHistoryService.activityInputsAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [activityId] }
    });
  }

  public loadLabItemsAuditHistory(
    experimentId: string,
    activityId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.labItemsAuditHistoryService.labItemsAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [activityId] }
    });
  }

  public loadTableAuditHistory(
    experimentId: string,
    tableId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.tableAuditHistoryService.tableAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [tableId] }
    });
  }

  public loadClientFacingNoteHistory(
    experimentId: string,
    pathId: string[],
    noteType: ClientFacingNoteContextType
  ): Observable<AuditHistoryDataRecordResponse> {
    let rec;
    switch (noteType) {
      case ClientFacingNoteContextType.TableCell:
        rec = this.loadTableAuditHistory(experimentId, pathId[0]);
        break;
      case ClientFacingNoteContextType.FormField:
        rec = this.loadFormAuditHistory(experimentId, pathId[0]);
        break;
      case ClientFacingNoteContextType.Experiment:
      case ClientFacingNoteContextType.Activity:
      case ClientFacingNoteContextType.ActivityGroup:
      case ClientFacingNoteContextType.Module:
      case ClientFacingNoteContextType.Form:
      case ClientFacingNoteContextType.Table:
      default:
        return EMPTY;
    }
    return rec.pipe(
      map((data) => {
        remove(data.dataRecords, (rec) => {
          return ![
            ExperimentEventType.ClientFacingNoteChanged,
            ExperimentEventType.ClientFacingNoteCreated
          ].includes(rec.eventContext.eventType);
        });
        return data;
      })
    );
  }

  public loadActivityOutputsAuditHistory(
    experimentId: string,
    activityId: string
  ): Observable<AuditHistoryDataRecordResponse> {
    return this.activityOutputAuditHistoryService.activityOutputsAuditHistoryExperimentIdPost$Json({
      experimentId: experimentId,
      body: { ids: [activityId] }
    });
  }

  /**
   * Shows the single history dialog, used by all locations that offer history.
   *
   * Delegates to AuditHistoryComponent for presentation
   * (which, currently, delegates to DataRecordService for entry formatting)
   */
  public showAuditDialog(
    dataRecords: ExperimentDataRecordNotification[],
    context?: string,
    nodeType?: NodeType,
    nodeId?: string
  ): DynamicDialogRef {
    return this.dialogService.open(AuditHistoryComponent, {
      header: `${context} - ` + $localize`:@@History:History`,
      width: '70%',
      autoZIndex: true,
      closeOnEscape: true,
      data: { records: dataRecords, contextType: nodeType, contextNodeId: nodeId },
      styleClass: 'eln-audit-dialog overflow-hidden',
      resizable: true,
    });
  }
}
