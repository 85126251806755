import { Injectable } from '@angular/core';
import { ActivityInputNode, Aliquot } from '../../api/models';
import { Activity } from '../../model/experiment.interface';

@Injectable({
  providedIn: 'root'
})
export class ExperimentDataService {
  constructor() { }

  getReferenceableItems(_activity: Activity, activityInputNode: ActivityInputNode | undefined): { sampleAliquots: Aliquot[] } {
    /*
      enum ActivityItemReferenceType values shown as .*
        Inputs: from activityInputNode
          .Input = 'input' // not a reference type but means these 3 types. Used when populating dropdown options.

          .SampleAliquot = 'sampleAliquot',                              from activityInputNode.aliquots
          .StudyActivtyMaterialAliquot = 'studyActivityMaterialAliquot', from activityInputNode.materials
          .InstrumentEvent = 'instrumentEvent',                          from activityInputNode.instruments should be exactly zero or one

        LabItems
          .Instrument = 'instrument',
          .Consumable = 'consumable',
          .Column = 'column',
          .MaterialAliquot = 'materialAliquot',
          .PreparationLabItem = 'preparationLabItem',

        New Preparation:
          Preparation = 'preparation', from activity.preparations
    */
    // TODO extend return type and add more stanzas for more ExperimentReferenceType # 3287400. Note: some types have to include RowIndex.
    const sampleAliquots = activityInputNode?.aliquots.filter(a => !a.isRemoved) ?? [];
    return { sampleAliquots };
  }
}
