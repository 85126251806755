import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ChangeReasonService } from "../services/change-reason.service";
import { Subscription } from "rxjs";
import { UnsubscribeAll } from "../../shared/rx-js-helpers";
import { ExperimentService } from "../services/experiment.service";
import { ChangeReasonsSliderDetails } from "../model/change-reason-slider-details";
import { ChangeReasonLookupEntity } from "../../api/models";
import { LocalizeHelper } from "../../shared/localize-helper";
import { RadioButton } from "primeng/radiobutton";

@Component({
  selector: 'app-change-reason',
  templateUrl: './change-reason.component.html',
  styleUrls: ['./change-reason.component.scss']
})
export class ChangeReasonComponent implements OnInit, OnDestroy {
  oldValue? = '';
  newValue? = '';
  contextTitle = 'Placeholder name';
  selectedChangeReason = '';
  changeReasonsMissingMessage =
    $localize`:@@changeReasonsMissingMessage:Not all change reasons are recorded, Change reasons must be recorded for all the values which got modified`;

  sliderOptions = {
    header: $localize`:@@changeReasonSliderHeader:Change Reason`,
    closeOnEscape: false,
    showCloseIcon: false,
    isFooterSticky: true,
    isPrimaryButtonDisabled: true,
    displayFooter: true,
    isModal: true,
    isVisible: false,
    displayFooterWithSecondaryButton: false,
    size: 'small'
  };

  changeReasonDetails: ChangeReasonDisplayDetails[] = [];

  subscriptions: Subscription[] = [];
  clientFacingReasonIndex = 9;
  isClientFacingReasonSelected = false;
  clientFacingReason = '';
  showChangedValues = true;
  private nodeId = '';
  private updateForMissingChangeReason = false;
  readonly oldValueHeader = $localize`:@@oldValueHeader:Old value:`;
  readonly newValueHeader = $localize`:@@newValueHeader:New value:`;
  readonly clientFacingChangeReasonText = $localize`:@@clientFacingChangeReasonText:Client facing change reason`;
  readonly clientFacingReasonPlaceholderText = $localize`:@@clientFacingReasonPlaceholder:Client facing reason addressing the change`;

  @ViewChildren('changeReasonRadioButtons') changeReasonRadioButtons!: QueryList<RadioButton>;

  constructor(
    private readonly changeReasonService: ChangeReasonService,
    private readonly experimentService: ExperimentService
  ){
    this.subscriptions.push(
      this.experimentService.changeReasonSliderDisplayDetails.subscribe({
        next: (details: ChangeReasonsSliderDetails) => this.initializeSlider(details)
      })
    );
  }

  ngOnInit() {
    this.changeReasonService.getChangeReasonsFromLookup();
  }

  initializeSlider(details: ChangeReasonsSliderDetails) {
    this.sliderOptions.isPrimaryButtonDisabled = true;
    this.changeReasonDetails = [];
    this.selectedChangeReason = '';
    if(details.isVisible) {
      let index = 1;
      this.changeReasonService.getPredefinedChangeReasons().forEach((changeReason: ChangeReasonLookupEntity) => {
        this.changeReasonDetails.push({
          index: index,
          data: LocalizeHelper.translateMessage(changeReason.translationKey, changeReason.changeReason),
          isSelected: false
        });
        index += 1;
      });
      this.clientFacingReasonIndex = this.changeReasonDetails.length + 1;
      this.changeReasonDetails.push({
        index: this.clientFacingReasonIndex,
        data: this.clientFacingChangeReasonText,
        isSelected: false
      })
    }
    this.newValue = details.newValue;
    this.oldValue = details.oldValue;
    this.contextTitle = details.contextTitle;
    this.sliderOptions.isVisible = details.isVisible;
    this.sliderOptions.showCloseIcon = details.showCloseIcon;
    this.showChangedValues = details.showChangedValues;
    this.nodeId = details.nodeId;
    this.updateForMissingChangeReason = details.updateForMissingReason ?? false;
  }

  changeReasonSelected() {
    if(this.selectedChangeReason === this.clientFacingChangeReasonText) {
      this.sliderOptions.isPrimaryButtonDisabled = this.clientFacingReason.trim().length === 0 ? true : false;
    }
    else {
      this.clientFacingReason = '';
      this.sliderOptions.isPrimaryButtonDisabled = false;
    }
  }

  changeReasonOptionClicked(changeReason: any) {
    this.selectedChangeReason = changeReason;
    this.changeReasonSelected();
  }

  onClientFacingReasonChanged(event: string) {
    if (event.trim().length > 0) {
      this.sliderOptions.isPrimaryButtonDisabled = false;
      this.clientFacingReason = event;
    }
    else {
      this.sliderOptions.isPrimaryButtonDisabled = true;
    }
  }

  saveButtonClicked() {
    this.isClientFacingReasonSelected = this.selectedChangeReason === this.clientFacingChangeReasonText;
    const changeReasonText = this.isClientFacingReasonSelected ? this.clientFacingReason : this.selectedChangeReason
    const index = this.isClientFacingReasonSelected ? this.clientFacingReasonIndex : this.getIndexOfSelectedReason();
    this.changeReasonService.saveChangeReason(index, changeReasonText, this.isClientFacingReasonSelected, this.nodeId, this.updateForMissingChangeReason);
    this.selectedChangeReason = '';
    this.clientFacingReason = '';
    this.sliderOptions.isPrimaryButtonDisabled = true;
    this.changeReasonDetails = [];
    this.updateForMissingChangeReason = false;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key >= '1' && event.key <= '9') {
      this.selectedChangeReason = this.changeReasonDetails.filter(c => c.index === Number.parseInt(event.key))[0].data;
      this.changeReasonRadioButtons.filter(c => c.focused)[0].focused = false;
      this.changeReasonOptionClicked(this.selectedChangeReason);
    }
  }

  private getIndexOfSelectedReason(): number {
    const reasonDetails = this.changeReasonDetails.find((reason: ChangeReasonDisplayDetails) => reason.data === this.selectedChangeReason);
    return reasonDetails !== undefined ? reasonDetails.index : 0;
  }

  ngOnDestroy() {
    UnsubscribeAll(this.subscriptions);
  }
}

export interface ChangeReasonDisplayDetails {
    index: number,
    data: string,
    isSelected: boolean
}