<div *ngIf="showRecipeScalableSlider" class="template-loader-slider-container">
  <bpt-slider
    id="eln-ScalableRecipeSlider"
    [(visible)]="scalableRecipeSliderOptions.visible"
    [closeOnEscape]="scalableRecipeSliderOptions.closeOnEscape"
    [headerText]="scalableRecipeDetails.name"
    [primaryButtonLabel]="scalableRecipeSliderOptions.primaryButtonLabel"
    (footerSecondaryButtonClick)="onCancel()"
    (visibleChange)="closeSlider($event)"
    (footerPrimaryButtonClick)="onLoad()"
  >
    <div>
      <div class="text-color-secondary mb-5">
        <span>
          {{ sbuLabel }}: {{ scalableRecipeDetails.sbu }} &nbsp;
          {{ versionLabel }}: {{ scalableRecipeDetails.version }} &nbsp;
          {{ publishedOnLabel }}: {{ scalableRecipeDetails.publishedOn }}
        </span>
      </div>

      <div *ngFor="let activity of activities" class="mb-4">
        <div *ngIf="activity.itemTitle" class="text-lg font-semibold mb-3">{{ activity.itemTitle }}</div>

        <div *ngFor="let module of activity.modules" class="mb-3 scalableGrid">
          <div class="scalableTableGrid" *ngIf="hasScalableTables([module])">
            <div class="text-base font-semibold mb-3">
              {{ scalableTableLabel }}
              <span *ngIf="module.moduleName"> - {{ module.moduleName }}</span>
            </div>
            <div class="mb-3">
              <bpt-grid
                #specificationGrid
                gridId="eln-scalable-recipe-bptGrid"
                [columnDefinitions]="specificationColumnDefs"
                [dataSource]="dataSourceMapForTables[module.moduleId]"
                [allowRowAdd]="false"
                [gridMode]="gridMode"
                [showFilterToggleButton]="false"
                [showGridOptionsButton]="false"
                [includeRowNumberColumn]="false"
                [useJsJodaTypes]="true"
                [paginated]="false"
                [rowActionConfigurations]="gridActions">
              </bpt-grid>
            </div>
          </div>

          <div class="scalableFormGrid" *ngIf="hasScalableForms([module])">
            <div class="text-base font-semibold mb-3">
              {{ scalableFormLabel }}
              <span *ngIf="module.moduleName"> - {{ module.moduleName }}</span>
            </div>
            <div class="mb-3">
              <bpt-grid
                #specificationGrid
                gridId="eln-scalable-form-recipe-bptGrid"
                [columnDefinitions]="specificationForFormColumnDefs"
                [dataSource]="dataSourceMapForForms[module.moduleId]"
                [allowRowAdd]="false"
                [gridMode]="gridMode"
                [showFilterToggleButton]="false"
                [showGridOptionsButton]="false"
                [includeRowNumberColumn]="false"
                [useJsJodaTypes]="true"
                [paginated]="false"
                [rowActionConfigurations]="gridActions">
              </bpt-grid>
            </div>
          </div>
        </div>

        <div class="scalablePreparationGrid" *ngIf="hasScalablePreparations(activity.preparations)">
          <div class="text-base font-semibold mb-3">Preparations</div>
          <div class="mb-3">
            <bpt-grid
            #preparationGrid
            gridId="eln-scalable-preparation-bptGrid"
            [columnDefinitions]="preparationColumnDefs"
            [dataSource]="getPreparationDataSource(activity.preparations)"
            [allowRowAdd]="false"
            [gridMode]="gridMode"
            [showFilterToggleButton]="false"
            [showGridOptionsButton]="false"
            [includeRowNumberColumn]="false"
            [useJsJodaTypes]="true"
            [paginated]="false"
            [rowActionConfigurations]="gridActions"
            >
            </bpt-grid>
          </div>
        </div>

        <p-divider></p-divider>
      </div>
      <div *ngIf="loading" class="eln-layout-content mb-4 ml-4">
        <bpt-progress-spinner
          id="eln-TemplateLoadProgressbar"
          [strokeWidth]="2"
          [diameter]="50"
          [useOverlay]="false"
          [message]="loadingMessage"
        ></bpt-progress-spinner>
      </div>
    </div>
  </bpt-slider>
</div>
