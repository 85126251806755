<div class="bpt-layout-container">
  <bpt-slider
    #changeReasonsSlider
    [(visible)]="sliderOptions.isVisible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [showCloseIcon]="sliderOptions.showCloseIcon"
    [displayFooter]="sliderOptions.displayFooter"
    [isFooterSticky]="sliderOptions.isFooterSticky"
    [headerText]="sliderOptions.header"
    [isPrimaryButtonDisabled]="sliderOptions.isPrimaryButtonDisabled"
    [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
    [isModal]="sliderOptions.isModal"
    [size]="sliderOptions.size"
    (footerPrimaryButtonClick)="saveButtonClicked()">
    <div class="slider-content">
      <div class="change-reason-container">
        <div class="values-container flex flex-column">
          <div class="values-title">
            <p class="context-path mb-4">{{contextTitle}}</p>
          </div>
          <div class="values grid" *ngIf="showChangedValues">
            <div *ngIf="oldValue !== undefined && newValue !== undefined" class="old-value col-6">
              <div class="value-header">{{oldValueHeader}}</div>
              <div class="font-bold">{{oldValue}}</div>
            </div>
            <div *ngIf="oldValue !== undefined && newValue !== undefined" class="new-value col-6">
              <div class="value-header">{{newValueHeader}}</div>
              <div class="font-bold">{{newValue}}</div>
            </div>
          </div>
        </div>
        <div class="predefined-reasons-container row-gap-2">
          <div
            *ngFor="let changeReason of changeReasonDetails"
            class="radio-container flex p-field-checkbox"
            (click)="changeReasonOptionClicked(changeReason.data)">
            <p-radioButton
              #changeReasonRadioButtons
              id="changeReasonRadioButtons"
              [inputId]="changeReason.data"
              name="changeReason"
              [value]="changeReason.data"
              [(ngModel)]="selectedChangeReason"></p-radioButton>
            <label class="eln-pre-defined-reason-radio-label"
              [for]="changeReason.data">
              {{changeReason.index}}. {{changeReason.data}}
            </label>
          </div>
        </div>
        <div class="client-facing-reason-box mt-2" *ngIf="selectedChangeReason === clientFacingChangeReasonText">
          <bpt-text-input
            #noteInput
            class="min-sized-textbox"
            [multiLine]="true"
            [rows]="2"
            [autoResize]="true"
            [placeholder]="clientFacingReasonPlaceholderText"
            [(ngModel)]="clientFacingReason"
            (ngModelChange)="onClientFacingReasonChanged($event)"
            [suppressContextMenu]="true"></bpt-text-input>
        </div>
      </div>
    </div>
  </bpt-slider>
</div>