import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { ColumnType } from '../../../../api/models/column-type';
import { BptGridComponent, BptGridMode, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LockType } from 'model/input-lock.interface';
import { ExperimentNotificationService } from 'services/experiment-notification.service';
import { Experiment } from 'model/experiment.interface';
import { ActivityInputType, MaterialAliquotDetails, ModifiableDataValue } from '../../../../api/models';
import { ExperimentService } from '../../../services/experiment.service';
import { ActivityInputEventsService } from '../../../../api/data-entry/services';
import { mapValues } from 'lodash-es';
import { DataValueService } from '../../../services/data-value.service';
import { ActivityInputRowRestoredResponse } from '../../../../api/data-entry/models/activity-input-row-restored-response';
import { BehaviorSubject } from 'rxjs';
import { StudyActivityTableGridOptions } from '../study-activity-table-grid-options';
import { ActivityInputStudyActivity } from '../../../model/activity-input-study-activity';
import { ActivityInputService } from '../../../../api/services';
import { ActivityInputHelper } from '../../shared/activity-input-helper';
import { UserService } from '../../../../services/user.service';
import { ChangeReasonService } from '../../../services/change-reason.service';
import { v4 as uuid } from 'uuid';
@Component({
  selector: 'app-study-activity-removed-rows-table',
  templateUrl: './study-activity-removed-rows.component.html',
  styleUrls: ['./study-activity-removed-rows.component.scss']
})
export class StudyActivityTableRemovedRowsComponent implements OnInit, OnDestroy {
  titleOfTable = $localize`:@@StudyActivityTableTitle:Study Activities`;
  subHeading = $localize`:@@StudyActivitiesRemovedSubHeading:Please click on the restore icon from the table to restore removed Material Aliquots`;
  removedRowsColumnDefinitions: ColumnDefinition[] = [];
  gridMode = BptGridMode.dataEntry;
  removedRowsTableId = 'removedStudyActivityTable';
  rowSelection = 'single';
  additionalInformation = 'AdditionalInformation';
  removedMaterialAliquots: ActivityInputStudyActivity[] = [];
  experimentId = '';
  experiment!: Experiment;
  displayScrollButtons = true;
  primitiveValue!: { [key: string]: any }[];
  activityInputType!: ActivityInputType;
  gridActions!: BptGridRowActionConfiguration;

  @ViewChild('removedMaterialAliquotsGrid') grid!: BptGridComponent;
  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly confirmationService: ConfirmationService,
    private readonly activityInputEventsService: ActivityInputEventsService,
    private readonly messageService: MessageService,
    private readonly experimentService: ExperimentService,
    private readonly dataValueService: DataValueService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly renderer: Renderer2,
    private readonly activityInputService: ActivityInputService,
    private readonly activityInputHelper: ActivityInputHelper,
    private readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.handleSubscriptions();
    this.removedRowsColumnDefinitions = StudyActivityTableGridOptions.prepareColumns(undefined, true);
    this.refreshDataSource();
    this.addGridActions();
  }

  ngOnDestroy(): void {
    StudyActivityTableGridOptions.isReadOnly = this.dynamicDialogConfig.data.isReadOnly;
  }

  public getPrimitiveDataValueRows(
    incomingRows: { [key: string]: ModifiableDataValue | string }[]
  ): { [key: string]: any }[] {
    return incomingRows.map((row) =>
      mapValues(row, (value: ModifiableDataValue | string, field: string) => {
        const column = (this.removedRowsColumnDefinitions ?? []).find((d) => d.field === field);
        return this.dataValueService.getPrimitiveValue(
          (column?.columnType as ColumnType) ?? ColumnType.String,
          value as ModifiableDataValue
        );
      })
    );
  }

  onGridReady() {
    this.activityInputHelper.loadCellLocks(this.grid.gridOptions, this.renderer);
    this.grid?.gridApi?.autoSizeAllColumns();
  }

  restoreCallbackForMaterialAliquot = (row: ActivityInputStudyActivity) => {
    this.activityInputHelper.sendInputStatus(
      LockType.lock,
      this.grid.gridId,
      row.code,
      this.activityInputHelper.rowRestoreIdentifier
    );
    this.confirmationService.confirm({
      message: $localize`:@@restoreRowConfirmation:Are you sure you wish to restore this row?`,
      header: $localize`:@@restoreRow:Restore row`,
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: false,
      accept: () => {
        ChangeReasonService.oldValue = undefined;
        if(!row.isRefreshed) this.processMaterialAliquotRestore(row);
        else {
          this.fetchLatestDetails(row);
          }

        this.activityInputHelper.sendInputStatus(LockType.unlock, this.grid.gridId, row.code, this.activityInputHelper.rowRestoreIdentifier)
      },
      reject: () => {
        this.activityInputHelper.sendInputStatus(LockType.unlock, this.grid.gridId, row.code, this.activityInputHelper.rowRestoreIdentifier);
      }
    });
  };

  private handleSubscriptions() {
    this.experimentNotificationService.inputLockReceiver.subscribe((lock) => {
      this.activityInputHelper.applyCellLock(lock, this.grid.gridOptions, this.renderer);
    }
    );
    this.activityInputHelper.updateStudyActivityTableDataSource.subscribe({
      next: this.refreshDataSource.bind(this)
    })
  }

  private refreshDataSource(): void {
    const materials = this.activityInputHelper.getMaterialAliquots(true);
    this.removedMaterialAliquots = [...materials];
    this.grid?.gridApi?.setGridOption('rowData', this.removedMaterialAliquots);
  }

  private processMaterialAliquotRestore(row: ActivityInputStudyActivity) {
    this.activityInputEventsService
      .activityInputEventsRestoreRowPost$Json({
        body: {
          activityId: this.experimentService.currentActivityId,
          activityInputReference: row.code,
          activityInputType: ActivityInputType.Material,
          experimentId:
            typeof this.experimentService.currentExperiment?.id === 'undefined'
              ? ''
              : this.experimentService.currentExperiment?.id,
          studyActivities:[],
          selectedStudyActivityCode:'',
          changeReasonId: uuid()
        }
      })
      .subscribe({
        next: (response: ActivityInputRowRestoredResponse) => {
          const materialAliquotNumber = response.rowRestoredEventNotification.activityInputReference
          this.activityInputHelper.restoreAliquotFromStudyActivitySource(materialAliquotNumber, response.rowRestoredEventNotification.activityId);
          this.refreshDataSource();
          this.activityInputHelper.materialAliquotRestored.next(materialAliquotNumber);
          this.messageService.add({
            key: 'notification',
            severity: 'success',
            summary: $localize`:@@activityInputRestored:Activity input successfully restored`,
            detail: `${materialAliquotNumber}`
          });
        },
        error: () => {
          this.showRestoreFailMessage(row);
        }
      });
  }

  private fetchLatestDetails(row: ActivityInputStudyActivity) {
    this.activityInputService.activityInputRetrieveMaterialDetailsPost$Json({
      materialAliquotNumber: row.code
    })
    .subscribe({
        next: (response: MaterialAliquotDetails) => {
          if(!response.studyActivities || response.studyActivities.length === 0){
            this.messageService.add({
              key: 'notification',
              severity: 'error',
              summary: $localize`:@@noAssociatedSAsMessage:The material does not have any associated study activities and cannot be restored`
            });
            return;
          }
          row.studyActivities = response.studyActivities;
          if (response.studyActivities.length === 1) {
            this.activityInputHelper.studyActivitySelected.next({
              AliquotNumber: row.code,
              StudyActivities:row.studyActivities,
              StudyActivityIds:[]
            })
          }
          this.processMaterialAliquotRestore(row);
        },
        error : () => {
          this.showRestoreFailMessage(row);
        }
      });
  }

  private showRestoreFailMessage(row: ActivityInputStudyActivity) {
    this.messageService.add({
      key: 'notification',
      severity: 'error',
      summary: $localize`:@@activityInputRestoreFailed:Failed to restore Activity input`,
      detail: `${row.code}`
    });
  }

  private addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);

    this.gridActions = {
      actions: actionsSubject,
      skipAutoSizeAllColumnsWhenFirstDataRendered: false
    };
  }

  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.activityInputHelper.rowRestoreIdentifier,
        enabled: !(this.userService.hasOnlyReviewerRights() || StudyActivityTableGridOptions.isReadOnly),
        styleClass: 'icon-s icon-restore-icon',
        click: this.rowRestoreActionClick.bind(this),
        tooltip: $localize`:@@RestoreItem:Restore item`
      }
    ];
  }

  private rowRestoreActionClick(e: BptGridRowActionClickEvent) {
    this.restoreCallbackForMaterialAliquot(e.params.data);
  }
}
