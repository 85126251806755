import { Routes } from '@angular/router';
import { ClientState } from '../app.states';
import { RecipeAboutComponent } from './recipe-about/recipe-about.component';
import { RecipeComponent } from './recipe.component';
import { RecipeDesignerComponent } from './recipe-designer/recipe-designer.component';
import { RecipeDataComponent } from './data/recipe-data.component';
import { RecipePromptsComponent } from './recipe-prompts/recipe-prompts.component';
import { RecipeReferencesComponent } from './recipe-references/recipe-references.component';
import { RecipePreparationsComponent } from './recipe-preparations/recipe-preparations.component';
import { blazorStartResolver } from '../shared/blazor-js/blazor-start-resolver';

export const RECIPE_ROUTES: Routes = [
  {
    path: ':recipeNumber',
    component: RecipeComponent,
    data: { title: $localize`:@@recipe:Recipe`, clientState: ClientState.RECIPE },
    resolve: {
      preloadScripts: blazorStartResolver
    },
    children: [
      { path: '', redirectTo: 'about', pathMatch: 'full' },
      {
        path: 'about',
        component: RecipeAboutComponent,
        data: { clientState: ClientState.RECIPE_ABOUT }
      },
      {
        path: 'designer',
        component: RecipeDesignerComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: ':itemTitle/Modules',
        component: RecipeDataComponent,
         data: { clientState: ClientState.RECIPE_DESIGNER }
       },
      {
        path: 'designer/Prompts',
        component: RecipePromptsComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: ':itemTitle/Prompts',
        component: RecipePromptsComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: 'designer/References',
        component: RecipeReferencesComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: ':itemTitle/References',
        component: RecipeReferencesComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: ':itemTitle/Preparations',
        component: RecipePreparationsComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      },
      {
        path: 'designer/Preparations',
        component: RecipePreparationsComponent,
        data: { clientState: ClientState.RECIPE_DESIGNER }
      }
    ]
  }
];

export function navigateRecipeToAboutPage(recipeNumber: string, version = '0.1'): void {
  window.open(`/recipe/${recipeNumber}_V${version}/about`, '_blank');
}

export function navigateRecipeToDesignerPage(recipeNumber: string, version = '0.1'): void {
  window.open(`/recipe/${recipeNumber}_V${version}/designer`, '_blank');
}
