export class ChangeReasonConstants {
  public static get AdditionalInformation() {
    return $localize`:@@additionalInfo:Additional Information`
  }
  public static get InputsHeader() {
    return $localize`:@@inputModuleHeader:Activity Inputs`;
  }

  public static get SampleTableHeader() {
    return $localize`:@@SampleAliquots:Samples & Aliquots`;
  }

  public static get StudyTableHeader() {
    return $localize`:@@StudyActivities:Study Activities`;
  }

  public static get TestReportableName() {
    return $localize`:@@TestReportableName:Test Reportable Name`;
  }

  public static get StudyActivity() {
    return $localize`:@@StudyActivity:Study Activity`;
  }

  public static get Instrument() {
    return $localize`:@@Instrument:Instrument`
  }

  public static get InstrumentEvent() {
    return $localize`:@@instrumentEvent:Instrument Event`
  }

  public static get InstrumentEventDescription() {
    return $localize`:@@instrumentEventDescription:Description of Situation`
  }

  public static get InstrumentEventDateRemoved() {
    return $localize`:@@instrumentEventDateRemoved:Date Removed`
  }

  public static get InstrumentEventRemovedFromService() {
    return $localize`:@@instrumentEventRemovedFromService:Removed from Service`
  }

  public static get ReferencesHeader() {
    return $localize`:@@ReferenceModuleHeader:References`;
  }

  public static get DocumentsTableHeader() {
    return $localize`:@@DocumentsLabel:Documents`;
  }

  public static get CompendiaTableHeader() {
    return $localize`:@@CompendiaLabel:Compendia`;
  }

  public static get CrossReferencesHeader() {
    return $localize`:@@CrossReferences:Cross References`;
  }

  public static get ImpactAssessmentHeader() {
    return $localize`:@@instrumentEventImpactAssessment:Instrument Event Impact Assessment`;
  }

  public static get OutputsHeader() {
    return $localize`:@@outputsHeader:Activity Outputs`;
  }

  public static get NonRoutineIssueHeader() {
    return $localize`:@@nonRoutineIssueEncountered:Non-Routine Issue Encountered`;
  }

  public static get ReturnToServiceHeader() {
    return $localize`:@@returnedToService:Returned to Service`;
  }

  public static get PurposeColumnHeader() {
    return $localize`:@@purpose:Purpose`;
  }

  public static get crossReferenceNodeHeader() {
    return '-crossReference';
  }

  public static get returnedToServiceFieldName() {
    return 'instrumentReturnedToService';
  }

  public static get nonRoutineIssueFieldName() {
    return 'instrumentNonRoutineIssueEncountered';
  }

  public static readonly InstrumentDescriptionKey = "instrumentEventDescription";
  public static readonly InstrumentDateRemovedKey = "instrumentEventDateRemoved";
  public static readonly NameDescriptionKey = "instrumentEventNameDescription";
  public static readonly RemovedFromServiceKey = "instrumentServiceRemovedFromService";
  public static readonly TestReportableNameKey = "testReportableName";
  public static readonly StudyActivityKey = "studyActivity";
  public static readonly AdditionalInformationKey = "additionalInformation";

  public static readonly SeverityIndicatorStyleClass = 'bpt-validation-error pi pi-info-circle';
  public static readonly SeverityIndicatorTooltip = $localize`:@@missingChangeReason:Missing Change Reason`;
}