import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../api/services';
import { Subscription } from 'rxjs';
import { ColumnType, FieldType, User, ValueState, ValueType } from '../../api/models';
import { UserService as CurrentUserService } from 'services/user.service';
import { PreparationService } from '../../preparation/services/preparation.service';
import { RecipeService } from '../services/recipe.service';
import {
  ChangePreparationInternalInformationCommand,
  ChangeRecipeTypeCommand,
  IncludePreparationAdditionalInformationCommand,
  IncludePreparationContainerCommand,
  IncludePreparationInternalInformationCommand,
  IncludePreparationSummaryCommand,
  PreparationCellChangeCommand,
  PreparationInternalInformationChangedResponse,
  RecipeAddPreparationCommand,
  RecipePreparationCellChangeCommand,
  RecipePreparationChangeInternalInformationCommand,
  RecipeRemovePreparationCommand,
  RecipeState,
  RecipeType
} from '../../api/cookbook/models';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { PreparationEventService } from '../../preparation/services/preparation-event.service';
import { ContainerGridData, NewPreparationsSubmission, Preparation } from '../../preparation/models/preparation.model';
import { PreparationsService } from '../../api/cookbook/services';
import { Activity } from '../../model/experiment.interface';
import { DataValueService } from '../../experiment/services/data-value.service';
import { v4 as uuid } from 'uuid';
import { StatusBar } from 'bpt-ui-library/bpt-grid/model/status-bar.interface';
import { PreparationItem } from '../../preparation/models/preparation-presentation.model';
import { RecipePreparationService } from './services/recipe-preparations.service';
import { elnDecodeSpecialChars } from '../../shared/url-path-serializer';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { ClientStateService } from '../../services/client-state.service';
import { cloneDeepWith } from 'lodash-es';

@Component({
  selector: 'app-recipe-preparations',
  templateUrl: './recipe-preparations.component.html',
  styleUrls: ['./recipe-preparations.component.scss']
})
export class RecipePreparationsComponent extends BaseComponent implements OnInit, OnDestroy {
  preparations: Array<PreparationItem> = [];
  subscriptions: Subscription[] = [];
  statusBar: StatusBar = {
    showTotalRowCount: true,
    showSelectedRowsCount: false,
    statusPanels: []
  };
  disableCreatePreparation = false;
  recipeReferencedTypeChange = false;
  disableGrid = false;
  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly userApiService: UserService,
    clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly preparationService: PreparationService,
    private readonly recipePreparationService: RecipePreparationService,
    private readonly recipeService: RecipeService,
    public readonly preparationEventService: PreparationEventService,
    private readonly preparationsService: PreparationsService,
    private readonly dataValueService: DataValueService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super(clientStateService, route);
    this.preparationService.ParentItemType = 'Recipe';
    this.mapRemovedPreparation();
  }

  ngOnInit(): void {
    const pickLists = this.preparationService.getPickListsForPreparation();
    this.subscriptions.push(pickLists.storageCondition.subscribe((storageConditionData) => {
      this.preparationService.storageOptions = storageConditionData.items;
      this.watchForActivityTitleDeterminationThroughRoute(this.route);
      this.loadDataSource();
    }));
    this.handleSubscriptions();
    this.checkRecipeState();
    this.getUsersWithRequiredRoles(['ELN Recipe Designer']);
    this.createPreparations();
    this.removePreparationInformation();
    this.updatePreparations();
    this.cellChangedNotification();
    this.changePreparationInternalInformation();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  private handleSubscriptions() {
    this.subscriptions.push(
      this.recipeService.recipeWorkFlowState.subscribe({ next: () => this.checkRecipeState() }),
      this.recipeService.isRecipeUsersChanged.subscribe({ next: () => this.checkRecipeState() }),
      this.recipeService.handleRecipeError.subscribe({
        next: (value) => {
          this.recipeReferencedTypeChange = value;
          this.checkRecipeState();
        }
      }),
      this.recipeService.recipeLoaded.subscribe({ next: (_) => this.loadDataSource() })
    );
  }

  /*
    * This Method will be triggered when there is change in route ,basically written to
    * handle when two different activities contains preparations and preparations should change when route changes.
    */
  public watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    this.subscriptions.push(route.params.subscribe((params) => {
      const currentActivityTitle = elnDecodeSpecialChars(params['itemTitle']);
      const activity = this.recipeService.currentRecipe?.activities.find(
        (activity) => activity.itemTitle === currentActivityTitle
      );
      if (activity) {
        this.recipeService.currentActivityId = activity.activityId;
        this.loadDataSource();
      }
    }));
  }

  /*Helps edit internal information from user input*/
  changePreparationInternalInformation() {
    this.subscriptions.push(this.preparationEventService.preparationInternalInformationChangedData.subscribe({
      next: (preparationInternalInformationChangedEvent) => {
        const internalInformationChangedCommand = this.buildChangePreparationInternalInformationCommand(preparationInternalInformationChangedEvent);
        internalInformationChangedCommand.command.preparedBy = this.currentUserService.currentUser.puid;
        this.changeInternalInformation(internalInformationChangedCommand);
      }
    }
    ))
  }

  /*Helps build change preparation internal information command from user input*/
  buildChangePreparationInternalInformationCommand(changeInternalInfoCommand: ChangePreparationInternalInformationCommand): RecipePreparationChangeInternalInformationCommand {
    const changeInternalInformationCommand: RecipePreparationChangeInternalInformationCommand = {
      recipeId: this.recipeService.currentRecipeId,
      activityId: this.currentActivityId ?? null,
      nodeId: (!this.currentActivityId || this.currentActivityId.trim() === '') ? this.recipeService.currentRecipeId : this.currentActivityId,
      command: changeInternalInfoCommand
    }
    return changeInternalInformationCommand;
  }

  private buildAdditionalInformationData(currentPreparation: PreparationItem | undefined, response: PreparationInternalInformationChangedResponse) {
    if (currentPreparation?.additionalInformation) {
      currentPreparation.additionalInformation.analysis = response.analysis !== null ? response.analysis : currentPreparation.additionalInformation.analysis;
      currentPreparation.additionalInformation.client = response.client !== null ? response.client : currentPreparation.additionalInformation.client;
      currentPreparation.additionalInformation.project = response.project !== null ? response.project : currentPreparation.additionalInformation.project;
      currentPreparation.additionalInformation.method = response.method !== null ? response.method : currentPreparation.additionalInformation.method;
      currentPreparation.additionalInformation.storedBy = response.storedBy !== null ? response.storedBy : currentPreparation.additionalInformation.storedBy;
      currentPreparation.additionalInformation.subBusinessUnit = response.subBusinessUnit !== null ? response.subBusinessUnit : currentPreparation.additionalInformation.subBusinessUnit;
      currentPreparation.additionalInformation.preparedBy = response.preparedBy;
    }
  }

  updateInternalInformation(currentPreparation: PreparationItem | undefined, response: PreparationInternalInformationChangedResponse) {
    if (currentPreparation?.internalInformation) {
      const info = currentPreparation.internalInformation;
      info.disposal = response.disposal ?? info.disposal;
      info.hazards = response.hazards ?? info.hazards;
      info.additionalLabel = response.additionalLabel ?? info.additionalLabel;
      info.originalQuantity = response.originalQuantity ?? info.originalQuantity;
      info.stability = response.stability ?? info.stability;
      info.storageLocation = response.storageLocation ?? info.storageLocation;
      info.allowScalingUp = response.allowScalingUp ?? info.allowScalingUp;
      info.allowScalingDown = response.allowScalingDown ?? info.allowScalingDown;
    }
  }

  /*Helps save the edited Internal Information to cosmos*/
  private changeInternalInformation(changeInternalInfoCommand: RecipePreparationChangeInternalInformationCommand) {
    this.subscriptions.push(
      this.preparationsService.preparationsChangeInternalInformationPost$Json({
        body: changeInternalInfoCommand
      }).subscribe({
        next: (response: PreparationInternalInformationChangedResponse) => {
          const currentPreparation = this.preparations.find(preparation => preparation.preparationId === response.preparationId);
          this.buildAdditionalInformationData(currentPreparation, response);
          this.updateInternalInformation(currentPreparation, response);
        }
      })
    );
  }

  /**
   * This method used to render all the preparation related to respective activity.
   */
  loadDataSource() {
    const currentActivity = this.recipeService.currentRecipe?.activities.find(act => act.activityId === this.recipeService.currentActivityId);
    if (currentActivity) {
      if (currentActivity.preparations) {
        this.preparations = [...currentActivity.preparations];
        this.cdr.detectChanges();
      }
    }
    else {
      this.preparations = [...this.recipeService.currentRecipe.orphan.preparations];
      this.cdr.detectChanges();
    }
  }

  private checkRecipeState() {
    this.disableCreatePreparation = !(
      this.recipeService.currentRecipe.tracking.state === RecipeState.Draft &&
      this.recipeService.currentRecipe.tracking.assignedEditors.includes(
        this.currentUserService.currentUser.puid
      ) && !this.recipeReferencedTypeChange
    );
    this.preparationService.isSliderDisabled = this.disableCreatePreparation;
    this.disableGrid = this.disableCreatePreparation;
    this.preparationService.isUserAllowedToDelete = !this.disableCreatePreparation;
  }

  get currentActivity() {
    return this.recipeService.currentRecipe.activities.find((activity) => activity.activityId === this.recipeService.currentActivityId) as Activity;
  }

  get currentActivityId() {
    return this.currentActivity?.activityId?.toString();
  }

  /* Get all users with the respective user role(ELN Analyst)
  and populates the variable currentUserService.usersWithAnalystRoles */
  private getUsersWithRequiredRoles(roles: string[]) {
    this.userApiService
      .usersActiveUsersPost$Json({
        body: roles
      })
      .subscribe({
        next: (userList: User[]) => {
          userList = userList.sort((firstValue, secondValue) =>
            firstValue.fullName.trim().localeCompare(secondValue.fullName.trim())
          );
          this.currentUserService.setUsersListBasedOnRoles('ELN Recipe Designer', userList);
        }
      });
  }

  createPreparations() {
    this.subscriptions.push(this.preparationEventService.newPreparationsSubmitData.subscribe({
      next: (newPreparationSubmittedEvent: NewPreparationsSubmission) => {
        const createdPreparations = this.buildCreatePreparationCommand(newPreparationSubmittedEvent.data);
        if (createdPreparations.additionalInformation) {
          createdPreparations.additionalInformation.preparedBy = this.currentUserService.currentUser.puid;
          createdPreparations.additionalInformation.subBusinessUnit = this.currentUserService.currentUser.primarySubBusinessUnit;
          if (createdPreparations.internalAdditionalInformation?.storageLocation) {
            createdPreparations.additionalInformation.storedBy = this.currentUserService.currentUser.puid;
          }
        }
        this.savePreparationInformation(createdPreparations, newPreparationSubmittedEvent);
      }
    }))
  }

  buildCreatePreparationCommand(preparation: Preparation): RecipeAddPreparationCommand {
    const preparationCommand: RecipeAddPreparationCommand = {
      recipeId: this.recipeService.currentRecipe.recipeId,
      activityId: this.currentActivityId ?? null,
      summary: this.buildSummary(preparation),
      containers: this.buildContainers(preparation),
      additionalInformation: this.buildAdditionalInformation(preparation),
      internalAdditionalInformation: this.buildInternalInformation(preparation),
      expirationValue: {
        expirationDateValue: preparation.expiration.expirationDate
      }
    }
    return preparationCommand;
  }

  buildSummary(preparation: Preparation): IncludePreparationSummaryCommand {
    const summaryCommand: IncludePreparationSummaryCommand = {} as IncludePreparationSummaryCommand;
    if (preparation?.concentration?.state !== ValueState.Empty) {
      summaryCommand.concentration = preparation?.concentration;
    } else {
      summaryCommand.concentration = {
        type: ValueType.Number,
        state: ValueState.NotApplicable
      }
    }
    if (preparation?.formulationOrComponents?.state !== ValueState.Empty) {
      summaryCommand.formulaComponents = preparation?.formulationOrComponents;
    } else {
      summaryCommand.formulaComponents = {
        type: ValueType.String,
        state: ValueState.NotApplicable
      }
    }
    if (preparation?.storageCondition?.state !== ValueState.Empty)
      summaryCommand.storageCondition = preparation?.storageCondition;
    return summaryCommand;
  };

  buildContainers(preparation: Preparation): Array<IncludePreparationContainerCommand> {
    return this.setUpContainers(preparation.containers, preparation.name?.value);
  }

  setUpContainers(gridData?: Array<ContainerGridData>, preparationName?: string): IncludePreparationContainerCommand[] {
    const containers: Array<IncludePreparationContainerCommand> = [];
    if (!gridData) {
      return containers;
    }
    gridData.forEach((container) => {
      const containerData: IncludePreparationContainerCommand = {} as IncludePreparationContainerCommand;
      containerData.preparationId = uuid();
       if (preparationName?.trim() !== '' &&
        preparationName !== undefined) {
          const preparationNameAlongWithTag = `${preparationName} ${container.tags ?? '' }`;
          containerData.name = this.dataValueService.getExperimentDataValue(ColumnType.String,preparationNameAlongWithTag?.trim());
      }
      const quantityDataValue = container.originalQuantity ? this.dataValueService.getExperimentDataValue(FieldType.Quantity, container.originalQuantity as any) : null;
      if (quantityDataValue) {
        containerData.originalQuantity = quantityDataValue;
      }
      containerData.containerDescription = container.containerDescription;
      containers.push(containerData);
    });
    return containers;
  }

  buildAdditionalInformation(preparation: Preparation): IncludePreparationAdditionalInformationCommand {
    const additionalInformation: IncludePreparationAdditionalInformationCommand = {
      analysis: preparation.additionalInformation?.analysis,
      client: preparation.additionalInformation?.client,
      compendia: preparation.additionalInformation?.compendia,
      method: preparation.additionalInformation?.method,
      preparedBy: preparation.additionalInformation?.preparedBy as string,
      project: preparation.additionalInformation?.project,
      discardedOrConsumed: preparation.additionalInformation?.discardedOrConsumed
    };
    return additionalInformation;
  }

  buildInternalInformation(preparation: Preparation): IncludePreparationInternalInformationCommand {
    const internalInformation: IncludePreparationInternalInformationCommand = {
      disposal: preparation.preparationInternalInformation?.disposal,
      hazards: preparation.preparationInternalInformation?.hazards,
      stability: preparation.preparationInternalInformation?.stability,
      storageLocation: preparation.preparationInternalInformation?.storageLocation,
      additionalLabel: preparation.preparationInternalInformation?.additionalLabel,
      allowScalingUp: preparation.preparationInternalInformation?.allowScalingUp,
      allowScalingDown: preparation.preparationInternalInformation?.allowScalingDown
    }
    return internalInformation;
  }

  private savePreparationInformation(command: RecipeAddPreparationCommand, submittedPreparation: NewPreparationsSubmission) {
    this.subscriptions.push(this.preparationsService
      .preparationsCreatePreparationPost$Json$Response({
        body: command
      }).subscribe({
        next: (data: any) => {
          submittedPreparation.closeHandler();
          const preparationData = this.recipePreparationService.buildPresentationModel(data.body).preparations;
          const currentActivity = this.recipeService.currentRecipe?.activities.find(act => act.activityId === this.recipeService.currentActivityId);
          const existingPreparation = cloneDeepWith(this.preparations);
          existingPreparation.push(...preparationData);
          this.preparations = existingPreparation;
          if (currentActivity) {
            if (currentActivity.preparations) {
              currentActivity.preparations.push(...preparationData);
            }
            else {
              currentActivity.preparations = preparationData;
            }
          }
          else {
            this.recipeService.currentRecipe.orphan.preparations = this.preparations;
            //update recipe type
            if (this.recipeService.currentRecipe.type === RecipeType.None) {
              var changeRecipeTypeCommand = {
                recipeId: this.recipeService.recipe.recipeId,
                recipeType: RecipeType.ReferencePromptPreparation
              } as ChangeRecipeTypeCommand
              this.recipeService.changeType(changeRecipeTypeCommand).subscribe((type) =>{
                  this.recipeService.currentRecipe.type = type;
              });
            }
          }
          if (submittedPreparation.toasterMessage)
            this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', submittedPreparation.toasterMessage, false);
        }
      }));
  }

  mapRemovedPreparation() {
    this.subscriptions.push(this.recipePreparationService.preparationRemovalResponse().subscribe((preparationRemovedResponse) => {
      const currentActivity = this.recipeService.currentRecipe?.activities.find(act => act.activityId === this.recipeService.currentActivityId);
      if (currentActivity && currentActivity.preparations) {
        this.preparations = currentActivity.preparations.map(preparation => {
          if (preparation.preparationId === preparationRemovedResponse.preparationId) {
            preparation.isRemoved = true;
          }
          return preparation
        })
      }
      else {
        this.preparations && this.preparations.map(preparation => {
          if (preparation.preparationId === preparationRemovedResponse.preparationId) {
            preparation.isRemoved = true;
          }
          return preparation
        })
      }
      this.cdr.detectChanges();
      const preparationRemovedSuccessMessage = $localize`:@@preparationRemovedSuccessMessage:The Preparation has been removed successfully`
      this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', preparationRemovedSuccessMessage, false)
      this.loadDataSource();
      this.preparationEventService.removePreparationSuccessNotification(true);
    }));
  }

  /*m /** Builds the cell changed command based on user entered values*/
  buildCellChangedCommand(preparationCellChangeCommand: PreparationCellChangeCommand): RecipePreparationCellChangeCommand {
    const recipePreparationCellChangeCommand: RecipePreparationCellChangeCommand = {
      recipeId: this.recipeService.currentRecipeId,
      activityId: this.currentActivityId ?? null,
      nodeId: (!this.currentActivityId || this.currentActivityId.trim() === '') ? this.recipeService.currentRecipeId : this.currentActivityId,
      command: preparationCellChangeCommand,
    }
    return recipePreparationCellChangeCommand;
  }

  public updatePreparations() {
    this.subscriptions.push(this.preparationEventService.preparationCellChanged.subscribe(
      ({preparationCellChangeCommand}) => {
        this.recipePreparationService.updatePreparation(this.buildCellChangedCommand(preparationCellChangeCommand));
      }))
  }

  removePreparation(preparationToBeRemoved: RecipeRemovePreparationCommand) {
    this.recipePreparationService.removePreparation(preparationToBeRemoved);
  }

  /* Helps to remove preparation information to the cosmos */
  private removePreparationInformation() {
    this.subscriptions.push(this.preparationEventService.preparationRemoved.subscribe(
      preparationRemovedEvent => {
        this.removePreparation(this.buildRemoveRestorePreparationCommand(preparationRemovedEvent));
      }))
  }

  buildRemoveRestorePreparationCommand(preparationId: string): RecipeRemovePreparationCommand {
    return {
      recipeId: this.recipeService.currentRecipeId,
      nodeId: (!this.currentActivityId || this.currentActivityId.trim() === '') ? this.recipeService.currentRecipeId : this.currentActivityId,
      preparationId: preparationId
    }
  }


  cellChangedNotification() {
    this.subscriptions.push(this.recipePreparationService.preparationCellChanged().subscribe((preparationCellChangedResponse) => {
      if (preparationCellChangedResponse) {
        const preparationCellChangedSuccessMessage = $localize`:@@preparationCellChangedSuccessMessage:The cell value has been updated successfully for Preparation`
        this.preparationService.buildToastMessage('notification', 'success', 'addSuccessToast', preparationCellChangedSuccessMessage, false)
      }
    }));
  }


}
