export class IdentifierFunctions {
  /**
   * Compares an "identifier", which is characters optionally followed by hyphen digits optionally followed by colon and suffix letters.
   *
   * Examples:
   *  Sample ZQ23AA0004-2
   *  Sample Aliquot ZQ23AA0004-2:a
   */
  public static compareIdentifier(a: string, b: string): number {
    //TODO test and code for the different identifers in # 3287400
    const regex = /^(?<root>[^-]*)(-(?<digits>[^:]*)(:(?<suffix>.*))?)?$/;
    const aParts = a.match(regex)?.groups ?? {};
    const bParts = b.match(regex)?.groups ?? {};
    return aParts.root.localeCompare(bParts.root)
      || IdentifierFunctions.compareDigitString(aParts.digits, bParts.digits)
      || IdentifierFunctions.compareLetterSuffix(aParts.suffix, bParts.suffix);
  }

  /**
   * Compares strings of digits, parsed as numbers.
   */
  public static compareDigitString(a: string, b: string): number {
    return parseInt(a) - parseInt(b);
  }

  /**
   * Compares strings in shortlex order: "z" comes before "yy"
   */
  public static compareLetterSuffix(a: string, b: string): number {
    return (a.length - b.length) || a.localeCompare(b);
  }
}
