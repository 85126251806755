<div class="eln-tree">
  <label class="mt-3 mb-3" i18n="@@tableDesignerItemAsOptionTitle">Items as options</label>
  <bpt-tree
    class="mb-3"
    name="itemsAsOptions"
    inputId="eln-table-designer-Items-as-options-tree"
    [treeData]="availableTreeNodes"
    [selectedNode]="selectedTreeNodes"
    selectionMode="checkbox"
    scrollHeight="100%"
    [ariaReadOnly]="readOnlyState"
    (nodeSelectClick)="nodeSelected($event)"
    (nodeUnselectClick)="nodeUnselected($event)">
  </bpt-tree>
</div>
