import { Component, Input, ViewChild, OnChanges, OnDestroy, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { CellLockDetails, CellLockRequest, ExpirationModel, ExpirationType, PreparationAuditHistoryInputs } from './models/preparation.model';
import { BptGridCellValueChangedEvent, BptGridComponent, BptGridMode, BptGridPreference, BptGridPreferences, BptGridRowActionClickEvent, BptGridRowActionConfiguration, BptRowActionElement, CellClickedEvent, ColumnDefinition, DropdownCellEditorParamsDefaults, GridContextMenuItem, IFlagConfig, ISeverityIndicatorConfig, PreferenceAdded, PreferenceDeleted, PreferenceSelectionChanged, PreferenceUpdated } from 'bpt-ui-library/bpt-grid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PreparationGridPreferences, PreparationService } from './services/preparation.service';
import { UnitLoaderService } from '../services/unit-loader.service';
import { ClientFacingNoteContextType, ColumnSpecification, ColumnType, ExperimentPreparationStatus, ModifiableDataValue, ValueState, ValueType } from '../api/models';
import { PreparationItem } from './models/preparation-presentation.model';
import { UnsubscribeAll } from '../shared/rx-js-helpers';
import { PreparationEventService } from './services/preparation-event.service';
import { TimeSelectOption } from 'bpt-ui-library/bpt-datetime';
import { PreparationConstants } from './preparation-constants';
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-enterprise';
import { DialogService } from 'primeng/dynamicdialog';
import { RemovedPreparationsComponent } from './removed-preparations/removed-preparations.component';
import { UserService } from 'services/user.service';
import { ChangePreparationInternalInformationCommand, PreparationCellChangeCommand } from '../api/data-entry/models';
import { isEqual } from 'lodash-es';
import { LocalDate } from '@js-joda/core';
import { DateAndInstantFormat, formatInstant, formatLocalDate } from '../shared/date-time-helpers';
import { TableDataForCompletionTracking } from '../experiment/model/cell-data-for-completion-tracking.interface';
import { CommentContextType } from '../api/internal-comment/models';
import { StatusBar } from 'bpt-ui-library/bpt-grid/model/status-bar.interface';
import { ClientFacingNoteRequest, FlagConfigRequest, InternalCommentsRequest } from './models/comments.model';
import { PreparationTableDataChangeContext } from './models/tracking.model';
import { CellLock, LockType } from '../model/input-lock.interface';
import { BptGridCellEditEvent } from 'bpt-ui-library/bpt-grid/model/bpt-grid-cell-edit-event.interface';
import { NA } from 'bpt-ui-library/shared';
import { ChangeReasonService } from '../experiment/services/change-reason.service';
import { ExperimentNotificationService } from '../services/experiment-notification.service';
export enum PreparationStatus {
  Pending,
  Reviewed
}
export interface PreparationRow {
  rowIndex: number,
  id: string,
  preparationNumber: string,
  name: ModifiableDataValue,
  formulaComponents?: ModifiableDataValue,
  status: ExperimentPreparationStatus,
  expiration?: ModifiableDataValue,
  storageCondition?: ModifiableDataValue,
  concentration?: ModifiableDataValue,
  description?: ModifiableDataValue,
  source?: ModifiableDataValue
}

@Component({
  selector: 'app-preparations',
  templateUrl: './preparations.component.html',
  styleUrls: ['./preparations.component.scss']
})
export class PreparationsComponent implements OnChanges, OnDestroy, OnInit {
  isParentRecipe: boolean;
  private readonly isRecipePrepCellEditable = (params : EditableCallbackParams) => {
    return this.isParentRecipe && params.data.source !== NA ? false : !this.disableGrid;
  };
  getColumnDefinitions(): ColumnDefinition[] {
    return [
      {
        label: $localize`:@@Index:Index`,
        field: 'rowIndex',
        columnType: ColumnType.Index,
        hidden: true,
        showInColumnChooser: false,
        editable: false,
        suppressContextMenu: true
      },
      {
        field: 'autoIncrement',
        label: '',
        columnType: ColumnType.AutoIncrement,
        width: 20,
        minWidth: 50,
        maxWidth: 100,
        editable: false,
        alwaysHidden: this.isParentRecipe,
        suppressContextMenu: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        showInColumnChooser: false,
        allowMultiSelect:true,
        pinned:'left'
      },
      {
        field:  this.keyNameOf<PreparationRow>('id'),
        columnType: ColumnType.RowId,
        label: $localize`:@@preparationRowId:Row ID`,
        cellClass: 'bpt-row-id-cell',
        editable: false,
        alwaysHidden: false,
        hidden: true,
        suppressContextMenu: true
      },
      {
        field: this.keyNameOf<PreparationRow>('preparationNumber'),
        label: $localize`:@@preparationId:Preparation ID`,
        width: PreparationConstants.widthMedium,
        minWidth: PreparationConstants.widthMedium,
        cellClass: 'cell-Link',
        editable: false,
        sort: 'asc',
        showInColumnChooser: false,
        filterParams: {
          filterOptions: ['contains']
        },
      },
      {
        field: this.keyNameOf<PreparationRow>('name'),
        label: $localize`:@@name:Name`,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        columnType: ColumnType.String,
        showInColumnChooser: false,
        editable: this.isRecipePrepCellEditable,
        valueSetter: (params: any) => {
          const newValue = params.newValue?.trim();
          if (!newValue.length || newValue.toUpperCase() === 'N/A') {
            params.data.name = params.oldValue;
            const preparationNameValidationMessage = $localize`:@@NamePreparatonValidation:Name field cannot be made empty or cannot be set to N/A`;
            this.preparationService.buildToastMessage('notification', 'error', 'validationToast', preparationNameValidationMessage, false)
            return false
          }
          else {
            params.data.name = params.newValue;
            return true
          }
        }
      },
      {
        field: this.keyNameOf<PreparationRow>('source'),
        label: $localize`:@@Source:Source`,
        columnType: ColumnType.String,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        allowNA: false,
        editable: false,
        hidden: true,
        showInColumnChooser: this.isParentRecipe,
        valueSetter: (params: any) => {
          params.data.name = params.newValue?.trim();
          return true;
        }
      },
      {
        field: this.keyNameOf<PreparationRow>('formulaComponents'),
        label: $localize`:@@Formulation/Components:Formulation/Components`,
        columnType: ColumnType.String,
        editable: this.isRecipePrepCellEditable,
      },
      {
        field: this.keyNameOf<PreparationRow>('status'),
        label: $localize`:@@Status:Status`,
        columnType: this.isParentRecipe ? ColumnType.String : ColumnType.List,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        editable: (params: EditableCallbackParams) => this.isParentRecipe ? false : this.isStatusCellEditable(params),
        dropdownEditorConfiguration: {
          ...DropdownCellEditorParamsDefaults,
          ...{
            allowCustomOptionsForDropdown: false,
            labelField: 'label',
            valueField: 'value',
            group: false,
            options: this.getOptions(),
            allowNA: false,
            dropdownVisible: (e: BptGridCellEditEvent) => this.lockStatusCell(e.rowId ?? '')
          },
        },
        valueSetter: (params: any) => {
          if(this.isParentRecipe){
            params.data.status = "";
            return false;
          }
          const currentPreparation = this.preparations.find(preparation => preparation.preparationId === params.data.id);
          if (!currentPreparation?.internalInformation?.disposal && params.newValue === 'Reviewed') {
            params.data.status = params.oldValue;
            this.releaseLockFromStatusCell(params.data.id);
            this.preparationService.buildToastMessage('notification', 'error', 'validationToast', PreparationConstants.preparationDisposalNotSetMessage, false);
            return false
          }
          else if(params.newValue === 'Invalid') {
            this.suppressCellEditStopped = true;
            let returnValue = false;
            this.confirmationServiceObject.accept = () => {
              this.suppressCellEditStopped = false;
              this.releaseLockFromStatusCell(params.data.id);
              params.data.status = "Invalid";
              const statusChangedCommand = {
                preparationId: params.data.id,
                status: ExperimentPreparationStatus.Invalid
              }
              this.preparationEventService.preparationStatusChangedEvent(statusChangedCommand);
              this.grid.gridApi.refreshCells();
              returnValue = true;
            }
            this.confirmationServiceObject.reject = () => {
              this.suppressCellEditStopped = false;
              this.releaseLockFromStatusCell(params.data.id);
              returnValue = false;
            }
            this.preparationService.confirmationService.confirm(this.confirmationServiceObject);
            return returnValue;
          }
          else {
            params.data.status = params.newValue;
            const statusChangedCommand = {
              preparationId: params.data.id,
              status: params.newValue
            }
            this.preparationEventService.preparationStatusChangedEvent(statusChangedCommand);
            this.releaseLockFromStatusCell(params.data.id);
            return true;
          }
        }
      },
      {
        field: this.keyNameOf<PreparationRow>('expiration'),
        label: $localize`:@@Expiration:Expiration`,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        columnType: ColumnType.String,
        allowTimeSelect: TimeSelectOption.Optional,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        valueGetter: (params) => {
          const expiration = params.data.expiration;
          //Recipe Preparations have a little different requirement, N/A denotes suitableForUseText and undefined denotes basedOnStability
          if(this.isParentRecipe){
            return expiration === "N/A" ? PreparationConstants.suitableForUseText : expiration === undefined ? PreparationConstants.basedOnStability : '';
          }
          if (expiration === "N/A" || expiration === undefined) {
            return PreparationConstants.suitableForUseText;
          }
          else {
            if (expiration instanceof LocalDate) {
              return formatLocalDate(expiration);
            }
            else {
              return formatInstant(expiration, DateAndInstantFormat.dateTimeToMinute);
            }
          }
        },
        onCellClicked: (event: CellClickedEvent) => {
          this.sendExpirationToSlider(event);
        },
        editable: false
      },
      {
        field: this.keyNameOf<PreparationRow>('storageCondition'),
        label: $localize`:@@StorageCondition:Storage Condition`,
        columnType: ColumnType.EditableList,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        editable: this.isRecipePrepCellEditable,
        dropdownEditorConfiguration: {
          ...DropdownCellEditorParamsDefaults,
          ...{
            allowCustomOptionsForDropdown: true,
            editable: this.isRecipePrepCellEditable,
            labelField: 'label',
            valueField: 'value',
            group: false,
            options: this.preparationService.storageOptions,
            allowNA: false,
            dropdownVisible: () => { }
          }
        },
        valueSetter: (params: any) => {
          const newValue = params.newValue?.trim();
          if (!newValue.length || newValue.toUpperCase() === 'N/A') {
            params.data.storageCondition = params.oldValue;
            const preparationStorageValidationMessage = $localize`:@@StorageConditionPreparatonValidation:Storage Condition field cannot be made empty or cannot be set to N/A`;
            this.preparationService.buildToastMessage('notification', 'error', 'validationToast', preparationStorageValidationMessage, false)
            return false;
          }
          else {
            params.data.storageCondition = params.newValue;
            return true;
          }
        }
      },
      {
        field: this.keyNameOf<PreparationRow>('concentration'),
        label: $localize`:@@Concentration:Concentration`,
        columnType: ColumnType.Quantity,
        width: PreparationConstants.widthSmall,
        minWidth: PreparationConstants.widthSmall,
        allowNA: true,
        allowNegative: true,
        allowDecimal: true,
        editable: this.isRecipePrepCellEditable,
        allowedUnits: this.unitLoaderService.allUnits.filter((unit) => unit.dimension === 'Mass/Volume'),
        showUnitNameInList: true,
        enableSignificantFigures: true
      },
      {
        field: this.keyNameOf<PreparationRow>('description'),
        width: PreparationConstants.widthLarge,
        minWidth: PreparationConstants.widthLarge,
        columnType: ColumnType.String,
        label: $localize`:@@containerDescription:Container Description`,
        valueGetter: (params: any) => {
          if (params.data.description.state === ValueState.Set) {
            return this.preparationService.buildContainerDescription(params.data.description);
          }
          return "N/A";
        },
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        editable: false,
      }
    ];
  }
  subscriptions: Subscription[] = [];
  @Input() readOnly = false;
  reloadGrid = true;
  @Input() preparations: PreparationItem[] = [];
  @Input() isStatusColumnEditable = false;
  @Input() hideReviewedByOption = false;
  optionsForStorageCondition: any[] = [];
  /* The flag to disable or enable create preparation button */
  @Input() disableCreatePreparation = false;
  @Input() initialCellLockDetails?: CellLockDetails[] = [];
  @Input() disableRemoveAndRestorePreparation = true;
  @Input() disablePreparationActions = false;
  @Input() disableGrid = false;
  @Input() currentActivityId? = '';
  private _statusBar: StatusBar = {
    statusPanels :[]
  };
  // This statusBar now made as optional property
  @Input() set statusBar(value: StatusBar) {
      this._statusBar = value;
  }
  get statusBarGetter(): StatusBar {
    return this._statusBar;
  }

  @Input() flagConfigurationProvider? : (flagConfigRequest: FlagConfigRequest) => IFlagConfig;
  @Input() completionTrackHandler : (changeContext: PreparationTableDataChangeContext) => number = () => {
    return 100;
  };

  @Output() internalCommentsRequest = new EventEmitter<InternalCommentsRequest>();
  @Output() clientFacingNoteRequest = new EventEmitter<ClientFacingNoteRequest>();
  @Output() cellLockStatus = new EventEmitter<CellLockRequest>();

  showSlider = false;
  internalInfoSliderReadonly = false;
  containsRemovedRows = false;
  youDoNotHaveAnyPreparationBanner = $localize`:@@YouDontHaveAnyPreparation:YOU DON'T HAVE ANY PREPARATIONS.`;
  yourPreparationWillAppearHereBanner = $localize`:@@yourPreparationWillAppearHere: Your Preparation will appear here.`;
  preparationModuleHeader = $localize`:@@preparationModuleHeader:Preparations`;
  preparationsOptions = {
    buttonLabel: $localize`:@@createPreparationForSlider:✛ Create preparation`
  }
  private readonly keyNameOf = <T>(name: Extract<keyof T, string>): string => name;
  @ViewChild('preparationsGrid') grid!: BptGridComponent;


  gridActions!: BptGridRowActionConfiguration;
  primitiveValue!: { [key: string]: any }[];
  confirmationServiceObject = {
    message: PreparationConstants.confirmMoveToInvalid,
    icon: 'icon-exclamation',
    header: $localize`:@@ConfirmationHeader:Confirmation`,
    closeOnEscape: true,
    dismissableMask: false,
    acceptVisible: true,
    acceptLabel: $localize`:@@ok:OK`,
    acceptIcon: 'icon-check icon-m',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    rejectVisible: true,
    rejectLabel: $localize`:@@confirmationCancel:Cancel`,
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    rejectIcon: 'icon-x icon-m',
    accept: () => {},
    reject: () => {}
  };
  gridOptions = {
    includeRowNumberColumn: true,
    showAutoSizeButton: true,
    showFilterToggleButton: true,
    showGridOptionsButton: true,
    allowRowAdd: false,
    gridMode: BptGridMode.dataEntry,
  }
  columnDefinitions: ColumnDefinition[] = this.getColumnDefinitions();
  preparationStatusOptionsWhenNotInReview = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Invalid', value: 'Invalid' }
  ];
  preparationStatusOptionsWhenInReview = [
    ...this.preparationStatusOptionsWhenNotInReview,
    { label: 'Reviewed', value: 'Reviewed' }
  ];
  showInternalInfoSlider = false;
  lockTimeOut = 0;
  suppressCellEditStopped = false;
  preparationDataForInternalInformation!: PreparationItem;
  lastRowToBeDeleted = 1;
  preparationViewRemovedId = 'preparationRemovedId';
  preparationRowDeleteActionId = 'bpt-preparation-delete-row';
  preparationOpenContainerFlaskId = 'bpt-preparation-container-flask';
  preparationExpirationId = 'bpt-preparation-expiration'
  preparationInternalInformationId = 'bpt-preparation-internal-information-icon';
  indexOfCurrentFlaskIconClickedOnGrid = "";
  indexOfCurrentInformationIconClickedOnGrid = 0;
  indexOfCurrentDeletedIconClickedOnGrid = 0;
  noOfPreparationsInActivity = 0;
  showExpirationSlider = false;
  sourceGridData: ExpirationModel = { suitableForUse: false };
  indexOfCurrentFlaskIconClickedOnGridForUserEditRevert = '';
  completionPercent = 0;
  preparationsGridId = '';
  preparationsGridSavedPreferences: BptGridPreferences = {
    enabled: true,
    preferences: []
  };
  tableDataForCompletionTracking: TableDataForCompletionTracking = {
    tableId: this.preparationsGridId,
    tableTitle: 'Preparations'
  }
  private readonly columnNamesForCompletionTracking = ['name','formulaComponents','concentration','description','status','expiration','storageCondition'];

  constructor(private readonly preparationService: PreparationService,
    private readonly unitLoaderService: UnitLoaderService,
    private readonly preparationEventService: PreparationEventService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly renderer: Renderer2,
    private readonly experimentNotificationService: ExperimentNotificationService
  ) {
    this.isParentRecipe = preparationService.ParentItemType === 'Recipe';
    this.buildContainerDescription();
    this.watchRefreshGridNotification();
    this.watchSubscriptions();
    this.watchCollaborativeEditingSubscriptions();
  }

  //Lifecycle hooks and other supporting methods
  ngOnInit() {
    this.currentUserRoleActionAssignment();
    this.UpdateExpirationIfChanged();
  }

  ngOnChanges() {
    this.preparationsGridId = this.currentActivityId ? this.currentActivityId.concat("-Preparations") : 'preparations';
    this.completionPercent = 0;
    if (this.preparations.length > 0) {
      const unRemovedPreparations = this.preparations.filter(preparation => !preparation.isRemoved);
      this.preparationService.checkAllFieldsHaveValuesOnLoad(unRemovedPreparations);
      this.containsRemovedRows = !this.isParentRecipe && this.preparations.filter(preparation => preparation.isRemoved).length > 0;
      this.columnDefinitions = this.getColumnDefinitions();
      this.addGridActions();
      this.reloadGridAction();
      this.refreshGrid();
      this.noOfPreparationsInActivity = unRemovedPreparations.length;
    }
  }

  getOptions(): { label: string, value: string }[] {
    return this.hideReviewedByOption ? this.preparationStatusOptionsWhenNotInReview : this.preparationStatusOptionsWhenInReview;
  }

  reloadGridAction(delay = 200) {
    this.reloadGrid = false;
    setTimeout(() => {
      this.reloadGrid = true;
    }, delay);
  }


  onGridReady() {
    this.grid.gridApi.setGridOption('domLayout', 'normal');
    this.augmentColumnsWithCornerFlagProviderForCells();
    this.setValidationStyles();
    this.preparationService.loadGridPreferences(this.preparationsGridId);
  }

  onFirstDataRendered(e: any) {
    this.loadCellLocks();
  }

  private  augmentColumnsWithCornerFlagProviderForCells ()  {
    if(this.flagConfigurationProvider){
      const flagConfigProvider = (
        flag: 'top-right' | 'bottom-right',
        rowId: string,
        field: string
      ): IFlagConfig => {
        const id = flag ==='bottom-right' ? (this.primitiveValue.find((x: any) => x.id ===  rowId) as any).id : rowId;
        const fieldName = PreparationConstants.columnDisplayNames[field];
          return this.flagConfigurationProvider!({
            flag,
            fieldName,
            id,
            field,
            commentContext:  CommentContextType.Preparations,
            rowId
          })
        };
      this.columnDefinitions.forEach((c: ColumnDefinition) => {
        c.flagConfigProvider = flagConfigProvider;
      });
    }
  }

  getContextMenu(): GridContextMenuItem[] {
    return [
            {
        label: $localize`:@@clientFacingNoteContextMenuOption:Client-facing Notes`,
        action: () => {
          const cell = this.grid?.gridApi.getFocusedCell();
          if (cell) {
            const row = this.grid?.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const colDef = cell.column.getColDef();
            const field = colDef.field as string;
            if (
              row &&
              row.id &&
              colDef?.field
            ) {
              this.clientFacingNoteRequest.next({
                  paths: [
                    row.id,
                    field,
                    this.currentActivityId ?? '',
                    ClientFacingNoteContextType.Preparations
                  ],
                  contextType:  ClientFacingNoteContextType.Preparations,
                  nodeId: this.currentActivityId ?? ''
                }
              );
            }
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />',
        disabled: this.userService.hasOnlyReviewerRights() || this.disablePreparationActions
      },
      {
        label: $localize`:@@commentsHeader:Internal Comments`,
        action: () => {
          const cell =
            this.grid && this.grid.gridApi.getFocusedCell();
          if (cell) {
            const row =
              this.grid &&
              this.grid.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const field = cell.column.getColDef().field;
            const fieldName = field ? PreparationConstants.columnDisplayNames[field] : '';
            const currentRowIndex = cell.rowIndex + 1;
            if (row && currentRowIndex != null)
              this.internalCommentsRequest.next({
                  paths: [
                    this.currentActivityId ?? '',
                    fieldName,
                    row.id ?? '',
                    field ?? '',
                    currentRowIndex.toString(),
                    CommentContextType.Preparations
                  ],
                  contextType: CommentContextType.TableCell
                }
              );
          }
        },
        icon: '<img src="assets/eln-assets/apps-add.svg" class="ag-icon ag-custom-icon" />'
      }
    ];
  }

  currentUserRoleActionAssignment() {
    if (!this.userService.hasOnlyReviewerRights() && this.preparations.length > 0) {
      this.addGridActions();
    }
  }

  public severityIndicatorConfig = (
  ) => {
    return {
      getIndicator: this.getSeverityIndicator
    } as ISeverityIndicatorConfig;
  };

  setValidationStyles = (): void => {
    this.columnDefinitions?.forEach((columnDefinition: ColumnDefinition) => {
      columnDefinition.severityIndicatorConfig = this.severityIndicatorConfig;
      this.grid?.updateColumnDefinitions(columnDefinition);
    });
  };

  private refreshGrid() {
    this.primitiveValue = this.preparationService.getAllPreparationsFromCurrentActivity(this.preparations.filter(preparation => !preparation.isRemoved));
    this.primitiveValue = this.preparationService.getPrimitiveDataValueRows(this.primitiveValue as any);
    this.grid?.gridApi?.setGridOption('rowData', this.primitiveValue);
    this.completionPercent = this.completionTrackHandler({
        rows: this.primitiveValue,
        initialCompletionPercent:  this.completionPercent,
        columnDataFieldIds:  this.columnNamesForCompletionTracking,
        tableInfo: this.tableDataForCompletionTracking,
        isTable: false
      }
    );
    this.currentUserRoleActionAssignment();
  }

  addGridActions() {
    const rowActions = this.getRowActionItems();
    const actionsSubject: BehaviorSubject<BptRowActionElement[]> = new BehaviorSubject(rowActions);
    this.gridActions = {
      actions: actionsSubject
    };
  }
  /**
    * On hover on row it will add icons to grid
   */
  public getRowActionItems(): BptRowActionElement[] {
    return [
      {
        id: this.preparationOpenContainerFlaskId,
        enabled: true,
        styleClass: 'icon-beaker',
        click: this.openContainerDescriptionSlider.bind(this),
        tooltip: $localize`:@@FlaskIcon:Container Description Form`
      },
      {
        id: this.preparationInternalInformationId,
        enabled: true,
        styleClass: 'pi pi-info-circle',
        click: this.openInternalInformationSlider.bind(this),
        tooltip: $localize`:@@EditInternalInformation:Internal Information`,// Need to check on this tooltip
        tooltipPosition: 'bottom'
      },
      {
        id: this.preparationRowDeleteActionId,
        enabled: (input: ICellRendererParams) => {
          if(!this.preparationService.isUserAllowedToDelete) {
            return false
          }
          const data = input.data as PreparationRow;
          if (data.status?.toLocaleLowerCase() === ExperimentPreparationStatus.Reviewed) {
            return false;
          }
          if(this.isParentRecipe && input.data.source !== NA) return false;
          return true;
        },
        styleClass: 'pi pi-trash',
        click: this.openConfirmationSliderToDeleteRow.bind(this),
        tooltip: $localize`:@@deletePreparationRow:Remove Preparation`,
        tooltipPosition: 'bottom'
      },
    ];
  }

  private readonly getSeverityIndicator = (params: ICellRendererParams) => {
    return this.preparationService.getSeverityIndicatorDefinitionForPreparations(this.preparations, params);
  }

  //Subscriptions
  private watchSubscriptions() {
    this.subscriptions.push(
      this.preparationService.gridPreferencesReceived.subscribe({
        next: (gridPreferences) => this.applyGridPreference(gridPreferences)
      })
    );
  }

  public buildContainerDescription() {
    this.subscriptions.push(this.preparationEventService.preparationDescriptionChanged.subscribe(containerData => {
      const rowToUpdate = this.primitiveValue?.find(row => row.id === this.indexOfCurrentFlaskIconClickedOnGrid);
      if (!!rowToUpdate) {
        ChangeReasonService.oldValue = rowToUpdate.description;
      }
      const prepRow = this.preparations?.find(row => row.preparationId === this.indexOfCurrentFlaskIconClickedOnGrid);
      if (rowToUpdate && containerData && prepRow) {
        if (isEqual(containerData.value, rowToUpdate.description.value)) {
          return;
        }
        this.preparations.forEach(preparation => {
          if (preparation.preparationId === this.indexOfCurrentFlaskIconClickedOnGrid) {
            if (preparation.description) {
              preparation.description = { isModified: true, value: containerData };
            }
          }
        });
        prepRow.description.isModified = true;
        prepRow.description.value = containerData;
        rowToUpdate.description = containerData;
        this.grid?.gridApi.applyTransaction({ update: [rowToUpdate] });
        const preparationCellChangeCommand: PreparationCellChangeCommand = {
          preparationId: this.indexOfCurrentFlaskIconClickedOnGrid,
          propertyName: PreparationConstants.description, propertyValue: prepRow.description.value
        }

        this.preparationEventService.preparationDataFieldChangedEvent(preparationCellChangeCommand, rowToUpdate.description.value);
        this.indexOfCurrentFlaskIconClickedOnGrid = '';
      }
    }));
  }

  private watchRefreshGridNotification() {
    this.subscriptions.push(
      this.preparationEventService.refreshGrid.subscribe({
        next: () => {
          this.grid?.gridApi?.refreshCells({ force: true });
        }
      })
    );
  }

  private UpdateExpirationIfChanged() {
    this.subscriptions.push(this.preparationEventService.preparationExpirationChanged.subscribe(expiration => {
      if (expiration) {
        this.preparations.forEach(preparation => {
          if (preparation.preparationId && preparation.preparationId === expiration.preparationId) {
            if (preparation.expirationValue) {
              ChangeReasonService.oldValue = preparation.expirationValue.expirationDateValue!.value;
              preparation.expirationValue.expirationDateValue = { isModified: true, value: expiration.expirationDate };
              this.refreshGrid();
            }
            if (expiration.duration && this.isParentRecipe && preparation.internalInformation) {
              //Setting exact when copying duration from expiration
              expiration.duration.exact = true;
              preparation.internalInformation.stability = this.preparationService.getQuantityPrimitiveValue(expiration.duration);
              this.refreshGrid();
              const preparationInternalInformationCommand: ChangePreparationInternalInformationCommand = {
                preparationId: expiration.preparationId,
                preparedBy: preparation.additionalInformation?.preparedBy!, stability: preparation.internalInformation.stability
              }
              this.preparationEventService.preparationInternalInformationChangedEvent(preparationInternalInformationCommand);
            }
          }
        });
        this.updatePreparationDataField(expiration);
      }
    }));
  }

  private updatePreparationDataField(expiration: ExpirationType) {
    if (expiration.preparationId) {
      const preparationCellChangeCommand: PreparationCellChangeCommand = {
        preparationId: expiration.preparationId,
        propertyName: PreparationConstants.expirationValue,
        propertyValue: expiration.expirationDate
      };
      this.preparationEventService.preparationDataFieldChangedEvent(preparationCellChangeCommand, expiration.expirationDate);
    }
  }

  //Cell editing section
  cellValueChanged(e: BptGridCellValueChangedEvent) {
    this.preparationService.checkAllFieldsHaveValuesOnCellChange(this.grid);
    if (isEqual(e.newValue, e.oldValue)) return;
    //equality check for concentration to prevent modified icon

    if(!this.isModifiedIconNeeded(e)) return;
    if (
      e.source === 'collaborativeEdit' ||
      e.newValue === undefined ||
      e.source === 'systemFields'
    ) {
      return;
    }
    if (!e.gridId || !e.field || !e.rowId) {
      return;
    }
    let columnType = ColumnType.String;
    let isStatus = false;
    switch (e.field) {
      case 'concentration':
        columnType = ColumnType.Number;
        break;
      case 'status':
        isStatus = true;
        break;
      default:
        break;
    }
    const propertyValue = this.preparationService.getExperimentDataValue(columnType, e.newValue)
    if (e.rowId) {
      this.preparationService.syncCellValueToClientSite(this.preparations, e.rowId, isStatus ? e.newValue.toLowerCase() : propertyValue, e.field);
    }
    if (e.field === PreparationConstants.preparationConcentrationColumn ||
      e.field === PreparationConstants.preparationStorageConditionColumn ||
      e.field === PreparationConstants.preparationNameColumn ||
      e.field === PreparationConstants.preparationFormulaComponentsColumn) {
      this.updatePreparation(e);
    }
    this.grid.gridApi.refreshCells({ force: true });
  }

  isModifiedIconNeeded(e: BptGridCellValueChangedEvent): boolean {
    if (((e.newValue.state === ValueState.NotApplicable && e.oldValue.state === ValueState.NotApplicable)
      || (e.newValue.state === ValueState.Empty && e.oldValue.state === ValueState.Empty))
      && e.newValue.type ===ValueType.Number) {
      return false;
    }
    return this.isSameValue(e);
  }

  isSameValue(e: BptGridCellValueChangedEvent): boolean {

    if (e.newValue.type === ValueType.Number && e.newValue.state === ValueState.Set
      && (e.newValue.value === e.oldValue.value)
      && e.newValue.unitDetails.id === e.oldValue.unitDetails.id) {
      return false;
    }
    return true;
  }

  updatePreparation(e: BptGridCellValueChangedEvent) {
    if (isEqual(e.newValue, e.oldValue)) return;
    let command: PreparationCellChangeCommand | undefined;
    if(e.oldValue) {
      ChangeReasonService.oldValue = e.oldValue;
    }
    switch (e.field) {
      case PreparationConstants.preparationConcentrationColumn:
        command = this.buildPreparationCommand(e, PreparationConstants.concentration, ColumnType.Quantity);
        if (command)
          this.preparationEventService.preparationDataFieldChangedEvent(command, e.oldValue);
        this.completionPercent = this.completionTrackHandler({
            rows: this.primitiveValue,
            initialCompletionPercent:  this.completionPercent,
            columnDataFieldIds: this.columnNamesForCompletionTracking,
            tableInfo: this.tableDataForCompletionTracking,
            isTable: false
          }
        );
        break;
      case PreparationConstants.preparationStorageConditionColumn:
        command = this.buildPreparationCommand(e, PreparationConstants.storageCondition, ColumnType.String);
        if (command)
          this.preparationEventService.preparationDataFieldChangedEvent(command, e.oldValue);
        break;
      case PreparationConstants.preparationNameColumn:
        command = this.buildPreparationCommand(e, PreparationConstants.PreparationName, ColumnType.String);
        if (command)
          this.preparationEventService.preparationDataFieldChangedEvent(command, e.oldValue);
        break;
      case PreparationConstants.preparationFormulaComponentsColumn:
        command = this.buildPreparationCommand(e, PreparationConstants.formulaComponents, ColumnType.String);
        if (command)
          this.preparationEventService.preparationDataFieldChangedEvent(command, e.oldValue);
        this.completionPercent = this.completionTrackHandler({
            rows :this.primitiveValue,
            initialCompletionPercent: this.completionPercent,
            columnDataFieldIds: this.columnNamesForCompletionTracking,
            tableInfo: this.tableDataForCompletionTracking,
            isTable: false
          }
        );
        break;
      default: this.preparationService.buildToastMessage('notification', 'error', 'validationToast', "Invalid Column Name", false);
    }
  }

  buildPreparationCommand(e: BptGridCellValueChangedEvent, propertyName: string, columnType: ColumnType) {
    if (!e.rowId) return undefined;
    const preparationCellChangeCommand: PreparationCellChangeCommand = {
      preparationId: e.rowId,
      propertyName: propertyName,
      propertyValue: this.preparationService.getExperimentDataValue(columnType, e.newValue)
    }
    return preparationCellChangeCommand
  }

  //Grid preference section
  applyGridPreference(preferences: PreparationGridPreferences) {
    if(preferences.gridId === this.preparationsGridId) {
      this.preparationsGridSavedPreferences = {
        enabled: !this.isParentRecipe,
        preferences: this.preparationService.buildGridPreference(preferences.preferences)
      }
      this.grid.savedPreferences = this.preparationsGridSavedPreferences;
      this.applyDefaultGridPreference(this.grid.savedPreferences.preferences);
    }
  }

  private applyDefaultGridPreference(preferences: BptGridPreference[]) {
    const selectedPreference = preferences.find(preference => preference.isDefault);
    if(selectedPreference) {
      this.grid.gridApi.applyColumnState({
        state: selectedPreference.columnState,
        applyOrder: true
      });
    }
    else {
      this.grid.gridApi.resetColumnState();
    }
  }

  saveNewGridPreference($event: PreferenceAdded) {
    this.preparationService.saveNewGridPreference(
      $event,
      this.userService.currentUser.puid,
      this.grid.savedPreferences ?? { enabled: true, preferences: [] },
      this.grid,
      this.preparationsGridId
    );
  }

  deleteGridPreference($event: PreferenceDeleted) {
    this.preparationService.deleteGridPreference($event);
  }

  updateGridPreference($event: PreferenceUpdated) {
    this.preparationService.updateGridPreference($event, this.userService.currentUser.puid, this.preparationsGridId);
  }

  changeDefaultGridPreference($event: PreferenceSelectionChanged) {
    this.preparationService.changeDefaultGridPreference($event, this.userService.currentUser.puid, this.preparationsGridId);
  }

  //Opening and closing logics for confirmations, sliders and dialogs
  loadAuditHistoryDialog(rowId?: string, field?: string) {
    const input: PreparationAuditHistoryInputs = {
      row: rowId,
      experimentId: '',
      field: field,
      columnDefinition: this.columnDefinitions,
      activityId: this.currentActivityId ?? ''
    }
    this.preparationEventService.requestAuditHistory(input);
  }

  openConfirmationSliderToDeleteRow(e: BptGridRowActionClickEvent) {
    this.preparationService.removePreparation(e.params.data.id, e.params.data.preparationNumber);
  }

  openConfirmationToRestoreRow(e: BptGridRowActionClickEvent) {
    this.preparationService.restorePreparation(e.params.data.preparationNumber, e.params.data.preparationNumber);
  }

  loadRemovedRowsDialog() {
    this.dialogService.open(RemovedPreparationsComponent, {
      width: '80%',
      autoZIndex: true,
      closable: true,
      closeOnEscape: true,
      header: $localize`:@@preparationRemovedColumns:Removed Preparation Rows`,
      styleClass: 'eln-removed-preparations-dialog'
    });
  }

  openCreatePreparationSlider() {
    this.showSlider = true;
  }

  closeCreatePreparationSlider(isClosed: boolean) {
    this.showSlider = isClosed;
  }

  openInternalInformationSlider(e: BptGridRowActionClickEvent) {
    this.indexOfCurrentInformationIconClickedOnGrid = e.params.data.hash;
    this.showInternalInfoSlider = true;
    this.preparationDataForInternalInformation = this.preparations.filter(preparation => preparation.preparationId === e.params.data.id)[0];
    if(this.isParentRecipe) {
      this.internalInfoSliderReadonly = this.disableGrid || (e.params.data.source !== NA);
    }
    else {
      this.internalInfoSliderReadonly = this.userService.hasOnlyReviewerRights() || this.disablePreparationActions;
    }
  }

  closeInternalInfoSlider(isClosed: boolean) {
    if (!isClosed) {
      this.showInternalInfoSlider = false;
    }
  }

  private sendExpirationToSlider(event: CellClickedEvent) {
    this.sourceGridData = {
      preparationId: event.data.id,
      expirationDate: event.data.expiration,
      suitableForUse: (event.data.expiration === undefined || event.data.expiration === NA) && !this.isParentRecipe
        ? true : event.data.expiration === NA && this.isParentRecipe ? true : false
    };
    this.showExpirationSlider = true;
    var isPrepImported = this.isParentRecipe && event.data.source !== NA;
    this.preparationEventService.openExpirationSliderSubject.next({ showSlider: true, isReadOnlyRow: isPrepImported });
  }

  closeExpirationSlider(isClosed: any) {
    this.showExpirationSlider = isClosed;
  }

  /**
   * On click of flask icon in row it will open slider and sends container description model
  */
  openContainerDescriptionSlider(e: BptGridRowActionClickEvent) {
    this.indexOfCurrentFlaskIconClickedOnGrid = e.params.data.id;
    this.indexOfCurrentFlaskIconClickedOnGridForUserEditRevert = e.params.data.id;
    this.preparationEventService.openContainerSlider(true, this.isParentRecipe && e.params.data.source !== NA);
    const containerDescriptionData = this.preparations.find(row => row.preparationId === this.indexOfCurrentFlaskIconClickedOnGrid)?.description.value;
    if (containerDescriptionData !== undefined) {
      this.preparationEventService.containerDescriptionModel.next(containerDescriptionData as any);
    }
    else {
      this.preparationEventService.containerDescriptionModel.next({ state: ValueState.NotApplicable, type: ValueType.StringDictionary });
    }
  }

  //Collaborative editing segment.
  private watchCollaborativeEditingSubscriptions() {
    this.subscriptions.push(
      this.preparationEventService.preparationCellLockData.subscribe({
        next: (data: CellLockDetails) => this.applyOrRemoveCellLock(data)
      })
    );
  }

  loadCellLocks() {
    if(this.initialCellLockDetails && this.initialCellLockDetails.length > 0) {
      this.initialCellLockDetails.forEach( cellLock => this.applyOrRemoveCellLock(cellLock));
    }
  }

  private applyOrRemoveCellLock(data: CellLockDetails) {
    data.lockList.forEach((lock) => {
      if (this.preparationsGridId === lock.tableId) {
        if (!this.grid.gridOptions.context?.inputLocks)
          this.grid.gridOptions.context = { inputLocks: {} };

        this.grid.gridOptions.context.inputLocks[lock.rowId + lock.columnName] =
          lock.lockType === LockType.lock;

        const lockOwner = data.collaborator;
        lock.experimentCollaborator = lockOwner ?? lock.experimentCollaborator;
        const cell = document.querySelector(
          `ag-grid-angular [row-id="${lock.rowId}"] [col-id="${lock.columnName}"]`
        );
        if (lock.lockType === LockType.lock) {
          const name = lockOwner?.fullName ?? lock.experimentCollaborator.firstName;
          const borderColor = lockOwner?.backgroundColor ?? 'blue';
          this.renderer.setAttribute(cell, 'title', name);
          this.renderer.setStyle(cell, 'background-color', '#F9F9F9');
          this.renderer.setStyle(cell, 'border', `1px solid ${borderColor}`);
        } else {
          this.renderer.removeStyle(cell, 'border');
          this.renderer.removeStyle(cell, 'background-color');
          this.renderer.removeAttribute(cell, 'title');
        }
      }
    });
  }

  cellLockStatusChanged(lockType: LockType, tableId: string, rowId: string, columnName: string, multiSelectCells = false) {
    this.cellLockStatus.emit({
      lockType,
      tableId,
      rowId,
      columnName,
      multiSelectCells
    });
  }

  cellEditStopped(e: BptGridCellEditEvent) {
    if(this.suppressCellEditStopped) return;
    this.cellLockStatusChanged(LockType.unlock, this.preparationsGridId, e.rowId!, e.field!);
  }

  releaseLockFromStatusCell(rowId: string) {
    this.cellLockStatusChanged(LockType.unlock, this.preparationsGridId, rowId, 'status');
  }

  lockStatusCell(rowId: string) {
    this.cellLockStatusChanged(LockType.lock, this.preparationsGridId, rowId, 'status');
  }

  isStatusCellEditable(params: EditableCallbackParams) {
    const collabEditLock = this.checkCollaborativeEditLock(params);
    const cellLocked = !this.isStatusColumnEditable || collabEditLock;
    return !cellLocked;
  }

  checkCollaborativeEditLock(params: EditableCallbackParams) {
    const rowId = params.node.id ?? '';
    const columnName = params.colDef.field;
    const currentColumnDefinition = this.columnDefinitions.find(
      (data) => data.field === columnName
    );
    if (!(currentColumnDefinition as ColumnSpecification)?.editable) return false;
    return this.experimentNotificationService.inputLocks.some(
      (item) => item.lockType === LockType.lock && (item as CellLock).rowId === rowId
        && (item as CellLock).columnName === columnName);
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
