import { ColumnDefinition, ColumnType } from "bpt-ui-library/bpt-grid";
import { Message, MessageService } from "primeng/api";

export class ScalableRecipeConstants {
  private static readonly Assembly: string = 'ELN.Blazor.Entry' as const;
  private static readonly CalculateScaledValue: string = 'CalculateScaledValue' as const;
  static errorMessageObj: Message = {
    key: 'notification',
    severity: 'error',
    summary: $localize`:@@ScalingFactorCannotBeZero:Scaling factor cannot be zero.`,
  }

  static invalidScalingMessageObj: Message = {
    key: 'notification',
    severity: 'error',
    summary: $localize`:@@invalidScalingFactor:Invalid Scaling Factor.`
  }

  static onCellValueChangedHandler(params: any, messageService?: MessageService): void {
    const newValue = params.newValue;
    const baseValue = params.data.base;
    const scalingFlag = params.data.scalingFlag;

    if (isNaN(newValue) || newValue == 0) {
      messageService?.add(ScalableRecipeConstants.errorMessageObj);
      if (params.node && params.colDef.field) {
        params.node.setDataValue(params.colDef.field, "1");
      }
      return;
    }

    if (!baseValue) {
      return;
    }

    if (this.isScalingValid(scalingFlag, newValue)) {
      const scaledValue: { result: string } = DotNet.invokeMethod(
        ScalableRecipeConstants.Assembly,
        ScalableRecipeConstants.CalculateScaledValue,
        newValue.toString(),
        ScalableRecipeConstants.stripNonNumeric(baseValue)
      );
      params.node.setDataValue('scaled', scaledValue.result);
    } else {
      this.handleInvalidScaling(params, messageService);
    }
  }

  private static stripNonNumeric(value: string): string {
    return value.replace(/[^\d.-]/g, '');
  }

  static isScalingValid(scalingFlag: string, newValue: any): boolean {
    const scalingUp = scalingFlag === 'Scaling up';
    const scalingDown = scalingFlag === 'Scaling down';
    const scalingBoth = scalingFlag === 'Scaling down, Scaling up';

    return (
      (scalingUp && newValue > 1) ||
      (scalingDown && newValue < 1) ||
      (scalingBoth) ||
      (parseFloat(newValue) === 1)
    );
  }

  static handleInvalidScaling(params: any, messageService?: MessageService): void {
    if (params.node && params.colDef.field && params.newValue !== '1') {
      messageService?.add(ScalableRecipeConstants.invalidScalingMessageObj);
      params.node.setDataValue(params.colDef.field, "1");
    }
  }

  static getColumnDefinitions(messageService?: MessageService): { [key: string]: ColumnDefinition[] } {
    return {
      'specification': [
        {
          columnType: ColumnType.autoIncrement,
          field: "index",
          label: "Index",
          width: 90,
          minWidth: 60
        },
        {
          columnType: ColumnType.string,
          field: "tableName",
          label: "Table Name",
          width: "auto",
          editable: false,
        },
        {
          columnType: ColumnType.number,
          field: "factor",
          label: "Factor",
          editable: true,
          allowDecimal: true,
          allowNegative: false,
          onCellValueChanged: (params) => this.onCellValueChangedHandler(params, messageService)
        }
      ] as ColumnDefinition[],
      'specificationForForm': [
        {
          columnType: ColumnType.autoIncrement,
          field: "index",
          label: "Index",
          width: 90,
          minWidth: 60,
        },
        {
          columnType: ColumnType.string,
          field: "formName",
          label: "Form Name",
          width: "auto",
          editable: false,
        },
        {
          columnType: ColumnType.number,
          field: "factor",
          label: "Factor",
          editable: true,
          allowDecimal: true,
          allowNegative: false,
          onCellValueChanged: (params) => this.onCellValueChangedHandler(params, messageService)
        }
      ] as ColumnDefinition[],
      'preparation': [
        {
          columnType: ColumnType.autoIncrement,
          field: "index",
          label: "Index",
          width: 90,
          minWidth: 60
        },
        {
          columnType: ColumnType.rowId,
          field: "rowId",
          label: "Row id",
          editable: false,
          hidden: true,
          width: "auto"
        },
        {
          columnType:ColumnType.string,
          field: "name",
          label: "Name",
          editable: false,
          width: "auto",
        },
        {
          columnType:ColumnType.string,
          field: "scalingFlag",
          label: "Scaling Flag",
          hidden: true,
          editable: false,
          width: "auto"
        },
        {
          columnType: ColumnType.quantity,
          field: "base",
          label: "Base",
          editable: false,
          filter: true,
          width: 90,
          minWidth: 60
        },
        {
          columnType:ColumnType.number,
          field: "factor",
          label: "Factor",
          editable: true,
          width: 90,
          minWidth: 60,
          allowDecimal: true,
          allowNegative: false,
          onCellValueChanged: (params) => this.onCellValueChangedHandler(params, messageService)
        },
        {
          columnType: ColumnType.number,
          field: "scaled",
          label: "Scaled",
          editable: false,
          width: 90,
          minWidth: 60,
          allowDecimal: true,
          allowNegative: false
        },
      ] as ColumnDefinition[]
    }
  };
}
