import { Injectable } from '@angular/core';
import { User } from 'model/user.interface';
import { ConstantUserData } from '../integrations/data/user/constant-user-data';
import { ELimsUserData } from '../integrations/data/user/elims-user-data';
import { UserData } from '../integrations/data/user/user-data.interface';
import { User as UserList } from '../api/models';
import { ZoneId } from '@js-joda/core';
import { FeatureService } from './feature.service';
import { ELNFeatureFlags } from '../shared/eln-feature-flags';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: User;
  private static userData: UserData;

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  usersWithReviewerRoles: Array<UserList> = [];
  usersWithSupervisorRoles: Array<UserList> = [];
  usersWithRecipeDesignerRoles: Array<UserList> = [];
  usersWithAnalystRoles: Array<UserList> = [];

  /**
   * UserService.CurrentLabSiteTimeZone depends on eLims UserTopBar API. It is expected that the UserTopBar is complete before ELN is loaded.
   */

  private static _currentLabSiteTimeZone: ZoneId;
  static get currentLabSiteTimeZone(): ZoneId
  {
    if (!UserService._currentLabSiteTimeZone) UserService._currentLabSiteTimeZone = UserService.getUserLabSiteTimeZone();
    return UserService._currentLabSiteTimeZone;
  }
  /**
   * Master is the global namespace in Helium which holds data like CurrentUser and ClientStatePermission
   * It is accessible through window object when ELN runs inside Helium
   */
  master = (window as any).Master;

  /**
   * It is accessible through window object when ELN runs inside Helium.
   * Can be used for LabSiteTimeZone and other Helium data
   */
  app = (window as any).app;

  get currentUser(): User {
    if (!this.user.displayValue) {
      this.user = UserService.userData.getCurrentUser();
    }
    return this.user;
  }

  set currentUser(user: User) {
    if (user) {
      this.user = user;
    }
  }

  constructor() {
    this.initializeUser();
    this.user = UserService.userData.getCurrentUser();
  }

  public initializeUser(): void {
    if (this.master) {
      UserService.userData = new ELimsUserData(this.master, this.app);
    } else {
      UserService.userData = new ConstantUserData();
    }
    this.user = UserService.userData.getCurrentUser();
  }

  /** Returns "[First Name] [Last Name] ([PUID])" */
  static getFullNameAndPuid(user: User): string {
    return `${user.firstName} ${user.lastName} (${user.puid})`;
  }

  /**
   * Retrieves Auth token from Helium using Helium's OAuthToken provider.
   * In Dev mode Authtoken is retrieved from jsonData in 'assets/eln-assets/Data' file.
   * Devs need to retrieve authtoken using clientCredentials and update the Data.json.
   */
  getOauthToken(url: string): Promise<string> {
    return UserService.userData.getOauthToken(url);
  }

  setUsersListBasedOnRoles(roles: string | undefined, userList: UserList[]) {
    switch (roles) {
      case "ELN Analyst":
        this.usersWithAnalystRoles = userList;
        break;
      case "ELN Reviewer":
        this.usersWithReviewerRoles = userList
        break;
      case "ELN Supervisor":
        this.usersWithSupervisorRoles = userList;
        break;
      case "ELN Recipe Designer":
        this.usersWithRecipeDesignerRoles = userList;
        break;
    }
  }

  /**
   * This takes the roles as mapped from the DB
   */

  private hasRole(userRoleList: UserList[], puid: string): boolean {
    const puidSet = new Set(userRoleList.map(item => item.puid));
    return puidSet.has(puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasAnalystRights(puid: string): boolean {
    return this.hasRole(this.usersWithAnalystRoles, puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasReviewerRights(puid: string): boolean {
    return this.hasRole(this.usersWithReviewerRoles, puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasSupervisorRights(puid: string): boolean {
    return this.hasRole(this.usersWithSupervisorRoles, puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasOnlyReviewerRights() {
    return this.hasReviewerRights(this.user.puid) && !this.hasAnalystRights(this.user.puid) && !this.hasSupervisorRights(this.user.puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasOnlySupervisorRights() {
    return this.hasSupervisorRights(this.user.puid) && !this.hasAnalystRights(this.user.puid) && !this.hasReviewerRights(this.user.puid);
  }

  /**
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasOnlyAnalystRights() {
    return this.hasAnalystRights(this.user.puid) && !this.hasReviewerRights(this.user.puid) && !this.hasSupervisorRights(this.user.puid);
  }

  /**
   * This method checks for all the permissions assigned to a Supervisor Role or Reviewer role.
   * Permissions should be used instead to control access, user lists are for selection purposes only (i.e dropdowns)
   */
  hasSupervisorOrReviewerRights() {
    return this.hasSupervisorRights(this.user.puid) || this.hasReviewerRights(this.user.puid);
  }

  /**
   * The method check if the the current user has an Analyst role or Reviewer or Supervisor role.
   */
  hasPreparationStatusEditRights() {
    return this.hasAnalystRights(this.user.puid) || this.hasReviewerRights(this.user.puid) || this.hasSupervisorRights(this.user.puid)
  }

  public userRoleDetails: UserRoleDetails = {
    isAnalyst: false,
    isReviewer: false,
    isSupervisor: false
  }

  currentUserRoleDetails() {
    this.userRoleDetails.isAnalyst = this.hasAnalystRights(this.user.puid);
    this.userRoleDetails.isReviewer = this.hasReviewerRights(this.user.puid);
    this.userRoleDetails.isSupervisor = this.hasSupervisorRights(this.user.puid);
    return this.userRoleDetails;
  }

  /**
   * Creates an IANA time zone representing a eLims lab site time zone when the FeatureFlag is ON and the eLims time zone is set, returns default system zone otherwise.
   * Eg., of eLims timezone `US__Eastern`
   * @returns IANA zone representation of eLims lab site time zone.
   */
  private static getUserLabSiteTimeZone() : ZoneId
  {
    let ianaTimeZone;
    const isLabSiteTimezoneEnabled = FeatureService.featureService?.isEnabled(ELNFeatureFlags.UseLabSiteTimeZone);
    if (isLabSiteTimezoneEnabled){
      const eLimsTimeZone = UserService.userData.getUserLabSiteTimeZone();
      ianaTimeZone = eLimsTimeZone?.replace('__', '/');
    }
    return ianaTimeZone ? ZoneId.of(ianaTimeZone) : ZoneId.SYSTEM;
  }
}

export class UserRoleDetails{
  public isAnalyst  = false;
  public isReviewer  = false;
  public isSupervisor = false;
}
